<div class="popup-header header-logo-center loginheadercolor">
  <div class="header-title textcolor noback">Select option</div>
</div>
<div class="page-content pb-0">
  <ul class="filter-popup">
    <span (click)="inquiryOption(1)" *ngIf="isStatus">
      <li class="col-12">Assessment Pending</li>
    </span>
    <span (click)="inquiryOption(2)" *ngIf="isStatus">
      <li class="col-12">Assessment Done</li>
    </span>
    <span (click)="inquiryOption(3)">
      <li class="col-12">Success</li>
    </span>
    <span (click)="inquiryOption(4)">
      <li class="col-12">Reject</li>
    </span>
    <span (click)="inquiryOption(6)">
      <li class="col-12">Prospective</li>
    </span>
    <li (click)="close()" class="col-12 text-center red"><img width="16" height="16" src="../../../assets/images/KridApp-Image/close.png" alt=""></li>
  </ul>
</div>