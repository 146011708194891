import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { ToastrModule } from 'ngx-toastr';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [],
  imports: [
    Ng2GoogleChartsModule,
    CommonModule,
    DashboardRoutingModule,
    MatDialogModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class DashboardModule { }
