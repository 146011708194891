import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { LoginComponent } from './login/login.component';
import { SelectAcademyComponent } from './select-academy/select-academy.component';
import { SignupComponent } from './signup/signup.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
//import { StripeComponent } from './stripe/stripe.component';
import { UsermessagesComponent } from './usermessages/usermessages.component';

const routes: Routes = [
  // {
  //   path:'',
  //   component:LoginComponent,
  // },
  // {
  //   path:'signup',
  //   component:SignupComponent
  // },

  {
    path: '',
    component: SignupComponent,
  },

  {
    path: 'login',
    component: LoginComponent,
  },


  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'selectacademy',
    component: SelectAcademyComponent
  },
  {
    path: 'usermessages',
    component: UsermessagesComponent
  },
  {
    path: 'feedback',
    component: FeedbackComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  },
  // {
  //   path:'mystrip',
  //   component:StripeComponent
  // },
  {
    path: 'message',
    loadChildren: () => import('./message/message.module').then(m => m.MessageModule)
  },
  {
    path: 'leaveapplication',
    loadChildren: () => import('./leaveapplication/leaveapplication.module').then(m => m.leaveapplicationModule)
  },
  {
    path: 'studentmodule',
    loadChildren: () => import('./studentmodule/studentmodule.module').then(m => m.StudentmoduleModule)
  },
  {
    path: 'Training',
    loadChildren: () => import('./training/training.module').then(m => m.TrainingModule)
  },
  {
    path: 'privateattendancestd',
    loadChildren: () => import('./privateattendancestd/privateattendancestd.module').then(m => m.PrivateattendancestdModule)
  },
  {
    path: 'otplogin',
    loadChildren: () => import('./otplogin/otplogin.module').then(m => m.OtploginModule)
  },
  {
    path: 'managefees',
    loadChildren: () => import('./managefees/managefees.module').then(m => m.ManagefeesModule)
  },
  {
    path: 'drills',
    loadChildren: () => import('./drills/drills.module').then(m => m.DrillsModule)
  },
  {
    path: 'attendance',
    loadChildren: () => import('../app/attendance/attendance.module')
      .then(m => m.AttendanceModule),
  },

  {
    path: 'inquiry',
    loadChildren: () => import('../app/inquiry/inquiry.module')
      .then(m => m.InquiryModule),
  },

  {
    path: 'studenteditlist',
    loadChildren: () => import('../app/attendance/studenteditlist/studenteditlist.module')
      .then(m => m.StudenteditlistModule),
  },

  {
    path: 'reasonlist',
    loadChildren: () => import('../app/attendance/reasonlist/reasonlist.module')
      .then(m => m.ReasonlistModule),
  },
  {
    path: 'venue',
    loadChildren: () => import('../app/venue/venue.module')
      .then(m => m.VenueModule),
  },
  {
    path: 'book-venue',
    loadChildren: () => import('../app/venuebooking/venuebooking.module')
      .then(m => m.VenuebookingModule),
  },
  {
    path: 'transfer-batch',
    loadChildren: () => import('../app/studentmodule/transfer-batch/transfer-batch.module')
      .then(m => m.TransferBatchModule),
  },

  { path: 'addstudent', loadChildren: () => import('./studentmodule/addstudent/addstudent.module').then(m => m.AddstudentModule) },
  { path: 'studentlist', loadChildren: () => import('./studentmodule/studentlist/studentlist.module').then(m => m.StudentlistModule) },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), },
  { path: 'inquiry', loadChildren: () => import('../app/inquiry/inquiry.module').then(m => m.InquiryModule), },
  { path: 'userassessment', loadChildren: () => import('../app/userassessment/userassessment.module').then(m => m.UserassessmentModule), },
  { path: 'usermessages', loadChildren: () => import('../app/usermessages/usermessages.module').then(m => m.UsermessagesModule), },
  { path: 'profile', loadChildren: () => import('./studentmodule/profile/profile.module').then(m => m.ProfileModule) },
  { path: 'studentprofile', loadChildren: () => import('./studentmodule/studentprofile/studentprofile.module').then(m => m.StudentprofileModule) },
  { path: 'coachattendance', loadChildren: () => import('./coachattendance/coachattendance.module').then(m => m.CoachattendanceModule) },
  { path: 'managefees', loadChildren: () => import('./managefees/managefees.module').then(m => m.ManagefeesModule) },
  { path: 'notification', loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule) },
  { path: 'coachassessment', loadChildren: () => import('./coachassessment/coachassessment.module').then(m => m.CoachassessmentModule) },
  { path: 'coachlistforassessment', loadChildren: () => import('./coachassessment/coachlistforassessment/coachlistforassessment.module').then(m => m.CoachlistforassessmentModule) },
  { path: 'studentfees', loadChildren: () => import('./studentfees/studentfees.module').then(m => m.StudentfeesModule) },
  { path: 'studentfeeslist', loadChildren: () => import('./studentfees/studentfeeslist/studentfeeslist.module').then(m => m.StudentfeeslistModule) },
  { path: 'payonline', loadChildren: () => import('./studentfees/payonline/payonline.module').then(m => m.PayonlineModule) },
  { path: 'events', loadChildren: () => import('./events/events.module').then(m => m.EventsModule) },
  { path: 'eventlist', loadChildren: () => import('./events/eventlist/eventlist.module').then(m => m.EventlistModule) },
  { path: 'eventdetails', loadChildren: () => import('./events/eventdetails/eventdetails.module').then(m => m.EventdetailsModule) },
  { path: 'inventory', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule) },
  { path: 'requisitionlist', loadChildren: () => import('./inventory/requisitionlist/requisitionlist.module').then(m => m.RequisitionlistModule) },
  { path: 'createrequisition', loadChildren: () => import('./inventory/createrequisition/createrequisition.module').then(m => m.CreaterequisitionModule) },
  { path: 'reportdamages', loadChildren: () => import('./inventory/reportdamages/reportdamages.module').then(m => m.ReportdamagesModule) },
  { path: 'createreportdamages', loadChildren: () => import('./inventory/createreportdamages/createreportdamages.module').then(m => m.CreatereportdamagesModule) },
  { path: 'inventoryreport', loadChildren: () => import('./inventory/inventoryreport/inventoryreport.module').then(m => m.InventoryreportModule) },
  { path: 'useranalytics', loadChildren: () => import('./studentmodule/useranalytics/useranalytics.module').then(m => m.UseranalyticsModule) },
  { path: 'renew', loadChildren: () => import('./studentmodule/renew/renew.module').then(m => m.RenewModule) },
  { path: 'renew-parent', loadChildren: () => import('./studentmodule/renew-parent/renew-parent.module').then(m => m.RenewParentModule) },
  { path: 'loginwithotp', loadChildren: () => import('./otplogin/loginwithotp/loginwithotp.module').then(m => m.LoginwithotpModule) },
  { path: 'verificationcode', loadChildren: () => import('./otplogin/verificationcode/verificationcode.module').then(m => m.VerificationcodeModule) },
  { path: 'popupassessmentlist', loadChildren: () => import('./studentmodule/popupassessmentlist/popupassessmentlist.module').then(m => m.PopupassessmentlistModule) },
  { path: 'batchplanning', loadChildren: () => import('./batch-planning/batch-planning.module').then(m => m.BatchPlanningModule) },
  { path: 'venuebooking', loadChildren: () => import('./venue/venuebooking/venuebooking.module').then(m => m.VenuebookingModule) },
  { path: 'createvenue', loadChildren: () => import('./venue/createvenue/createvenue.module').then(m => m.CreatevenueModule) },
  { path: 'clock', loadChildren: () => import('./punchin-out/punchin-out.module').then(m => m.PunchinOutModule) },
  { path: 'forgetpassword', loadChildren: () => import('./forget-password/forget-password.module').then(m => m.ForgetPasswordModule) },
  { path: 'privateattendancestd', loadChildren: () => import('./privateattendancestd/privateattendancestd.module').then(m => m.PrivateattendancestdModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AppRoutingModule { }
