<div class="popup-header header-logo-center loginheadercolor">
  <div class="header-title textcolor noback">Select option</div>
</div>
<div class="page-content pb-0">
  <ul class="filter-popup">
    <ng-container *ngIf="Attendance && SlotName == null && SlotName1 == null && SlotName2 == null">
      <span (click)="clickOption(1)">
        <li class="col-12">Attendance</li>
      </span>
    </ng-container>
    <ng-container *ngIf="SlotName != ''">
      <span (click)="openslotattendance(SlotName)">
        <li class="col-12">Attendance {{SlotName}}</li>
      </span>
    </ng-container>
    <ng-container *ngIf="SlotName1 != ''">
      <span (click)="openslotattendance(SlotName1)">
        <li class="col-12">Attendance {{SlotName1}}</li>
      </span>
    </ng-container>
    <ng-container *ngIf="SlotName2 != ''">
      <span (click)="openslotattendance(SlotName2)">
        <li class="col-12">Attendance {{SlotName2}}</li>
      </span>
    </ng-container>

    <ng-container *ngIf="!Attendance">
      <span (click)="clickOption(1)" class="not-active">
        <li class="col-12">Attendance</li>
      </span>
    </ng-container>

    <ng-container *ngIf="Student">
      <span (click)="clickOption(2)">
        <li class="col-12">Player</li>
      </span>
    </ng-container>

    <ng-container *ngIf="!Student">
      <span (click)="clickOption(2)" class="not-active">
        <li class="col-12">Player</li>
      </span>
    </ng-container>

    <ng-container *ngIf="CoachAttendance">
      <span (click)="clickOption(3)">
        <li class="col-12">Coach Attendance</li>
      </span>
    </ng-container>

    <ng-container *ngIf="!CoachAttendance">
      <span (click)="clickOption(3)" class="not-active">
        <li class="col-12">Coach Attendance</li>
      </span>
    </ng-container>

    <ng-container *ngIf="Fees">
      <span (click)="clickOption(6)">
        <li class="col-12">Fees</li>
      </span>
    </ng-container>

    <ng-container *ngIf="!Fees">
      <span (click)="clickOption(6)" class="not-active">
        <li class="col-12">Fees</li>
      </span>
    </ng-container>
    <ng-container *ngIf="Drills">
      <span (click)="clickOption(4)">
        <li class="col-12">Drills</li>
      </span>
    </ng-container>

    <ng-container *ngIf="!Drills">
      <span (click)="clickOption(4)" class="not-active">
        <li class="col-12">Drills</li>
      </span>
    </ng-container>

    <ng-container *ngIf="Inquiry">
      <span (click)="clickOption(5)">
        <li class="col-12">Inquiry</li>
      </span>
    </ng-container>

    <ng-container *ngIf="!Inquiry">
      <span (click)="clickOption(5)" class="not-active">
        <li class="col-12">Inquiry</li>
      </span>
    </ng-container>

    <li (click)="close()" class="col-12 text-center red"><img width="16" height="16"
        src="../../../assets/images/KridApp-Image/close.png" alt=""></li>
  </ul>
</div>