import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InquiryRoutingModule } from './inquiry-routing.module';
import { CreateinquiryComponent } from './createinquiry/createinquiry.component';
import { NgbModule,NgbNavModule, } from '@ng-bootstrap/ng-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import { PopupfilterComponent } from './popupfilter/popupfilter.component';
import { SortfilterComponent } from './sortfilter/sortfilter.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { DotpopupComponent } from './dotpopup/dotpopup.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RejectreasonComponent } from './rejectreason/rejectreason.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { InquiryComponent } from './inquiry.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { InquiryProfileComponent } from './inquiry-profile/inquiry-profile.component';
@NgModule({
  declarations: [CreateinquiryComponent, PopupfilterComponent, SortfilterComponent, DotpopupComponent, RejectreasonComponent, InquiryProfileComponent],
  imports: [
    MatTabsModule,
    TabsModule,
    NgbNavModule,
    NgbModule,
    CommonModule,
    InquiryRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    Ng2SearchPipeModule,
    FlatpickrModule.forRoot()
  ]
})
export class InquiryModule { }