export const environment = {
  // appUrl: "https://localhost:7077/",
  //appUrl: "http://192.168.43.93:57069/api/",

  //Kridapp Core deployment changes
  // appUrl: "https://apibeta.kridapp.com/",
  appUrl: "https://api.kridapp.com/",
  webUrl: 'https://beta.kridapp.com/',

  //ECPA Deployment changes
  //appUrl: "https://ecrmapi.elitecricketpa.com/api/",
  //webUrl: 'https://ecrm.elitecricketpa.com/',

  razorpay_Key: 'rzp_live_xCBoMkuS2yDZ73',
  // razorpay_Key : 'rzp_test_OggS95fRfPALAK', /*Nirav Account*/
  //  appUrl:"http://api.kridapp.com/api/",
  production: false,
};
