<div id="page" class="graybg">
    <!-- Header -->
    <div class="header header-fixed header-logo-center">
        <span (click)="closemodel()" class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <h2 class="header-title textcolor">{{UserName}}</h2>
        <span (click)="closemodel()" class="header-icon header-icon-4 textcolor right15">Close</span>
        <!-- <span (click)="onclick()" data-menu="menu-sidebar-left-1"
            class="header-icon header-icon-4 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
        </span> -->
    </div>
    <!-- Header -->


    <!-- Main Content -->
    <div class="page-content header-clear-medium messages graybg" (click)="onremoveclick()">
        <div class="content" *ngFor="let item of UserAllMessageList">
            <ng-container *ngIf="item.MyMessage==false">
                <div class="speech-bubble speech-left bg-highlight">
                    <p class="nametime">{{item.ToName}}</p>
                    <p>{{item.Message}}</p>
                    <p class="nametime">{{item.DateSent| date:'shortTime'}}</p>
                </div>
            </ng-container>
            <div class="clearfix"></div>
            <ng-container *ngIf="item.MyMessage==true">
                <div class="speech-bubble speech-right color-black">
                    <p class="nametime">{{item.ToName}}</p>
                    <p>{{item.Message}}</p>
                    <p class="nametime">{{item.DateSent| date:'shortTime'}}</p>
                </div>
            </ng-container>
            <div class="clearfix"></div>
        </div>
        

    <!-- Footer (ngSubmit)=-->
    <form [formGroup]="ChatFormGroup">
        <div id="footer-bar" class="d-flex pl-2">
            <div class="flex-fill speach-input">
                <input type="text" class="form-control" formControlName="textmessage"
                    placeholder="Enter your Message here">
            </div>
            <div class="ml-3 speach-icon">
                <a (click)="MessagesSubmit(false)" class="bg-blue2-dark align-items-center d-flex justify-content-center">
                    <img width="18" height="18" src="../../../assets/images/icons/arrow-right.png" alt="" />
                </a>
            </div>
        </div>
    </form>
    <!-- Footer -->
    </div>
    <!-- Main Content -->
</div>