<div class="popup-header header-logo-center loginheadercolor">
    <div class="header-title textcolor noback">Select option</div>
  </div>
  <div class="page-content pb-0">
    <ul class="filter-popup">
       <span (click)="openSortFilter()"> <li class="col-12">Sort</li></span>
       <span (click)="openPopupFilter()"> <li class="col-12">Filter</li></span>
        <li class="col-12 text-center red"><img width="16" height="16" src="../../../assets/images/KridApp-Image/close.png" alt=""></li>
    </ul>
  </div>
