import { Component, HostListener, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LoginwithotpComponent } from '../otplogin/loginwithotp/loginwithotp.component';
import { environment } from "./../../environments/environment";
import { Router, ActivatedRoute } from '@angular/router';
import { CommonFunction } from '../service/commonfunction';
import { Platform } from '@angular/cdk/platform';
import { OneSignalService } from 'ngx-onesignal';
import $ from 'jquery'
import { Capacitor } from '@capacitor/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GetListService } from 'src/app/service/getlist.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  isIOS: any = false;
  isAndroid: any = false;
  deferredPrompt: any;
  showButton = false;
  isFooter = '1';
  logo;
  academyData: any;
  Userdata: any;
  ioshtml: any = "";
  androidhtml: any = "";
  webURL: string = environment.webUrl;
  tagline: any = "One Team One Dream";
  IsReseller: boolean;
  isloginwithOTP = true;
  IsPlayAndPlay = false;
  isNativePlatform:boolean;
  VenueList;
  VenueList1;
  VenueList2;
  VenueListcount;
  academyName;
  IsBooking;
  constructor( public getListService: GetListService,public dialog: MatDialog, public route: ActivatedRoute,
    public router: Router, public communFunction: CommonFunction, private platform: Platform, public readonly onesignal: OneSignalService,    private spinner: NgxSpinnerService,
    ) { }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
    let displayMode = 'browser';
    const mqStandAlone = '(display-mode: standalone)';
  }
  
  ngOnInit(): void { 
    (window as any).ngxOnesignal = this.onesignal;
    this.onesignal.subscribe();
    this.isNativePlatform = Capacitor.isNativePlatform();
    this.Userdata = this.communFunction.GetUserData();
    this.getListService
        .GetVenueByAcademyId(481,0)
        .subscribe(
          (response: any) => {
            this.VenueList = response;
            this.VenueListcount = this.VenueList.length;
            this.VenueList1 = this.VenueList.slice(0, 2);
            this.VenueList2 = this.VenueList.slice(2, 4);
          },
          (error) => {
          }
        );
    setTimeout(() => {
      // console.log(this.Userdata);
    
      if (this.Userdata != null) {
        if (this.Userdata.AuthKey != '') {
          this.router.navigate(['/selectacademy'])
            .then(() => {
              window.location.reload();
            });
        }
      }
      else {
        this.academyData = JSON.parse(localStorage.getItem("academyData"));
        // console.log(this.academyData);
        this.academyName = this.academyData.Name;
        this.IsReseller = this.academyData.IsReseller;
        this.IsBooking = this.academyData.IsBooking;
        if (this.academyData != null || this.academyData != undefined) {
          this.tagline = this.academyData.TagLine
          if (this.academyData.Id == 41) {
            this.logo = this.webURL + this.academyData.AcademyDarkLogoImage;
          }
          else {
            this.logo = this.webURL + this.academyData.AcademyLightLogoImage;
          }
          if (this.academyData.Id == 391) {
            this.androidhtml = '<img class="mr-1 w-img-24 h-img-24"  src="' + this.logo + '"  alt="">Add RSA to Home Screen';
            this.ioshtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">To install this web app on your device tap the Menu button and then "Add to Home Screen" button';
          }
          else if (this.academyData.Id == 1) {
            this.androidhtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">Add Kridapp to Home Screen';
            this.ioshtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">To install this web app on your device tap the Menu button and then "Add to Home Screen" button';
          }
          else if (this.academyData.Id == 41) {
            this.androidhtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">Add Neon to Home Screen';
            this.ioshtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">To install this web app on your device tap the Menu button and then "Add to Home Screen" button';
          }
          else if (this.academyData.Id == 43) {
            this.androidhtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">Add ASA to Home Screen';
            this.ioshtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">To install this web app on your device tap the Menu button and then "Add to Home Screen" button';
          }
          else if (this.academyData.Id == 399) {
            this.isloginwithOTP = false
            this.androidhtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">Add ECPA to Home Screen';
            this.ioshtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">To install this web app on your device tap the Menu button and then "Add to Home Screen" button';
            this.router.navigate(['/login']);
          }
          else if (this.academyData.Id == 405) {
            this.androidhtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">Add SSFC to Home Screen';
            this.ioshtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">To install this web app on your device tap the Menu button and then "Add to Home Screen" button';
          }
          else if (this.academyData.Id == 337) {
            this.isloginwithOTP = true
            this.androidhtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">Add Sporton to Home Screen';
            this.ioshtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">To install this web app on your device tap the Menu button and then "Add to Home Screen" button';
            // this.router.navigate(['/login']);
          }
          else if (this.academyData.Id == 481) {
            this.isloginwithOTP = false;
            this.IsPlayAndPlay = true;
            this.androidhtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">Add Seven Star to Home Screen';
            this.ioshtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">To install this web app on your device tap the Menu button and then "Add to Home Screen" button';
            // this.router.navigate(['/book-venue']);
          }
          else if (this.academyData.Id == 409) {
            this.androidhtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">Add Shimla FC to Home Screen';
            this.ioshtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">To install this web app on your device tap the Menu button and then "Add to Home Screen" button';
          }
          else if (this.academyData.Id == 410) {
            this.androidhtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">Add  South United Academy to Home Screen';
            this.ioshtml = '<img class="mr-1 w-img-24 h-img-24" src="' + this.logo + '"  alt="">To install this web app on your device tap the Menu button and then "Add to Home Screen" button';
          }
        }

        this.initPwaPrompt();
        var isFooter = localStorage.getItem('footer');
        if (window.matchMedia('(display-mode: standalone)').matches) {
          this.isFooter = '0';
        }
        else {
          this.isFooter = '1';
        }
      }
    }, 10);
    
  }

  openOTPpopup() {
    const dialogRef = this.dialog.open(LoginwithotpComponent, {
      width: '100%',
      height: '100%',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  
  openVenueBooking() {
    this.router.navigate(['/book-venue']);
  }
  BookingScreen(item){
    this.router.navigate(['/book-venue/selectdatetime'],
    { state: { example: item } });    
  }
  public initPwaPrompt() {
    if (this.platform.ANDROID || this.platform.isBrowser || this.platform.FIREFOX || this.platform.EDGE || this.platform.BLINK) {
      this.isAndroid = true;
      this.isIOS = false
    }
    if (this.platform.IOS || this.platform.SAFARI) {
      this.isAndroid = false;
      this.isIOS = true;
    }
  }
  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          this.close();
        } else {
        }
        this.deferredPrompt = null;

      });
  }
  close() {
    this.isFooter = '0';
    localStorage.setItem('footer', '0');
  }
  openLogin() {
    this.router.navigate(['/login']);
  }
}
