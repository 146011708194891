import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonFunction } from '../service/commonfunction';
import { GetListService } from '../service/getlist.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
@Component({
  selector: 'app-batch-planning',
  templateUrl: './batch-planning.component.html',
  styleUrls: ['./batch-planning.component.scss']
})
export class BatchPlanningComponent implements OnInit {
  submitted = false;
  BatchPlanningForm: UntypedFormGroup;
  BatchesList: any = [];
  ScheduleList: any = [];
  SlotList: any = [];
  BatchId: any;
  SlotId: any;
  dayOfWeek: 11;
  HideShowSchedule: boolean = false;
  HideShowSlot: boolean = false;
  constructor(
    public router: Router,
    public getListService: GetListService,
    public commonFunction: CommonFunction,
    public formBuilder: UntypedFormBuilder,
  ) { }

  initForm() {
    this.BatchPlanningForm = this.formBuilder.group({
      BatchId: new UntypedFormControl('', Validators.required),
      SlotId: new UntypedFormControl('', Validators.required),
      ScheduleId: new UntypedFormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.initForm();
    this.GetBatches();
    this.onremoveclick();
    if (window.history.state != undefined) {
      if (window.history.state.parentbatchId != undefined) {
        this.GetSchedule(window.history.state.parentbatchId)
      }
      if (window.history.state.batchId != undefined) {
        this.BatchId = window.history.state.batchId;
        setTimeout(() => {
          this.GetSlot(window.history.state.batchId);
        }, 500);
      }
      if (window.history.state.parentbatchId != undefined && window.history.state.batchId != undefined) {
        this.BatchPlanningForm.setValue({
          "BatchId": window.history.state.parentbatchId,
          "ScheduleId": window.history.state.batchId,
          "SlotId": window.history.state.slotId,
        });
      }

    }
  }

  get f() {
    return this.BatchPlanningForm.controls;
  }

  onremoveclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.remove('menu-active');
  }
  back() {
    this.router.navigate(['/dashboard'])
  }

  GetBatches() {
    var GetUserData = this.commonFunction.GetUserData();
    // console.log(GetUserData);
    
    this.getListService
      .GetBatchesforBatchPlanning(GetUserData.AcademyId, GetUserData.ParentAcademyId, GetUserData.UserId, GetUserData.isAccessAllBatches)
      .subscribe(
        (response: any) => {
          this.BatchesList = response;
        },
        (error) => {
        }
      );
  }

  BatchChangeEvent(event) {
    this.HideShowSchedule = false;
    if (event.target.value == "0") {
      this.HideShowSchedule = false;
    }
    this.GetSchedule(event.target.value);
  }
  GetSchedule(batchId) {
    this.getListService
      .GetScheduleforBatchPlanning(batchId)
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response.length > 0) {
            this.HideShowSchedule = true;
            this.ScheduleList = response;
            this.GetBatchSlotTime();

          }
          else {
            this.GetBatchSlotTime();
          }

        },
        (error) => {
        }
      );
  }

  GetBatchSlotTime() {
    this.SlotList = [];
    this.HideShowSlot = false;

    if (this.HideShowSchedule == false) {
      var _BatchId = (document.getElementById("BatchId")) as HTMLSelectElement;
      this.BatchId = parseInt(_BatchId.options[_BatchId.selectedIndex].value);
    }
    if (this.BatchId != undefined) {
      this.GetSlot(this.BatchId);
    }
  }
  GetSlot(batchId) {
    var curruntdate = new Date();
    var dayOfWeek = curruntdate.getDay();
    this.getListService
      .GetBatchSlotTime(batchId, dayOfWeek)
      .subscribe(
        (response: any) => {
          if (response.length > 0) {
            this.HideShowSlot = true;
            for (let i = 0; i < response.length; i++) {
              this.SlotList.push({
                Id: response[i],
                Name: response[i]
              })
            }
          }
        },
        (error) => {
        }
      );
  }

  ScheduleChangeEvent(event) {
    this.BatchId = event.target.value;
    this.GetBatchSlotTime();
  }

  SlotChangeEvent(event) {
    this.SlotId = event.target.value;
  }

  Next() {
    this.submitted = true;
    let pid = this.BatchPlanningForm.value.BatchId;
    if (this.HideShowSchedule == true && this.HideShowSlot == true) {
      if (this.BatchPlanningForm.invalid) {
        return;
      }
      else {
        var _SlotId = (document.getElementById("slotId")) as HTMLSelectElement;
        if (_SlotId != undefined) {
          this.SlotId = _SlotId.options[_SlotId.selectedIndex].value;
        }
        else {
          this.SlotId = "";
        }


        this.router.navigate(['batch-planning-dates'], {
          state: {
            parentbatchid: pid,
            batchId: this.BatchId,
            slotId: this.SlotId
          }
        });
      }
    }

    else if (this.HideShowSchedule == true) {
      if (this.HideShowSlot == true) {
        if (this.BatchPlanningForm.invalid) {
          return;
        }
      }
      else {
        var _SlotId = (document.getElementById("slotId")) as HTMLSelectElement;
        if (_SlotId != undefined) {
          this.SlotId = _SlotId.options[_SlotId.selectedIndex].value;
        }
        else {
          this.SlotId = "";
        }
        this.router.navigate(['batch-planning-dates'], {
          state: {
            parentbatchid: pid,
            batchId: this.BatchId,
            slotId: this.SlotId
          }
        });
      }
    }

    else if (this.HideShowSlot == true) {
      if (this.BatchPlanningForm.invalid) {
        return;
      }
    }

    else if (this.BatchPlanningForm.invalid) {
      return;
    }

    else {
      var _SlotId = (document.getElementById("slotId")) as HTMLSelectElement;
      if (_SlotId != undefined) {
        this.SlotId = _SlotId.options[_SlotId.selectedIndex].value;
      }
      else {
        this.SlotId = "";
      }

      this.router.navigate(['batch-planning-dates'], {
        state: {
          batchId: this.BatchId,
          slotId: this.SlotId
        }
      });
    }
  }

}
