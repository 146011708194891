<div class="header header-fixed header-logo-center" id="popup2">
    <span (click)="closemodel()" class="header-icon header-icon-1 textcolor">
        <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
    </span>
    <h2 class="header-title textcolor">Players</h2>
    <span class="header-icon header-icon-4 textcolor right15" (click)="opencommonfeedbackModal()">Next</span>
</div>
<div class="page-content header-clear-medium popup-mainarea">
    <div class="content multicheckbox p-0 border-0">
        <div class="list-group list-custom demo-color border-0">
            <a class="d-flex align-items-center">
                <input type="checkbox" id="selectAllCheck" checked
                    class="checkboxsize studentcheckboxList studentcheckboxcount" (click)="CheckOrUnCheck($event);" />
                <span class="checkboxtextcss badge">Select All</span>
                <span class="badge bg-red2 font-14 count">{{studentCount}}</span>
            </a>
        </div>
    </div>
    <div class="content multicheckbox d-block">
        <div class="list-group demo-color" *ngFor="let item of GetStudentList; index as i">
            <label class="mb-0 d-flex align-items-center">
                <ng-container *ngIf="item.LeaveTypeId==0">
                    <input type="checkbox" checked class="checkboxsize studentcheckboxList studentcheckboxcount"
                        (click)="ChangeIcon(item.UserId,$event);" id="stuhchk_{{item.UserId}}" />
                </ng-container>

                <ng-container *ngIf="item.LeaveTypeId>0">
                    <input type="checkbox" class="checkboxsize studentcheckboxList studentcheckboxcount"
                        (click)="ChangeIcon(item.UserId,$event);" id="stuhchk_{{item.UserId}}" />
                </ng-container>
                <span class="checkboxtextcss AllStudentCheckboxvalue" value={{item.UserId}}>{{item.UserName}}</span>
            </label>
            <ng-container *ngIf="item.LeaveTypeId==0">
                <span class="badge bg-red2 font-11 AllIsStudentStatus" id="IsStudentStatus_{{item.UserId}}">
                    <img class="editiconbtn" alt="" src="../../../assets/images/KridApp-Image/editicon.png"
                        (click)="openFeedbackModal(item.UserId,item.Name);">
                </span>

                <span class="badge bg-red2 font-11 AllIsReasonStatus" id="IsReasonStatus_{{item.UserId}}" hidden>
                    <input type="button"
                        class="btn btn-block text-uppercase rounded-sm loginheadercolor textcolor smallview"
                        id="IsReasonButton_{{item.UserId}}" (click)="openresonModal(item.UserId)" value="REASON">
                </span>
            </ng-container>

            <ng-container *ngIf="item.LeaveTypeId>0">
                <span class="badge bg-red2 font-11 AllIsStudentStatus" id="IsStudentStatus_{{item.UserId}}" hidden>
                    <img class="editiconbtn" alt="" src="../../../assets/images/KridApp-Image/editicon.png"
                        (click)="openFeedbackModal(item.UserId);">
                </span>

                <span class="badge bg-red2 font-11 AllIsReasonStatus" id="IsReasonStatus_{{item.UserId}}">
                    <input type="button" class="btn btn-block text-uppercase rounded-sm textcolor smallview"
                        id="IsReasonButton_{{item.UserId}}" (click)="openresonModal(item.UserId)"
                        value="{{item.LeaveShortName}}">
                </span>
            </ng-container>
        </div>
    </div>