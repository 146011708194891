import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonFunction } from 'src/app/service/commonfunction';
import { GetListService } from 'src/app/service/getlist.service';
import { StudentModel, StudentProfileModel } from 'src/app/_models/user.model';

@Component({
  selector: 'app-inquiry-profile',
  templateUrl: './inquiry-profile.component.html',
  styleUrls: ['./inquiry-profile.component.scss']
})
export class InquiryProfileComponent implements OnInit {
  result;
  pipe = new DatePipe('en-US');
  userData;
  profileData;
  isEnrolled = false;
  actionbutton = false;
  isFatherExist = false;
  isMotherExist = false;
  fatherName;
  fatherPhone;
  fatherEmail;
  motherName;
  motherPhone;
  motherEmail;
  TempId;
  isSubscription = false;
  profilephoto = '../../assets/images/KridApp-Image/No-Image-User.png';
  state: any;
  setstudentmodel: StudentModel;
  setStudentProfileModel: StudentProfileModel
  constructor(
    public getlistService: GetListService,
    public commonFunction: CommonFunction,
    private spinner: NgxSpinnerService,
    public route: ActivatedRoute,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.setstudentmodel = window.history.state.example;
    // console.log(this.setstudentmodel);

    if (window.history.state.backInquiry != undefined) {
      if (window.history.state.backInquiry == true) {
        this.actionbutton = false;
        this.isSubscription = false;
      }
    }

    let _academyuserId;
    let _userId;
    let _academySportId;
    let _batchId;
    let _status;
    let _enrolled;
    let _inquiryId;
    let _academyStudentId;

    if (this.setstudentmodel == undefined) {
      this.TempId = window.history.state.val;

    } else {
      this.TempId = this.setstudentmodel.Id;
      _academyuserId = this.TempId; //this.setstudentmodel.Id;
      _userId = this.setstudentmodel.UserId;
      _academySportId = this.setstudentmodel.AcademySportId;
      _batchId = this.setstudentmodel.BatchId;
      _status = this.setstudentmodel.Status;
      _enrolled = this.setstudentmodel.IsDisplayDots;
      _inquiryId = this.setstudentmodel.InquiryId;
      _academyStudentId = this.setstudentmodel.AcademyStudentId;
    }
    this.TempId = (this.TempId != undefined) ? this.TempId : window.history.state.userAcademyId;

    this.GetUserProfileData(this.TempId);
    if (window.history.state.backInquiry == undefined) {
      if (_enrolled) {
        this.actionbutton = false;
        this.isSubscription = false;
      } else {
        this.actionbutton = true;
        this.isSubscription = true;
      }
    }
  }

  Edit(id) {
    if (window.history.state.isInquiryEdit == true) {
      this.router.navigate(['/inquiry/createinquiry'], {
        state: { userAcademyId: id }
      });
    }
    else {
      this.router.navigate(['/studentmodule/add'], {
        state: { userAcademyId: id }
      });
    }
  }

  public GetUserProfileData(data) {
    this.spinner.show();
    this.getlistService
      .GetStudentProfile(data) //data.Id
      .subscribe((response: any) => {
        this.spinner.hide();
        this.setStudentProfileModel = response;

        if (this.setStudentProfileModel.UserPic == null || this.setStudentProfileModel.UserPic == undefined || this.setStudentProfileModel.UserPic == "") {
          this.setStudentProfileModel.UserPic = "../../assets/images/KridApp-Image/No-Image-User.png";
        }
        if (this.setStudentProfileModel.AcademyStudentContactList.length > 0) {
          for (var i = 0; i < this.setStudentProfileModel.AcademyStudentContactList.length; i++) {
            let objname = this.setStudentProfileModel.AcademyStudentContactList[i]['FirstName'];
            let objphone = this.setStudentProfileModel.AcademyStudentContactList[i]['PhoneNumber'];
            let objemail = this.setStudentProfileModel.AcademyStudentContactList[i]['Email'];
            if (i == 0) {
              this.isFatherExist = true;
              this.fatherName = objname;
              this.fatherPhone = objphone;
              this.fatherEmail = objemail;
            }
            else if (i == 1) {
              this.isMotherExist = true;
              this.motherName = objname;
              this.motherPhone = objphone;
              this.motherEmail = objemail;
            }
          }
        }
      },
        (error) => {
          this.spinner.hide();
        });
  }
}
