<div id="page">
    <div class="header header-fixed header-logo-center">
        <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <h2 class="header-title textcolor">Change Password</h2>
        <span (click)="onclick()" data-menu="menu-sidebar-left-1" class="header-icon header-icon-4 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
        </span>
    </div>
    <div class="page-content detail header-clear-medium" (click)="onremoveclick()">
        <form [formGroup]="changepasswordForm" (ngSubmit)="changepasswordSubmit()">
            <div class="content">
                <div class="inputsection">
                    <input class="form-control" type="hidden" formControlName="GetOldPassword" value="">
                    <span class="input-title font-14">Old Password<span class="requird-field">*</span></span>
                    <div class="input-style input-required">
                        <input class="form-control" type="password" placeholder="Old Password" formControlName="oldpassword"
                            [ngClass]="{ 'is-invalid': submitted && f.oldpassword.errors }">
                        <div *ngIf="submitted && f.oldpassword.errors" class="invalid-feedback">
                            <div *ngIf="f.oldpassword.errors.required">This is required field</div>
                            <div *ngIf="f.oldpassword.errors.mustOldMatch">current password do not match</div>
                        </div>
                    </div>
                </div>
                <div class="inputsection">
                    <span class="input-title font-14">New Password<span class="requird-field">*</span></span>
                    <div class="input-style input-required">
                        <input class="form-control" type="password" placeholder="New Password" formControlName="newpassword"
                            [ngClass]="{ 'is-invalid': submitted && f.newpassword.errors }">
                        <div *ngIf="submitted && f.newpassword.errors" class="invalid-feedback">
                            <div *ngIf="f.newpassword.errors.required">This is required field</div>
                        </div>
                    </div>
                </div>
                <div class="inputsection">
                    <span class="input-title font-14">Confirm Password<span class="requird-field">*</span></span>
                    <div class="input-style input-required">
                        <input class="form-control" type="password" placeholder="Confirm Password" formControlName="confirmpassword"
                            [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors }">
                        <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback">
                            <div *ngIf="f.confirmpassword.errors.required">Confirm Password is required</div>
                            <div *ngIf="f.confirmpassword.errors.mustMatch">Passwords and retype password do not match</div>
                        </div>
                    </div>
                </div>
                <div class="inputsection">
                    <button type="submit" class="btn-block loginheadercolor textcolor">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>