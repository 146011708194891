<div id="page" class="graybg">
    <!-- Header -->
    <div class="header header-fixed header-logo-center">
        <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <h2 class="header-title textcolor">Inbox</h2>
        <span (click)="openPopupInbox()" *ngIf="isEditIcon" class="header-icon header-icon-3 textcolor">
            <img width="18" height="18" src="../../../assets/images/icons/edit.png" alt="" />
        </span>
        <span (click)="onclick()" data-menu="menu-sidebar-left-1" class="header-icon header-icon-4 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
        </span>
    </div>
    <!-- Header -->
    <!-- Main Content -->
    <div class="page-content header-clear-medium" (click)="onremoveclick()">
        <div class="studentlistview">
            <div class="content" *ngFor="let item of UserMessageList">
                <div class="student-list">
                    <div class="d-flex align-items-center">
                        <div class="allimage mt-0">
                            <div class="contact-img">
                                <img src="{{item.Pic}}" class="preload-img w-img-90" alt="" *ngIf="item.Pic!=null" />
                                <img src="../../assets/images/KridApp-Image/No-Image-User.png" alt=""
                                    class=" preload-img w-img-90" *ngIf="item.Pic==null" />
                            </div>
                        </div>
                        <div class="w-100 bd-highlight" (click)="profilepage(item)">
                            <div class="indextext pull-left">
                                <h2 class="item-title m-0">{{item.FirstName}}</h2>
                                <p>{{item.Message}}</p>
                            </div>
                            <p class="pull-right date">{{item.DateSent|date: 'MMM dd'}}</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="divider m-0"></div> -->
            </div>
        </div>
        <div class="sentreceived bg-white text-center" *ngIf="UserMessageList.length==0">
            <span>You have not sent/received any messages as of now</span>
        </div>
    </div>
</div>