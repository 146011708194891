<!-- Menu Sidebar Left 1 -->
<div id="menu-sidebar-left-1" class="bg-white menu menu-box-left">
    <div class="mr-3 ml-3">
        <!-- RoleTypeID 1 -->
        <div class="list-group list-custom-small list-icon-0" *ngIf="RoleTypeId==1">
            <span (click)="RedirectToDashboard(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/panel-dashboard.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Dashboard</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToSwitchAcademy(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/panel-help.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Switch Academy</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToClock(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/clock-menu.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Punch In/Out</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToProfile(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/panel-profile.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Profile</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span *ngIf="ParentDrillAccess&&RoleTypeId==3" (click)="RedirectToDrills(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/drills.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Drills</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <div *ngFor="let item of MenuList;">
                <span id="attendance" *ngIf="item.text=='Attendence'" (click)="RedirectToAttendence(RoleTypeId)">
                    <i><img src="../../assets/images/KridApp-Image/menuattendance.png" width="100%" height="100%" alt=""
                            class="img-height"></i>
                    <span class="TextDecorate">Attendence</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
                <span *ngIf="item.text=='BatchPlanning'" (click)="RedirectToBatchPlanning(RoleTypeId)">
                    <i><img src="../../assets/images/KridApp-Image/drills.png" width="100%" height="100%" alt=""
                            class="img-height"></i>
                    <span class="TextDecorate">Batch Planning</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
                <span *ngIf="item.text=='CoachAttendance'" (click)="RedirectToCoachAttendanceAdmin(RoleTypeId)">
                    <i><img src="../../assets/images/KridApp-Image/coachattendance.png" width="100%" height="100%"
                            alt="" class="img-height"></i>
                    <span class="TextDecorate">Coach Attendance</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
                <span *ngIf="item.text=='CoachEvalution'" (click)="RedirectToCoachAssessment(RoleTypeId)">
                    <i><img src="../../assets/images/KridApp-Image/coachassessment.png" width="100%" height="100%"
                            alt="" class="img-height"></i>
                    <span class="TextDecorate">Coach Assessment</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
                <span (click)="RedirectToAssessment(RoleTypeId)" *ngIf="item.text=='Report'">
                    <i><img src="../../assets/images/KridApp-Image/assessment.png" alt="" width="100%" height="100%"
                            class="img-height"></i>
                    <span class="TextDecorate">Assessment</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
                <span (click)="RedirectToStudent(RoleTypeId)" *ngIf="item.text=='Student'">
                    <i><img src="../../assets/images/KridApp-Image/graduate_student.png" width="100%" height="100%"
                            alt="" class="img-height"></i>
                    <span class="TextDecorate">Players</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
                <span (click)="RedirectToInquiry(RoleTypeId)" *ngIf="item.text=='AcademyInquiry'">
                    <i><img src="../../assets/images/KridApp-Image/Inquiry.png" width="100%" height="100%" alt=""
                            class="img-height"></i>
                    <span class="TextDecorate">Inquiry</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
                <span (click)="RedirectToEventList(RoleTypeId)" *ngIf="item.text=='Event'">
                    <i><img src="../../assets/images/KridApp-Image/event.png" width="100%" height="100%" alt=""
                            class="img-height"></i>
                    <span class="TextDecorate">Events</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
                <span (click)="RedirectToManageFees(RoleTypeId)" *ngIf="item.text=='AcademyFeesType'">
                    <i><img src="../../assets/images/KridApp-Image/manage-fees.png" width="100%" height="100%" alt=""
                            class="img-height"></i>
                    <span class="TextDecorate">Manage Fees</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
                <span *ngIf="item.text=='Venue'" (click)="RedirectToVenue(RoleTypeId)">
                    <i><img src="../../assets/images/KridApp-Image/manage-venue.png" width="100%" height="100%" alt=""
                            class="img-height"></i>
                    <span class="TextDecorate">Venue Booking</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
            </div>
            <span (click)="RedirectToChangePassword(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/password.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Change Password</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToFeedback(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/panel-help.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Help/Feedback</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="logout()">
                <i><img src="../../assets/images/KridApp-Image/logout.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Sign Out</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
        </div>

        <!-- RoleTypeID 2-->
        <div class="list-group list-custom-small list-icon-0" *ngIf="RoleTypeId==2">
            <span (click)="RedirectToDashboard(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/panel-dashboard.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Dashboard</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToSwitchAcademy(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/panel-help.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Switch Academy</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToClock(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/clock-menu.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Punch In/Out</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToProfile(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/panel-profile.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Profile</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <div *ngFor="let item of MenuList;">
                <span id="attendance" *ngIf="item.text=='Attendence'" (click)="RedirectToAttendence(RoleTypeId)">
                    <i><img src="../../assets/images/KridApp-Image/menuattendance.png" width="100%" height="100%" alt=""
                            class="img-height"></i>
                    <span class="TextDecorate">Attendence</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
                <span (click)="RedirectToCoachAttendance(RoleTypeId)" *ngIf="item.text=='CoachAttendance'">
                    <i><img src="../../assets/images/KridApp-Image/coachattendance.png" width="100%" height="100%"
                            alt="" class="img-height"></i>
                    <span class="TextDecorate">Coach Attendance</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
                <span (click)="RedirectToCoachAssessment(RoleTypeId)" *ngIf="item.text=='CoachEvalution'">
                    <i><img src="../../assets/images/KridApp-Image/coachassessment.png" width="100%" height="100%"
                            alt="" class="img-height"></i>
                    <span class="TextDecorate">Coach Assessment</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
                <span (click)="RedirectToAssessment(RoleTypeId)" *ngIf="item.text=='Report'">
                    <i><img src="../../assets/images/KridApp-Image/assessment.png" width="100%" height="100%" alt=""
                            class="img-height"></i>
                    <span class="TextDecorate">Assessment</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
                <span (click)="RedirectToEventList(RoleTypeId)" *ngIf="item.text=='Event'">
                    <i><img src="../../assets/images/KridApp-Image/event.png" width="100%" height="100%" alt=""
                            class="img-height"></i>
                    <span class="TextDecorate">Events</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
                <span (click)="RedirectToStudent(RoleTypeId)" *ngIf="item.text=='Student'">
                    <i><img src="../../assets/images/KridApp-Image/graduate_student.png" width="100%" height="100%"
                            alt="" class="img-height"></i>
                    <span class="TextDecorate">Players</span>
                    <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
                </span>
            </div>
            <span (click)="RedirectToTraining(RoleTypeId)">
                <i><img src="../../../assets/icons/19222909.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Training</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToUserMessages(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/usermessages.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Message</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToManageFees(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/manage-fees.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Manage Fees</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToRequisition(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/requisition.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Requisition</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToDamageReport(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/damagereport.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Damage Report</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToInventoryReport(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/inventoryreport.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Inventory Report</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToChangePassword(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/password.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Change Password</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToFeedback(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/panel-help.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Help/Feedback</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="logout()">
                <i><img src="../../assets/images/KridApp-Image/logout.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Sign Out</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
        </div>

        <!-- RoleTypeID 3-->
        <div class="list-group list-custom-small list-icon-0" *ngIf="RoleTypeId==3">
            <span (click)="RedirectToDashboard(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/panel-dashboard.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Dashboard</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToSwitchAcademy(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/panel-help.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Switch Academy</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToProfile(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/panel-profile.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Profile</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span *ngIf="ParentDrillAccess&&RoleTypeId==3" (click)="RedirectToDrills(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/drills.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Drills</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="GetUserAnalyticsAssessmentReport()">
                <i><img src="../../assets/images/KridApp-Image/assessment.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Assessment</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="RedirectToUserMessages(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/usermessages.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Message</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>

            <span (click)="RedirectToleaveapplication(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/usermessages.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Leave Application</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>

            <span (click)="RedirectToChangePassword(RoleTypeId)">
                <i><img src="../../assets/images/KridApp-Image/password.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Change Password</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
            <span (click)="logout()">
                <i><img src="../../assets/images/KridApp-Image/logout.png" width="100%" height="100%" alt=""
                        class="img-height"></i>
                <span class="TextDecorate">Sign Out</span>
                <i><img width="8" height="8" src="../../../assets/images/icons/angle-right.png" alt=""></i>
            </span>
        </div>
    </div>
</div>
<!-- Menu Sidebar Left 1 -->