<div class="header header-fixed header-logo-center">
    <h2 class="header-title textcolor">Varification</h2>
    <span (click)="closemodel()" class="header-icon header-icon-1 textcolor">
      <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
    </span>
    <div class="titlearea">
        <p class="textcolor mt-3">You will receive OTP (One time passord) vai SMS soon.<br />Please enter the 6 digit number below to login.</p>
    </div>
</div>
<div class="page-content header-clear-medium popup-mainarea">
    <form>
      <div class="content d-block">
          <div class="w-100">
            <input class="form-control" placeholder="" id="txtVerificationCode" type="number">
          </div>
          <span (click)="ResendOTP()">
              <p class="text-center mt-3 resend">Resend OTP</p>
          </span>
      </div>
      <div class="Continuebtn">
        <button type="button" (click)="Otpverification()" class="btn-block loginheadercolor textcolor">Continue</button>
      </div>
    </form>
</div>