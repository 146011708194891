import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonFunction } from 'src/app/service/commonfunction';
import { GetListService } from 'src/app/service/getlist.service';

@Component({
  selector: 'app-popupbatchplanningdrills',
  templateUrl: './popupbatchplanningdrills.component.html',
  styleUrls: ['./popupbatchplanningdrills.component.scss']
})
export class PopupbatchplanningdrillsComponent implements OnInit {
  categoryId: any;
  drillsarray: any;
  isedit: boolean = false;
  isadd: boolean = false;
  selectedarrayfill: any=[];
  show:any;
  constructor(
    public dialogRef: MatDialogRef<PopupbatchplanningdrillsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public getListService: GetListService,
    public commonFunction: CommonFunction,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    
    this.drillsarray = this.data.drillslist;
    // console.log(this.drillsarray);

    
    let count = 0;
    this.categoryId = this.data.category.Id;
    // // console.log(this.categoryId);
    for(let i=0; i<this.drillsarray.length; i++){
      if(this.drillsarray[i].DrillCategoryId == this.categoryId)
      {
        count++;
        // console.log(count);
        break;
      }
    }
    this.show = count;
    // console.log(this.show);
    
    this.selectedarrayfill = this.data.selectedarrayfill;
    
    if (this.selectedarrayfill.length == 0) {
      this.isadd = true;
      this.isedit = false;
    }
    else {
      this.isadd = false;
      this.isedit = true;
    }
  }
  checkarray(id) {
    let val = this.selectedarrayfill.findIndex(itm => itm === id);
    // console.log(val);
    
    if (val !== -1) {
      return true;
    }
    else {
      return false;
    }
  }
  closemodel() {
    let SelectedDrillsCheckBoxValue = [];
    let NotSelectedDrillsCheckBoxValue=[];
    var GetDrillsAllcheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("getDrillsAllcheckBoxValue"));
    for (let i = 0; i < GetDrillsAllcheckbox.length; i++) {
      if (GetDrillsAllcheckbox[i].checked) {
        SelectedDrillsCheckBoxValue.push(GetDrillsAllcheckbox[i].value);
      }
      else
      {
        NotSelectedDrillsCheckBoxValue.push(GetDrillsAllcheckbox[i].value);
      }
    }
    let val = {};
    this.dialogRef.close({ drillsarryselected: SelectedDrillsCheckBoxValue,nodrillsarryselected:NotSelectedDrillsCheckBoxValue});
  }
}
