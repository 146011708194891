import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import{InquiryComponent} from '../inquiry/inquiry.component'
import { CreateinquiryComponent } from './createinquiry/createinquiry.component';

const routes: Routes = [
  {
    path : '',
    children:[
      {
        path:'',
        component:InquiryComponent
      },
      {
        path:'createinquiry',
        component:CreateinquiryComponent

      },
      {
        path: 'inquiryprofile',
        loadChildren: () => import('./inquiry-profile/inquiry-profile.module').then(module => module.InquiryProfileModule
          )
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InquiryRoutingModule { }
