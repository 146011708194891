import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { GetListService } from 'src/app/service/getlist.service';
import { CommonFunction } from 'src/app/service/commonfunction';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-batch-planning-dates',
  templateUrl: './batch-planning-dates.component.html',
  styleUrls: ['./batch-planning-dates.component.scss']
})
export class BatchPlanningDatesComponent implements OnInit {
  daysSelected: any[] = [];
  daysSelectedab: any[] = [];
  displaydatalist: any[] = [];
  DrillListCateGory: any = [];
  DrillNameList: any = [];
  DrillListHideShow: boolean = false;
  ClickedDate: Date;
  isSelected = (event: any) => {
    var className = '';
    const date =
      event.getFullYear() +
      '-' +
      ('00' + (event.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + event.getDate()).slice(-2);
    if (this.daysSelected) {
      className = this.daysSelected.find((x) => x == date) ? 'selectedP' : null;
      if (className == '' || className == null) {
        className = this.daysSelectedab.find((x) => x == date)
          ? 'selectedA'
          : null;
      }
      return className;
    }
    if (this.daysSelectedab) {
      return className;
    }
  };

  CurrentDate = new Date();
  @ViewChild('calendar', { static: false }) calendar: MatCalendar<Date>;
  constructor(public router: Router, public datePipe: DatePipe,
    public getListService: GetListService,
    public commonFunction: CommonFunction,
    private spinner: NgxSpinnerService,

  ) {
    setTimeout(() => {
      const prev = document.querySelector('.mat-calendar-previous-button');
      const next = document.querySelector('.mat-calendar-next-button');
      prev.addEventListener('click', () => {
        this.CurrentDate = this.calendar.activeDate;
        var lastDay = new Date(this.CurrentDate.getFullYear(), this.CurrentDate.getMonth() + 1, 0).getDate();
        var end = new Date(this.CurrentDate.getFullYear(), this.CurrentDate.getMonth(), lastDay);
        let startDate = this.datePipe.transform(this.calendar.monthView._weeks[0][0].ariaLabel, 'yyyy-MM-dd');
        let endDate = this.datePipe.transform(end, 'yyyy-MM-dd');
        var data = window.history.state;
        this.GetDrillList(data.batchId, startDate, endDate, data.slotId);

      });
      next.addEventListener('click', () => {
        this.CurrentDate = this.calendar.activeDate;
        var lastDay = new Date(this.CurrentDate.getFullYear(), this.CurrentDate.getMonth() + 1, 0).getDate();
        var end = new Date(this.CurrentDate.getFullYear(), this.CurrentDate.getMonth(), lastDay);
        let startDate = this.datePipe.transform(this.calendar.monthView._weeks[0][0].ariaLabel, 'yyyy-MM-dd');
        let endDate = this.datePipe.transform(end, 'yyyy-MM-dd');
        var data = window.history.state;
        this.GetDrillList(data.batchId, startDate, endDate, data.slotId);
      });
    }, 150);
  }

  ngOnInit(): void {
    this.spinner.show();

    if (window.history.state != undefined) {
      var data = window.history.state;
      var date = new Date();
      var startDate = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
      var endDate = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
      this.GetDrillList(data.batchId, startDate, endDate, data.slotId);
    }

    // this.spinner.hide();

  }

  GetDrillList(batchId, fromDate, toDate, slotId) {
    // this.spinner.show();
    slotId = (slotId != "") ? slotId : "";
    slotId = (slotId != undefined) ? slotId : "";
    batchId = (batchId != undefined) ? batchId : 0;

    this.daysSelected = [];
    this.displaydatalist = [];
    this.getListService
      .GetDrillList(batchId, fromDate, toDate, slotId)
      .subscribe(
        (response: any) => {
          if (response.length > 0) {
            this.DrillListCateGory = this.commonFunction.removeDuplicatesBy(x => x.FromDate, response);

            for (let i = 0; i < this.DrillListCateGory.length; i++) {
              for (let j = 0; j < response.length; j++) {
                if (this.DrillListCateGory[i].FromDate == response[j].FromDate) {
                  this.DrillNameList.push({
                    DrillName: response[j].DrillName,
                    Sequence: j,
                    FromDate: this.DrillListCateGory[i].FromDate
                  });
                }
              }
              this.DrillNameList.sort((a, b) => 0 - (a.Sequence > b.Sequence ? -1 : 1));
            }

            this.DrillListHideShow = true;
            for (let i = 0; i < response.length; i++) {
              const newdate = response[i].FromDate;
              let dateq = this.datePipe.transform(newdate, 'yyyy-MM-dd');
              this.daysSelected.push(dateq);
              this.calendar.updateTodaysDate();
            }
          }
          else {
            this.DrillListHideShow = false;
          }
        },
        (error) => {
        }
      );
      setTimeout(() => {
        this.spinner.hide();
      }, 4000);    
  }

  back() {
    var data = window.history.state;
    this.router.navigate(['batchplanning'], {
      state: {
        batchId: data.batchId,
        slotId: data.slotId,
        parentbatchId: data.parentbatchid
      }
    });
  }
  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      if (date.getDate() === 1) {  
        return 'special-date';
      } else {
        return;
      }
      
    };
  }
  onSelect(val) {
    this.ClickedDate = new Date(val);
  }
  openCreateBatchPlanning() {
    // this.spinner.show();
    if (this.ClickedDate == undefined) {
      this.ClickedDate = new Date();
    }
    this.router.navigate(['create-batch-planning'], {
      state: {
        batchId: window.history.state.batchId,
        slotId: window.history.state.slotId,
        mode: "Add",
        clickeddate: this.ClickedDate
      }
    });
    // this.spinner.hide();
  }

  Edit(item) {
    var date = new Date(item.FromDate);
    this.router.navigate(['create-batch-planning'], {
      state: {
        items: item,
        batchId: window.history.state.batchId,
        slotId: window.history.state.slotId,
        mode: "Add",
        clickeddate: date
      }
    });
  }
}
