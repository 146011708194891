import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from 'src/app/service/commonfunction';
import { GetListService } from 'src/app/service/getlist.service';

@Component({
  selector: 'app-popupfilterfeesdetails',
  templateUrl: './popupfilterfeesdetails.component.html',
  styleUrls: ['./popupfilterfeesdetails.component.scss']
})
export class PopupfilterfeesdetailsComponent implements OnInit {
  AacdemyFeesTypeList: any=[];

  constructor( 
  @Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<PopupfilterfeesdetailsComponent>,
  public route: ActivatedRoute,
  public getlistservice: GetListService,
  public commonFunction: CommonFunction,
  private router: Router) { }

  ngOnInit(): void {
    this.GetAacdemyFeesTypeList();
  }
  GetAacdemyFeesTypeList()
  {
    var UserData=this.commonFunction.GetUserData();
    this.getlistservice
    .GetAcademyFeesList(UserData.AcademyId,UserData.ParentAcademyId)
    .subscribe((response: any) => {
        this.AacdemyFeesTypeList=response;
    },
      (error) => {
      });
  }
  FeestypeChange(item)
  {
    this.dialogRef.close(item);
  }
}
