import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PostService } from '../service/post.service';
import { NotificationService } from '../service/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  feedbackForm: UntypedFormGroup;
  submitted = false;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public postService: PostService,
    public notifyService:NotificationService
  ) { }

  ngOnInit(): void {
    this.onremoveclick();
    this.initform();
  }

  initform() {
    this.feedbackForm = this.formBuilder.group(
      {
        subject: ['', [Validators.required]],
        body: ['', [Validators.required]]
      }
    );
  }
  get f() { return this.feedbackForm.controls; }
  onclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.add('menu-active');
  }
  onremoveclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.remove('menu-active');
  }
  back()
  {
    this.router.navigate(['/dashboard'])
  }

  feedbackSubmit() {
    this.submitted = true;
    if (this.feedbackForm.invalid) {
      this.notifyService.showError("Unable to save Feedback. Please try again later. Contact the admin if problem persists.", "");
      return;

    }
    else {
      var data = {
        to: 'feedback@kridappacademy.com',
        from:'feedback@kridappacademy.com',
        subject:this.feedbackForm.controls['subject'].value,
        body:this.feedbackForm.controls['body'].value
      };
      this.postService
        .saveFeedback(data)
        .subscribe((response: any) => {
          if (response == null || response != null || response == undefined) {
            this.notifyService.showSuccess("Feedback saved successfully!", "");
            setTimeout(() => {
              this.router.navigate(['/dashboard']);
            }, 1000);
            }
        },
          (error) => {
            this.notifyService.showError("Unable to save Feedback. Please try again later. Contact the admin if problem persists.", "");
          });

    }
  }

}
