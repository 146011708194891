<form [formGroup]="paymentForm">
  <div class="content">
    <div class="inputsection">
      <label>Name</label>
      <input class="form-control" type="text" formControlName="name">
    </div>
    <div class="inputsection">
      <label>Email</label>
      <input class="form-control" type="text" placeholder="Email" value="{{email}}" formControlName="Email">
    </div>
    <div class="inputsection">
      <label>Reference</label>
      <input class="form-control" type="text" placeholder="Camp (First name, surname initial) e.g. CampPrashantP"
        formControlName="Reference">
    </div>
    <button
      class="btn btn-m btn-block shadow-l text-uppercase font-900 text-center loginheadercolor textcolor d-flex justify-content-center"
      (click)="buys(payamount.OutStandingAmount)" [disabled]="!validForm">
      <div class="currency" [innerHTML]="currencysymbol"></div> {{payamount.OutStandingAmount}} Pay
    </button>
  </div>
</form>