<div class="popup-header header-logo-center loginheadercolor">
    <div class="header-title textcolor noback">Select Set</div>
</div>
<div class="page-content pb-0">
    <ul class="filter-popup">
        <ng-container>
            <span (click)="openStudentlistModal(1)">
                <li class="col-12">Set 1</li>
            </span>
        </ng-container>
        <ng-container>
            <span (click)="openStudentlistModal(2)">
                <li class="col-12">Set 2</li>
            </span>
        </ng-container>
        <ng-container>
            <span (click)="openStudentlistModal(3)">
                <li class="col-12">Set 3</li>
            </span>
        </ng-container>
        <ng-container>
            <span (click)="openStudentlistModal(4)">
                <li class="col-12">Set 4</li>
            </span>
        </ng-container>
        <ng-container>
            <span (click)="openStudentlistModal(5)">
                <li class="col-12">Set 5</li>
            </span>
        </ng-container>
        <ng-container>
            <span (click)="openStudentlistModal(6)">
                <li class="col-12">Set 6</li>
            </span>
        </ng-container>
        <ng-container>
            <span (click)="openStudentlistModal(7)">
                <li class="col-12">Set 7</li>
            </span>
        </ng-container>
        <ng-container>
            <span (click)="openStudentlistModal(8)">
                <li class="col-12">Set 8</li>
            </span>
        </ng-container>
        <ng-container>
            <span (click)="openStudentlistModal(9)">
                <li class="col-12">Set 9</li>
            </span>
        </ng-container>
        <ng-container>
            <span (click)="openStudentlistModal(10)">
                <li class="col-12">Set 10</li>
            </span>
        </ng-container>

        <li (click)="close()" class="col-12 text-center red"><img width="16" height="16"
                src="../../../assets/images/KridApp-Image/close.png" alt=""></li>
    </ul>
</div>