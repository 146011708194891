import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserassessmentRoutingModule } from './userassessment-routing.module';
import { UserreportComponent } from './userreport/userreport.component';
import { UserreportinfoComponent } from './userreportinfo/userreportinfo.component';
import {ReactiveFormsModule,FormsModule} from '@angular/forms';
import { PopupcommentsComponent } from './popupcomments/popupcomments.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { NgxSpinnerModule } from "ngx-spinner";
import { PopupinfoComponent } from './popupinfo/popupinfo.component';
import { FeedbackreportComponent } from './feedbackreport/feedbackreport.component';

@NgModule({
  declarations: [UserreportComponent, UserreportinfoComponent, PopupcommentsComponent, PopupinfoComponent, FeedbackreportComponent],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    UserassessmentRoutingModule,
    MatDialogModule,
    NgxSpinnerModule,
  ]
})
export class UserassessmentModule { }
