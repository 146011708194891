import { Component, OnInit } from '@angular/core';
import { GetListService } from 'src/app/service/getlist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PopupviewtransactionComponent } from '../popupviewtransaction/popupviewtransaction.component';
import { AddfeesComponent } from '../addfees/addfees.component';
import { CommonFunction } from 'src/app/service/commonfunction';
@Component({
  selector: 'app-feesinfo',
  templateUrl: './feesinfo.component.html',
  styleUrls: ['./feesinfo.component.scss']
})
export class FeesinfoComponent implements OnInit {
  FeesInfoDetailsList: any=[];
  FeesHeaderTaxList:any=[];
  param: any;
  UserName:any;
  menuopenstatus:boolean=true;
  currencysymbol: any;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public getlistservice: GetListService,
    public dialog: MatDialog,
    public commonFunction: CommonFunction,
  ) { }

  ngOnInit(): void {
    if(window.history.state.feesinfo!=undefined)
    {
      this.param=window.history.state;
      let feesdataparam=window.history.state.feesinfo;
      this.GetFeesDetailsByFeesTypeId(feesdataparam.AcademyUserId,feesdataparam.HeaderId);
      this.GetFeesHeaderDetails(feesdataparam.HeaderId);
      this.UserName=this.param.DisplayUserName;
      var userdata = this.commonFunction.GetUserData();
      this.currencysymbol=userdata.CountryCurrencySymbol;
    }
    this.onremoveclick();
  }

  onclick() {
     var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
           element.classList.add('menu-active'); 
   }
   onremoveclick() {
     var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
     element.classList.remove('menu-active'); 
   }


  GetFeesDetailsByFeesTypeId(academyUserId,headerId)
  {
    this.getlistservice
    .GetFeesInfoDetailsByFeesTypeId(academyUserId, headerId)
    .subscribe((response: any) => {
      if (response != null) {
        let length=response.length-1;
        this.FeesInfoDetailsList.push(response[length]);
      }
    },
      (error) => {
      });
  }

  GetFeesHeaderDetails(headerId)
  {
    this.getlistservice
    .GetFeesHeaderDetails(headerId)
    .subscribe((response: any) => {
      if (response != null) {
        this.FeesHeaderTaxList.push(response);
      }
  },
  (error) => {
  });
}

  back()
  {
    this.router.navigate(['studentfeestype'], {
      state: {
        AcademyUserId:this.param.AcademyUserId,
        BathchId:this.param.BathchId,
        DisplayUserName:this.param.DisplayUserName,
        ScheduleId:this.param.ScheduleId,
        Redirect:window.history.state.Redirect,
        UserListData:window.history.state.UserListData
      }
    });
  }

  opendotpopup(param){
    const dialogRef = this.dialog.open(PopupviewtransactionComponent, {
      width: '100%',
      height:'100%',
      data:{val:param}
    });
  }
  AcceptPayment(item){
    const dialogRef = this.dialog.open(AddfeesComponent, {
      width: '100%',
      height:'100%',
      data:{item:item,isSMS:false}
    });
  }

}
