import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagefeesRoutingModule } from './managefees-routing.module';
import { UserlistComponent } from './userlist/userlist.component';
import { UserfeesdetailsComponent } from './userfeesdetails/userfeesdetails.component';
import { PopupviewtransactionComponent } from './popupviewtransaction/popupviewtransaction.component';
import { StudentfeestypeComponent } from './studentfeestype/studentfeestype.component';
import { FeesinfoComponent } from './feesinfo/feesinfo.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollectionreportComponent } from './collectionreport/collectionreport.component';
import { AddfeesComponent } from './addfees/addfees.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { PopupfilterfeesdetailsComponent } from './popupfilterfeesdetails/popupfilterfeesdetails.component';
import { AddextrafeesComponent } from './addextrafees/addextrafees.component';
import { NgxSpinnerModule } from "ngx-spinner";


@NgModule({
  declarations: [UserlistComponent, UserfeesdetailsComponent, PopupviewtransactionComponent, StudentfeestypeComponent, FeesinfoComponent, CollectionreportComponent, AddfeesComponent, PopupfilterfeesdetailsComponent, AddextrafeesComponent ],
  imports: [
    CommonModule,
    ManagefeesRoutingModule,
    Ng2SearchPipeModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    FlatpickrModule.forRoot()
  ]
})
export class ManagefeesModule { }
