<!-- Main Page -->
<div id="page">
    <form [formGroup]="inquiryFormGroup" (ngSubmit)="checkuserphoneemail()">
        <!-- Header -->
        <div class="header header-fixed header-logo-center">
            <span (click)="Backclick()" data-back-button class="header-icon header-icon-1 textcolor">
                <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
            </span>
            <h2 class="header-title textcolor" *ngIf="createinquirytext">Create Inquiry</h2>
            <h2 class="header-title textcolor" *ngIf="editinquirytext">Edit Inquiry</h2>
            <span (click)="onclick()" data-menu="menu-sidebar-left-1" class="header-icon header-icon-4 textcolor">
                <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
            </span>
        </div>
        <!-- Header -->
        <!--Main Content-->
        <div class="page-content tabpage header-clear-medium" (click)="onremoveclick()">
            <!--Tab Content-->
            <div class="addStudent">
                <div class="content">
                    <mat-tab-group>
                        <mat-tab label="Profile details">
                            <!-- Tab 1 Player Detail's-->
                            <!-------Tab 1 Start -->
                            <div class="inputsection">
                                <span class="input-title font-14">Preferred Center<span
                                        class="requird-field">*</span></span>
                                <div class="input-style input-required">
                                    <select class="form-control k_required" formControlName="PreferredCenterId"
                                        [ngClass]="{ 'is-invalid': submitted && f.PreferredCenterId.errors }"
                                        (change)="CenterChange($event);">
                                        <option value="">Select</option>
                                        <option *ngFor="let item of PreferredCenterList" value="{{item.Id}}">
                                            {{item.Name}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.PreferredCenterId.errors" class="invalid-feedback">
                                        <div *ngIf="f.PreferredCenterId.errors.required">This is required field</div>
                                    </div>
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Inquiry Source<span
                                        class="requird-field">*</span></span>
                                <div class="input-style input-required">
                                    <select class="form-control k_required" formControlName="InquirySourceId"
                                        [ngClass]="{ 'is-invalid': submitted && f.InquirySourceId.errors }">
                                        <option value="">Select</option>
                                        <option *ngFor="let item of InquirySourceList" value="{{item.Id}}">
                                            {{item.Value}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.InquirySourceId.errors" class="invalid-feedback">
                                        <div *ngIf="f.InquirySourceId.errors.required">This is required field</div>
                                    </div>
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Sport<span class="requird-field">*</span></span>
                                <div class="input-style input-required">
                                    <select class="form-control" formControlName="AcademySportId"
                                        (change)="SportNameChange($event);"
                                        [ngClass]="{ 'is-invalid': submitted && f.AcademySportId.errors }">
                                        <option value="">Select</option>
                                        <option *ngFor="let item of AcademySportList" value="{{item.AcademySportId}}">
                                            {{item.SportName}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.AcademySportId.errors" class="invalid-feedback">
                                        <div *ngIf="f.AcademySportId.errors.required">This is required field</div>
                                    </div>
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Player Name<span class="requird-field">*</span></span>
                                <div class="input-style input-required">
                                    <input class="form-control" type="text" placeholder="Player Name"
                                        formControlName="StudentName"
                                        [ngClass]="{ 'is-invalid': submitted && f.StudentName.errors }">
                                    <div *ngIf="submitted && f.StudentName.errors" class="invalid-feedback">
                                        <div *ngIf="f.StudentName.errors.required">This is required field</div>
                                    </div>
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Date of Birth</span>
                                <div class="input-style input-required dob-input">
                                    <input class="form-control" type="text" mwlFlatpickr
                                        (change)="ageCalculator($event)" formControlName="DOB" dateFormat="m/d/Y"
                                        ref="flatpickr" maxDate="today">

                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Age Group<span class="requird-field">*</span></span>
                                <div class="input-style input-required">
                                    <select class="form-control" formControlName="AgeGroup"
                                        [ngClass]="{ 'is-invalid': submitted && f.AgeGroup.errors }">
                                        <option value="">Select</option>
                                        <option *ngFor="let item of GetAgeGroupsList" value="{{item.Id}}">{{item.Title}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && f.AgeGroup.errors" class="invalid-feedback">
                                        <div *ngIf="f.AgeGroup.errors.required">This is required field</div>
                                    </div>
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Relation</span>
                                <div class="input-style input-required">
                                    <select class="form-control" formControlName="RealtionId">
                                        <option value="0">Self</option>
                                        <option value="1">Father</option>
                                        <option value="2">Mother</option>
                                        <option value="3">Other</option>
                                    </select>
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Phone Number</span>
                                <div class="input-style input-required">
                                    <!-- <input type="number" class="form-control" formControlName="PhoneNumber"
                                        pattern="/^-?\d+\.?\d*$/"
                                        onKeyPress="if(this.value.length==10) return false;" /> -->
                                    <input type="text" class="form-control" formControlName="PhoneNumber"
                                         />

                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Email</span>
                                <div class="input-style input-required">
                                    <input class="form-control" type="text"
                                        [class.is-invalid]="inquiryFormGroup.get('Email').invalid && inquiryFormGroup.get('Email').touched"
                                        formControlName="Email">
                                    <div *ngIf="inquiryFormGroup.get('Email').invalid && inquiryFormGroup.get('Email').touched"
                                        class="invalid-feedback">
                                        <div
                                            *ngIf="inquiryFormGroup.get('Email').invalid && inquiryFormGroup.get('Email').touched">
                                            Please enter valid Email address</div>
                                    </div>
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">School Name</span>
                                <div class="input-style input-required">
                                    <input class="form-control" type="text" placeholder="SchoolName"
                                        formControlName="SchoolName">
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Standard</span>
                                <div class="input-style input-required">
                                    <input class="form-control" type="text" placeholder="Standard"
                                        formControlName="Standard">
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Address</span>
                                <div class="input-style input-required">
                                    <input class="form-control" type="text" placeholder="Address"
                                        formControlName="Address">
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">City</span>
                                <div class="input-style input-required">
                                    <input class="form-control" type="text" placeholder="Enter a Location"
                                        formControlName="City">
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">State</span>
                                <div class="input-style input-required">
                                    <input class="form-control" type="text" placeholder="State" formControlName="State">
                                </div>
                            </div>
                            <div class="inputsection">
                                <span class="input-title font-14">Country</span>
                                <div class="input-style input-required">
                                    <input class="form-control" type="text" placeholder="Country"
                                        formControlName="Country">
                                </div>
                            </div>

                            <div class="guardian">
                                GUARDIAN 1
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Relation</span>
                                <div class="input-style input-required">
                                    <select class="form-control" formControlName="GardianRealtionId1">
                                        <option value="0">Self</option>
                                        <option value="1">Father</option>
                                        <option value="2">Mother</option>
                                        <option value="3">Other</option>
                                    </select>
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Name</span>
                                <div class="input-style input-required">
                                    <input class="form-control" type="text" placeholder=""
                                        formControlName="GardianName1">
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Phone Number</span>
                                <div class="input-style input-required">
                                    <!-- <input type="number" class="form-control" formControlName="GardianPhone1"
                                        pattern="/^-?\d+\.?\d*$/"
                                        onKeyPress="if(this.value.length==10) return false;" /> -->
                                        <input type="text" class="form-control" formControlName="GardianPhone1"
                                        />
                                    </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Email</span>
                                <div class="input-style input-required">
                                    <input class="form-control" type="text"
                                        [class.is-invalid]="inquiryFormGroup.get('GardianEmail1').invalid && inquiryFormGroup.get('GardianEmail1').touched"
                                        formControlName="GardianEmail1">
                                    <div *ngIf="inquiryFormGroup.get('GardianEmail1').invalid && inquiryFormGroup.get('GardianEmail1').touched"
                                        class="invalid-feedback">
                                        <div
                                            *ngIf="inquiryFormGroup.get('GardianEmail1').invalid && inquiryFormGroup.get('GardianEmail1').touched">
                                            Please enter valid Email address</div>
                                    </div>
                                </div>
                            </div>

                            <div class="inputsection">
                                <div class="input-title font-14 d-flex align-items-center">
                                    <input id="GardianIsPrimary1" (change)="checkchkValue(1,$event)" type="checkbox"
                                        formControlName="GardianIsPrimary1" />
                                    <label for="GardianIsPrimary1" class="ml-2 mb-0">Is Primary</label>
                                </div>
                            </div>

                            <div class="guardian">
                         `                                                                                                                                                                                                                                                                                                                                                                                                                                                     GUARDIAN 2
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Relation</span>
                                <div class="input-style input-required">
                                    <select class="form-control" formControlName="GardianRealtionId2">
                                        <option value="0">Self</option>
                                        <option value="1">Father</option>
                                        <option value="2">Mother</option>
                                        <option value="3">Other</option>
                                    </select>
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Name</span>
                                <div class="input-style input-required">
                                    <input class="form-control" type="text" placeholder=""
                                        formControlName="GardianName2">
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Phone Number<span
                                        class="requird-field">*</span></span>
                                <div class="input-style input-required">
                                    <!-- <input type="number" class="form-control" formControlName="GardianPhone2"
                                        pattern="/^-?\d+\.?\d*$/"
                                        onKeyPress="if(this.value.length==10) return false;" /> -->
                                        <input type="text" class="form-control" formControlName="GardianPhone2"
                                        />
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Email</span>
                                <div class="input-style input-required">
                                    <input class="form-control" type="text"
                                        [class.is-invalid]="inquiryFormGroup.get('GardianEmail2').invalid && inquiryFormGroup.get('GardianEmail2').touched"
                                        formControlName="GardianEmail2">
                                    <div *ngIf="inquiryFormGroup.get('GardianEmail2').invalid && inquiryFormGroup.get('GardianEmail2').touched"
                                        class="invalid-feedback">
                                        <div
                                            *ngIf="inquiryFormGroup.get('GardianEmail2').invalid && inquiryFormGroup.get('GardianEmail2').touched">
                                            Please enter valid Email address
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="inputsection">
                                <div class="input-title font-14 d-flex align-items-center">
                                    <input id="GardianIsPrimary2" (change)="checkchkValue(2,$event)" type="checkbox"
                                        formControlName="GardianIsPrimary2" />
                                    <label for="GardianIsPrimary2" class="ml-2 mb-0">Is Primary</label>
                                </div>
                            </div>

                            <div class="inputsection">
                                <button (click)="tabchanges(1)" type="button" data-tab="tab-2"
                                    class="btn btn-block loginheadercolor textcolor">Next</button>
                            </div>
                            <!-- Tab 1 End -->

                            <!-- Tab 1 Player Detail's-->
                        </mat-tab>
                        <mat-tab label="Id / Age Proof" *ngIf="idproofmenu">
                            <div class="inputsection text-center">
                                <div class="image-upload profile">
                                    <label for="file-input">
                                        <ng-container *ngIf="UploadimageUrl!=''">
                                            <img [src]="UploadimageUrl" alt="" (click)="selectImage.click()">
                                        </ng-container>
                                        <ng-container *ngIf="UploadimageUrl==''">
                                            <img src="../../../assets/images/KridApp-Image/uploadpic.jpg" alt=""
                                                (click)="selectImage.click()">
                                        </ng-container>
                                    </label>
                                    <input type="file" id="fileone" accept=".png, .jpg, .jpeg"
                                        (change)="uploadFile($event)" #selectImage>
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Select ID / Age Proof</span>
                                <div class="input-style input-required">
                                    <select class="form-control">
                                        <option value="">Select</option>
                                        <option *ngFor="let item of IdAgeProofList" value="{{item.Id}}">{{item.Proof}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="inputsection">
                                <span class="input-title font-14">Details</span>
                                <div class="input-style input-required">
                                    <input class="form-control" type="photodetails" placeholder="">
                                </div>
                            </div>

                            <div class="inputsection text-center">
                                <div class="image-upload">
                                    <label for="file-input">
                                        <ng-container *ngIf="UploadIdAgeProofImageUrl!=''">
                                            <img [src]="UploadIdAgeProofImageUrl" alt=""
                                                (click)="selectImagetwo.click()">
                                        </ng-container>
                                        <ng-container *ngIf="UploadIdAgeProofImageUrl==''">
                                            <img src="../../../assets/images/KridApp-Image/upload-id.jpg" alt=""
                                                (click)="selectImagetwo.click()">
                                        </ng-container>
                                    </label>
                                    <input type="file" id="filetwo" (change)="uploadAgeProofImage($event)"
                                        #selectImagetwo>
                                </div>
                            </div>

                            <div class="inputsection d-flex">
                                <button type="button" (click)="tabchanges(0)"
                                    class="btn mt-0 mr-1 btn-block btn-secondary textcolor">Back</button>
                                <button type="button" (click)="tabchanges(2)"
                                    class="btn mt-0 ml-1 btn-block loginheadercolor textcolor">Next</button>
                            </div>

                        </mat-tab>
                        <mat-tab label="Batch details">

                            <div class="inputsection">
                                <span class="input-title font-14">Batches<span class="requird-field">*</span></span>
                                <div class="input-style input-required">
                                    <select class="form-control" formControlName="BachesId"
                                        (change)="BatchNameChange($event);" id="drpBatches"
                                        [ngClass]="{ 'is-invalid': submitted && f.BachesId.errors }">
                                        <option value="">Select</option>
                                        <option *ngFor="let item of BachesList" value="{{item.Id}}">{{item.BatchName}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && f.BachesId.errors" class="invalid-feedback">
                                        <div *ngIf="f.BachesId.errors.required">This is required field</div>
                                    </div>
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Schedule<span class="requird-field">*</span></span>
                                <div class="input-style input-required">
                                    <select class="form-control" formControlName="ScheduleId"
                                        (change)="ScheduleNameChange($event);" id="drpSchedule"
                                        [ngClass]="{ 'is-invalid': submitted && f.ScheduleId.errors }">
                                        <option value="">Select</option>
                                        <option *ngFor="let item of ScheduleList" value="{{item.Id}}">{{item.BatchName}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && f.ScheduleId.errors" class="invalid-feedback">
                                        <div *ngIf="f.ScheduleId.errors.required">This is required field</div>
                                    </div>
                                </div>
                            </div>

                            <div class="inputsection" *ngIf="isShown">
                                <span class="input-title font-14">UserType</span>
                                <div class="input-style input-required">
                                    <select class="form-control" formControlName="UsertypeId"
                                        (change)="UserTypeChange($event);">
                                        <option value="">Select</option>
                                        <option *ngFor="let item of AcademyUserTypeList" value="{{item.Id}}">
                                            {{item.Title}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Inquiry Date<span
                                        class="requird-field">*</span></span>
                                <div class="input-style input-required dob-input">
                                    <input id="InquiryDate" matInput readonly [matDatepicker]="InquiryDatePicker"
                                        class="form-control" formControlName="InquiryDate">
                                    <mat-datepicker-toggle matSuffix [for]="InquiryDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #InquiryDatePicker></mat-datepicker>
                                </div>
                            </div>

                            <div class="inputsection">
                                <span class="input-title font-14">Status</span>
                                <div class="input-style input-required">
                                    <ng-container *ngIf="inquiryEditId==0">
                                        <select class="form-control" formControlName="AcademyInquiryStatusId" disabled>
                                            <option [selected]="item.StatusValue==5"
                                                *ngFor="let item of AcademyInquiryStatuslist"
                                                value="{{item.StatusValue}}">
                                                {{item.InquiryStatus}}</option>
                                        </select>
                                    </ng-container>

                                    <ng-container *ngIf="inquiryEditId>0">
                                        <select class="form-control" formControlName="AcademyInquiryStatusId"
                                            (change)="toggleShow($event)">
                                            <option [selected]="item.StatusValue==5"
                                                *ngFor="let item of AcademyInquiryStatuslist"
                                                value="{{item.StatusValue}}">
                                                {{item.InquiryStatus}}</option>
                                        </select>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="inputsection" *ngIf="isShownMonthPlan">
                                <span class="input-title font-14">Month Plan</span>
                                <div class="input-style input-required">
                                    <select class="form-control" formControlName="MonthplanId"
                                        (change)="MonthPlanChange($event)">
                                        <option value="">Select</option>
                                        <option id={{item.Fees}} *ngFor="let item of MonthPlanList"
                                            value="{{item.ScheduleTypeId}}">
                                            {{item.ScheduleType}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="inputsection" *ngIf="isShownsubscriptionfees">
                                <span class="input-title font-14">Subscription Fees (Before Tax)</span>
                                <div class="input-style input-required">
                                    <input type="text" class="form-control" formControlName="SubscriptionBeforeFees"
                                        id="txtfees">
                                </div>
                            </div>

                            <div class="inputsection" *ngIf="isShownDuration">
                                <span class="input-title font-14">Duration</span>
                                <div class="input-style input-required">
                                    <select class="form-control" formControlName="DurationId"
                                        (change)="DurationChange($event)">
                                        <option value="">Select</option>
                                        <option *ngFor="let item of DurationList" value="{{item.id}}">{{item.value}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="inputsection" *ngIf="isShownFollowupDate">
                                <span class="input-title font-14">Follow up Date</span>
                                <div class="input-style input-required dob-input">
                                    <input class="form-control" matInput readonly
                                        [matDatepicker]="expertFollowupTimepicker" formControlName="expertFollowupTime"
                                        [ngClass]="{ 'is-invalid': submitted && f.expertFollowupTime.errors }">
                                    <mat-datepicker-toggle matSuffix [for]="expertFollowupTimepicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #expertFollowupTimepicker></mat-datepicker>
                                </div>
                            </div>

                            <div class="inputsection" *ngIf="isShownAssesmentScheduleDate">
                                <span class="input-title font-14">Assessment Scheduled Date<span
                                        class="requird-field">*</span></span>
                                <div class="input-style input-required dob-input">
                                    <input class="form-control" matInput readonly
                                        [matDatepicker]="expertAssessmentscheduleTimepicker"
                                        formControlName="expertAssessmentscheduleTime"
                                        [ngClass]="{ 'is-invalid': submitted && f.expertAssessmentscheduleTime.errors }">
                                    <mat-datepicker-toggle matSuffix [for]="expertAssessmentscheduleTimepicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #expertAssessmentscheduleTimepicker></mat-datepicker>
                                </div>
                            </div>


                            <div class="inputsection" *ngIf="isShownAssesmentDate">
                                <span class="input-title font-14">Assessment Date<span
                                        class="requird-field">*</span></span>
                                <div class="input-style input-required dob-input">
                                    <input matInput readonly [matDatepicker]="expertAssessmentTimepicker"
                                        formControlName="expertAssessmentTime"
                                        [ngClass]="{ 'is-invalid': submitted && f.expertAssessmentTime.errors }">
                                    <mat-datepicker-toggle matSuffix [for]="expertAssessmentTimepicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #expertAssessmentTimepicker></mat-datepicker>
                                </div>
                            </div>

                            <div class="inputsection" *ngIf="isFromDate">
                                <div>
                                    <span class="input-title font-14">From Date<span
                                            class="requird-field">*</span></span>
                                    <div class="input-style input-required dob-input">
                                        <input id="FromDate" class="form-control" type="text" mwlFlatpickr
                                            formControlName="FromDate" (change)="FromDateChanged()" dateFormat="m/d/Y"
                                            ref="flatpickr">
                                    </div>
                                </div>
                            </div>

                            <div class="content">
                                <div class="inputsection">
                                    <span class="input-title font-14">Comments</span>
                                    <div class=" input-style  input-required Comments">
                                        <textarea class="form-control" rows="2" placeholder="Comments"
                                            formControlName="InquiryComments"></textarea>
                                    </div>
                                </div>
                            </div>

                            <!-- Fees Detail *ngIf="isShownfeesdetails"-->
                            <div class="feesdetails" *ngIf="isShownfeesdetails">
                                <span class="title loginheadercolor text-white">Fees Details</span>
                                <div class="accordion" id="accordion-1">
                                    <div class="kit-fees GetkitfeesArrayList"
                                        *ngFor="let item of RegitrationFeeslist; let i = index">
                                        <div>
                                            <button type="button" class="btn accordion-btn" data-toggle="collapse" #elem
                                                [attr.data-target]="'#RegisterFees'+i">
                                                <input type="hidden" id="feesId{{i}}" value="{{item.Id}}">
                                                <input type="hidden" id="issubfeesTypeId{{i}}"
                                                    value="{{item.IsSubFeesType}}">
                                                <input type="hidden" id="OptionsId{{i}}" value="{{item.Options}}">
                                                <input type="hidden" id="RecurringId{{i}}" value="{{item.Recurring}}">
                                                <input type="hidden" id="TaxNameId{{i}}" value="{{item.TaxName}}">
                                                <input type="hidden" id="TaxPercentageId{{i}}"
                                                    value="{{item.TaxPercentage}}">
                                                <input type="hidden" id="TotalAmountId{{i}}"
                                                    value="{{item.TotalAmount}}">
                                                <div class="row m-0 align-items-center">
                                                    <span class="col-5 pl-0">{{item.FeesType}}</span>
                                                    <div class="col-4 p-0" *ngIf="item.Options===true">
                                                        <select class="form-control" selected="1"
                                                            (change)="KitFeesChange($event,i,item.Id);">
                                                            <option value="0">Select</option>
                                                            <ng-container *ngFor="let item1 of academyFeesTypeList">
                                                                <option
                                                                    *ngIf="item.Options===true && item1.IsSubFeesType===true && item.Id===item1.Id"
                                                                    value="{{item1.AcademyFeesTypeOptionId}}">
                                                                    {{item1.FeesType}}</option>
                                                            </ng-container>
                                                        </select>
                                                    </div>
                                                    <span class="col-3 text-right studentfees">
                                                        <i class="ml-2 accordion-icon"><img width="10" height="10"
                                                                src="../../../assets/images/icons/chevron-down.png"
                                                                alt="" /></i>
                                                        <div class="pull-right" id="recurringamount{{i}}">
                                                            {{item.Recurring}}</div>
                                                    </span>
                                                </div>
                                            </button>
                                            <div id=RegisterFees{{i}} class="collapse" data-parent="#accordion-1">
                                                <div class="fees">
                                                    <div>Fees :<span id="recurringfees{{i}}"></span></div>
                                                    <div>Tax (0%) :<span id=kitfeestax{{i}} value="0"></span></div>
                                                    <div>Waive Off : <input type="checkbox" id=kitwaiveoff{{i}}
                                                            class="ml-2" (click)="WaiveOff($event,i,item.Id)" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="kit-fees">
                                        <button type="button" class="btn accordion-btn" data-toggle="collapse"
                                            data-target="#nofees">
                                            <div class="row m-0 align-items-center">
                                                <span class="col-6 pl-0">Subscription Fees</span>
                                                <span class="col-6 pr-0 text-right studentfees">
                                                    <span id="spn_SubscriptionFees"></span>
                                                    <i class="ml-2 accordion-icon"><img width="10" height="10"
                                                            src="../../../assets/images/icons/chevron-down.png"
                                                            alt="" /></i>
                                                </span>
                                            </div>
                                        </button>
                                        <div id="nofees" class="collapse" data-parent="#accordion-1">
                                            <div class="fees">
                                                <div><span>Discount Type :</span>
                                                    <select class="form-control" selected="1"
                                                        formControlName="SubscriptionDiscountTypeId"
                                                        (change)="DiscountChange($event)">
                                                        <option value="">Select</option>
                                                        <option *ngFor="let item of DiscountTypeList"
                                                            value="{{item.id}}">{{item.value}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div><span>Discount :</span><input type="text" class="form-control"
                                                        (change)="onKey($event)"
                                                        formControlName="SubscriptionDiscountId" /></div>
                                                <div><span>Fees :</span> {{subfees}}</div>
                                                <div><span>Tax ({{Taxname}}{{TaxPercentage}}%) :</span>{{tax| number :
                                                    '1.0-0'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="title">
                                        <div class="row m-0 align-items-center">
                                            <span class="col-6 pl-0">Total:</span>
                                            <span class="col-6 pr-0 text-right">
                                                {{TotalAmount | number : '1.0-0'}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Fees Detail-->
                            <div class="inputsection d-flex">
                                <ng-container *ngIf="idproofmenu">
                                    <button type="button" (click)="tabchanges(1)"
                                        class="btn mt-0 mr-1 btn-block btn-secondary textcolor">Back</button>
                                </ng-container>
                                <ng-container *ngIf="!idproofmenu">
                                    <button type="button" (click)="tabchanges(0)"
                                        class="btn mt-0 mr-1 btn-block btn-secondary textcolor">Back</button>
                                </ng-container>
                                <button type="submit"
                                    class="btn mt-0 ml-1 btn-block loginheadercolor textcolor">Submit</button>
                            </div>

                        </mat-tab>
                    </mat-tab-group>
                    <!-- Tab 3 group Detail's-->
                    <!-- tab content text (click)="SubmitInquiry()" -->
                </div>
            </div>
            <!--Tab Content-->
        </div>
        <!--Main Content-->
    </form>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
        <p class="color-white"> Loading... </p>
    </ngx-spinner>
</div>
<!-- Main Page -->