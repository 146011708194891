<div class="header header-fixed header-logo-center">
  <h2 class="header-title textcolor">Forget Password</h2>
  <span (click)="closemodel()" class="header-icon header-icon-1 textcolor">
    <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
  </span>
  <div class="titlearea">
    <p class="textcolor mt-3">Enter your e-mail address below to reset your password.</p>
  </div>
</div>
<div class="page-content header-clear-medium popup-mainarea">
  <form [formGroup]="ForgetPasswordFormGroup" (ngSubmit)="forgetpasswordSubmit()">
    <div class="content">
      <div class="w-100">
        <input class="form-control text-center" formControlName="Email" placeholder="Please Enter E-mail Address" type="text">
      </div>
    </div>
    <div class="Continuebtn">
      <button type="submit" class="btn-block loginheadercolor textcolor">Submit</button>
    </div>
  </form>
</div>