import { Component, OnInit } from '@angular/core';
import { CommonFunction } from 'src/app/service/commonfunction';
import { GetListService } from 'src/app/service/getlist.service';
import { ActivatedRoute, Router } from '@angular/router';
import $ from 'jquery'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})
export class UserlistComponent implements OnInit {
  menuopenstatus:boolean=true;
  UserlistFormGroup: UntypedFormGroup;
  academyId:any;
  userId:any;
  isAccessAllBatches:any;
  Batchnameid: any;
  batchId:any;
  scheduleId:any;
  RoleType:any;
  UserListCount:number=0;
  selected:any="";
  searchText : string;
  BatchesList: any[];
  ScheduleList: any[];
  UserList:any[];
  constructor(  
    public getlistservice: GetListService,
    public commonFunction: CommonFunction,
    public route: ActivatedRoute,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,

    ) { }

  ngOnInit(): void {
    this.initform();
    this.onremoveclick();
   if(window.history.state.batchId!=undefined&&window.history.state.scheduleId!=undefined)
   {
     let batchId=window.history.state.batchId;
     this.selected=batchId;
     let scheduleId=window.history.state.scheduleId;
    this.BatchList();
    this.BatchNameChange(batchId);
    this.GetAcademyStudentlist(scheduleId);
    this.UserlistFormGroup.controls['BatchesId'].setValue(batchId);
    this.UserlistFormGroup.controls['ScheduleId'].setValue(scheduleId);
   }
    else
    {
      this.BatchList();  
    }
    
  }
  onclick(menuopenstatus) {
    if(menuopenstatus==true)
    {
      $("body").addClass("modal-open");
      $('#menu-sidebar-left-1').addClass('menu-active')
      this.menuopenstatus=false;
    }
    else
    {
      $("body").removeClass("modal-open");
      $('#menu-sidebar-left-1').removeClass('menu-active');
      this.menuopenstatus=true;
    }
    
  }
  
  back()
  {
    this.router.navigate(['/dashboard'])
  }


  onremoveclick() {
    $("body").removeClass("modal-open");
    $('#menu-sidebar-left-1').removeClass('menu-active')
  }

  initform()
  {
    this.UserlistFormGroup = this.formBuilder.group({
      BatchesId: new UntypedFormControl(''),
      ScheduleId: new UntypedFormControl(''),
    });
  }
  public BatchList() {
    let userdata = this.commonFunction.GetUserData();
    this.academyId = userdata.AcademyId;
    this.userId = userdata.UserId;
    this.isAccessAllBatches = userdata.isAccessAllBatches;

    this.getlistservice
      .GetUserBatches(this.academyId, this.userId, this.isAccessAllBatches)
      .subscribe((response: any) => {
        this.BatchesList = response;
      },
        (error) => {
        });
  }

  BatchNameChange(event) {
    let userdata = this.commonFunction.GetUserData();
     this.Batchnameid = (event.target!=undefined)?event.target.value:event;
    this.batchId = this.Batchnameid;
    this.userId = userdata.UserId,
      this.RoleType = userdata.RoleType,

      this.getlistservice
        .GetUserScheduleList(this.batchId, this.userId, this.RoleType)
        .subscribe((response: any) => {
          this.ScheduleList = response;
        },
          (error) => {
          });
  }

  GetAcademyStudentlist(event)
  {
    this.spinner.show();
    this.scheduleId=(event.target!=undefined)?event.target.value:event;
    this.getlistservice
    .GetAcademyStudentlist(this.scheduleId)
    .subscribe((response: any) => {
      this.UserList = response;
      // console.log(this.UserList);
      
      this.UserListCount=response.length;
      this.spinner.hide();
    },
      (error) => {
      });
  }

  UserFeesDetails(item:any)
  {
    this.router.navigate(['userfeesdetails'], {
      state: { UserListData: item,batchId:this.batchId,scheduleId:this.scheduleId,Redirect:'userlist'}
    });
  }

}
