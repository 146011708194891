<div id="page">
    <form [formGroup]="assessmentForm">
        <!-- Header -->
        <div class="header header-fixed header-logo-center">
            <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
                <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
            </span>
            <h2 class="header-title textcolor">Assessment</h2>
            <span (click)="onclick()"data-menu="menu-sidebar-left-1" class="header-icon header-icon-4 textcolor">
                <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
            </span>
        </div>
        <!-- Header -->
        <div class="page-content header-clear-medium" (click)="onremoveclick()">
            <div class="content">
                <div class="inputsection">
                    <span class="input-title font-14">Select Batch<span class="requird-field">*</span></span>
                    <div class="input-style input-required">
                        <select class="form-control" formControlName="BatchId" (change)='BatchChangeEvent($event)'>
                            <option value="0">Select</option>
                            <option *ngFor="let item of batchList" value="{{item.Id}}">{{item.BatchName}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="inputsection">
                    <span class="input-title font-14">Select Schedule<span class="requird-field">*</span></span>
                    <div class="input-style input-required">
                        <select class="form-control" formControlName="SubBatchId" (change)=ScheduleChangeEvent($event)>
                            <option value="0">Select</option>
                            <option *ngFor="let item of scheduleList" value="{{item.Id}}">{{item.BatchName}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="inputsection" *ngIf="isassessment">
                    <span class="input-title font-14">Assessment Type<span class="requird-field">*</span></span>
                    <div class="input-style input-required">
                        <select class="form-control" formControlName="AssessmentTypeId"
                            (change)=AssessmentTypeChangeEvent($event)>
                            <option value="">Select</option>
                            <option *ngFor="let item of assessmentTypeList " value="{{item.Id}}">{{item.AssessmentType}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="inputsection">
                    <span class="input-title font-14">Select Report Date<span class="requird-field">*</span></span>
                    <div class="input-style input-required">
                        <select class="form-control" formControlName="ReportDate"
                            (change)=ReportDateChangeEvent($event)>
                            <option *ngFor="let item of dateList" value="{{item}}">{{item |date:'dd MMM yyyy'}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="assessment">
                <ul class="formview">
                    <li class="loginheadercolor text-white">
                        <div class="row">
                            <div class="col-75 light-gray">Name</div>
                            <div class="col-25 text-right">Report</div>
                        </div>
                    </li>
                    <li *ngFor="let item of userList">
                        <div class="row">
                            <div class="col-75 light-gray">{{item.FirstName}}</div>
                            <div class="col-25 text-right"><span (click)='GetStudentReport(item)'>

                                <span *ngIf="item.IsCompleted == false; else elseBlockheader">
                                    <i><img width="13" height="13" src="../../../assets/images/icons/plus.png" alt="" /></i>
                                </span>
									<ng-template #elseBlockheader>
                                        <i class="loginheadercolor">
                                            <img width="13" height="13" src="../../../assets/images/icons/edit.png" alt="" />
                                        </i>
									</ng-template>
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </form>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
        <p class="color-white"> Loading... </p>
    </ngx-spinner>
</div>
