import { Component, OnInit } from '@angular/core';
import { LoginwithotpModule } from '../loginwithotp/loginwithotp.module';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import $ from 'jquery'
import { CommonFunction } from '../../service/commonfunction';
import { VerificationcodeComponent } from '../verificationcode/verificationcode.component';
import { NotificationService } from '../../service/notification.service';
import { GetListService } from '../../service/getlist.service';
import { PostService } from '../../service/post.service';
@Component({
  selector: 'app-loginwithotp',
  templateUrl: './loginwithotp.component.html',
  styleUrls: ['./loginwithotp.component.scss']
})
export class LoginwithotpComponent implements OnInit {
  academyData
  parentAcademyId;
  isphone: any;
  constructor(
    public commonFunction: CommonFunction,
    public dialogRef: MatDialogRef<LoginwithotpComponent>,
    public dialog: MatDialog,
    public notifyService: NotificationService,
    public route: ActivatedRoute,
    public getlistservice: GetListService,
    public postService: PostService,
    public router: Router) {

  }
  ngOnInit(): void {
    this.academyData = this.commonFunction.GetAcademyData();
    if (this.academyData.ParentAcademyId > 0) {
      this.parentAcademyId = this.academyData.ParentAcademyId;
    }
    else {
      this.parentAcademyId = this.academyData.Id;
    }
  }
  closemodel() {
    $('#phoneNumber').val();
    this.dialogRef.close({ phonenumber: 1255211 });
  }
  Phonenumbercheck() {
    var parentid = 0;
    if ($("#OtpPhoneNumber").val() != "") {
      this.isphone = this.telephoneCheck($("#OtpPhoneNumber").val());
      localStorage.removeItem('tmp_LoginotpSessionId');
      localStorage.removeItem('tmp_LoginPhoneNumber');
      
      this.postService
      .PhonenumberVerification($("#OtpPhoneNumber").val())
      .subscribe((response: any) => {
        parentid = response;
        console.log(parentid)
        if(this.parentAcademyId != 0){
          this.OtpGenerate($("#OtpPhoneNumber").val(), parentid, 1, this.isphone);
        }
        else{
          this.notifyService.showError("Your phone number is not registered.", "");
        }
      });           
    }
     else {
      this.notifyService.showError("Please enter phone number.", "");
    }
  }
  
  telephoneCheck(str) {
    var a = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(str);
    return a;
  } 

  OtpGenerate(phoneNumber, parentacId, getstatus, isphonevalue) {
    this.postService
      .OtpGenerate(phoneNumber, parentacId,isphonevalue)
      .subscribe((response: any) => {
        let result = response;
        if (result.status == "otprecivedsuccess") {
          if (getstatus == 1) {
            localStorage.setItem("tmp_LoginPhoneNumber", phoneNumber);
            localStorage.setItem("tmp_LoginotpSessionId", result.sessionid);
            localStorage.setItem("tmp_LoginIsPhoneValue", isphonevalue);
            $("#OtpPhoneNumber").val('');
            this.dialogRef.close({ phonenumber: $("#OtpPhoneNumber").val() });
            this.openVerificationPopup();

          }
          else {
            localStorage.setItem("tmp_LoginotpSessionId", result.sessionid);
            this.notifyService.showSuccess("New OTP sent on your mobile number.", "");
          }
        } else if (result.status == "otprecivederror") {
          this.notifyService.showError("Please try again later.", "");
        } else {
          this.notifyService.showError("The user does not exist.", "");
        }
      },
        (error) => {
        });
  }
  openVerificationPopup() {
    const dialogRef = this.dialog.open(VerificationcodeComponent, {
      width: '100%',
      height: '100%',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        // this.router.navigate(['/inquiry/createinquiry'], {
        //   state: {userAcademyId:result.data.val.Id,InquiryoptionData: result}
        // });
      }
    });
  }
}
