<div class="popup-header header-logo-center loginheadercolor">
    <div class="header-title textcolor noback">Select option</div>
</div>
<div class="page-content pb-0">
    <ul class="filter-popup">
        <ng-container>
            <span (click)="clickOption(1)">
                <li class="col-12">Attendance</li>
            </span>
        </ng-container>
        <ng-container>
            <span (click)="clickOption(2)">
                <li class="col-12">Player</li>
            </span>
        </ng-container>

        <ng-container>
            <span (click)="clickOption(4)" class="not-active">
                <li class="col-12">Drills</li>
            </span>
        </ng-container>

        <li (click)="close()" class="col-12 text-center red"><img width="16" height="16"
                src="../../../assets/images/KridApp-Image/close.png" alt=""></li>
    </ul>
</div>