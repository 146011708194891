import { Component, OnInit } from '@angular/core';
import { CommonFunction } from '../service/commonfunction';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PostService } from '../service/post.service';

@Component({
  selector: 'app-stripefailure',
  templateUrl: './stripefailure.component.html',
  styleUrls: ['./stripefailure.component.scss']
})
export class StripefailureComponent implements OnInit {
  userdata: any;
  feesheaderid: any;
  amount: any;

  constructor(private commonFunction: CommonFunction, private route: ActivatedRoute, public dialog: MatDialog,
    public router: Router, private postservice: PostService,) { }

  ngOnInit(): void {
    this.userdata = this.commonFunction.GetUserData();
    this.route.queryParams.subscribe(params => {
      this.feesheaderid = params['f'];
      this.amount = params['a'];
      if (this.feesheaderid != null || this.feesheaderid != "") {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          panelClass: 'paymentsuccess',
          data: {
            message: 'Your payment got failed. please try again later.',
            buttonText: {
              ok: 'Continue'
            }
          }
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.router.navigate(['/dashboard'])
          }
          else {
            this.router.navigate(['/dashboard'])
          }
        });
      }
    });
  }
}
