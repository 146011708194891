import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NotificationService } from '../../service/notification.service';
import { GetListService } from '../../service/getlist.service';
import { PostService } from '../../service/post.service';
import $ from 'jquery'
import { CommonFunction } from '../../service/commonfunction';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-verificationcode',
  templateUrl: './verificationcode.component.html',
  styleUrls: ['./verificationcode.component.scss']
})
export class VerificationcodeComponent implements OnInit {
  academyData
  parentAcademyId
  constructor(
    public dialogRef: MatDialogRef<VerificationcodeComponent>,
    public commonFunction: CommonFunction,
    public dialog: MatDialog,
    public notifyService: NotificationService,
    public route: ActivatedRoute,
    public getlistservice: GetListService,
    public postService: PostService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.academyData = this.commonFunction.GetAcademyData();
    if (this.academyData.ParentAcademyId > 0) {
      this.parentAcademyId = this.academyData.ParentAcademyId;
    }
    else {
      this.parentAcademyId = this.academyData.Id;
    }
  }
  closemodel() {
    this.dialogRef.close({ phonenumber: localStorage.getItem("tmp_LoginPhoneNumber") });
  }
  ResendOTP()
  {
    this.OtpGenerate(localStorage.getItem("tmp_LoginPhoneNumber"),  this.parentAcademyId, 2 ,localStorage.getItem("tmp_LoginIsPhoneValue"));
  }
  OtpGenerate(phoneNumber, parentacId, getstatus,isphonevalue) {
    this.postService
      .OtpGenerate(phoneNumber, parentacId ,isphonevalue)
      .subscribe((response: any) => {
        let result = response;
        if (result.status == "otprecivedsuccess") {
          if (getstatus == 1) {
            localStorage.setItem("tmp_LoginPhoneNumber", phoneNumber);
            localStorage.setItem("tmp_LoginotpSessionId", result.sessionid);
            localStorage.setItem("tmp_LoginIsPhoneValue", isphonevalue);
            $("#OtpPhoneNumber").val('');
          }
          else {
            localStorage.setItem("tmp_LoginotpSessionId", result.sessionid);
            this.notifyService.showSuccess("New OTP sent on your mobile number.", "");
          }
        } else if (result.status == "otprecivederror") {
          this.notifyService.showError("Please try again later.", "");
        } else {
          this.notifyService.showError("The user does not exist.", "");
        }
      },
        (error) => {
        });
  }
  Otpverification() {
    this.postService
      .Otpverification(localStorage.getItem("tmp_LoginotpSessionId"), $('#txtVerificationCode').val(), this.academyData.Id, localStorage.getItem("tmp_LoginPhoneNumber"), localStorage.getItem("tmp_LoginIsPhoneValue"))
      .subscribe((response: any) => {
        let result = response;
        if (result.status == false) {
          this.notifyService.showError("Wrong or expired OTP, Please re-enter.", "");
          return false
        }
        else{
          this.notifyService.showSuccess("You are successfully logged in", "");
          localStorage.removeItem('tmp_LoginotpSessionId');
          localStorage.removeItem('tmp_LoginPhoneNumber');
          localStorage.removeItem('tmp_LoginIsPhoneValue');
          
          
          if (response.AuthKey != undefined) {
            this.commonFunction.SetLocalStorageData(result);
          }
          this.router.navigate(['/selectacademy'])
          .then(() => {
            window.location.reload();
          });
        }
      },
        (error) => {
        });
  }
}
