import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { GetListService } from '../service/getlist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from '../service/commonfunction';
import { AlertComponent } from '../AlertPopup/alert/alert.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { IdbService } from '../service/idb.service';
import { sequence } from '@angular/animations';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-side-bar-menu',
  templateUrl: './side-bar-menu.component.html',
  styleUrls: ['./side-bar-menu.component.scss']
})
export class SideBarMenuComponent implements OnInit {
  ParentDrillAccess: any;
  MenuList: any = [];
  RoleTypeId: number;
  userdata: any;
  sportList: any;
  strrequired = "";
  constructor(
    private getListService: GetListService,
    public route: ActivatedRoute,
    public router: Router,
    public idbService: IdbService,
    public commonFunction: CommonFunction,
    public SimpleModalService: SimpleModalService
  ) { }
  ngOnInit(): void {
    this.getMenuList();
  }
  
  onremoveclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.remove('menu-active');
  }
  public getMenuList() {
    var data = this.commonFunction.GetUserData();
    
    if (data != null) {
      if (data.ParentDrillAccess != null) {
        this.ParentDrillAccess = data.ParentDrillAccess;
      }
    }
    if (data != null && data != undefined) {
      this.RoleTypeId = data.RoleType;
      this.getListService
        .GetMenuList(data)
        .subscribe((response: any) => {
          // console.log(response);
          
          const filteredArr = response.reduce((acc, current) => {
            const x = acc.find(item => item.Name === current.Name);
            // console.log(x);
            
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
            
          response = filteredArr
    
          for (let i = 1; i < response.length; i++) {
            
            if (response[i].Name == "Attendence") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 0 });
            }
            else if (response[i].Name == "BatchPlanning") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 1 });
            }
            else if (response[i].Name == "CoachAttendance") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 2 });
            }
            else if (response[i].Name == "CoachEvalution") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 3 });
            }
            else if (response[i].Name == "Report") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 4 });
            }
            else if (response[i].Name == "Student") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 5 });
            }
            else if (response[i].Name == "AcademyInquiry") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 6 });
            }

            else if (response[i].Name == "Event") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 7 });
            }

            else if (response[i].Name == "AcademyFeesType") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 8 });
            }

            else if (response[i].Name == "Venue") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 9 });
            }

            else if (response[i].Name == "UserReport") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 10 });
            }
          }
          this.MenuList = this.MenuList.sort((a, b) => 0 - (a.sequence > b.sequence ? -1 : 1));
          // console.log(this.MenuList);
          
        },
        
          (error) => {

          });
    }
    // console.log(this.MenuList);
    
    
  }


  logout() {
    let academyData = JSON.parse(localStorage.getItem("academyData"));
    localStorage.clear();
    this.idbService.clear("academy");
    this.idbService.clear("academybatch");
    this.idbService.clear("academyuserattendance");
    this.idbService.clear("dashboard");
    this.idbService.clear("leavetype");
    this.idbService.clear("notificationcount");
    this.idbService.clear("rolemaster");
    this.idbService.clear("subbatch");
    this.idbService.clear("user");
    this.idbService.clear("userlist");
    localStorage.setItem("academyData", (JSON.stringify(academyData)));
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }
  reloadpage(event) {
    var URLNAME = event.target.id;
    this.router.navigate(['/' + URLNAME])
      .then(() => {
        window.location.reload();
      });
  }
  RedirectToDashboard(RoleTypeId) {
    if (RoleTypeId == 1 || RoleTypeId == 2 || RoleTypeId == 3) {
      this.router.navigate(['/dashboard'])
    }
  }
  RedirectToSwitchAcademy(RoleTypeId) {
    if (RoleTypeId == 1 || RoleTypeId == 2 || RoleTypeId == 3) {
      this.router.navigate(['/selectacademy'])
    }
  }
  RedirectToClock(RoleTypeId) {
    if ( RoleTypeId == 1 || RoleTypeId == 2){
      this.router.navigate(['/clock'])
    }
  }
  RedirectToProfile(RoleTypeId) {
    if (RoleTypeId == 1 || RoleTypeId == 2 || RoleTypeId == 3) {
      this.router.navigate(['/studentmodule/profile'])
    }
  }
  RedirectToAttendence(RoleTypeId) {
    if (RoleTypeId == 1 || RoleTypeId == 2) {
      var getUserDatathis = this.commonFunction.GetUserData();
      
      if (window.navigator.onLine == false) { // Ofline
        this.router.navigate(['/attendance'])
      }
      else {
        // debugger;
        this.getListService.getAcademyPackageExpire(getUserDatathis.ParentAcademyId).subscribe(
          (response: any) => {
            // console.log(response);
            
            if (response.PackageErrorMessage != "") {
              this.onremoveclick();
              this.strrequired = response.PackageErrorMessage;
              if (this.strrequired != "") {
                this.SimpleModalService.addModal(AlertComponent, { title: getUserDatathis.SelectAcademyName, message: this.strrequired });
              }
            }
            else {
              this.router.navigate(['/attendance'])
            }
          },
          (error) => {
          }
        );

      }

    }
  }
  RedirectToCoachAttendance(RoleTypeId) {
    if (RoleTypeId == 2) {
      this.router.navigate(['/coachattendance/coachview'])
    }
  }
  RedirectToCoachAttendanceAdmin(RoleTypeId) {
    if (RoleTypeId == 1) {
      this.router.navigate(['/coachattendance/adminview'])
    }
  }
  RedirectToCoachAssessment(RoleTypeId) {
    if (RoleTypeId == 1 || RoleTypeId == 2) {
      this.router.navigate(['/coachlistforassessment'])
    }
  }
  RedirectToAssessment(RoleTypeId) {
    if (RoleTypeId == 1 || RoleTypeId == 2 || RoleTypeId == 3) {
      this.router.navigate(['/userassessment/userreport'])
    }
  }
  RedirectToTraining(RoleTypeId) {
    if (RoleTypeId == 2) {
      this.router.navigate(['/Training'])
    }
  }
  RedirectToStudent(RoleTypeId) {
    if (RoleTypeId == 1 || RoleTypeId == 2) {
      this.router.navigate(['/studentmodule/list'])
        .then(() => {
          window.location.reload();
        });
    }
  }
  RedirectToInquiry(RoleTypeId) {
    if (RoleTypeId == 1) {
      this.router.navigate(['/inquiry'])
    }
  }
  RedirectToEventList(RoleTypeId) {
    // if (RoleTypeId == 1) {
    this.router.navigate(['/eventlist'])
    // }
  }
  RedirectToUserMessages(RoleTypeId) {
    if (RoleTypeId == 2 || RoleTypeId == 3) {
      this.router.navigate(['/usermessages'])
    }
  }
  RedirectToRequisition(RoleTypeId) {
    if (RoleTypeId == 2) {
      this.router.navigate(['/inventory/requisitionlist'])
    }
  }
  RedirectToDamageReport(RoleTypeId) {
    if (RoleTypeId == 2) {
      this.router.navigate(['/inventory/reportdamages'])
    }
  }
  RedirectToInventoryReport(RoleTypeId) {
    if (RoleTypeId == 2) {
      this.router.navigate(['/inventory/inventoryreport'])
    }
  }
  RedirectToManageFees(RoleTypeId) {
    if (RoleTypeId == 1 || RoleTypeId == 2) {
      this.router.navigate(['/userlist'])
    }
  }
  RedirectToVenue(RoleTypeId) {
    if (RoleTypeId == 1) {
      this.router.navigate(['/venue/veneubooking'])
    }
  }
  RedirectToDrills(RoleTypeId) {
    if (RoleTypeId == 3 || RoleTypeId == 1) {
      this.router.navigate(['/drills/drillview'])
    }

  }
  RedirectToBatchPlanning(RoleTypeId) {
    if (RoleTypeId == 1 ) {
      this.router.navigate(['/batchplanning'])
    }

  }

  RedirectToleaveapplication(RoleTypeId) {
    if (RoleTypeId == 3) {
      this.router.navigate(['/leaveapplication/leavedetails'])
    }
  }
  RedirectToChangePassword(RoleTypeId) {
    if (RoleTypeId == 1 || RoleTypeId == 2 || RoleTypeId || 3) {
      this.router.navigate(['/change-password'])
    }
  }
  RedirectToFeedback(RoleTypeId) {
    if (RoleTypeId == 1 || RoleTypeId == 2 || RoleTypeId || 3) {
      this.router.navigate(['/feedback'])
    }
  }
  GetUserAnalyticsAssessmentReport() {
    this.userdata = this.commonFunction.GetUserData();
    this.getListService
      .GetAcademySportList(this.userdata.StudentId, this.userdata.AcademyId)
      .subscribe((response: any) => {
        this.sportList = response;
        if (this.sportList.length > 0) {
          if (this.sportList.length == 1) {
            this.router.navigate(['/studentmodule/useranalytics'], {
              state: { userId: this.userdata.StudentId, userSportId: this.sportList[0].AcademySportId, isAssessmentPage: true }
            });
          }
        }
      },
        (error) => {
        });
  }
}
