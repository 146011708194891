import { Component, OnInit, Injectable, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetListService } from '../../service/getlist.service';
import { CommonFunction } from '../../service/commonfunction';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { MatCalendar } from '@angular/material/datepicker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-coachattendanceadmin',
  templateUrl: './coachattendanceadmin.component.html',
  styleUrls: ['./coachattendanceadmin.component.scss'],
})
export class CoachattendanceadminComponent implements OnInit {
  pipe = new DatePipe('en-US');
  centerTypeList = [];
  batchList = [];
  scheduleList = [];
  coachList:any = [];
  daysSelected: any[] = [];
  daysSelectedab: any[] = [];
  attendDate = new Date();
  batchId: any;
  centerId: any;
  scheduleId: any;
  UserId:any;

  isSelected = (event: any) => {
    var className = "";
    const date =
      event.getFullYear() +
      '-' +
      ('00' + (event.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + event.getDate()).slice(-2);

      if(this.daysSelected){
        className = this.daysSelected.find((x) => x == date) ? 'selectedP' : null;
        if(className=="" || className==null){
         className = this.daysSelectedab.find((x) => x == date) ? 'selectedA' : null
        }
        return className;
      }
      if(this.daysSelectedab){
      ;
       return className;
      }

  };
  GetUserData: any;
  @ViewChild('calendar', { static: false }) calendar: MatCalendar<Date>;
  constructor(
    public route: ActivatedRoute,
    public commonFunction: CommonFunction,
    public router: Router,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public datePipe: DatePipe,
    public getListService: GetListService
  ) 
  {
    setTimeout(() => {
      const prev = document.querySelector('.mat-calendar-previous-button');
      const next = document.querySelector('.mat-calendar-next-button');
      prev.addEventListener('click', () => { // Previous Button
        this.attendDate = this.calendar.activeDate;
        this.CoachChangeEvent("");
      });
      next.addEventListener('click', () => { // Next Button
        this.attendDate = this.calendar.activeDate;
        this.CoachChangeEvent("");
      });

    }, 150);
  }

  ngOnInit(): void {
    this.GetUserData = this.commonFunction.GetUserData();
    this.onremoveclick();
    this.CenterList();
  }
  onclick() {
     var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
           element.classList.add('menu-active'); 
   }
   onremoveclick() {
     var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
     element.classList.remove('menu-active'); 
   }
  CenterChangeEvent(event) {
    var acadeMyId =event.target.value;
    this.getListService
      .GetBachesListbyAcademyId(acadeMyId)
      .subscribe(
        (response: any) => {
          this.batchList = response;
          this.scheduleList=[];
          this.coachList=[];
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }
  CenterList() {
    this.GetUserData = this.commonFunction.GetUserData();
    this.getListService
      .GetUserAcademy(this.GetUserData.ParentAcademyId, this.GetUserData.UserId)
      .subscribe(
        (response: any) => {
          this.centerTypeList = response;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }
  BatchChangeEvent(val) {
    this.getListService
      .GetAcademySubBatchList(
        this.batchId,
        this.GetUserData.UserId,
        this.GetUserData.RoleTypeId
      )
      .subscribe(
        (response: any) => {
          this.scheduleList = response;
          this.spinner.hide();
          this.coachList=[];
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }
 
  ScheduleChangeEvent(event)
  {
    this.scheduleId=event.target.value;
    this.getListService
      .GetCoachesForAttendanceAPP(event.target.value)
      .subscribe(
        (response: any) => {
          this.coachList = response;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }
  CoachChangeEvent(event)
  {
    if(event.target!=undefined)
    {
      this.UserId=event.target.value;
    }
   this.GetUserData = this.commonFunction.GetUserData();
    var acadeMyId = this.centerId ? this.centerId : this.GetUserData.AcademyId;
    var dayofWeek = new Date(this.attendDate).getDay();
    var userId =event.target!=undefined?event.target.value:this.UserId;
    var month = new Date(this.attendDate).getMonth() + 1;
    var year = new Date(this.attendDate).getFullYear();
    this.daysSelected = [];
    this.daysSelectedab=[];
    if( this.scheduleId==undefined ||  this.scheduleId ==null ||  this.scheduleId =="")
    {
      this.scheduleId = 0;
    }
    this.getListService
      .GetCoachAttendance(
        acadeMyId,
        this.scheduleId,
        userId,
        dayofWeek,
        month,
        year
      )
      .subscribe(
        (response: any) => {
          for (let i = 0; i < response.length; i++) {
            const livedate = new Date(response[i].AttendanceDate);
            const date = new Date(response[i].AttendanceDate).getDate();
            const month = (livedate.getMonth() + 1).toString().padStart(2, '0');
            const year = new Date(response[i].AttendanceDate).getFullYear();

            const newdate = year + '-' + month + '-' + date;
            const LeaveTypeId = response[i].LeaveTypeId;
            let now2 = moment(livedate, 'DD.MM.YYYY');
            let dateq = this.datePipe.transform(newdate, 'yyyy-MM-dd');
            if (LeaveTypeId >0) {
              this.daysSelectedab.push(dateq)
            } else {
              this.daysSelected.push(dateq);
            }
            this.calendar.updateTodaysDate();
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }
}
