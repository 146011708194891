<div>
  <form [formGroup]="attendanceReasonForm">
    <div class="header header-fixed header-logo-center">
      <span (click)="closemodel()" class="header-icon header-icon-1 textcolor">
        <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
      </span>
      <h2 class="header-title textcolor">Player Reason</h2>
    </div>
    <div class="page-content header-clear-medium detail popup-mainarea">
      <input type="hidden" formControlName="userid">
      <div class="pt-3 pb-3 content" *ngFor="let item of GetLeaveTypeList">
        <span (click)="attendanceReasonFormSubmit(item.Id,item.ShortName)">{{item.ShortName}} - {{item.LeaveName}}</span>
      </div>
    </div>
  </form>
</div>