import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { GetListService } from '../service/getlist.service';
import { PostService } from '../service/post.service';
import { CommonFunction } from '../service/commonfunction';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NotificationService } from '../service/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardParam } from '../../app/_models/user.model'
import { DatePipe } from '@angular/common';
import { ConfirmationDialogComponent } from 'src/app/common/confirmation-dialog/confirmation-dialog.component';
import { PrivateattenstudentlistComponent } from 'src/app/privateattendancestd/privateattenstudentlist/privateattenstudentlist.component';

@Component({
  selector: 'app-privateattendancestd',
  templateUrl: './privateattendancestd.component.html',
  styleUrls: ['./privateattendancestd.component.scss']
})
export class PrivateattendancestdComponent implements OnInit {
  PrivateattendanceForm: UntypedFormGroup;
  public GetStudentList: any[];
  attendanceDate: any;
  dashboardparam: DashboardParam;
  StudnetListArray: any = [];
  LeaveTypeListSArray: any = [];
  submitted = false;
  date: any;
  CurrentDate: any;
  HideShowSchedule = false;
  HideShowStudent = false;
  SelectedStudentCount: string = "0";
  daysSelected: any[] = [];
  daysSelectedab: any[] = [];
  attendDate = new Date();
  isSelected = (event: any) => {
    var className = "";
    const date =
      event.getFullYear() +
      '-' +
      ('00' + (event.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + event.getDate()).slice(-2);
    if (this.daysSelected) {
      className = this.daysSelected.find((x) => x == date) ? 'selectedP' : null;
      if (className == "" || className == null) {
        className = this.daysSelectedab.find((x) => x == date) ? 'selectedA' : null
      }
      return className;
    }
    if (this.daysSelectedab) {
      ;
      return className;
    }
  };
  constructor(public route: ActivatedRoute,
    public formBuilder: UntypedFormBuilder,
    public router: Router,
    public getListService: GetListService,
    public postService: PostService,
    public commonFunction: CommonFunction,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService, private datePipe: DatePipe) {
  }

  loadform() {
    this.PrivateattendanceForm = this.formBuilder.group({
      Latitude: [''],
      Longitude: [''],
      AttendanceDate: new UntypedFormControl('', [Validators.required]),
    })
  }

  ngOnInit(): void {
    this.onremoveclick();
    this.initForm();
    this.CurrentDate = new Date(this.datePipe.transform(new Date(), 'MM/dd/yyyy'));
    this.PrivateattendanceForm.controls['AttendanceDate'].setValue(this.CurrentDate);

  }
  onclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.add('menu-active');
  }
  onremoveclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.remove('menu-active');
  }
  back() {
    this.router.navigate(['/dashboard'])
  }
  ChangeDate(e) {
    this.PrivateattendanceForm.controls['AttendanceDate'].setValue(e.target.value);
    this.attendanceDate = e.target.value;
  }
  openStudentlistModal(): void {
    const dialogRef = this.dialog.open(PrivateattenstudentlistComponent, {
      width: '100%',
      height: '100%',
      data: {
        AttendanceDate: (this.attendanceDate != undefined) ? this.attendanceDate : this.CurrentDate
      }
    });
  }

  initForm() {
    this.PrivateattendanceForm = this.formBuilder.group(
      {
        AttendanceDate: ["", [Validators.required]],
        Latitude: ["", [Validators.required]],
        Longitude: ["", [Validators.required]],
      }
    );

    this.PrivateattendanceForm.controls['AttendanceDate'].setValue(new Date(), 'MM/dd/yyyy');
    this.attendanceDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    var element = <HTMLInputElement>document.getElementById("AttendanceDate");
    element.value = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    this.commonFunction.getPosition().then(pos => {
      if (pos.lat == 0 || pos.lat == 0) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          panelClass: 'attendancesave',
          data: {
            message: 'If you wish to continue without sharing your location then click "Continue" else click "Cancel" to share your location. ',
            buttonText: {
              ok: 'Continue',
              cancel: 'Cancel'
            }
          }
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.PrivateattendanceForm.controls['Latitude'].setValue(pos.lat);
            this.PrivateattendanceForm.controls['Longitude'].setValue(pos.lng);
            const a = document.createElement('a');
            a.click();
            a.remove();
          }
        });
      }
      else {
      }
    });
  }
}