import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonFunction } from 'src/app/service/commonfunction';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';
import { NgxSpinnerService } from 'ngx-spinner';
import $ from 'jquery';
import { GetListService } from 'src/app/service/getlist.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { PostService } from '../../service/post.service';
import { from } from 'rxjs';
import { NotificationService } from 'src/app/service/notification.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { AlertComponent } from '../../AlertPopup/alert/alert.component';
import { MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { ConfirmationDialogComponent } from 'src/app/common/confirmation-dialog/confirmation-dialog.component';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { Geolocation } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-createinquiry',
  templateUrl: './createinquiry.component.html',
  styleUrls: ['./createinquiry.component.scss']
})

export class CreateinquiryComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  @ViewChild('fileInput') el: ElementRef;
  @ViewChild(MatDatepicker) datepicker: MatDatepicker<Date>;
  @Input() max: any;
  strrequired: "";
  UploadimageUrl: any = "";
  UploadIdAgeProofImageUrl: any = "";
  future = new Date();
  AddEditInquiryDataArray: any;
  submitted = false;
  displayname: any = '';
  displayphone: any = "";
  public inquiryFormGroup: UntypedFormGroup;
  public PreferredCenterList: any[];
  public InquirySourceList: any[];
  public AcademySportList: any[];
  public GetAgeGroupsList: any[];
  public RealtionList: any[];
  public AcademyUserTypeList: any[];
  public BachesList: any[];
  public AcademyInquiryStatuslist: any = [];
  public ScheduleList: any[];
  public GardinRelationList1: any[];
  public GardinRelationList2: any[];
  IdAgeProofList: any[];
  MonthPlanList: any[];
  monthplanfees: any = [];
  DurationList: any[];
  TempTotalAmount: any = 0;
  TaxPercentage: any = 0;
  subfees: any = 0;
  tax: any = 0;
  SubscriptionFeesAmount: any = 0;
  DiscountTypeChangevalue: any;
  DiscountAmount: any;
  TotalAmount: any = 0;
  Taxname: any;
  academyFeesTypeList: any = [];
  studentFeesTypeList: any = [];
  RegitrationFeeslist: any = [];
  Feeslist: any = [];
  kitfeestax: any;
  recurring: any = [];
  feeslisttypearray: any = [];
  recurringamount: any;
  temprecurringamount: any = 0;
  recurringfees: any = 0;
  AcademyFeesTypeOptionId: any;
  kitfeesrecurrungId: any;
  kitfeeschangeId: any;
  kitfeesname: any;
  fileToUpload: any;
  imagepath: any;
  rcf: any;
  fromdate: any;
  fees: any = 0;
  disc: any;
  discountamount: any = 0;
  p: any;
  DurationChangedValue: any;
  duration: number = 1;
  finalamount: any = 0;
  monthplanchangeId: any;
  academyUserId: any = 0;
  EndDate: any;
  hdnToDate: any;
  hdnProrataMsg: any;
  hdnMsg: any;
  ProrateOnDate: any = 0;
  calculatefees: any;
  tabactive: number = 0;
  DiscountTypeList: any[];
  TaxDetailList: any[];
  prorataData: any[];
  showidproof;
  sportnameid;
  Batchnameid;
  Schedulenameid;
  SubscriptionBeforeFees: any;
  Usertypeid;
  userdata;
  academyId;
  sportId;
  userId;
  batchId;
  RoleType;
  subbatchId;
  parentacademyId;
  isShown: boolean = false; // hidden by default
  isShownFollowupDate: boolean = false;
  isShownAssesmentScheduleDate: boolean = false;
  idproofmenu: boolean = false; // hidden by default
  isShownUserType: boolean = false
  isShownfeesdetails: boolean = false
  isShownMonthPlan: boolean = false;
  isShownDuration: boolean = false;
  isShownsubscriptionfees: boolean = false;
  isStatusPending: boolean = false;
  isStatusDone: boolean = false;
  isStatusSuccess: boolean = false;
  isStatusRejected: boolean = false;
  isFromDate: boolean = false;
  isShownAssesmentDate: boolean = false;
  selectionoption: boolean = false;
  createinquirytext: boolean = true;
  editinquirytext: boolean = false;
  currentOrientation = 'horizontal';
  active = 1;
  isactive = false;
  //edit Inquiry
  inquiryEditId: any;
  userAcademyId: any;
  id: any
  GardianId1: any;
  GardianId2: any;
  GardianUserId1: any;
  GardianUserId2: any;
  FeesScheduleTypeId: any;
  AcademyInquiryId: any;
  AcademyUserAdmissionToDate: any;
  InvoiceId: any;
  RegistrationNumber: any;
  AcademyUserId: any;
  AcademyUserSportId: any;
  AdjustFees: any;
  CentreName: any;
  IsEnroll: any;
  IsGlobleUser: any;
  IsGusetUser: any;
  IsInquiry: any;
  IsRenew: any;
  IsRenewFeesSave: any;
  ParentAcademyId: any;
  EditUserId: any;
  AgeGroupId: any;
  academyStudentGuardianUserid: any;
  academyStudentId: any;
  academyStudentUserTypeId: any;
  AcademyInquiryStatus: any;
  ProofType: any;
  showAge;
  @ViewChild('tabset') tabset: TabsetComponent;
  prorataFeesAmount: number;
  Platform;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public getlistService: GetListService,
    public commonFunction: CommonFunction,
    private spinner: NgxSpinnerService,
    public postservice: PostService,
    public notifyService: NotificationService,
    config: NgbNavConfig,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private SimpleModalService: SimpleModalService) {
    this.future.setDate(this.future.getDate());
    config.destroyOnHide = false;
    config.roles = false;
  }
  ngOnInit(): void {
    this.userAcademyId = (window.history.state.userAcademyId != undefined) ? window.history.state.userAcademyId : 0;
    this.academyUserId = this.userAcademyId;
    this.inquiryEditId = this.userAcademyId;
    this.isShown = false;
    this.showidproof = false;
    this.isShownUserType = false;
    this.userdata = this.commonFunction.GetUserData();
    if (this.userAcademyId == 0) {
      this.editinquirytext = false;
      this.createinquirytext = true;
      this.getPreferredCetnerList();
      //this.getInquirySourceList();
      //this.getAcademySportList();
      this.GetAgeGroupsByAcademyId();
      this.GetRealtionList();
      this.GetIdAgeProofList();
      this.GetAcademyInquiryStatuslist();
      this.GetDurationList();
      this.GetAcademyFeesType();
      this.DiscountType();
      this.GetTaxDetails();
      this.forminit();
      this.onremoveclick();
      this.inquiryFormGroup.controls['AcademyInquiryStatusId'].setValue(5);
      this.isShownFollowupDate = false;
      this.inquiryFormGroup.controls['InquiryDate'].setValue(this.datePipe.transform(new Date(), 'MM/dd/yyyy'));
      var FromDate = <HTMLInputElement>document.getElementById("FromDate");
      let _date = new Date();
    }
    else {
      if(window.history.state.InquiryoptionData!=undefined)
      {
        this.academyId=window.history.state.InquiryoptionData.data.val.AcademyId;
      }
      this.tabactive = 1;
      this.createinquirytext = false;
      this.editinquirytext = true;
      this.GetDurationList();
      this.GetAcademyFeesType();
      this.GetTaxDetails();
      this.DiscountType();
      this.getPreferredCetnerList();
      this.getInquirySourceList();
      this.getAcademySportList(this.academyId);
      this.GetAgeGroupsByAcademyId();
      this.GetAcademyInquiryStatuslist();
      this.GetRealtionList();
      this.GetIdAgeProofList();
      this.GetByStudentId(this.userAcademyId);
      this.onremoveclick();
      if (window.history.state.InquiryoptionData != undefined) {
        this.toggleShow(window.history.state.InquiryoptionData.InquiryOption);
      }
      this.forminit();
    }
  }


  forminit() {
    this.inquiryFormGroup = this.formBuilder.group({
      PreferredCenterId: new UntypedFormControl('', [Validators.required]),
      InquirySourceId: new UntypedFormControl('', [Validators.required]),
      AcademySportId: new UntypedFormControl('', [Validators.required]),
      UsertypeId:new UntypedFormControl(''),
      StudentName: new UntypedFormControl('', [Validators.required]),
      DOB: new UntypedFormControl('', [Validators.required]),
      AgeGroup: new UntypedFormControl('', [Validators.required]),
      RealtionId: new UntypedFormControl(0),
      PhoneNumber: new UntypedFormControl('', [Validators.required]),
      Email: new UntypedFormControl('', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      SchoolName: new UntypedFormControl(''),
      Standard: new UntypedFormControl(''),
      Address: new UntypedFormControl(''),
      City: new UntypedFormControl(''),
      State: new UntypedFormControl(''),
      Country: new UntypedFormControl(''),

      GardianRealtionId1: new UntypedFormControl(1),
      GardianName1: new UntypedFormControl(''),
      GardianPhone1: new UntypedFormControl('', [Validators.max(9999999999)]),
      GardianEmail1: new UntypedFormControl('', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      GardianIsPrimary1: new UntypedFormControl(''),

      GardianRealtionId2: new UntypedFormControl(2),
      GardianName2: new UntypedFormControl(''),
      GardianPhone2: new UntypedFormControl('', [Validators.max(9999999999)]),
      GardianEmail2: new UntypedFormControl('', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      GardianIsPrimary2: new UntypedFormControl(''),


      BachesId: new UntypedFormControl('', [Validators.required]),
      ScheduleId: new UntypedFormControl('', [Validators.required]),
      InquiryDate: new UntypedFormControl(new Date()),
      MonthplanId: new UntypedFormControl(''),
      KitfeesId1: new UntypedFormControl(''),
      KitfeesDiscountId: new UntypedFormControl(''),
      KitfeesDiscountTypeId: new UntypedFormControl(''),
      kitwaiveoffId: new UntypedFormControl(''),
      waiveoffId: new UntypedFormControl(''),
      DurationId: new UntypedFormControl(1),
      InquiryComments: new UntypedFormControl(''),
      AcademyInquiryStatusId: new UntypedFormControl(''),
      SubscriptionFeesId: new UntypedFormControl(''),
      RegisterfeesId: new UntypedFormControl(''),
      Latitude: [""],
      Longitude: [""],
      expertFollowupTime: new UntypedFormControl(''),
      expertAssessmentscheduleTime: new UntypedFormControl(''),
      FromDateId: new UntypedFormControl(''),
      SubscriptionBeforeFees: new UntypedFormControl(''),
      SubscriptionDiscountTypeId: new UntypedFormControl(''),
      SubscriptionDiscountId: new UntypedFormControl(''),
      FromDate: new UntypedFormControl('')
    });
    //Get Latitude and Longitude and set on form
    this.Platform = Capacitor.getPlatform();
    if(this.Platform == 'ios'){
      this.printCurrentPosition();
    }
    else{
      if (navigator.geolocation) {
        this.commonFunction.getPosition().then(pos => {
          this.inquiryFormGroup.controls['Latitude'].setValue(pos.lat);
          this.inquiryFormGroup.controls['Longitude'].setValue(pos.lng);
        });
      } else {
      }
    }  

  }

  printCurrentPosition = async () => {
    let coordinates = await Geolocation.getCurrentPosition();
    this.inquiryFormGroup.controls['Latitude'].setValue(coordinates.coords.latitude);
    this.inquiryFormGroup.controls['Longitude'].setValue(coordinates.coords.latitude);
  };

  ageCalculator(event) {
    let GetDate = event.target != undefined ? event.target.value : event;
    if (GetDate) {
      const convertAge = new Date(GetDate);
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());
      this.showAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);

      if (this.showAge == 4 || this.showAge <= 6) {
        this.inquiryFormGroup.controls['AgeGroup'].setValue(4);
      }
      else if (this.showAge == 7 || this.showAge <= 10) {
        this.inquiryFormGroup.controls['AgeGroup'].setValue(5);
      }
      else if (this.showAge == 11 || this.showAge <= 15) {
        this.inquiryFormGroup.controls['AgeGroup'].setValue(6);
      }
      else if (this.showAge == 16 || this.showAge <= 20) {
        this.inquiryFormGroup.controls['AgeGroup'].setValue(7);
      }
      else {
        this.inquiryFormGroup.controls['AgeGroup'].setValue(8);
      }
    }

  }

  ngAfterViewChecked() {
    if (this.userAcademyId == 0) {
      var element = <HTMLInputElement>document.getElementById("InquiryDate");
      if (element != null) {
        element.value = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
      }
    }
    if (this.AcademyInquiryStatus == 2) {
      this.tabGroup.selectedIndex = 1;
      this.AcademyInquiryStatus = -1;
    }
  }

  onclick() {
    $("body").addClass("modal-open");
    $('#menu-sidebar-left-1').addClass('menu-active')
  }

  onremoveclick() {
    $("body").removeClass("modal-open");
    $('#menu-sidebar-left-1').removeClass('menu-active')
  }

  Backclick() {
    if (window.history.state.dotpopup == true) {
      let data = {
        academyId: this.userdata.AcademyId,
        academyName: "",
        batchId: 0,
        batchName: "",
        parentBatchId: 0,
        parentBatchName: "",
      };
      let dashboardParam = data;

      this.router.navigate(['/inquiry'], {
        state: { example: dashboardParam },
      });
    }
    else if (window.history.state.userAcademyId != undefined) {
      this.router.navigate(['/studentmodule/studentprofile'], {
        state: { userAcademyId: window.history.state.userAcademyId, backInquiry: true }
      });
    }
    else {
      let data = {
        academyId: this.userdata.AcademyId,
        academyName: "",
        batchId: 0,
        batchName: "",
        parentBatchId: 0,
        parentBatchName: "",
      };
      let dashboardParam = data;

      this.router.navigate(['/inquiry'], {
        state: { example: dashboardParam },
      });
    }
  }
  tabchanges(val) {
    this.tabGroup.selectedIndex = val;
  }
  GetByStudentId(userAcademyId) {
    this.getlistService
      .GetStudentById(userAcademyId)
      .subscribe((response: any) => {
        let InquiryOption = 5;
        if (window.history.state.InquiryoptionData != undefined) {
          if (window.history.state.InquiryoptionData.InquiryOption == 3) {
            this.isShownFollowupDate = false;
            InquiryOption = 2;
            this.toggleShow(InquiryOption);

          }
          else {
            InquiryOption = 1;
          }
        }
        // let AcademyInquiryStatus
        this.SportNameChange(response.AcademyUserSport.AcademySportId);
        this.BatchNameChange(response.AcademyUserSport.BatchId);
        
        this.userId = response.AcademyUser.UserId,
          this.id = response.AcademyUser.Id;
        this.Schedulenameid = response.AcademyUserSport.AcademySubBatchId;
        this.subbatchId = response.AcademyUserSport.AcademySubBatchId;
        this.displayname = response.User.FirstName;
        this.displayphone = response.User.PhoneNumber;
        this.inquiryFormGroup.patchValue({
          StudentName: response.User.FirstName,
          DOB: this.datePipe.transform(response.User.DateOfBirth, 'MM/dd/yyyy'),
          GetGenderId: response.User.Gender,
          PhoneNumber: response.User.PhoneNumber,
          Email: response.User.Email,
          SchoolName: response.academyStudent.School,
          Standard: response.academyStudent.Standard,
          City: response.User.City,
          Address: response.User.Address1,
          State: response.User.State,
          Country: response.User.Country,
          InquiryComments: response.AcademyUser.Comments,
          GardianRealtionId1: (response.AcademyStudentContactList.length > 0) ? response.AcademyStudentContactList[0].Relation : 0,
          GardianName1: (response.AcademyStudentContactList.length > 0) ? response.AcademyStudentContactList[0].FirstName : "",
          GardianPhone1: (response.AcademyStudentContactList.length > 0) ? response.AcademyStudentContactList[0].PhoneNumber : "",
          GardianEmail1: (response.AcademyStudentContactList.length > 0) ? response.AcademyStudentContactList[0].Email : "",
          GardianIsPrimary1: (response.AcademyStudentContactList.length > 0) ? response.AcademyStudentContactList[0].Primary : false,
          GardianRealtionId2: (response.AcademyStudentContactList.length > 1) ? response.AcademyStudentContactList[1].Relation : 0,
          GardianName2: (response.AcademyStudentContactList.length > 1) ? response.AcademyStudentContactList[1].FirstName : "",
          GardianPhone2: (response.AcademyStudentContactList.length > 1) ? response.AcademyStudentContactList[1].PhoneNumber : "",
          GardianEmail2: (response.AcademyStudentContactList.length > 1) ? response.AcademyStudentContactList[1].Email : "",
          GardianIsPrimary2: (response.AcademyStudentContactList.length > 1) ? response.AcademyStudentContactList[1].Primary : false,
          BachesId: response.AcademyUserSport.BatchId,
          ScheduleId: response.AcademyUserSport.AcademySubBatchId,
          FromDate: response.AcademyUserAdmission.FromDate,
          AcademySportId: response.AcademyUserSport.AcademySportId,
          InquirySourceId: response.academyInquiry.InquirySource,
          PreferredCenterId: response.AcademyUser.AcademyId,
          AgeGroup: response.academyStudent.AgeGroupId,
          RealtionId: 0,
          AcademyInquiryStatusId: (window.history.state.InquiryoptionData != undefined) ? InquiryOption : 5,
          InquiryDate: response.academyInquiry.InquiryDate,
          expertFollowupTime: (response.academyInquiry.Status == 1 || response.academyInquiry.Status == 5 && response.academyInquiry.TrialDate != "0001-01-01T00:00:00") ? response.academyInquiry.TrialDate : null,
          expertAssessmentscheduleTime: (response.academyInquiry.Status == 1) ? response.academyInquiry.ExpectedEnrolmentDate : null,
        });


        if (window.history.state.InquiryoptionData == undefined) {
          if (response.academyInquiry.Status == 1) {
            this.isShownFollowupDate = true;
            this.isShownAssesmentScheduleDate = true;
            this.inquiryFormGroup.controls['AcademyInquiryStatusId'].setValue(response.academyInquiry.Status);
          }
          if (response.academyInquiry.Status == 5) {
            this.inquiryFormGroup.controls['AcademyInquiryStatusId'].setValue(response.academyInquiry.Status);
            this.isShownFollowupDate = true;
          }
        }

        this.GetMonthplan(this.Schedulenameid, 0);
        this.ScheduleNameChange(response.AcademyUserSport.AcademySubBatchId);
        this.GardianId1 = (response.AcademyStudentContactList.length > 0) ? response.AcademyStudentContactList[0].Id : 0;
        this.GardianId2 = (response.AcademyStudentContactList.length > 1) ? response.AcademyStudentContactList[1].Id : 0;
        this.GardianUserId1 = (response.AcademyStudentContactList.length > 0) ? response.AcademyStudentContactList[0].UserId : 0;
        this.GardianUserId2 = (response.AcademyStudentContactList.length > 1) ? response.AcademyStudentContactList[1].UserId : 0;
        this.FeesScheduleTypeId = response.AcademyUserAdmission.FeesScheduleTypeId;
        this.AcademyInquiryId = response.academyInquiry.Id;
        this.AcademyUserAdmissionToDate = response.AcademyUserAdmission.ToDate;
        this.InvoiceId = response.AcademyUserAdmission.InvoiceId;
        this.RegistrationNumber = response.AcademyUserAdmission.RegistrationNumber;
        this.AcademyUserSportId = response.AcademyUserSport.Id;
        this.AdjustFees = response.AdjustFees;
        this.CentreName = response.CentreName;
        this.IsEnroll = response.IsEnroll;
        this.IsGlobleUser = response.IsGlobleUser;
        this.IsGusetUser = response.IsGusetUser;
        this.IsInquiry = response.IsInquiry;
        this.IsRenew = response.IsRenew;
        this.IsRenewFeesSave = response.IsRenewFeesSave;
        this.ParentAcademyId = response.ParentAcademyId;
        this.EditUserId = response.User.Id;
        this.AgeGroupId = response.academyStudent.AgeGroupId;
        this.academyStudentGuardianUserid = response.academyStudent.GuardianUserid;
        this.academyStudentId = response.academyStudent.Id;
        this.academyStudentUserTypeId = response.academyStudent.UserTypeId;
        this.UploadIdAgeProofImageUrl = response.academyStudent.ProofTypePic;
        this.UploadimageUrl = response.User.Pic;
        this.ageCalculator(response.User.DateOfBirth);
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
        });
  }
  uploadFile(event) {
    if (event.target.id == "fileone") {
      var file1: File = event.target.files[0];
      var myReader: FileReader = new FileReader();
      myReader.onloadend = (e) => {
        this.UploadimageUrl = myReader.result;
      }
      myReader.readAsDataURL(file1);
    }
  }

  uploadAgeProofImage(event) {
    if (event.target.id == "filetwo") {
      var file2: File = event.target.files[0];
      var myReader1: FileReader = new FileReader();
      myReader1.onloadend = (e) => {
        this.UploadIdAgeProofImageUrl = myReader1.result;
      }
      myReader1.readAsDataURL(file2);
    }
  }

  toggleShow(event) {
    let value = (event.target != undefined) ? event.target.value : event;
    this.AcademyInquiryStatus = value;
    this.Usertypeid = value;
    if (this.Usertypeid == '24') {
      this.isShown = !this.isShown;
      this.idproofmenu = !this.idproofmenu;
    } else {
      this.isShown = false;
      this.idproofmenu = false;
    }

    if (this.AcademyInquiryStatus == '1') {
      this.isShownMonthPlan = false;
      this.isShown=true;
      this.isShownsubscriptionfees = false;
      this.isShownDuration = false;
      this.isFromDate = false;
      this.isShownfeesdetails = false;
      this.isShownFollowupDate = true;
      this.isShownAssesmentScheduleDate = true;
      this.isShownAssesmentDate = false;
    }

    if (this.AcademyInquiryStatus == '2') {
      this.idproofmenu = true;
      this.isShown=true;
      this.isShownMonthPlan = true;
      this.isShownsubscriptionfees = true;
      this.isShownDuration = true;
      this.isFromDate = true;
      this.isShownfeesdetails = true;
      this.isShownAssesmentDate = false;
      this.isShownFollowupDate = false;
      this.isShownAssesmentScheduleDate = false;
    }

    if (this.AcademyInquiryStatus == '4') {
      this.isShownMonthPlan = false;
      this.isShownsubscriptionfees = false;
      this.isShownDuration = false;
      this.isFromDate = false;
      this.isShownfeesdetails = false;
      this.isShownFollowupDate = true;
      this.isShownAssesmentScheduleDate = false;
      this.isShownAssesmentDate = true;
    }

    if (this.AcademyInquiryStatus == '5') {
      this.isShownMonthPlan = false;
      this.isShownsubscriptionfees = false;
      this.isShownDuration = true;
      this.isFromDate = false;
      this.isShownAssesmentDate = false;
      this.isShownfeesdetails = false;
      this.isShownFollowupDate = true;
    }
  }

  checkchkValue(type, event) {
    if (type == 1) {
      $('#GardianIsPrimary2').prop('checked', false);
    } else {
      $('#GardianIsPrimary1').prop('checked', false);
    }
  }

  SportNameChange(event) {
    let value = (event.target != undefined) ? event.target.value : event;
    this.sportnameid = value;
   // this.academyId = this.userdata.AcademyId;
    this.sportId = this.sportnameid;
    this.getlistService
      .GetBachesList(this.academyId, this.sportId)
      .subscribe((response: any) => {
        this.BachesList = response;
        // this.ScheduleList=[];
        // this.AcademyUserTypeList=[];
      },
        (error) => {

        });
  }

  BatchNameChange(event) {
    let value = (event.target != undefined) ? event.target.value : event;
    this.Batchnameid = value;
    this.batchId = this.Batchnameid;
    this.userId = this.userdata.UserId;
    this.RoleType = this.userdata.RoleType;
    this.getlistService
      .GetUserScheduleList(this.batchId, this.userId, this.RoleType)
      .subscribe((response: any) => {
        this.ScheduleList = response;
      },
        (error) => {
        });
  }
  ScheduleNameChange(event) {
    this.isShown=true;
    let value = (event.target != undefined) ? event.target.value : event;
    this.Schedulenameid = value;
     //this.academyId = this.userdata.AcademyId,
      this.subbatchId = this.Schedulenameid
    if (this.userdata.ApplyUserType == true) {
      this.isShownUserType = true;
      this.getlistService
        .GetAcademyUserTypeList(this.academyId, this.subbatchId)
        .subscribe((response: any) => {
          this.AcademyUserTypeList = response;
        },
          (error) => {
          });
    }
    else {
      this.GetMonthplan(this.Schedulenameid, 0);
    }
  }
  GetMonthplan(schedueleId, userTypeId) {
    this.getlistService
      .GetBatchFeesScheduleByBatchId(schedueleId)
      .subscribe((response: any) => {
        this.MonthPlanList = response;
        this.monthplanfees = [];
        for (let i = 0; i < response.length; i++) {
          this.monthplanfees.push(
            {
              ScheduleTypeId: response[i].ScheduleTypeId,
              Fees: response[i].Fees,
              Months: response[i].Months,
              AllowEdit: response[i].AllowEdit
            });
        }
      },
        (error) => {
        });
  }
  UserTypeChange(event) {
    let value = event.target.value;
    this.Usertypeid = value;
    this.GetMonthplan(this.Schedulenameid, this.Usertypeid)
  }
  MonthPlanChange(event) {
    let ID = (event.target != undefined) ? parseInt(event.target.value) : parseInt(event);
    this.monthplanchangeId = parseInt(event.target.value);
    for (let i = 0; i < this.monthplanfees.length; i++) {
      if (this.monthplanfees[i].ScheduleTypeId == ID) {
        this.inquiryFormGroup.controls['SubscriptionBeforeFees'].setValue(
          this.monthplanfees[i].Fees
        );
        if (this.ProrateOnDate > 0) {
          this.subfees = this.monthplanfees[i].Fees - this.ProrateOnDate;
        }
        else {
          this.subfees = this.monthplanfees[i].Fees;
        }
        this.tax = (this.subfees * this.TaxPercentage) / 100;
        this.SubscriptionFeesAmount = this.subfees + this.tax;
        $('#spn_SubscriptionFees').text(this.SubscriptionFeesAmount);
        setTimeout(() => {
          var studentfees = document.getElementsByClassName('studentfees');
          this.TotalAmount = 0;
          for (let j = 0; j < studentfees.length; j++) {
            let val = studentfees[j]['innerText'];
            if (parseInt(val) > 0) {
              this.TotalAmount = this.TotalAmount + parseInt(val);
            }
          }
        }, 1000);

        this.recurringamount = 0;
        this.recurringfees = 0;
        this.kitfeestax = 0;
      }
    }
  }
  AnnualFees(AcademyId, finalamount, monthplanchangeId, fromdate, duration, academyUserId, subbatchId, i) {

    this.getlistService
      .GetProrateAmountAnnualFees(AcademyId, finalamount, monthplanchangeId, fromdate, duration, academyUserId, subbatchId)
      .subscribe((response: any) => {
        if (response != '' && response != undefined) {
          let prorataDatafees = response;
          let ProrateOnDateAnnualFees = Math.round(prorataDatafees["ProrataAmount"]);
          $('#recurringamount' + i).text(ProrateOnDateAnnualFees);
          this.academyFeesTypeList[i].Recurring = finalamount;
          this.Feeslist[i].Recurring = finalamount;
          this.academyFeesTypeList[i].TotalAmount = ProrateOnDateAnnualFees;
          this.Feeslist[i].TotalAmount = ProrateOnDateAnnualFees;
          this.academyFeesTypeList[i].ProrataFeesAmount = finalamount - ProrateOnDateAnnualFees;
          this.Feeslist[i].ProrataFeesAmount = finalamount - ProrateOnDateAnnualFees;
        }
      })
  }
  DurationChange(event) {
    this.DurationChangedValue = parseInt(event.target.value);
    this.inquiryFormGroup.controls['DurationId'].setValue(this.DurationChangedValue);
    this.FromDateChanged()
    for (let i = 0; i < this.monthplanfees.length; i++) {
      if (this.monthplanfees[i].ScheduleTypeId == this.monthplanchangeId) {
        this.inquiryFormGroup.controls['SubscriptionBeforeFees'].setValue(
          this.monthplanfees[i].Fees
        );
        if (this.ProrateOnDate > 0) {
          this.subfees = this.monthplanfees[i].Fees - this.ProrateOnDate;
        }
        else {
          this.subfees = this.monthplanfees[i].Fees * this.DurationChangedValue;
        }

        this.tax = (this.TaxPercentage * this.DurationChangedValue * 10);
        this.SubscriptionFeesAmount = this.subfees + this.tax;
        $('#spn_SubscriptionFees').text(this.SubscriptionFeesAmount);

        setTimeout(() => {
          var studentfees = document.getElementsByClassName('studentfees');
          this.TotalAmount = 0;
          for (let j = 0; j < studentfees.length; j++) {
            let val = studentfees[j]['innerText'];
            if (parseInt(val) > 0) {
              this.TotalAmount = this.TotalAmount + parseInt(val);
            }
          }
        }, 1000);

        this.recurringamount = 0;
        this.recurringfees = 0;
        this.kitfeestax = 0;
      }
    }

  }
  KitFeesChange(event, id, feesId) {

    let value = event.target.value;
    this.kitfeeschangeId = event.target.value;
    this.kitfeesname = event.target.options[id].text;

    if (value != 0) {
      for (let i = 0; i < this.academyFeesTypeList.length; i++) {
        if (this.academyFeesTypeList[i].AcademyFeesTypeOptionId == value) {
          this.recurringfees = <HTMLInputElement>(
            document.getElementById('recurringfees' + id)
          );
          this.recurringfees.textContent = this.academyFeesTypeList[
            i
          ].Recurring;
          this.recurringamount = <HTMLInputElement>(
            document.getElementById('recurringamount' + id)
          );
          this.recurringamount.textContent = this.academyFeesTypeList[
            i
          ].Recurring;

          var studentfees = document.getElementsByClassName('studentfees');
          this.TotalAmount = 0;
          for (let j = 0; j < studentfees.length; j++) {
            let val = studentfees[j]['innerText'];
            if (parseInt(val) > 0) {
              this.TotalAmount = this.TotalAmount + parseInt(val);
            }
          }

          this.TempTotalAmount = this.TotalAmount;
        }
      }
    } else {
      this.recurringfees = <HTMLInputElement>(
        document.getElementById('recurringamount' + id)
      );
      this.recurringfees.textContent = '0';
      this.recurringamount = '';
      this.TotalAmount = this.subfees + this.tax - this.recurringamount;
    }

    for (let i = 0; i < this.Feeslist.length; i++) {
      if (this.Feeslist[i].Id == feesId) {
        for (let j = 0; j < this.academyFeesTypeList.length; j++) {
          if (this.academyFeesTypeList[j].AcademyFeesTypeOptionId == value) {
            this.Feeslist[i].AcademyFeesTypeOptionId = value;
            this.Feeslist[i].Recurring = this.academyFeesTypeList[j].Recurring;
          }
        }
      }
    }
  }

  WaiveOff(event, id, feesId) {
    if (event.target.checked == true) {
      for (let i = 0; i < this.Feeslist.length; i++) {
        if (this.Feeslist[i].Id == feesId) {
          this.recurringamount = <HTMLInputElement>(
            document.getElementById('recurringamount' + id)
          );
          this.Feeslist[i].WaiverOff = true;
          this.temprecurringamount = 0;
          this.recurringamount.textContent = 0;
        }
      }
    } else {
      for (let i = 0; i < this.Feeslist.length; i++) {
        if (this.Feeslist[i].Id == feesId) {
          this.recurringamount = <HTMLInputElement>(
            document.getElementById('recurringamount' + id)
          );
          this.Feeslist[i].WaiverOff = true;
          this.temprecurringamount = this.Feeslist[i].Recurring;
          this.recurringamount.textContent = this.Feeslist[i].Recurring;
        }
      }
    }
    var studentfees = document.getElementsByClassName('studentfees');
    this.TotalAmount = 0;
    for (let j = 0; j < studentfees.length; j++) {
      let val = studentfees[j]['innerText'];
      if (parseInt(val) > 0) {
        this.TotalAmount = this.TotalAmount + parseInt(val);
      }
    }
  }

  DiscountChange(event) {
    this.DiscountTypeChangevalue = event.target.value;
    this.inquiryFormGroup.controls['SubscriptionDiscountId'].setValue(0);
    if (this.DiscountTypeChangevalue == 1) {
      this.DiscountAmount = this.inquiryFormGroup.controls[
        'SubscriptionDiscountId'
      ].value;
      this.subfees = this.subfees - this.DiscountAmount;
      this.tax = (this.subfees * this.TaxPercentage) / 100;
      this.SubscriptionFeesAmount = this.subfees + this.tax;
    } else {
      this.DiscountAmount = this.inquiryFormGroup.controls[
        'SubscriptionDiscountId'
      ].value;
      if (this.DiscountAmount <= 100) {
        let dis = (this.subfees * this.DiscountAmount) / 100;


        let fees = this.inquiryFormGroup.controls['SubscriptionBeforeFees'].value;
        this.tax = (this.subfees * this.TaxPercentage) / 100;
        this.SubscriptionFeesAmount = this.subfees + this.tax;

        var studentfees = document.getElementsByClassName('studentfees');
        this.TotalAmount = 0;
        for (let j = 0; j < studentfees.length; j++) {
          let val = studentfees[j]['innerText'];
          if (parseInt(val) > 0) {
            this.TotalAmount = this.TotalAmount + parseInt(val);
          }
        }
      } else {
        this.notifyService.showError('Discount should be less then 100%', "");
        this.inquiryFormGroup.controls['SubscriptionDiscountId'].setValue(0);
        $('#SubscriptionDiscountId').text(0);
      }
    }
  }

  onKey(event: any) {
    // without type info
    let fees = this.inquiryFormGroup.controls['SubscriptionBeforeFees'];
    this.subfees = fees.value;
    if (this.DiscountTypeChangevalue == 1) {
      this.DiscountAmount = event.target.value;
      this.subfees = this.subfees - this.DiscountAmount;
      this.tax = (this.subfees * this.TaxPercentage) / 100;
      this.SubscriptionFeesAmount = this.subfees + this.tax;
      $('#spn_SubscriptionFees').text(this.SubscriptionFeesAmount);
    } else {
      this.DiscountAmount = event.target.value;
      if (this.DiscountAmount <= 100) {
        let dis = (this.subfees * this.DiscountAmount) / 100;
        this.subfees = this.subfees - dis;
        this.tax = (this.subfees * this.TaxPercentage) / 100;
        this.SubscriptionFeesAmount = this.subfees + this.tax;
        $('#spn_SubscriptionFees').text(this.SubscriptionFeesAmount);

        var studentfees = document.getElementsByClassName('studentfees');
        this.TotalAmount = 0;
        for (let j = 0; j < studentfees.length; j++) {
          let val = studentfees[j]['innerText'];
          if (parseInt(val) > 0) {
            this.TotalAmount = this.TotalAmount + parseInt(val);
          }
        }
      } else {
        this.notifyService.showWarning('Discount should be less then 100%', "");
        this.inquiryFormGroup.controls['SubscriptionDiscountId'].setValue(0);
        $('#SubscriptionDiscountId').text(0);
      }
    }
  }

  FromDateChanged() {
    this.fromdate = $('#FromDate').val();
    if (this.fromdate == '') {
      this.notifyService.showWarning('Please Select Fromdate.', "");
    } else {
      this.fees = this.subfees;

      if (
        this.DiscountTypeChangevalue == 1 ||
        this.DiscountAmount != '' ||
        this.DiscountAmount != undefined
      ) {
        this.disc = this.DiscountAmount;

        if (parseInt(this.disc) <= parseInt(this.fees)) {
          this.discountamount = this.DiscountAmount;
        } else {
          this.discountamount = '';
          this.discountamount = 0;

          if (this.DiscountAmount != undefined) {
            this.notifyService.showWarning('Discount should be less then fees.', "");
          }
        }
      } else if (
        this.DiscountTypeChangevalue == 2 ||
        this.DiscountAmount != '' ||
        this.DiscountAmount != undefined
      ) {
        this.p = this.DiscountAmount;
        if (parseInt(this.p) <= 100) {
          this.discountamount = (this.fees * this.p) / 100;
        } else {
          this.discountamount = '';
          this.discountamount = 0;
          this.notifyService.showWarning('Discount percentage should be less then 100.', "");
        }
      } else {
        this.discountamount = 0;
      }
      if (this.DurationChangedValue == undefined) {
        this.duration = 1;
      } else {
        this.duration = parseInt(this.DurationChangedValue);
      }

      this.finalamount = this.fees;

      if (
        this.fees != undefined &&
        this.fees != '' &&
        this.fromdate != '' &&
        this.fromdate != undefined
      ) {
        let mainfees = $('#txtfees').val();

        if (this.userdata.AcademyId == 391 || this.userdata.AcademyId == 392 || this.userdata.AcademyId == 394 || this.userdata.AcademyId == 393) {
          if (this.academyFeesTypeList.length > 0) {
            for (let i = 0; i < this.academyFeesTypeList.length; i++) {
              if (this.academyFeesTypeList[i].IsProrate == true) {
                this.AnnualFees(this.userdata.AcademyId, this.academyFeesTypeList[i].Recurring, this.monthplanchangeId, this.fromdate, this.duration, this.academyUserId, parseInt(this.subbatchId), i)
              }
            }
          }

        }


        this.getlistService
          .GetProrateAmount(this.userdata.AcademyId, mainfees, this.monthplanchangeId, this.fromdate, this.duration, this.academyUserId, parseInt(this.subbatchId))
          .subscribe((response: any) => {
            if (response != '' && response != undefined) {
              this.prorataData = response;
              this.EndDate = this.prorataData["todate"];
              if (this.prorataData["status"] == true) {
                this.ProrateOnDate = Math.round(this.prorataData["ProrataAmount"]);
                this.prorataFeesAmount = (mainfees * this.duration) - this.ProrateOnDate;
                let fees = this.inquiryFormGroup.controls['SubscriptionBeforeFees'].value;
                this.subfees = this.ProrateOnDate - this.discountamount;
                this.tax = (this.subfees * this.TaxPercentage) / 100;
                this.SubscriptionFeesAmount = this.subfees + Math.round(this.tax);
                $('#spn_SubscriptionFees').text(this.SubscriptionFeesAmount);
                this.GrandTotal();

              }
              else {
                $('#FromDate').val('');
                this.notifyService.showWarning(this.prorataData["message"], "");
                return false;
              }
            }

          })
      }


    }
    // fromdate function else over
  }
  GrandTotal() {
    var studentfees = document.getElementsByClassName('studentfees');
    this.TotalAmount = 0;
    for (let j = 0; j < studentfees.length; j++) {
      let val = studentfees[j]['innerText'];
      if (parseInt(val) > 0) {
        this.TotalAmount = this.TotalAmount + parseInt(val);
      }
    }
  }

  get f() { return this.inquiryFormGroup.controls; }

  public getPreferredCetnerList() {
    this.userId = this.userdata.UserId;
    this.parentacademyId = this.userdata.ParentAcademyId;
    this.getlistService
      .GetPreferredCetnerList(this.userId, this.parentacademyId)
      .subscribe((response: any) => {
        this.PreferredCenterList = response;
      },
        (error) => {
        });

  }

  public getInquirySourceList() {
    //this.academyId = this.userdata.AcademyId;
    this.getlistService
      .GetInquirySourceList(this.academyId)
      .subscribe((response: any) => {
        this.InquirySourceList = response;
      },
        (error) => {
        });
  }

  public CenterChange(event)
  {
    this.academyId=event.target.value;
    this.getAcademySportList(event.target.value);
    this.BachesList=[];
    this.ScheduleList=[];
    this.getInquirySourceList();
  }

  public getAcademySportList(academyId) {
    this.userId = this.userdata.UserId;
    this.getlistService
      .GetAcademySportList(this.userId,academyId)
      .subscribe((response: any) => {
        this.AcademySportList = response;
      },
        (error) => {
        });
  }

  public GetAgeGroupsByAcademyId() {
    this.academyId = this.userdata.AcademyId;
    this.getlistService
      .GetAgeGroupsByAcademyId(this.academyId)
      .subscribe((response: any) => {
        this.GetAgeGroupsList = response;
      },
        (error) => {
        });

  }

  public GetRealtionList() {
    this.RealtionList = [
      { id: 0, value: "Self", selected: true },
      { id: 1, value: "Father", selected: false },
      { id: 2, value: "Mother", selected: false },
      { id: 3, value: "Other", selected: false },
    ];
    this.GardinRelationList1 = [
      { id: 0, value: "Self" },
      { id: 1, value: "Father" },
      { id: 2, value: "Mother" },
      { id: 3, value: "Other" },
    ];
    this.GardinRelationList2 = [
      { id: 0, value: "Self" },
      { id: 1, value: "Father" },
      { id: 2, value: "Mother" },
      { id: 3, value: "Other" },
    ];
  }

  public GetDurationList() {
    this.DurationList = [
      { id: 1, value: "1" },
      { id: 2, value: "2" },
      { id: 3, value: "3" },
      { id: 4, value: "4" },
      { id: 5, value: "5" },
      { id: 6, value: "6" },
      { id: 7, value: "7" },
      { id: 8, value: "8" },
      { id: 9, value: "9" },
      { id: 10, value: "10" },
      { id: 11, value: "11" },
      { id: 12, value: "12" },
    ];
  }

  public GetIdAgeProofList() {
    this.academyId = this.userdata.AcademyId,
      this.getlistService
        .GetUserIdAgeProofList(this.academyId)
        .subscribe((response: any) => {
          this.IdAgeProofList = response;
        },
          (error) => {
          });
  }

  public GetAcademyInquiryStatuslist() {
    this.parentacademyId = this.userdata.ParentAcademyId,
      this.getlistService
        .GetAcademyInquiryStatuslist(this.parentacademyId)
        .subscribe((response: any) => {
          if (this.userAcademyId == 0) {
            for (let i = 0; i < response.length; i++) {
              if (response[i].StatusValue == 5) {
                this.AcademyInquiryStatuslist.push(response[i]);
              }
            }
            this.AcademyInquiryStatuslist.push({
              AcademyId: 0,
              EncId: null,
              EncStatusValue: null,
              Id: 0,
              InquiryStatus: "Prospective",
              StatusValue: 6
            });
          }
          else {
            for (let i = 0; i < response.length; i++) {
              this.AcademyInquiryStatuslist.push(response[i]);
            }
            this.AcademyInquiryStatuslist.push({
              AcademyId: 0,
              EncId: null,
              EncStatusValue: null,
              Id: 0,
              InquiryStatus: "Prospective",
              StatusValue: 6
            });
          }
        },
          (error) => {
          });
  }

  public GetAcademyFeesType() {
    this.spinner.show();
    (this.academyId = this.userdata.AcademyId),
      this.getlistService.GetAcademyFeesType(this.academyId).subscribe(
        (response: any) => {
          this.academyFeesTypeList = response;
          this.spinner.hide();
          for (let i = 0; i < this.academyFeesTypeList.length; i++) {
            if (this.academyFeesTypeList[i].IsSubFeesType == false) {
              this.RegitrationFeeslist.push(this.academyFeesTypeList[i]);
            }
          }
          for (let i = 0; i < this.academyFeesTypeList.length; i++) {
            if (this.academyFeesTypeList[i].IsSubFeesType == false) {
              this.Feeslist.push(this.academyFeesTypeList[i]);
            }
            if (this.academyFeesTypeList[i].IsSubFeesType == true) {
              this.recurring.push({
                AcademyFeesTypeOptionId: this.academyFeesTypeList[i]
                  .AcademyFeesTypeOptionId,
                kitfeesrecurrungId: this.academyFeesTypeList[i].Id,
                Recurring: this.academyFeesTypeList[i].Recurring,
              });
            }
          }
          var studentfees = document.getElementsByClassName('studentfees');
          this.TotalAmount = 0;
          for (let j = 0; j < studentfees.length; j++) {
            let val = studentfees[j]['innerText'];
            if (parseInt(val) > 0) {
              this.TotalAmount = this.TotalAmount + parseInt(val);
            }
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }
  public DiscountType() {
    this.DiscountTypeList = [
      { id: 1, value: "Percentage" },
      { id: 2, value: "Fixed" }
    ];

  }

  public GetTaxDetails() {
    this.parentacademyId = this.userdata.ParentAcademyId;
    this.getlistService
      .GetTaxDetails(this.parentacademyId)
      .subscribe((response: any) => {
        if (response != null) {
          this.TaxDetailList = response;
          this.TaxPercentage = response.TaxPercentage;
          this.Taxname = response.TaxName;
        }
      },
        (error) => {
        });
  }


  CheckEmailPhoneExist(email, phoneNumber, type) {
    let val1 = $('#first').val();
    let val2 = $('#txtFatherNumber').val();
    let val3 = $('#txtMotherNumber').val();
    if (type == 1) {
      if (val1 === val2 || val1 == val3) {
        $('#first').val('');
        this.notifyService.showWarning('Duplicate Phone number/Email entered for contacts', '');
      }
    } else if (type == 2) {
      if (val2 === val1 || val2 == val3) {
        $('#txtFatherNumber').val('');
        this.notifyService.showError('Duplicate Phone number/Email entered for contacts', "");
      }
    } else if (type == 3) {
      if (val3 === val1 || val3 == val2) {
        $('#txtMotherNumber').val('');
        this.notifyService.showError('Duplicate Phone number/Email entered for contacts', "");
      }
    }
    if (type == 1) {
      this.getlistService
        .CheckUserExist(email, phoneNumber, this.userdata.AcademyId, 0)
        .subscribe((result: any) => {
          if (result.Id != 0) {
            if (
              result.PhoneNumber == phoneNumber &&
              result.PhoneNumber != '' &&
              result.Email == email &&
              result.Email != ''
            ) {
              this.notifyService.showError('Phone number and email already exist.', "");
            } else if (
              result.PhoneNumber == phoneNumber &&
              result.PhoneNumber != ''
            ) {
              this.notifyService.showError('Phone number already exist.', "");
            } else if (result.Email == email && result.Email != '') {
              this.notifyService.showError('Email already exist.', "");
            }
          }
        });
    }
  }

  formValidate(frm) {
    var validationMsg = "";
    var isValidate = false;

    //Required Fields
    $('#' + frm + ' ' + '.k_required').each(function (index) {
      if ($(this).val() == "") {
        validationMsg = validationMsg + $(this)[0].dataset.message + '</br>';
        $(this).addClass("required")
      } else if ($(this).val() == "0") {
        validationMsg = validationMsg + $(this)[0].dataset.message + '</br>';
        $(this).addClass("required")
      } else {
        $(this).removeClass("required")
      }
    });
    return validationMsg;
  }

  checkuserphoneemail() {
    this.submitted = true;
    if (this.inquiryFormGroup.invalid) {
      let strrequired = "";
      if (this.inquiryFormGroup.value.InquirySourceId == "") {
        strrequired = strrequired + "Inquiry Source" + '</br>';
      }
      if (this.inquiryFormGroup.value.AcademySportId == "") {
        strrequired = strrequired + "Sport Name" + '</br>';
      }
      if (this.inquiryFormGroup.value.StudentName == "") {
        strrequired = strrequired + "Player Name" + '</br>';
      }
      if (this.inquiryFormGroup.value.AgeGroup == "") {
        strrequired = strrequired + "Age Group" + '</br>';
      }
      if (this.inquiryFormGroup.value.BachesId == "") {
        strrequired = strrequired + "Batch Name" + '</br>';
      }
      if (this.inquiryFormGroup.value.ScheduleId == "") {
        strrequired = strrequired + "Schedule Name" + '</br>';
      }
      if (strrequired != "") {
        this.SimpleModalService.addModal(AlertComponent, { title: 'Required Field!', message: strrequired });
        return;
      }
    }
    if (
      (this.inquiryFormGroup.value.PhoneNumber == "" || this.inquiryFormGroup.value.PhoneNumber == null) &&
      (this.inquiryFormGroup.value.GardianPhone1 == "" || this.inquiryFormGroup.value.GardianPhone1 == null) &&
      (this.inquiryFormGroup.value.GardianPhone2 == "" || this.inquiryFormGroup.value.GardianPhone2 == null)) {
      this.notifyService.showError('Required at least one phone number', "")
      return false;
    }
    if (this.userAcademyId == 0) {
      var stringcheckexist = '';
      var txtFatherEmailinq = '';
      var txtFatherNumberinq = '';
      var txtMotherEmailinq = '';
      var txtMotherNumberinq = '';
      var userIdfather = 0;
      var userIdmother = 0;
      if (this.inquiryFormGroup.value.GardianEmail1 != '' || this.inquiryFormGroup.value.GardianPhone1 != '') {
        if (this.inquiryFormGroup.value.GardianEmail1 == '') {
          txtFatherEmailinq = null;
        } else {
          txtFatherEmailinq = this.inquiryFormGroup.value.GardianEmail1;
        }
        if (this.inquiryFormGroup.value.GardianPhone1 == '') {
          txtFatherNumberinq = null;
        } else {
          txtFatherNumberinq = this.inquiryFormGroup.value.GardianPhone1;
        }
        stringcheckexist =
          stringcheckexist +
          txtFatherEmailinq +
          ',' +
          txtFatherNumberinq +
          ',' +
          this.userdata.AcademyId +
          ',' +
          userIdfather +
          ',' +
          1 +
          '/';
      }
      if (this.inquiryFormGroup.value.GardianEmail2 != '' || this.inquiryFormGroup.value.GardianPhone2 != '') {
        if (this.inquiryFormGroup.value.GardianEmail2 == '') {
          txtMotherEmailinq = null;
        } else {
          txtMotherEmailinq = this.inquiryFormGroup.value.GardianEmail2;
        }
        if (this.inquiryFormGroup.value.GardianPhone2 == '') {
          txtMotherNumberinq = null;
        } else {
          txtMotherNumberinq = this.inquiryFormGroup.value.GardianPhone2;
        }
        stringcheckexist =
          stringcheckexist +
          txtMotherEmailinq +
          ',' +
          txtMotherNumberinq +
          ',' +
          this.userdata.AcademyId +
          ',' +
          userIdmother +
          ',' +
          2 +
          '/';
      }

      this.getlistService
        .CheckUserExistApp(stringcheckexist)
        .subscribe((result: any) => {
          if (result.IsExist == true) {
            this.saveattendancecoach(result.Message)
          }
          else {

            this.CreateInquiry();
          }

        });
    }
    else {
      this.CreateInquiry();
    }
  }

  saveattendancecoach(Message) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: Message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.CreateInquiry();
        const a = document.createElement('a');
        a.click();
        a.remove();

      }
    });
  }

  CreateInquiry() {
    var userdata = this.commonFunction.GetUserData();
    for (let i = 0; i < this.Feeslist.length; i++) {
      this.studentFeesTypeList.push(this.Feeslist[i]);
    }
    let GetFessArrayList = <HTMLInputElement[]>(
      (<any>document.getElementsByClassName('GetkitfeesArrayList'))
    );
    if (this.userAcademyId == 0) {
      this.AddEditInquiryDataArray = {
        Sportlist: this.inquiryFormGroup.value.AcademySportId,
        strDOB: this.inquiryFormGroup.value.DOB,
        AdjustFees: false,
        ExtraBatch: false,
        FeesTraking: true,
        IsBatchFlexible: false,
        IsEnroll: false,
        IsGlobleUser: false,
        IsGusetUser: false,
        IsInquiry: false,
        IsRenew: false,
        IsRenewFeesSave: false,
        academyId: userdata.AcademyId,
        userId: userdata.UserId,
        isAccessAllBatches: userdata.isAccessAllBatches,
        parentacademyId: userdata.ParentAcademyId,
        RoleType: userdata.RoleType,
        batchId: this.Batchnameid,

        AcademyStudentContactList: [{
          Id: 0,
          AcademyUserId: this.userAcademyId,
          Email: this.inquiryFormGroup.value.GardianEmail1,
          FirstName: this.inquiryFormGroup.value.GardianName1,
          PhoneNumber: this.inquiryFormGroup.value.GardianPhone1,
          Primary: this.inquiryFormGroup.value.GardianIsPrimary1,
          Relation: this.inquiryFormGroup.value.GardianRealtionId1,
          UserId: 0
        },
        {
          Id: 0,
          AcademyUserId: this.userAcademyId,
          Email: this.inquiryFormGroup.value.GardianEmail2,
          FirstName: this.inquiryFormGroup.value.GardianName2,
          PhoneNumber: this.inquiryFormGroup.value.GardianPhone2,
          Primary: this.inquiryFormGroup.value.GardianIsPrimary2,
          Relation: this.inquiryFormGroup.value.GardianRealtionId2,
          UserId: 0
        }],
        AcademyUser:
        {
          AcademyId: userdata.AcademyId,
          Comments: this.inquiryFormGroup.value.InquiryComments
        },

        AcademyUserSport:
        {
          Id: 0,
          AcademySportId: parseInt(this.inquiryFormGroup.value.AcademySportId),
          AcademySubBatchId: this.inquiryFormGroup.value.ScheduleId,	//271
          AcademyUserId: this.userAcademyId,
          BatchId: this.inquiryFormGroup.value.BachesId// 911	int?,
        },

        User:
        {
          Id: 0,
          Address1: this.inquiryFormGroup.value.Address,
          AgeGroupId: this.inquiryFormGroup.value.AgeGroup, //	null	int?
          City: this.inquiryFormGroup.value.City,//	"Ahmedabad"	string
          Country: this.inquiryFormGroup.value.Country,//null	string
          CreatedBy: userdata.userId,
          CreatedDate: new Date(),
          DateOfBirth: this.inquiryFormGroup.value.DOB,//	null	System.DateTime?
          Email: this.inquiryFormGroup.value.Email,//"dharm@gmail.com"	string
          FirstName: this.inquiryFormGroup.value.StudentName,// "dharmadeep"	string
          Latitude: this.inquiryFormGroup.value.Latitude,
          Longitude: this.inquiryFormGroup.value.Longitude,
          ModifiedBy: userdata.UserId,
          ModifiedDate: new Date(),
          PhoneNumber: this.inquiryFormGroup.value.PhoneNumber,//	"70417603"	string
          Pic: null,
          State: this.inquiryFormGroup.value.State//	"Gujarat"	string
        },

        academyInquiry:
        {
          AcademyId: userdata.AcademyId,//	0	int
          AcademyUserId: this.userAcademyId,
          Comments: this.inquiryFormGroup.value.InquiryComments,//	"Comments2"	string,
          CreatedBy: userdata.UserId,//null	int?
          CreatedDate: new Date(),//null	System.DateTime?
          InquiryDate: this.inquiryFormGroup.value.InquiryDate,//	{10/6/2020 12:00:00 AM}	System.DateTime?,
          InquirySource: this.inquiryFormGroup.value.InquirySourceId,//	3	int?,
          ModifiedBy: userdata.UserId,
          ModifiedDate: new Date(),//	null	System.DateTime?,
          PreferredAcademyId: this.inquiryFormGroup.value.PreferredCenterId,//	null	int?,
          Status: this.inquiryFormGroup.value.AcademyInquiryStatusId//	5	int?
        },
        academyBatchData:
        {
          Fees: this.inquiryFormGroup.value.SubscriptionBeforeFees
        },
        academyStudent:
        {
          AcademyId: userdata.AcademyId,
          AgeGroupId: this.inquiryFormGroup.value.AgeGroup,//	364	int?,
          IsActive: false,//	bool,
          Comments: this.inquiryFormGroup.value.InquiryComments,
          School: this.inquiryFormGroup.value.SchoolName,//"NM "	string,
          Standard: this.inquiryFormGroup.value.Standard,//	"12th"	string,
          UserTypeId: this.inquiryFormGroup.value.PreferredCenterId//	65	int?,
        },
      };
    }

    else {
      this.AddEditInquiryDataArray = {
        AdjustFees: this.AdjustFees,
        CentreName: this.CentreName,
        ExtraBatch: false,
        IsBatchFlexible: false,
        FeesTraking: true,
        IsEnroll: this.IsEnroll,
        IsGlobleUser: this.IsGlobleUser,
        IsGusetUser: this.IsGusetUser,
        IsInquiry: this.IsInquiry,
        IsRenew: this.IsRenew,
        IsRenewFeesSave: this.IsRenewFeesSave,
        ParentAcademyId: this.userdata.ParentAcademyId,
        hdnSumofTotalFees: this.TotalAmount,
        hdnSumofTotalwithTax: this.SubscriptionFeesAmount,
        ProrataFeesAmount: this.prorataFeesAmount,
        hdnSumofTaxAmount: this.tax,
        IsAllBatchAccess: true,
        AcademyFeesTypeList: this.studentFeesTypeList,
        Sportlist: this.inquiryFormGroup.value.AcademySportId,
        strDOB: this.datePipe.transform(this.inquiryFormGroup.value.DOB, 'MM/dd/yyyy hh:mm:ss'),
        AcademyStudentContactList: [
          {
            AcademyUserId: this.userAcademyId,
            Email: this.inquiryFormGroup.value.GardianEmail1,
            FirstName: this.inquiryFormGroup.value.GardianName1,
            PhoneNumber: this.inquiryFormGroup.value.GardianPhone1,
            Primary: this.inquiryFormGroup.value.GardianIsPrimary1,
            Relation: this.inquiryFormGroup.value.GardianRealtionId1,
            UserId: this.GardianUserId1,
            Id: this.GardianId1
          },
          {
            AcademyUserId: this.userAcademyId,
            Email: this.inquiryFormGroup.value.GardianEmail2,
            FirstName: this.inquiryFormGroup.value.GardianName2,
            PhoneNumber: this.inquiryFormGroup.value.GardianPhone2,
            Primary: this.inquiryFormGroup.value.GardianIsPrimary2,
            Relation: this.inquiryFormGroup.value.GardianRealtionId2,
            UserId: this.GardianUserId2,
            Id: this.GardianId2
          },
        ],
        AcademyUser: {
          AcademyId: this.userdata.AcademyId, //2	int
          Comments: this.inquiryFormGroup.value.InquiryComments,
          UserId: this.userId,
          Id: this.userAcademyId,
          IsActive: false
        },

        AcademyUserSport: {
          Id: this.AcademyUserSportId,
          AcademySportId: this.inquiryFormGroup.value.AcademySportId,
          AcademySubBatchId: this.inquiryFormGroup.value.ScheduleId, //271
          BatchId: this.inquiryFormGroup.value.BachesId, // 911 int?,
        },

        AcademyUserAdmission: {
          CreatedBy: this.userdata.UserId, //	2371	int?
          CreatedDate: new Date(), //	{10/16/2020 4:04:39 PM}	System.DateTime?
          DiscountType: this.inquiryFormGroup.value.SubscriptionDiscountTypeId, //2,//	int?
          DiscountPercent: this.inquiryFormGroup.value.SubscriptionDiscountId, //50.0,//	decimal?
          Duration: this.inquiryFormGroup.value.DurationId, //	1,	//int?
          FeesMode: this.inquiryFormGroup.value.MonthplanId, //	int half yearly , ?
          FeesScheduleTypeId: this.inquiryFormGroup.value.ScheduleId, //4,//	int?
          FromDate: this.inquiryFormGroup.value.FromDate, //{9/9/2020 12:00:00 AM}	System.DateTime?
          ModifiedBy: this.userdata.UserId, //	null	int?
          ModifiedDate: new Date(), //	null	System.DateTime?
          ToDate: this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm:ss'), // end date	{11/30/2021 12:00:00 AM}	System.DateTime?,
        },
        User: {
          Id: this.EditUserId,
          Address1: this.inquiryFormGroup.value.Address,
          Address2: this.inquiryFormGroup.value.Address,
          AgeGroupId: this.inquiryFormGroup.value.AgeGroup,
          Gender: this.inquiryFormGroup.value.GetGenderId,
          City: this.inquiryFormGroup.value.City,
          Country: this.inquiryFormGroup.value.Country,
          CreatedBy: this.userdata.userId,
          CreatedDate: new Date(),
          DateOfBirth: this.datePipe.transform(this.inquiryFormGroup.value.DOB, 'MM/dd/yyyy hh:mm:ss'),
          Email: this.inquiryFormGroup.value.Email,
          FirstName: this.inquiryFormGroup.value.StudentName,
          ModifiedBy: this.userdata.userId,
          ModifiedDate: new Date(),
          PhoneNumber: this.inquiryFormGroup.value.PhoneNumber,
          Pic: this.UploadimageUrl,
          State: this.inquiryFormGroup.value.State,
        },
        ProfileImage: {
          img: this.UploadimageUrl
        },
        academyFeesHeader: {
          AcademyFeesOptionId: null,
          AcademyFeesTypeId: null, // null,//	int?
          Amount: this.SubscriptionFeesAmount, //250.0,	//decimal?
          CreatedBy: this.userdata.userId, //int?
          CreatedDate: new Date(), //System.DateTime?
          Fees: this.subfees, //250.0,	//decimal?
          Tax: this.TaxPercentage, //	0	,//int?
          TaxAmount: this.tax, //decimal?
        },
        academyInquiry:
        {
          Id: this.AcademyInquiryId,
          AcademyId: userdata.AcademyId,//	0	int
          AcademyUserId: this.userAcademyId,
          Comments: this.inquiryFormGroup.value.InquiryComments,//	"Comments2"	string,
          CreatedBy: userdata.UserId,//null	int?
          CreatedDate: new Date(),//null	System.DateTime?
          InquiryDate: this.datePipe.transform(this.inquiryFormGroup.value.InquiryDate, 'MM/dd/yyyy hh:mm:ss'),//	{10/6/2020 12:00:00 AM}	System.DateTime?,
          InquirySource: this.inquiryFormGroup.value.InquirySourceId,//	3	int?,
          ModifiedBy: userdata.UserId,
          ModifiedDate: new Date(),//	null	System.DateTime?,
          PreferredAcademyId: this.inquiryFormGroup.value.PreferredCenterId,//	null	int?,
          Status: this.inquiryFormGroup.value.AcademyInquiryStatusId,//	5	int?
          TrialDate: this.datePipe.transform(this.inquiryFormGroup.value.expertFollowupTime, 'MM/dd/yyyy hh:mm:ss'),
          ExpectedEnrolmentDate: this.datePipe.transform(this.inquiryFormGroup.value.expertAssessmentscheduleTime, 'MM/dd/yyyy hh:mm:ss')
        },

        academyStudent: {
          Id: this.academyStudentId,
          AcademyId: this.userdata.AcademyId, //0 int,
          AgeGroupId: this.inquiryFormGroup.value.AgeGroup, // 364 int?,
          IsActive: true, // bool,
          ProofTypeId: this.inquiryFormGroup.value.ProofTypeId,
          ProofTypePic: this.UploadIdAgeProofImageUrl,
          GuardianUserid: this.academyStudentGuardianUserid,
          School: this.inquiryFormGroup.value.SchoolName, //"NM " string,
          Standard: this.inquiryFormGroup.value.Standard, // "12th" string,
          UserTypeId: this.academyStudentUserTypeId, // 65 int?,
          UserId: this.userdata.UserId,
          Category: 0,
          Subcategory: 0
        },
        academyId: this.userdata.AcademyId,
        userId: this.userdata.UserId,
        isAccessAllBatches: true,
        RoleType: this.userdata.RoleType,
        batchId: this.Batchnameid,
      };

    }

    var getUserDatathis = this.commonFunction.GetUserData();
    this.getlistService.getAcademyPackageExpire(getUserDatathis.ParentAcademyId).subscribe(
      (response: any) => {
        if (response.PackageErrorMessage != "") {
          this.onremoveclick();
          this.strrequired = response.PackageErrorMessage;
          if (this.strrequired != "") {
            this.SimpleModalService.addModal(AlertComponent, { title: getUserDatathis.SelectAcademyName, message: this.strrequired });
          }
        }
        else {
          this.spinner.show();
          this.postservice
            .CreateInquiry(this.AddEditInquiryDataArray, this.userAcademyId)
            .subscribe((response: any) => {
              this.AcademyInquiryStatuslist = response;
              this.notifyService.showSuccess("Inquiry saved successfully!", "");
              this.router.navigate(['/inquiry']);
              this.spinner.hide();
            },
              (error) => {
                this.submitted = false;
                this.notifyService.showError("Unable to save inqury. Please try again later. Contact the admin if problem persists.", "");
                this.spinner.hide();
              });
        }
      },
      (error) => {
      }
    );
  }
}
