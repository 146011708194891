<div id="page">
    <a class="skip-link" href="#maincontent">Skip to main</a>
    <!-- Header -->
    <div class="header header-fixed header-logo-center">
        <span (click)="back();" data-back-button class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <h2 class="header-title textcolor">Recent Payments</h2>
        <span class="header-icon header-icon-3 textcolor" (click)="openpopupfilterfeesdetails()">
            <img width="16" height="16" src="../../../assets/images/icons/filter.png" alt="" />
        </span>
        <span (click)="onclick(menuopenstatus)" data-menu="menu-sidebar-left-1"
            class="header-icon header-icon-4 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
        </span>
        <div class="profile-section">
            <div class="feesinfo-header">
                <h2>{{UserName}}</h2>
                <h3 *ngIf="OutStadning==true" class="font-14 textcolor m-0 d-flex">Outstanding: &nbsp; <span class="p-0 border-0 d-flex align-items-center" ><span class="p-0 pull-left border-0" [innerHTML]="currencysymbol"></span> {{OutStandingAmount}}</span></h3>
                <div class="lightblack btnmargin">
                    <div class="row m-0">
                        <div class="col-12 text-left"><b>{{diffDays}}</b> days left for renewal</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Header -->

    <!-- Main Content -->
    <div class="page-content header-clear-medium">
        <div class="recent-payment pb-0">
            <div class="inputsection">
                <button type="button" (click)="OnclickSelectdFees()" class="w-100 btn loginheadercolor textcolor" *ngIf="newPaymentButton==true">New Payment</button>
                <button type="button" (click)="OnclickSelectdFees()" class="w-100 btn loginheadercolor textcolor" *ngIf="payButton==true">Pay Now</button>
            </div>
            <div id="recent-payment-data" *ngFor="let item of RecentPaymentsDetails;index as i">

                <ng-Container *ngIf="item.FeesName == 'Subscription Fees' &&isViewAll==false && i<=2">
                    <div class="row lightgreen" (click)="opendotpopup(item);" *ngIf="item.OutStandingAmount == 0">
                        <div class="col-7"><span>{{item.FeesName}}</span><div class="date">{{item.PaymentDate|date:'dd MMM yyyy'}}</div></div>
                        <div class="col-5 text-right pricebar"><span class="d-flex align-items-center"><span class="p-0" [innerHTML]="currencysymbol"></span> {{item.Amount}}</span></div>
                    </div>

                    <div class="row lightred" (click)="opendotpopup(item);" *ngIf="item.OutStandingAmount != 0">
                        <div class="col-7"><span>{{item.FeesName}}</span><div class="date">{{item.PaymentDate|date:'dd MMM yyyy'}}</div></div>
                        <div class="col-5 text-right pricebar"><span class="d-flex align-items-center"><span class="p-0" [innerHTML]="currencysymbol"></span> {{item.Amount}}</span></div>
                    </div>
                </ng-Container>

                <ng-Container *ngIf="item.FeesName != 'Subscription Fees' &&isViewAll==false && i<=2">
                    <div class="row lightyellow" (click)="opendotpopup(item);" *ngIf="item.OutStandingAmount == 0">
                        <div class="col-7"><span>{{item.FeesName}}</span><div class="date">{{item.PaymentDate|date:'dd MMM yyyy'}}</div></div>
                        <div class="col-5 text-right pricebar"><span class="d-flex align-items-center"><span class="p-0" [innerHTML]="currencysymbol"></span> {{item.Amount}}</span></div>
                    </div>

                    <div class="row lightred" (click)="opendotpopup(item);" *ngIf="item.OutStandingAmount != 0">
                        <div class="col-7"><span>{{item.FeesName}}</span><div class="date">{{item.PaymentDate|date:'dd MMM yyyy'}}</div></div>
                        <div class="col-5 text-right pricebar"><span class="d-flex align-items-center"><span class="p-0" [innerHTML]="currencysymbol"></span> {{item.Amount}}</span></div>
                    </div>
                </ng-Container>

                <ng-Container *ngIf="item.FeesName == 'Subscription Fees' &&isViewAll==true">
                    <div class="row lightgreen" (click)="opendotpopup(item);" *ngIf="item.OutStandingAmount == 0">
                        <div class="col-7"><span>{{item.FeesName}}</span><div class="date">{{item.PaymentDate|date:'dd MMM yyyy'}}</div></div>
                        <div class="col-5 text-right pricebar"><span class="d-flex align-items-center"><span class="p-0" [innerHTML]="currencysymbol"></span> {{item.Amount}}</span></div>
                    </div>

                    <div class="row lightred" (click)="opendotpopup(item);" *ngIf="item.OutStandingAmount != 0">
                        <div class="col-7"><span>{{item.FeesName}}</span><div class="date">{{item.PaymentDate|date:'dd MMM yyyy'}}</div></div>
                        <div class="col-5 text-right pricebar"><span class="d-flex align-items-center"><span class="p-0" [innerHTML]="currencysymbol"></span> {{item.Amount}}</span></div>
                    </div>
                </ng-Container>


                <ng-Container *ngIf="item.FeesName != 'Subscription Fees' &&isViewAll==true">
                    <div class="row lightyellow" (click)="opendotpopup(item);" *ngIf="item.OutStandingAmount == 0">
                        <div class="col-7"><span>{{item.FeesName}}</span><div class="date">{{item.PaymentDate|date:'dd MMM yyyy'}}</div></div>
                        <div class="col-5 text-right pricebar"><span class="d-flex align-items-center"><span class="p-0" [innerHTML]="currencysymbol"></span> {{item.Amount}}</span></div>
                    </div>

                    <div class="row lightred" (click)="opendotpopup(item);" *ngIf="item.OutStandingAmount != 0">
                        <div class="col-7"><span>{{item.FeesName}}</span><div class="date">{{item.PaymentDate|date:'dd MMM yyyy'}}</div></div>
                        <div class="col-5 text-right pricebar"><span class="d-flex align-items-center"><span class="p-0" [innerHTML]="currencysymbol"></span> {{item.Amount}}</span></div>
                    </div>
                </ng-Container>
            </div>
            <div class="less-all-btn">
                <span (click)="ViewClick(true)" *ngIf="isViewAll==false&&!NoFound">View All</span>
                <span (click)="ViewClick(false)" *ngIf="isViewAll==true">View Less</span>
            </div>
        </div>
        <div class="parameter_report_info eventdate" *ngIf="NoFound">
            <ul>
                <li class="notificationfound">
                    <div class="student-list">No records found.</div>
                </li>
            </ul>
        </div>
    </div>
</div>