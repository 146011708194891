<div class="header header-fixed header-logo-center" id="popup3">
  <span (click)="closemodel()" class="header-icon header-icon-1 textcolor">
    <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
  </span>
  <h2 class="header-title textcolor">Feedback</h2>
  <div class="titlearea">
    <p class="textcolor m-0">Provide feedback for {{displayAttendanceDate}}</p>
  </div>
</div>
<div class="page-content header-clear-medium detail popup-mainarea">
  <div class="d-block">
    <div class="content border-0">
      <input type="hidden">
      <div class="inputsection">
        <span class="color-highlight input-title font-14">Homework</span>
        <textarea class="form-control" id="txt_homework" rows="2" placeholder="Homework"></textarea>
      </div>
      <div class="inputsection">
        <span class="color-highlight input-title font-14">Diet</span>
        <textarea class="form-control" id="txt_diet" rows="2" placeholder="Diet"></textarea>
      </div>
      <div class="inputsection">
        <span class="color-highlight input-title font-14">Comments</span>
        <textarea class="form-control" id="txt_comment" rows="2" placeholder="Comments"></textarea>
      </div>
      <!-- <div class="inputsection">
        <div class="image-upload">
          <label for="filetwo1" >
            <div *ngIf="profilephoto1 != ''" style="margin-bottom: 10px;">
              <img [src]="profilephoto1">
            </div>
            <button (click)="selectImage1.click()" class="btn btn-block loginheadercolor textcolor">Pic 1</button>
          </label>
          <input hidden type="file" name="filetwo1" id="filetwo1" (change)="uploadAgeProofImage1($event)" #selectImage1>
        </div>
      </div>
      <div class="inputsection" >
        <div class="image-upload">
          <label for="filetwo2">
            <div *ngIf="profilephoto2 != ''" style="margin-bottom: 10px;">
              <img [src]="profilephoto2">
            </div>
            <button (click)="selectImage2.click()" class="btn btn-block loginheadercolor textcolor">Pic 2</button>
          </label>
          <input hidden type="file" name="filetwo2" id="filetwo2" (change)="uploadAgeProofImage1($event)" #selectImage2>
        </div>
      </div>
      <div class="inputsection" >
        <div class="image-upload">
          <label for="filetwo3">
            <div *ngIf="profilephoto3 != ''" style="margin-bottom: 10px;">
              <img [src]="profilephoto3">
            </div>
            <button (click)="selectImage3.click()" class="btn btn-block loginheadercolor textcolor">Pic 3</button>
          </label>
          <input hidden type="file" name="filetwo3" id="filetwo3" (change)="uploadAgeProofImage1($event)" #selectImage3>
        </div>
      </div> -->
      <div class="inputsection">
        <button (click)="SaveAttendance()" class="btn btn-block loginheadercolor textcolor">Submit</button>
      </div>
    </div>
  </div>
</div>