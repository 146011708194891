<div id="page" class="graybg">
    <!-- Header -->
    <div class="header header-fixed header-logo-center">
      <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
        <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
      </span>
      <h2 class="header-title textcolor">Feedback ( {{date}} )</h2>
      <span (click)="onclick()" data-menu="menu-sidebar-left-1" class="header-icon header-icon-4 textcolor">
        <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
      </span>
      <div class="titlearea">
        <div class="logo"><img alt="" src="{{userimg}}"></div>
        <div class="pull-left">
          <p class="textcolor m-0">{{stdName}}</p>
          <p class="textcolor m-0">Mobile: {{stdContact}}</p>
          <p class="textcolor m-0">Age: {{stdAge}}</p>
        </div>
      </div>
    </div>
    <!-- Header -->
    <div class="page-content header-clear-medium graybg" (click)="onremoveclick()">
      <div class="parameter_report_info CoachReportRating pt-0">
        <!-- Add New Report Start  -->
        <ul *ngIf='isAdd==true'>
          <ng-container *ngFor="let item of lstCategory; let i = index">
            <li class="info-title infoli">
              <div class="width75">{{item.CategoryName}}</div>
              <div class="width25 text-right" (click)="OpenCommentPopup(item,1)"><img
                  src="../../assets/images/comments1.png" alt="" class="w-img-20"></div>
            </li>
            <li class="infoli" *ngFor="let item1 of lstReport; let j = index">
              <ng-container *ngIf='item.CategoryName==item1.CategoryName'>
                <div class="boxdiv">
                  <!-- EvaluationTypeId == 1  start -->
                  <ng-container *ngIf='item1.EvaluationTypeId==1 && item1.Max > 10 '>
                    <!-- <ng-container *ngIf="item.CategoryName=='REMARKS_&_RECOMMENDATIONS'">
                        <div class="input d-inline-block">
                          <div class="infotitle w-100"><span class="pull-left">REMARKS &amp; RECOMMENDATIONS</span>
                            <img src="../../assets/images/KridApp-Image/info.png" class="infoicon">
                          </div>
                          <div class="parameter_report w-100">
                            <div class="infoinput w-100">
                              <div class="item-input item-input-field">
                                <textarea class="form-control" id="txt_homework" rows="2" placeholder=""></textarea>
                              </div>
                              <div class="infosize"> </div>
                            </div>
                          </div>
                        </div>
                    </ng-container> -->
                    <!-- <ng-container *ngIf="item.CategoryName!='REMARKS_&_RECOMMENDATIONS'"> -->
                      <div class="input">
                        <div class="infotitle"><span class="pull-left">{{item1.ParameterName}}</span><img
                            src="../../assets/images/KridApp-Image/info.png" class="infoicon" (click)="openInfoModal(item1);"></div>
                        <div class="parameter_report">
                          <div class="infoinput">
                            <div class="item-input item-input-field">
                              <input type="number" id="txt_{{item1.SportParameterId}}"
                                (change)="ChangeText(item1.SportParameterId,$event,item1.Min , item1.Max)"
                                class="form-control" value="">
                            </div>
                            <div class="infosize">{{item1.UOM}} </div>
                          </div>
                        </div>
                      </div>
                    <!-- </ng-container> -->
                   
                    <div class="input" *ngIf='item1.DrillsName!=""'>
                      <div class="infotitle font-14"><span class="pull-left">{{item1.DrillsName}}</span></div>
                      <div class="parameter_report">
                        <div class="infoinput">
                          <div class="item-input item-input-field">
                            <input type="number" (change)="ChangeDrills(item1.SportParameterId,$event)"
                              class="form-control" value="">
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
  
                  <ng-container *ngIf='item1.EvaluationTypeId==1 && item1.Max <= 10'>
                    <div class="d-inline-block w-100">
                      <div class="width75">
                        <div class="infotitle"><span class="pull-left">{{item1.ParameterName}}</span><img
                            src="../../assets/images/KridApp-Image/info.png" class="infoicon"
                            (click)="openInfoModal(item1);"></div>
                      </div>
                      <div class="width25">
                        <div class="infosize">{{item1.UOM}} </div>
                      </div>
                      <div class="rating">
                        <ul id="ul_rating_{{item1.SportParameterId}}">
                          <li class="blank" (click)="ChangeRating(item1.SportParameterId ,k,  item1.Max)"
                            *ngFor="let item2 of counter(item1.Max); let k = index"><span>{{k+1}}</span></li>
                        </ul>
                        <div class="comment" (click)="OpenCommentPopup(item1,2)"><img
                            src="../../assets/images/comments1.png" alt="" class="w-img-20"></div>
                      </div>
                    </div>
                    <div class="input" *ngIf='item1.DrillsName!=""'>
                      <div class="infotitle font-14"><span class="pull-left">{{item1.DrillsName}}</span></div>
                      <div class="parameter_report">
                        <div class="infoinput">
                          <div class="item-input item-input-field">
                            <input type="number" (change)="ChangeDrills(item1.SportParameterId,$event)"
                              class="form-control" value="">
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- EvaluationTypeId == 1  End -->
  
                  <!-- EvaluationTypeId == 2  start -->
                  <ng-container *ngIf='item1.EvaluationTypeId==2'>
                    <!-- <ng-container *ngIf="item.CategoryName=='REMARKS_&_RECOMMENDATIONS'">
                      <div class="input d-inline-block">
                        <div class="infotitle w-100"><span class="pull-left">REMARKS &amp; RECOMMENDATIONS</span>
                          <img src="../../assets/images/KridApp-Image/info.png" class="infoicon">
                        </div>
                        <div class="parameter_report w-100">
                          <div class="infoinput w-100">
                            <div class="item-input item-input-field">
                              <textarea class="form-control" id="txt_homework" rows="2" placeholder=""></textarea>
                            </div>
                            <div class="infosize"> </div>
                          </div>
                        </div>
                      </div>
                    </ng-container> -->
  
                    <!-- <ng-container *ngIf="item.CategoryName!='REMARKS_&_RECOMMENDATIONS'"> -->
                    <div class="input d-inline-block">
                      <div class="infotitle w-100"><span class="pull-left">{{item1.ParameterName}}</span><img
                          src="../../assets/images/KridApp-Image/info.png" class="infoicon"></div>
                      <div class="parameter_report w-100">
                        <div class="infoinput w-100 m-0">
                          <div class="item-input item-input-field">
                            <textarea class="form-control" id="txt_{{item1.SportParameterId}}" rows="2" 
                            (change)="ChangeText(item1.SportParameterId,$event,item1.Min , item1.Max)"
                            placeholder=""></textarea>
                            <!--<input type="text" id="txt_{{item1.SportParameterId}}" class="form-control" value="">-->
                          </div>
                          <div class="infosize">{{item1.UOM}} </div>
                        </div>
                      </div>
                    </div>
                   <!-- </ng-container> -->
                    <div class="input" *ngIf='item1.DrillsName!=""'>
                      <div class="infotitle font-14"><span class="pull-left">{{item1.DrillsName}}</span></div>
                      <div class="parameter_report">
                        <div class="infoinput">
                          <div class="item-input item-input-field">
                            <input type="number" (change)="ChangeDrills(item1.SportParameterId,$event)"
                              class="form-control" value="">
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- EvaluationTypeId == 2  End -->
  
                  <!-- EvaluationTypeId == 3  start -->
                  <ng-container *ngIf='item1.EvaluationTypeId==3'>
                    <div class="input">
                      <div class="infotitle"><span class="pull-left">{{item1.ParameterName}}</span><img
                          src="../../assets/images/KridApp-Image/info.png" class="infoicon"></div>
                      <div class="parameter_report">
                        <div class="infoinput">
                          <div class="item-input item-input-field">
                            <select class="form-control" (change)="ChangeDropdown(item1.SportParameterId,$event)">
                              <option value="0">Select</option>
                              <option value="1">Yes</option>
                              <option value="2">No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="input" *ngIf='item1.DrillsName!=""'>
                      <div class="infotitle font-14"><span class="pull-left">{{item1.DrillsName}}</span></div>
                      <div class="parameter_report">
                        <div class="infoinput">
                          <div class="item-input item-input-field">
                            <input type="number" (change)="ChangeDrills(item1.SportParameterId,$event)"
                              class="form-control" value="">
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- EvaluationTypeId == 3  End -->
  
                  <!-- EvaluationTypeId == 4  start -->
                  <ng-container *ngIf='item1.EvaluationTypeId==4'>
                    <div class="input">
                      <div class="infotitle"><span class="pull-left">{{item1.ParameterName}}</span><img
                          src="../../assets/images/KridApp-Image/info.png" class="infoicon"></div>
                      <div class="parameter_report">
                        <div class="infoinput">
                          <div class="item-input item-input-field">
                            <select class="form-control" (change)="ChangeDropdown(item1.SportParameterId,$event)">
                              <option value="0">Select</option>
                              <option value="1">Yes</option>
                              <option value="2">No</option>
                              <option value="3">May be</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="input" *ngIf='item1.DrillsName!=""'>
                      <div class="infotitle font-14"><span class="pull-left">{{item1.DrillsName}}</span></div>
                      <div class="parameter_report">
                        <div class="infoinput">
                          <div class="item-input item-input-field">
                            <input type="number" (change)="ChangeDrills(item1.SportParameterId,$event)"
                              class="form-control" value="">
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- EvaluationTypeId == 4  End -->
                </div>
              </ng-container>
            </li>
          </ng-container>
        </ul>
        <!-- Add New Report End  -->
        <!-- Edit Report Start  -->
        <ul *ngIf='isAdd==false'>
          <ng-container *ngFor="let item of lstCategory; let i = index">
            <li class="info-title infoli">
              <div class="width75">{{item.CategoryName}}</div>
              <div class="width25 text-right" (click)="OpenCommentPopup(item , 1)"><img
                  src="../../assets/images/comments1.png" alt="" class="w-img-20"></div>
            </li>
            <li class="infoli" *ngFor="let item1 of lstReport; let j = index">
              <ng-container *ngIf='item.CategoryName==item1.CategoryName'>
                <div class="boxdiv">
                  <!-- EvaluationTypeId == 1  start -->
                  <ng-container *ngIf='item1.EvaluationTypeId==1 && item1.Max > 10 '>
                    <!-- <ng-container *ngIf="item.CategoryName=='REMARKS_&_RECOMMENDATIONS'">
                      <div class="input d-inline-block">
                        <div class="infotitle w-100"><span class="pull-left">REMARKS &amp; RECOMMENDATIONS</span><img
                            src="../../assets/images/KridApp-Image/info.png" class="infoicon"></div>
                        <div class="parameter_report w-100">
                          <div class="infoinput w-100">
                            <div class="item-input item-input-field"><textarea class="form-control" id="txt_homework" rows="2"
                                placeholder=""></textarea>
                            </div>
                            <div class="infosize"> </div>
                          </div>
                        </div>
                      </div>
                    </ng-container> -->
                    <!-- <ng-container *ngIf="item.CategoryName!='REMARKS_&_RECOMMENDATIONS'"> -->
                      <div class="input">
                        <div class="infotitle"><span class="pull-left">{{item1.ParameterName}}</span><img
                            src="../../assets/images/KridApp-Image/info.png" class="infoicon" (click)="openInfoModal(item1);"></div>
                        <div class="parameter_report">
                          <div class="infoinput">
                            <div class="item-input item-input-field">
                              <input type="number" value="{{item1.Value}}" id="txt_{{item1.SportParameterId}}"
                                (change)="ChangeText(item1.SportParameterId,$event,item1.Min , item1.Max)" class="form-control" value="">
                            </div>
                            <div class="infosize">{{item1.UOM}} </div>
                          </div>
                        </div>
                      </div>
                    <!-- </ng-container> -->
                  
                    <div class="input" *ngIf='item1.DrillsName!=""'>
                      <div class="infotitle font-14"><span class="pull-left">{{item1.DrillsName}}</span></div>
                      <div class="parameter_report">
                        <div class="infoinput">
                          <div class="item-input item-input-field">
                            <input type="number" value="{{item1.SportDrillValue}}"
                              (change)="ChangeDrills(item1.SportParameterId,$event)" class="form-control" value="">
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
  
                  <ng-container *ngIf='item1.EvaluationTypeId==1 && item1.Max <= 10'>
                    <div class="d-inline-block w-100">
                      <div class="width75">
                        <div class="infotitle"><span class="pull-left">{{item1.ParameterName}}</span><img
                            src="../../assets/images/KridApp-Image/info.png" class="infoicon"
                            (click)="openInfoModal(item1);"></div>
                      </div>
                      <div class="width25">
                        <div class="infosize">{{item1.UOM}} </div>
                      </div>
                      <div class="rating">
                        <ul id="ul_rating_{{item1.SportParameterId}}">
                          <ng-container *ngFor="let item2 of counter(item1.Max); let k = index">
                            <li class="fill" *ngIf="k < item1.Value "
                              (click)="ChangeRating(item1.SportParameterId ,k,  item1.Max)"><span>{{k+1}}</span>
                            </li>
                            <li class="blank" *ngIf="k >= item1.Value"
                              (click)="ChangeRating(item1.SportParameterId ,k,  item1.Max)"><span>{{k+1}}</span>
                            </li>
                          </ng-container>
                        </ul>
                        <div class="comment" (click)="OpenCommentPopup(item1,2)"><img
                            src="../../assets/images/comments1.png" alt="" class="w-img-20"></div>
                      </div>
                    </div>
                    <div class="input" *ngIf='item1.DrillsName!=""'>
                      <div class="infotitle font-14"><span class="pull-left">{{item1.DrillsName}}</span></div>
                      <div class="parameter_report">
                        <div class="infoinput">
                          <div class="item-input item-input-field">
                            <input type="number" value="{{item1.SportDrillValue}}"
                              (change)="ChangeDrills(item1.SportParameterId,$event)" class="form-control">
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- EvaluationTypeId == 1  End -->
  
                  <!-- EvaluationTypeId == 2  start -->
                  <ng-container *ngIf='item1.EvaluationTypeId==2'>
                    <!-- <ng-container *ngIf="item.CategoryName=='REMARKS_&_RECOMMENDATIONS'">
                      <div class="input d-inline-block">
                        <div class="infotitle w-100"><span class="pull-left">REMARKS &amp; RECOMMENDATIONS</span><img
                            src="../../assets/images/KridApp-Image/info.png" class="infoicon"></div>
                        <div class="parameter_report w-100">
                          <div class="infoinput w-100">
                            <div class="item-input item-input-field"><textarea class="form-control" id="txt_homework"
                                rows="2" placeholder=""></textarea>
                            </div>
                            <div class="infosize"> </div>
                          </div>
                        </div>
                      </div>
                    </ng-container> -->
                    <!-- <ng-container *ngIf="item.CategoryName!='REMARKS_&_RECOMMENDATIONS'"> -->
                    <div class="input d-inline-block">
                      <div class="infotitle w-100"><span class="pull-left">{{item1.ParameterName}}</span><img
                          src="../../assets/images/KridApp-Image/info.png" class="infoicon"
                          (click)="openInfoModal(item1);"></div>
                      <div class="parameter_report w-100">
                        <div class="infoinput w-100 m-0">
                          <div class="item-input item-input-field">
                            <textarea class="form-control" id="txt_{{item1.SportParameterId}}"
                            (change)="ChangeText(item1.SportParameterId,$event,item1.Min , item1.Max)"
                            value="{{item1.Value}}" rows="2" placeholder=""></textarea>
                            <!--<input type="text" value="{{item1.Value}}" id="txt_{{item1.SportParameterId}}" class="form-control">-->
                          </div>
                          <div class="infosize">{{item1.UOM}} </div>
                        </div>
                      </div>
                    </div>
                  <!-- </ng-container> -->
                    <div class="input" *ngIf='item1.DrillsName!=""'>
                      <div class="infotitle font-14"><span class="pull-left">{{item1.DrillsName}}</span></div>
                      <div class="parameter_report">
                        <div class="infoinput">
                          <div class="item-input item-input-field">
                            <input type="number" value="{{item1.SportDrillValue}}"
                              (change)="ChangeDrills(item1.SportParameterId,$event)" class="form-control" value="">
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- EvaluationTypeId == 2  End -->
  
                  <!-- EvaluationTypeId == 3  start -->
                  <ng-container *ngIf='item1.EvaluationTypeId==3'>
                    <div class="input">
                      <div class="infotitle"><span class="pull-left">{{item1.ParameterName}}</span><img
                          src="../../assets/images/KridApp-Image/info.png" class="infoicon"
                          (click)="openInfoModal(item1);"></div>
                      <div class="parameter_report">
                        <div class="infoinput">
                          <div class="item-input item-input-field">
                            <select [(ngModel)]="item1.Value" class="form-control" (change)="ChangeDropdown(item1.SportParameterId,$event)">
                              <option value="0">Select</option>
                              <option value="1">Yes</option>
                              <option value="2">No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="input" *ngIf='item1.DrillsName!=""'>
                      <div class="infotitle font-14"><span class="pull-left">{{item1.DrillsName}}</span></div>
                      <div class="parameter_report">
                        <div class="infoinput">
                          <div class="item-input item-input-field">
                            <input type="number" value="{{item1.SportDrillValue}}"
                              (change)="ChangeDrills(item1.SportParameterId,$event)" class="form-control" value="">
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- EvaluationTypeId == 3  End -->
  
                  <!-- EvaluationTypeId == 4  start -->
                  <ng-container *ngIf='item1.EvaluationTypeId==4'>
                    <div class="input">
                      <div class="infotitle"><span class="pull-left">{{item1.ParameterName}}</span><img
                          src="../../assets/images/KridApp-Image/info.png" class="infoicon"
                          (click)="openInfoModal(item1);"></div>
                      <div class="parameter_report">
                        <div class="infoinput">
                          <div class="item-input item-input-field">
  
                            <select [(ngModel)]="item1.Value" class="form-control"
                              (change)="ChangeDropdown(item1.SportParameterId,$event)">
                              <option value="null">Select</option>
                              <option value="1">Yes</option>
                              <option value="2">No</option>
                              <option value="3">May be</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="input" *ngIf='item1.DrillsName!=""'>
                      <div class="infotitle font-14"><span class="pull-left">{{item1.DrillsName}}</span></div>
                      <div class="parameter_report">
                        <div class="infoinput">
                          <div class="item-input item-input-field">
                            <input type="number" value="{{item1.SportDrillValue}}"
                              (change)="ChangeDrills(item1.SportParameterId,$event)" class="form-control" value="">
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- EvaluationTypeId == 4  End -->
                </div>
              </ng-container>
            </li>
          </ng-container>
        </ul>
        <!-- Edit Report End  -->
        <div class="content">
          <button type="button" (click)="SaveUserReport()"
            class="btn btn-block loginheadercolor textcolor">Submit</button>
        </div>
      </div>
    </div>
  </div>