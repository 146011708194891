import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from 'src/app/service/commonfunction';
import { GetListService } from '../../service/getlist.service';
import { PostService } from '../../service/post.service';
import { NotificationService } from 'src/app/service/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from 'src/app/common/confirmation-dialog/confirmation-dialog.component';
@Component({
  selector: 'app-popupviewtransaction',
  templateUrl: './popupviewtransaction.component.html',
  styleUrls: ['./popupviewtransaction.component.scss']
})
export class PopupviewtransactionComponent implements OnInit {
  transaction:any;
  ViewFeesTransactionDetails:any;
  FeesType;
  feesHeaderId;
  feesDetailsId;
  Amount;
  Penalty_Discount;
  TotalAmount;
  OutstandingAmount;
  SubStartDate;
  SubEndDate;
  ExtensionDays;
  Monthplan;
  PaymentDate;
  ModeOfPayment;
  ReceivedBy;
  BankName;
  ChequeNumber;
  DraftNumber;
  TransactionId;
  AdminApproveDisplay;
  TransactionDetails;
  DraftDate;
  Role:any;
  UserData;
  TaxName;
  TaxPercent;
  TaxableAmount;
  KitType;
  FillStatus:boolean=false;
  ApprovedStatus:boolean=false;
  RejectStatus:boolean=false;
  constructor( 
    public getlistservice: GetListService,
    public postService:PostService,
    public commonFunction: CommonFunction,
    public route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public notifyService:NotificationService,
    public router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopupviewtransactionComponent>) { }

  ngOnInit(): void {
    this.UserData=this.commonFunction.GetUserData();
    this.transaction = this.data.val;
    this.feesHeaderId=this.transaction.HeaderId;
    this.feesDetailsId=(this.transaction!=undefined)?this.transaction.Id:0;
    this.ViewFeesTransaction();
  } 

  close() {
    this.dialogRef.close();
  }

  ViewFeesTransaction(){
    this.getlistservice
    .ViewFeesTransactionDetails(this.feesHeaderId,this.feesDetailsId)
    .subscribe((response: any) => {

      this.Role=this.UserData.RoleType;
      this.ViewFeesTransactionDetails=response;
      this.Amount=response[0].ActualAmount;
      this.TaxPercent=response[0].TaxPercent;
      this.TaxableAmount=response[0].TaxableAmount;
      this.TaxName=response[0].TaxName;
      this.Penalty_Discount=response[0].Penalty_Discount;
      this.TotalAmount=response[0].ActualAmount + response[0].Penalty_Discount;
      this.OutstandingAmount=response[0].OutstandingAmount;
      this.SubStartDate=response[0].FromDate;
      this.SubEndDate=response[0].ToDate;
      this.ExtensionDays=response[0].ExtensionDays;
      this.Monthplan=response[0].MonthPlan;
      this.PaymentDate=response[0].PaymentDate;
      this.ModeOfPayment=response[0].ModeOfPayment;
      this.ReceivedBy=response[0].ReceivedBy;
      this.BankName=response[0].BankName;
      this.ChequeNumber=response[0].ChequeNumber;
      this.DraftNumber=response[0].DraftNumber;
      this.DraftDate=response[0].DraftDate;
      this.TransactionId=response[0].TransactionId;
      this.AdminApproveDisplay =response[0].AdminApproveDisplay;
      this.TransactionDetails=response[0].TransactionDetails;
      this.FeesType=response[0].FeesType;
      this.KitType=response[0].FeesOptionName;
  },
  (error) => {
  });
  }
  Close()
  {
    this.dialogRef.close();
  }

  AdminApproval()
  {
    var Message="Do you wish to approve the payment?";
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: Message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.postService
        .AdminApproval(this.transaction.Id,true)
        .subscribe((response: any) => {
          if(response==true)
          {
            this.FillStatus=true;
            this.ApprovedStatus=true;
            this.RejectStatus=false;
            this.AdminApproveDisplay=1;
          }
      },
      (error) => {
      });
      }
      else
      {
        this.postService
        .AdminApproval(this.transaction.Id,false)
        .subscribe((response: any) => {
          if(response==false)
          {
            this.FillStatus=true;
            this.RejectStatus=true;
            this.ApprovedStatus=false;
            this.AdminApproveDisplay=1;
          }
      },
      (error) => {
      });
      }
    });
  }
  EmailInvoice()
  {
    this.UserData=this.commonFunction.GetUserData();
    this.spinner.show();
    var data={
      userId:this.UserData.UserId,
      email:this.UserData.UserEmail,
      parentAcademyId:this.UserData.ParentAcademyId,
      feesHeaderID: this.feesHeaderId
    }
    this.getlistservice
    .EmailInvoice(data)
    .subscribe((response: any) => {
       if(response==true)
       {
        this.notifyService.showSuccess("Email sent successfully to your register email id.", "");
        this.spinner.hide();
        this.dialogRef.close();
       }
    },
    (error) => {
      this.notifyService.showError("Unable not Email sent to your register email id", "");
      this.spinner.hide();
    });
  }
}
