<!-- Main Page -->
<div id="page">
    <form [formGroup]="PrivateattendanceForm">
        <input type="hidden" value="" formControlName="Latitude">
        <input type="hidden" value="" formControlName="Longitude">
        <!-- Header -->
        <div class="header header-fixed header-logo-center">
            <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
                <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
            </span>
            <h2 class="header-title textcolor">Attendance Date</h2>
            <span (click)="onclick()" data-menu="menu-sidebar-left-1" class="header-icon header-icon-4 textcolor">
                <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
            </span>
        </div>
        <!-- Header -->
        <!-- Main Content-->
        <div class="page-content detail header-clear-medium" (click)="onremoveclick()">
            <div class="content">
                <div class="inputsection">
                    <span class="input-title font-14">Date<span class="requird-field">*</span></span>
                    <div class="input-style input-required dob-input">
                        <input id="AttendanceDate" class="form-control" type="text" mwlFlatpickr
                            (change)="ChangeDate($event)" formControlName="AttendanceDate" dateFormat="m/d/Y"
                            ref="flatpickr" maxDate="today">
                    </div>
                </div>
                <div class="inputsection">
                    <button (click)="openStudentlistModal()"
                        class="btn mt-2 btn-block loginheadercolor textcolor">Next</button>
                </div>
            </div>
            <img style="display: none;" width="16" height="16" src="../../../assets/images/icons/chevron-left.png"
                alt="" />
            <img style="display: none;" class="editiconbtn" alt=""
                src="../../../assets/images/KridApp-Image/editicon.png">
        </div>
        <!-- Main Content-->
    </form>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
        <p class="color-white"> Loading... </p>
    </ngx-spinner>
</div>