<div id="page">
    <div class="header header-fixed header-logo-center">
        <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <h2 class="header-title textcolor">Feedback</h2>
        <span (click)="onclick()" data-menu="menu-sidebar-left-1" class="header-icon header-icon-4 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
        </span>
    </div>

    <div class="page-content detail header-clear-medium" (click)="onremoveclick()">
        <form [formGroup]="feedbackForm" (ngSubmit)="feedbackSubmit()">
            <div class="content">
                <div class="inputsection">
                    <span class="input-title font-14">Subject<span class="requird-field">*</span></span>
                    <div class="input-style input-required">
                        <input class="form-control" type="text" placeholder="Subject" formControlName="subject"
                            [ngClass]="{ 'is-invalid': submitted && f.subject.errors }">
                        <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                            <div *ngIf="f.subject.errors.required">This is required field</div>
                        </div>
                    </div>
                </div>
                <div class="inputsection">
                    <span class="input-title font-14">Body<span class="requird-field">*</span></span>
                    <div class="input-style input-required">
                        <input class="form-control" type="text" placeholder="Body" formControlName="body"
                            [ngClass]="{ 'is-invalid': submitted && f.body.errors }">
                        <div *ngIf="submitted && f.body.errors" class="invalid-feedback">
                            <div *ngIf="f.body.errors.required">This is required field</div>
                        </div>
                    </div>
                </div>
                <div class="inputsection">
                    <button type="submit" class="btn-block loginheadercolor textcolor">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>