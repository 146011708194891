import { Component, OnInit } from '@angular/core';
import { GetListService } from 'src/app/service/getlist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from 'src/app/service/commonfunction';
@Component({
  selector: 'app-collectionreport',
  templateUrl: './collectionreport.component.html',
  styleUrls: ['./collectionreport.component.scss']
})
export class CollectionreportComponent implements OnInit {
  CollectionReportList:any=[];
  CollectionReportListTotal:any=[];
  currencysymbol: any;
  constructor(
    public getlistservice: GetListService,
    public commonFunction: CommonFunction,
    public route: ActivatedRoute,
    public router: Router,
   
  ) { }

  ngOnInit(): void {
    let currentDate=new Date();
    let userdata=this.commonFunction.GetUserData();
    let data={
      academyId:userdata.AcademyId,
      batchId:0,
      subBatchId:0,
      month:(currentDate.getMonth()+1),
      year:currentDate.getFullYear(),
      sportId:0,
      userType:0
    };
    this.currencysymbol=userdata.CountryCurrencySymbol;
     this.GetCollectionReport(data);
  }

  back()
  {
    this.router.navigate(['/dashboard'])
  }

  GetCollectionReport(data)
  {
    this.getlistservice
    .GetCollectionReport(data)
    .subscribe((response: any) => {

      let ResponseLength=response.length;

      for(let i=0;i<response.length-1;i++)
      {
        this.CollectionReportList.push(response[i]);
      }
      this.CollectionReportListTotal.push(response[ResponseLength-1]);
    },
      (error) => {
      });

  }

  filterinquiry()
  {
    alert('test');
  }
}
