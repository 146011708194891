import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GetListService } from '../../service/getlist.service';
import { CommonFunction } from '../../service/commonfunction';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardParam } from 'src/app/_models/user.model';
import { PopupstudentlistComponent } from '../popupstudentlist/popupstudentlist.component';
import { IdbService } from 'src/app/service/idb.service';


@Component({
  selector: 'app-popupbatches',
  templateUrl: './popupbatches.component.html',
  styleUrls: ['./popupbatches.component.scss']
})
export class PopupbatchesComponent implements OnInit {
  dashboardparam: DashboardParam;
  public GetStudentList: any[];
  public GetBatchesList: any = [];
  public GetBatchesListNew: any = [];
  public slotlist: any = [];
  Dayofweek: Date;
  userdata: any;
  slotlistcount;
  slottime;
  MultipleBatchSchedule: boolean;
  dayofweekk: any;
  attendanceDate: any = new Date(this.data.attendDate);;
  StudnetListArray: any = [];
  LeaveTypeListSArray: any = [];
  public GetScheduleList: any = [];
  SelectedStudentCount: string = "0";
  CurrentDate: any;
  constructor(
    public dialogRef: MatDialogRef<PopupbatchesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public getListService: GetListService,
    public commonFunction: CommonFunction,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public idbService: IdbService,
  ) { }

  ngOnInit(): void {

    this.userdata = this.commonFunction.GetUserData();
    this.batchesList();
    this.dayofweekk = this.attendanceDate.getDay();
    this.MultipleBatchSchedule = this.data.MultipleBatchSchedule;
  }

  selectChangeHandler(event: any) {
    this.slottime = event.target.value;
  }

  openStudentlistModal(): void {
    var ScheduleListId = new Array();
    var GetAllcheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("getAllcheckBoxValue"));
    if (this.MultipleBatchSchedule == true) {
      var slottt = (<HTMLInputElement>document.getElementById('slotvalue')).value;

      if (this.slottime == null) {
        this.slottime = slottt;
      }
    }
    else {
      this.slottime = "";
    }

    let SelectedCheckboxName = "";
    for (let i = 0; i < GetAllcheckbox.length; i++) {
      if (GetAllcheckbox[i].checked) {
        ScheduleListId.push(Number(GetAllcheckbox[i].value));
        SelectedCheckboxName += GetAllcheckbox[i].name + ',';
      }
    }
    const dialogRef = this.dialog.open(PopupstudentlistComponent, {
      width: '100%',
      height: '100%',
      data: {
        SelectedCheckboxName: SelectedCheckboxName,
        AttendanceDate: (this.attendanceDate != undefined) ? this.attendanceDate : this.CurrentDate,
        BatchId: ScheduleListId,
        Latitude: this.data.Latitude,
        Longitude: this.data.Longitude,
        slot: this.slottime
      }
    });


    dialogRef.afterClosed().subscribe(result => {

    });
  }

  batchesList() {
    this.spinner.show();
    var data = {
      academyId: this.userdata.AcademyId,
      userId: this.userdata.UserId,
      isAccessAllBatches: this.userdata.isAccessAllBatches
    };
    if (window.navigator.onLine == false) { // Ofline
      this.idbService.getAllData("academybatch").then((item: any) => {
        let batches = item;
        this.GetBatchesList = batches;
        this.idbService.getAllData("subbatch").then((item: any) => {
          let subbatches = item;


          this.idbService.getAllData("academyuserattendance").then((attendance: any) => {
            let userattendance = attendance;
            let todayattendance: any;
            if (userattendance.length > 0) {
              for (let index = 0; index < userattendance.length; index++) {
                const element = userattendance[index];
                let attendancedate = new Date(element.userAttendance.AttendanceDate).getDate();
                let curdate = new Date().getDate();
                if (attendancedate == curdate) {

                }
              }
            }
          });


          for (let i = 0; i < subbatches.length; i++) {
            if (this.data.GetScheduleList != undefined) {
              for (let j = 0; j < this.data.GetScheduleList.length; j++) {
                if (subbatches[i].Id == this.data.GetScheduleList[j]) {
                  this.GetScheduleList[i] = {
                    Id: subbatches[i].Id,
                    BatchName: subbatches[i].BatchName,
                    checked: true,
                    ParentBatchId: subbatches[i].ParentBatchId
                  };
                  break;
                }
                else {
                  this.GetScheduleList[i] = {
                    Id: subbatches[i].Id,
                    BatchName: subbatches[i].BatchName,
                    checked: false,
                    ParentBatchId: subbatches[i].ParentBatchId
                  };
                }
              }
            }
            else {
              if (this.data.parentBatchId > 0) {
                this.GetScheduleList.push({
                  Id: subbatches[i].Id,
                  BatchName: subbatches[i].BatchName,
                  checked: true,
                  ParentBatchId: subbatches[i].ParentBatchId
                });
              }
              else {

                this.GetScheduleList.push({
                  Id: subbatches[i].Id,
                  BatchName: subbatches[i].BatchName,
                  checked: false,
                  ParentBatchId: subbatches[i].ParentBatchId
                });


              }
              let BatchIdArray = this.GetUniqueArrayDrills(this.GetScheduleList)
              this.GetBatchesListNew = [];
              for (let i = 0; i < BatchIdArray.length; i++) {
                for (let j = 0; j < this.GetBatchesList.length; j++) {
                  if (BatchIdArray[i] == this.GetBatchesList[j].Id) {
                    this.GetBatchesListNew.push(this.GetBatchesList[j])
                  }
                }
              }
              this.spinner.hide();
            }
          }
        })
      })

    }
    else {
      this.getListService
        .getBatchesList(data)
        .subscribe((response: any) => {
          let idstring = "";
          for (let i = 0; i < response.length; i++) {

            this.GetBatchesList.push({
              Id: response[i].Id,
              BatchName: response[i].BatchName,
              checked: false
            });
          }
          idstring = Array.prototype.map.call(this.GetBatchesList, s => s.Id).toString();
          this.spinner.hide();
          this.scheduleList(idstring)

        },
          (error) => {

          });
    }
  }
  public scheduleList(id: any) {
    this.spinner.show();
    this.Dayofweek = new Date(this.data.attendDate);
    var userdata = this.commonFunction.GetUserData();
    var data = {
      academyId: userdata.AcademyId,
      batchIds: id,
      userId: userdata.UserId,
      dayofWeek: this.Dayofweek.getDay(),
      roleId: userdata.RoleType
    };
    this.getListService
      .getScheduleList(data)
      .subscribe((response: any) => {
        for (let i = 0; i < response.length; i++) {
          if (this.data.GetScheduleList != undefined) {
            for (let j = 0; j < this.data.GetScheduleList.length; j++) {
              if (response[i].Id == this.data.GetScheduleList[j]) {
                this.GetScheduleList[i] = {
                  Id: response[i].Id,
                  BatchName: response[i].BatchName,
                  checked: true,
                  ParentBatchId: response[i].ParentBatchId,
                  DayOfWeek: response[i].DayOfWeek
                };
                break;
              }
              else {
                this.GetScheduleList[i] = {
                  Id: response[i].Id,
                  BatchName: response[i].BatchName,
                  checked: false,
                  ParentBatchId: response[i].ParentBatchId,
                  DayOfWeek: response[i].DayOfWeek
                };
              }
            }
          }
          else {
            if (this.data.parentBatchId > 0) {
              this.GetScheduleList.push({
                Id: response[i].Id,
                BatchName: response[i].BatchName,
                checked: true,
                ParentBatchId: response[i].ParentBatchId,
                DayOfWeek: response[i].DayOfWeek
              });
            }
            else {
              this.GetScheduleList.push({
                Id: response[i].Id,
                BatchName: response[i].BatchName,
                checked: false,
                ParentBatchId: response[i].ParentBatchId,
                DayOfWeek: response[i].DayOfWeek
              });

            }

          }
        }
        let BatchIdArray = this.GetUniqueArrayDrills(this.GetScheduleList)
        this.GetBatchesListNew = [];
        for (let i = 0; i < BatchIdArray.length; i++) {
          for (let j = 0; j < this.GetBatchesList.length; j++) {
            if (BatchIdArray[i] == this.GetBatchesList[j].Id) {
              this.GetBatchesListNew.push(this.GetBatchesList[j])
            }
          }
        }
      },
        (error) => {

        });
    this.spinner.hide();
  }
  GetUniqueArrayDrills(array) {
    var flags = [], output = [], l = array.length, i;
    for (i = 0; i < l; i++) {
      if (flags[array[i].ParentBatchId]) continue;
      flags[array[i].ParentBatchId] = true;
      output.push(array[i].ParentBatchId);
    }
    return output;
  }
  closemodel() {
    this.dialogRef.close();

  }

  scheduleDiv(id) {
    let GetSchByDom = (<HTMLInputElement>document.getElementById('schchchk_' + id));
    if (GetSchByDom.checked == true) {
      GetSchByDom.checked = true;
    }
    else {
      GetSchByDom.checked = false;
    }
  }


  scheduleDivclick(id) {

    var GetAllcheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("getAllcheckBoxValue"));
    let GetSchByDom = (<HTMLInputElement>document.getElementById('schchchk_' + id));
    for (var i = 0; i < GetAllcheckbox.length; i++) {
      GetAllcheckbox[i].checked = false;
    }
    GetSchByDom.checked = true;

    var Getalldrop = (<HTMLInputElement[]><any>document.getElementsByClassName("dropdown"));
    let dropbox = (<HTMLInputElement>document.getElementById('DropDown_' + id));

    this.getListService.GetBatchSlotTime(id, this.dayofweekk).subscribe((data: any) => {
      this.slotlist = data;
      this.slotlistcount = data.length;
      if (this.slotlistcount > 0) {
        for (var i = 0; i < Getalldrop.length; i++) {
          Getalldrop[i].hidden = true;
        }
        dropbox.hidden = false;
      }
      else {
        for (var i = 0; i < Getalldrop.length; i++) {
          dropbox[i] = true;
        }
      }
    })


  }



  done() {
    var batchesListId = new Array();
    var GetAllcheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("getAllcheckBoxValue"));
    let SelectedCheckboxName = "";
    for (let i = 0; i < GetAllcheckbox.length; i++) {
      if (GetAllcheckbox[i].checked) {
        batchesListId.push(Number(GetAllcheckbox[i].value));
        SelectedCheckboxName += GetAllcheckbox[i].name + ',';
      }
    }
    SelectedCheckboxName = this.commonFunction.removeLastComma(SelectedCheckboxName);
    this.dialogRef.close({ data: batchesListId, SelectedCheckboxName: SelectedCheckboxName });
  }

  batchDivclick(id) {
    let GetBatchByDom = (<HTMLInputElement>document.getElementById('batchchk_' + id));
    if (GetBatchByDom.checked == true) {
      GetBatchByDom.checked = true;
    }
    else {
      GetBatchByDom.checked = false;
    }
  }

}
