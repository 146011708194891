import { Component, OnInit, Injectable, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetListService } from '../../service/getlist.service';
import { CommonFunction } from '../../service/commonfunction';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PopupcoachattendanceComponent } from '../popupcoachattendance/popupcoachattendance.component';
import {MatCalendar} from '@angular/material/datepicker';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-coachattendancecoach',
  templateUrl: './coachattendancecoach.component.html',
  styleUrls: ['./coachattendancecoach.component.scss'],
})
export class CoachattendancecoachComponent implements OnInit {
  pipe = new DatePipe('en-US');
  centerTypeList = [];
  batchList = [];
  scheduleList = [];
  coachList = [];
  daysSelected: any[] = [];
  daysSelectedab: any[] = [];
  attendDate = new Date();
  batchId: any;
  centerId: any;
  scheduleId: any;

  isSelected = (event: any) => {
    var className = "";
    const date =
      event.getFullYear() +
      '-' +
      ('00' + (event.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + event.getDate()).slice(-2);

    if (this.daysSelected) {
      className = this.daysSelected.find((x) => x == date) ? 'selectedP' : null;
      if (className == "" || className == null) {
        className = this.daysSelectedab.find((x) => x == date) ? 'selectedA' : null
      }
      return className;
    }
    if (this.daysSelectedab) {
      ;
      return className;
    }

  };
  GetUserData: any;
  @ViewChild('calendar', { static: false }) calendar: MatCalendar<Date>;

  constructor(
    public route: ActivatedRoute,
    public commonFunction: CommonFunction,
    public router: Router,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public datePipe: DatePipe,
    public getListService: GetListService
  ) {
    setTimeout(() => {
      const prev = document.querySelector('.mat-calendar-previous-button');
      const next = document.querySelector('.mat-calendar-next-button');
      prev.addEventListener('click', () => { // Previous Button
        this.attendDate = this.calendar.activeDate;
        this.ScheduleChangeEvent("")

      });
      next.addEventListener('click', () => { // Next Button
        this.attendDate = this.calendar.activeDate;
        this.ScheduleChangeEvent("")
      });

    }, 150);

  }

  ngOnInit(): void {
    this.GetUserData = this.commonFunction.GetUserData();
    this.onremoveclick();
    this.CenterList();
    this.ScheduleChangeEvent('');
  }
  onclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.add('menu-active');
  }
  onremoveclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.remove('menu-active');
  }
  CenterList() {
    this.getListService
      .GetUserAcademy(this.GetUserData.ParentAcademyId, this.GetUserData.UserId)
      .subscribe(
        (response: any) => {
          this.centerTypeList = response;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  CenterChangeEvent() {
    var attendanceDate = this.datePipe.transform(this.attendDate, 'yyyy-MM-dd');
    var acadeMyId = this.centerId ? this.centerId : this.GetUserData.AcademyId;
    var time = '';
    var dayofWeek = new Date(this.attendDate).getDay();
    this.getListService
      .GetBatchByTimeApp(
        attendanceDate,
        acadeMyId,
        time,
        dayofWeek,
        this.GetUserData.UserId,
        this.GetUserData.isAccessAllBatches
      )
      .subscribe(
        (response: any) => {
          this.batchList = response;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }
  BatchChangeEvent(val) {
    this.getListService
      .GetAcademySubBatchList(
        this.batchId,
        this.GetUserData.UserId,
        this.GetUserData.RoleTypeId
      )
      .subscribe(
        (response: any) => {
          this.scheduleList = response;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  ScheduleChangeEvent(val) {
    var acadeMyId = this.centerId ? this.centerId : this.GetUserData.AcademyId;
    var dayofWeek = new Date(this.attendDate).getDay();
    var userId = this.GetUserData.UserId;
    var month = new Date(this.attendDate).getMonth() + 1;
    var year = new Date(this.attendDate).getFullYear();
    this.daysSelected = [];
    this.daysSelectedab = [];
    if (this.scheduleId == undefined || this.scheduleId == null || this.scheduleId == "") {
      this.scheduleId = 0;
    }
    this.getListService
      .GetCoachAttendance(
        acadeMyId,
        this.scheduleId,
        userId,
        dayofWeek,
        month,
        year
      )
      .subscribe(
        (response: any) => {
          for (let i = 0; i < response.length; i++) {
            const livedate = new Date(response[i].AttendanceDate);
            const date = new Date(response[i].AttendanceDate).getDate();
            const month = (livedate.getMonth() + 1).toString().padStart(2, '0');
            const year = new Date(response[i].AttendanceDate).getFullYear();

            const newdate = year + '-' + month + '-' + date;
            const LeaveTypeId = response[i].LeaveTypeId;
            let now2 = moment(livedate, 'DD.MM.YYYY');
            let dateq = this.datePipe.transform(newdate, 'yyyy-MM-dd');
            if (LeaveTypeId == 1) {
              //Absent
              this.daysSelectedab.push(dateq)
            } else {
              this.daysSelected.push(dateq);
            }
            this.calendar.updateTodaysDate();
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  coachattendance() {
    const dialogRef = this.dialog.open(PopupcoachattendanceComponent, {
      width: '100%',
      height: '100%',
      data: { GetDate: '22-10-2020' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.ScheduleChangeEvent('');
    });
  }

  changefilter(val) {
    this.attendDate = val;
    this.CenterChangeEvent();
  }

  select(event: any, calendar: any) {
  }

}
