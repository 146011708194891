<div class="inquiryinnerpopup">
  <div class="overlay"></div>
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="m-0"><strong>{{title || 'Alert!'}}</strong></h3>
    </div>
    <div class="modal-body">
      <p [innerHtml]="message"></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="close()">Ok</button>
    </div>
  </div>
</div>  