import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { CommonFunction } from '../../service/commonfunction';
import { GetListService } from '../../service/getlist.service';
import { IdbService } from 'src/app/service/idb.service';

@Component({
  selector: 'app-popupresonlist',
  templateUrl: './popupresonlist.component.html',
  styleUrls: ['./popupresonlist.component.scss']
})
export class PopupresonlistComponent implements OnInit {
  attendanceReasonForm: UntypedFormGroup;
  GetLeaveTypeList: any[];
  LeaveTypeListSArray: any = [];
  constructor(
    public dialogRef: MatDialogRef<PopupresonlistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonFunction: CommonFunction,
    public idbService: IdbService,
    public getListService: GetListService,
    public formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.LeaveTypeListSArray = this.data.LeaveTypeListSArray;
    this.getLeavetypeList();
    this.forminit();
  }

  forminit() {
    this.attendanceReasonForm = this.formBuilder.group(
      {
        userid: ["", new UntypedFormControl],
      }
    );
    this.attendanceReasonForm.controls['userid'].setValue(this.data.UserId);
  }

  getLeavetypeList() {
    let userData = this.commonFunction.GetUserData();
    if (window.navigator.onLine == false) {
      this.idbService.getAllData("leavetype").then((response: any) => {
        this.GetLeaveTypeList = response;
      });
    }
    else {
      this.getListService
        .getLeaveTypeList(userData)
        .subscribe((response: any) => {
          this.GetLeaveTypeList = response;
        },
          (error) => {
          });
    }
  }

  closemodel() {
    this.dialogRef.close()
  }

  attendanceReasonFormSubmit(LeaveTypeId: any, LeaveShortName: any) {
    for (let i = 0; i < this.LeaveTypeListSArray.length; i++) {
      if (this.LeaveTypeListSArray[i].UserId == this.attendanceReasonForm.value.userid) {
        this.LeaveTypeListSArray[i] = {
          UserId: this.attendanceReasonForm.value.userid,
          Id: LeaveTypeId,
          LeaveShortName: LeaveShortName
        }
      }
    }
    this.dialogRef.close({ data: this.LeaveTypeListSArray, LeaveShortName: LeaveShortName, Id: this.attendanceReasonForm.value.userid })
  }
}
