<div id="page">
    <form [formGroup]="BatchPlanningForm" (ngSubmit)="Next()">
        <div class="header header-fixed header-logo-center">
            <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
                <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
            </span>
            <h2 class="header-title textcolor">Batch Drills Planning</h2>
            <button type="submit" data-menu="menu-sidebar-left-1"
                class="header-icon header-icon-4 textcolor right15">Next</button>
            <div class="titlearea">
                <p class="textcolor m-0">Step 1: Select the batch and schedule to plan day-wise drills.</p>
            </div>
        </div>

        <div class="page-content header-clear-medium">
            <div class="content">
                <div class="inputsection">
                    <span class="input-title font-14">Batch<span class="requird-field">*</span></span>
                    <div class="input-style input-required">
                        <select class="form-control" (change)='BatchChangeEvent($event)' id="BatchId"
                            formControlName="BatchId" [ngClass]="{ 'is-invalid': submitted && f.BatchId.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of BatchesList" value="{{item.Id}}">{{item.BatchName}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.BatchId.errors" class="invalid-feedback">
                            <div *ngIf="f.BatchId.errors.required">This is required field
                            </div>
                        </div>
                    </div>
                </div>

                <div class="inputsection">
                    <span class="input-title font-14">Schedule<span class="requird-field">*</span></span>
                    <div class="input-style input-required" id="ScheduleId">
                        <select class="form-control" (change)=ScheduleChangeEvent($event) formControlName="ScheduleId"
                            [ngClass]="{ 'is-invalid': submitted && f.ScheduleId.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of ScheduleList" value="{{item.Id}}">{{item.BatchName}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.ScheduleId.errors" class="invalid-feedback">
                            <div *ngIf="f.ScheduleId.errors.required">This is required field
                            </div>
                        </div>
                    </div>
                </div>

                <div class="inputsection">
                    <span class="input-title font-14">Slot<span class="requird-field">*</span></span>
                    <div class="input-style input-required">
                        <select class="form-control" id="slotId" (change)=SlotChangeEvent($event)
                            formControlName="SlotId" [ngClass]="{ 'is-invalid': submitted && f.SlotId.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of SlotList" value="{{item.Id}}">{{item.Name}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.SlotId.errors" class="invalid-feedback">
                            <div *ngIf="f.SlotId.errors.required">This is required field
                            </div>
                        </div>
                    </div>
                </div>
                <div class="inputsection">
                    <button type="submit" class="btn btn-block loginheadercolor textcolor">Next</button>
                </div>
            </div>
        </div>
    </form>
</div>