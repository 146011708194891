<div id="pagepagedash" class="graybg">
  <a class="skip-link" href="#maincontent">Skip to main</a>
  <div class="header header-fixed mainpagedashboard header-logo-center" *ngIf="!isParent">
    <span (click)="notification()" class="header-icon header-icon-4 textcolor countdisplay">
      <div class="notification-count" *ngIf="noti_Count>0">{{noti_Count}}</div><img width="16" height="16"
        src="../../../assets/images/icons/bell.png" alt="" />
    </span>
    <h2 class="header-title textcolor">
      <div class="logo"><img src="{{imageURL}}" width="100%" height="100%" alt="" /></div> {{DisplayacademyName}}
    </h2>
    <span (click)="onclick()" data-menu="menu-sidebar-left-1" class="header-icon header-icon-4 textcolor">
      <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
    </span>
  </div>

  <div class="header header-fixed mainpagedashboard header-logo-center" *ngIf="isParent">
    <span (click)="notification()" class="header-icon header-icon-4 textcolor countdisplay">
      <div class="notification-count" *ngIf="noti_Count>0">{{noti_Count}}</div><img width="16" height="16"
        src="../../../assets/images/icons/bell.png" alt="" />
    </span>
    <span (click)="onclick()" data-menu="menu-sidebar-left-1" class="header-icon header-icon-4 textcolor">
      <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
    </span>
  </div>

  <img style="display: none;" width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
  <img style="display: none;" class="editiconbtn" alt="" src="../../../assets/images/KridApp-Image/editicon.png">
  <div class="page-content header-clear-medium graybg" (click)="onremoveclick()">
    <div *ngIf="isAdmin">
      <div class="dashboard-admin-page">
        <div class="row m-0">
          <div class="col-6 position-relative" *ngIf="Student">
            <div class="bg" (click)="StudentClick(0);">
              <div class="w-40"><img src="../../assets/images/tennis-player.png" width="100%" height="100%" alt="" />
              </div>
              <div>
                <span id="spn_totalstudentsCount">{{totalstudentsCount}}</span><span class="title"
                  data-back-button>Players</span>
              </div>
            </div>
          </div>
          <div class="col-6 position-relative" *ngIf="Overdue">
            <div class="bg" (click)="StudentClick(1);">
              <div class="w-40"><img src="../../assets/images/overdue.png" width="100%" height="100%" alt="" /></div>
              <div>
                <span id="spn_Overdue">{{OverdueCount}}</span><span class="title" data-back-button>Overdue</span>
              </div>
            </div>
          </div>
          <div class="col-6 position-relative" *ngIf="Dropout">
            <div class="bg" (click)="StudentClick(2);">
              <div class="w-40"><img src="../../assets/images/dropuser.png" width="100%" height="100%" alt="" /></div>
              <div>
                <span id="spn_dropOuts">{{dropOuts}}</span><span class="title" data-back-button>Dropout</span>
              </div>
            </div>
          </div>
          <div class="col-6 position-relative" *ngIf="Inquiry">
            <div class="bg" (click)="inquiry();">
              <div class="w-40"><img src="../../assets/images/opencall.png" width="100%" height="100%" alt="" /></div>
              <div>
                <span id="spn_OpenLeads">{{OpenLeads}}</span><span class="title" data-back-button>Inquiry</span>
              </div>
            </div>
          </div>
          <div class="col-6 position-relative Collection" *ngIf="Collection">
            <div class="bg" (click)="ReportCollection();">
              <div class="d-flex align-items-center">
                <span class="font-40" [innerHTML]="currencysymbol"></span>
              </div>
              <div>
                <span id="spn_collectedFees">{{collectedFees}}</span>
                <span class="title" data-back-button>Collection</span>
              </div>
            </div>
          </div>
          <div class="col-6 position-relative" *ngIf="Attendence">
            <div class="bg" (click)="attendance();">
              <div class="w-40"><img src="../../assets/images/group-mark.png" width="100%" height="100%" alt="" /></div>
              <div>
                <span id="spn_attendanceandMarked">{{attendanceandMarked}}</span><span class="title"
                  data-back-button>Attendance</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="listing dashobard">
        <ul>
          <li>
            <div><span id="spn_AttendanceCoachPending"></span>Attendance for coaches pending </div>
          </li>
          <li>
            <div><span id="spn_AttendanceBatchPending"></span>Attendance for batches pending</div>
          </li>
          <li>
            <div (click)="StudentClick(1);"><span id="spn_StudentOverDue"></span>Renewals of players Overdue</div>
          </li>
          <li>
            <div (click)="StudentClick(3);"><span id="spn_StudentUpcoming"></span>Renewals of players upcoming</div>
          </li>
          <li>
            <div (click)="inquiry();"><span id="spn_OpenLeadsBottom"></span>Leads are open</div>
          </li>
        </ul>
      </div>
      <div class="Headertitle">
        <p>Leave Applications</p>
      </div>
      <div class="box-view event">
        <div *ngIf="pendingcount>0 ">
          <div *ngFor="let item of StudentLeaveList" (click)="leavedetail(item)">
            <div class="col-100 text-left" style="border-bottom: 1px solid #dde0e2;" *ngIf="item.StatusId == 0">
              <span>{{item.StudentName}}</span>
              <div class="pull-right">
                <div *ngIf="item.StatusId == 0">
                  Pending
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-100 " *ngIf="pendingcount==0">
          No pending leave applications
        </div>
      </div>
    </div>

    <div *ngIf="isCoach">
      <div class="dashboard-coach">
        <div class="row">
          <div class="col-6" [routerLink]="['/inquiry']" *ngIf="Inquiry">
            <p><img src="../../assets/images/student.png" width="100%" height="100%" alt="" /></p><span data-back-button
              (click)="inquiry();">Inquiry</span>
          </div>
          <div class="col-6" [routerLink]="['/studentmodule/list']" *ngIf="Subsciption">
            <p><img src="../../assets/images/overdue.png" width="100%" height="100%" alt="" /></p><span
              data-back-button>Subscription</span>
          </div>
          <div class="col-6" [routerLink]="['/attendance']" *ngIf="Attendence">
            <p><img src="../../assets/images/dropuser.png" width="100%" height="100%" alt="" /></p><span
              data-back-button>Attendance</span>
          </div>
          <div class="col-6" [routerLink]="['/clock']" *ngIf="Attendence">
            <p><img src="../../assets/images/KridApp-Image/clock-menu.png" width="100%" height="100%" alt="" /></p><span
              data-back-button>Punch In/Out</span>
          </div>
        </div>
      </div>

      <div class="box-view coachdata" *ngFor="let item of barchart;">
        <div class="tableHeader heading">{{item.BatchName}} <img (click)="opendotpopup(item)" width="100%" height="100%"
            class="option" src="../../assets/images/menu.png" alt="" /> </div>
        <div class="col-100 text-left" *ngIf="item.DailyStrength!=0">
          <app-chartdashboard *ngIf="Ischart" [chartobj]="barchartdata"></app-chartdashboard>
        </div>
        <div class="col-100 text-left" *ngIf="item.DailyStrength==0">
          <p class="m-0">Not enough data for the chart</p>
        </div>
      </div>

      <div class="Headertitle">
        <p>Leave Applications</p>
      </div>
      <div class="box-view event">
        <div *ngIf="pendingcount>0">
          <div *ngFor="let item of StudentLeaveList" (click)="leavedetail(item)">
            <div class="col-100 text-left" style="border-bottom: 1px solid #dde0e2;" *ngIf="item.StatusId == 0">
              <span>{{item.StudentName}}</span>
              <div class="pull-right">
                <div *ngIf="item.StatusId == 0">
                  Pending
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-100 " *ngIf="pendingcount==0">
          No pending leave applications
        </div>
      </div>

      <div class="Headertitle">
        <p>Upcoming Batches</p><span class="pull-right">PLAYERS</span>
      </div>
      <ng-container *ngIf="!upcomingBatchdataNotfound">
        <div class="box-view event coachdata">
          <div class="col-100 text-left" *ngFor="let val of upcomingBatchdata">
            <p>{{val.BatchName }} {{val.Time}}</p><span class="pull-right">{{val.StudentCount}}</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="upcomingBatchdataNotfound">
        <div class="box-view border-0">
          <div class="col-100 text-left">
            <p class="m-0">No data found.</p>
          </div>
        </div>
      </ng-container>
      <!-- <div class="Headertitle">
        <p>Leave Applications</p>
      </div>
      <ng-container *ngIf="!StudentLeaveListNotfound">
        <div class="box-view event coachdata">
          <div class="col-100 text-left" *ngFor="let val of StudentLeaveList">
            <p>{{val.StudentName}} {{val.FromLeaveDate | date:'dd MMM,yyyy'}} To {{val.ToLeaveDate | date:'dd
              MMM,yyyy'}} For {{val.LeaveName}} ({{val.BatchName}})</p><span class="pull-right"><button
                class="aprooverej">Aproove</button><button>Reject</button></span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="StudentLeaveListNotfound">
        <div class="box-view border-0">
          <div class="col-100 text-left">
            <p class="m-0">No data found.</p>
          </div>
        </div>
      </ng-container> -->
      <!-- <div class="Headertitle">
        <p>Personal Training</p>
      </div>
      <div class="coachdata" *ngFor="let item of StudentLeaveList;">
        <div class="tableHeader heading">{{item.UserName}} <img (click)="opendotpopupForPrivateTraining(item)"
            width="100%" height="100%" class="option" src="../../assets/images/menu.png" alt="" /> </div>
      </div> -->
    </div>
    <div *ngIf="isParent">
      <div class="dashboard-parent">
        <div class="text-center">
          <img src="{{imageURLParent}}" class="w-img-150" width="100%" height="100%" alt="" />
        </div>
        <div class="row m-0" id="socialIconClick">
          <span *ngIf="webicon" id="webIcon" target="_blank" (click)="socialIconClick($event);" class="col-3"><img
              src="../../assets/images/web.png" width="100%" height="100%" class="w-img-35"
              alt="{{academyList.Website}}" /></span>
          <span *ngIf="twittericon" id="twitterIcon" (click)="socialIconClick($event);" class="col-3"><img
              src="../../assets/images/twitter.png" width="100%" height="100%" class="w-img-35"
              alt="{{academyList.Twitter}}" /></span>
          <span *ngIf="youtubeicon" id="youtubeIcon" (click)="socialIconClick($event);" class="col-3"><img
              src="../../assets/images/youtube.png" width="100%" height="100%" class="w-img-35"
              alt="{{academyList.Youtube}}" /></span>
          <span *ngIf="instagramicon" id="instagramIcon" (click)="socialIconClick($event);" class="col-3"><img
              src="../../assets/images/instagram.png" width="100%" height="100%" class="w-img-35"
              alt="{{academyList.Instagram}}" /></span>
          <span *ngIf="facebookicon" id="facebookIcon" (click)="socialIconClick($event);" class="col-3"><img
              src="../../assets/images/facebook.png" width="100%" height="100%" class="w-img-35"
              alt="{{academyList.Facebook}}" /></span>
        </div>
        <!-- Dropdown -->
        <form [formGroup]="StudentFormGroup" *ngIf="isMultipleStudent">
          <div class="row">
            <div class="col-12 p-0">
              <select class="form-control" selected="1" formControlName="MultipleStudentId"
                (change)="MultipleStudentChange($event);">
                <option value="">Select</option>
                <option *ngFor="let item of StudentList" value="{{item.Id}}">
                  {{item.FirstName}}</option>
              </select>
            </div>
          </div>
        </form>
        <!-- Dropdown -->
      </div>
      <div class="row parentDiv mb-0">
        <div class="col-4 pb-4 text-center">
          <span (click)="ParentOption(1)"><img src="../../assets/images/KridApp-Image/parent_dashboard/Attendance.png"
              width="100%" height="100%" alt="" /></span><b>Attendance</b>
        </div>
        <div class="col-4 pb-4 text-center">
          <span (click)="ParentOption(2)"><img
              src="../../assets/images/KridApp-Image/parent_dashboard/Player-assessment.png" width="100%" height="100%"
              alt="" /></span><b>Assessment</b>
        </div>
        <div class="col-4 pb-4 text-center" [routerLink]="['/events/eventlist']">
          <span><img src="../../assets/images/KridApp-Image/parent_dashboard/event.png" width="100%" height="100%"
              alt="" /></span><b>Event</b>
        </div>
        <div class="col-4 pb-4 text-center" (click)="ParentOption(4)">
          <span>
            <div class="feesnotifiction">{{ feesCount}}</div><img width="100%" height="100%"
              src="../../assets/images/KridApp-Image/parent_dashboard/fees.png" alt="" />
          </span><b>Fees</b>
        </div>
        <div class="col-4 pb-4 text-center">
          <!-- [routerLink]="['/usermessages']" -->
          <span (click)="UserMessage()"><img src="../../assets/images/KridApp-Image/parent_dashboard/feedback.png"
              width="100%" height="100%" alt="" /></span><b>Ask
            the coach</b>
        </div>
        <div class="col-4 pb-4 text-center">
          <span (click)="ParentOption(6)">
            <img src="../../assets/images/KridApp-Image/parent_dashboard/notification.png" width="100%" height="100%"
              alt="" />
          </span><b>Notification</b>
        </div>
      </div>
      <div class="marquee-data">
        <marquee *ngIf="RedmarqueeForPayment" class="red-marquee" (click)="ParentOption(4)"><span>{{msg}}</span>
        </marquee>
        <marquee *ngIf="Redmarquee" class="red-marquee" (click)="renew()"><span>{{msg}} Tap here to renew</span>
        </marquee>
        <marquee *ngIf="Yellowmarquee" class="yellow-marquee" (click)="renew()"><span>{{msg}} Tap here to renew
          </span>
        </marquee>
      </div>
    </div>
    <!-- End of Page Content-->
    <!-- open Side Menu -->
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
      <p class="color-white"> Loading... </p>
  </ngx-spinner>
</div>