<div class="popup-header header-logo-center loginheadercolor">
    <div class="header-title textcolor noback">Comments</div>
    <span (click)="closemodel()" class="header-icon header-icon-4 textcolor">
        <img width="16" height="16" src="../../../assets/images/icons/time.png" alt="" />
    </span>
</div>
<div class="page-content pb-0">
    <div class="content">
        <div class="input-style input-required mb-0">
            <textarea class="form-control" id="txt_comments"
                placeholder="Please comment here for the student's ability"></textarea>
            <button type="button" (click)="SaveComments()"
                class="btn mt-3 btn-block loginheadercolor textcolor">Submit</button>
        </div>
    </div>
</div>