import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import $ from 'jquery'
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class CommonFunction {
  UserId: any;
  ParentAcademyId: any;
  RoleType: any;
  public SetLocalStorageData(response: any) {
    var GetResponce =
    {
      logindata: response.AuthKey,
      AcademyId: response.AcademyId,
      UserId: response.Id,
      ParentAcademyId: response.ParentAcademyId,
      RoleType: response.rolesList[0].RoleType,
      isAccessAllBatches: response.AccessAllBatches,
      ApplyUserType: "",
      ManageVenues: response.ManageVenues,
      FeesTracking: response.FeesTracking,
      LeaveReasonRequired: response.LeaveReasonRequired,
      VideoUpload: response.VideoUpload,
      ParentDrillAccess: null,
      ProrataFees: response.ProrataFees,
      UserEmail: response.Email,
      PhoneNumber: response.PhoneNumber,
      RoleListLength: response.rolesList.length,
      ApproveFees: response.ApproveFees,
      GuardianId: response.GuardianId,
      FirstName: response.FirstName,
      AuthKey: response.AuthKey,
      SelectAcademyName: "",
      ClockUserId: 0,
      ClockStarttime: null,
      WaiveTypeId: 1,
      BackWaveType: false,
      ParentMultiSelectUserId: 0,
    };
    localStorage.setItem("logindata", (response.AuthKey));
    localStorage.setItem('Userdata', JSON.stringify(GetResponce));
  }
  public SetAttendanceStorageData(response: any) {
    localStorage.setItem('attendancedata', JSON.stringify(response));
  }

  public GetAttendanceStorageData() {
    var data = JSON.parse(localStorage.getItem("attendancedata"));
    return data;
  }
  public RemoveAttendanceData() {
    localStorage.removeItem("attendancedata");
  }
  public GetUserData() {
    var data = JSON.parse(localStorage.getItem("Userdata"));
    return data;
  }
  public GetUserToken() {
    var data = localStorage.getItem("logindata");
    return data;
  }
  public GetUserId() {
    var data = JSON.parse(localStorage.getItem("Userdata"));
    return this.UserId = parseInt(data.UserId);
  }
  public GetParentAcademyId() {
    var data = JSON.parse(localStorage.getItem("Userdata"));
    return this.ParentAcademyId = data.ParentAcademyId;
  }
  public GetRoleTypeId() {
    var data = JSON.parse(localStorage.getItem("Userdata"));
    return this.RoleType = data.RoleType;
  }
  public ClearProfileData() {
    localStorage.setItem("profiledata", null);
  }
  public SetUserProfileData(val) {
    localStorage.setItem("profiledata", JSON.stringify(val));
  }
  public GetUserProfileData() {
    var data = JSON.parse(localStorage.getItem("profiledata"));
    return data;
  }
  public GetAcademyData() {
    var data = JSON.parse(localStorage.getItem("academyData"));
    return data;
  }
  encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), "kridapp").toString();
    } catch (e) {
    }
  }

  decryptData(data) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, "kridapp");
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      (e);
    }
  }
  public SetParentAcademyAndRoleTypeId(data: any) {
    var GetUserData = JSON.parse(localStorage.getItem("Userdata"));
    GetUserData.AcademyId = parseInt(data.AcademyId);
    GetUserData.RoleTypeId = parseInt(data.RoleTypeId);
    GetUserData.RoleType = parseInt(data.RoleTypeId);
    localStorage.setItem('Userdata', JSON.stringify(GetUserData))
  }

  public SetAcademyNameandArray(SelectAcademyName) {
    var GetUserData = JSON.parse(localStorage.getItem("Userdata"));
    GetUserData.SelectAcademyName = SelectAcademyName;
    localStorage.setItem('Userdata', JSON.stringify(GetUserData));
  }


  public ClockUserIdSet(UserId) {
    var GetUserData = JSON.parse(localStorage.getItem("Userdata"));
    GetUserData.ClockUserId = UserId;
    localStorage.setItem('Userdata', JSON.stringify(GetUserData));
  }

  public ClockStartTimeSet(StartTime) {
    var GetUserData = JSON.parse(localStorage.getItem("Userdata"));
    GetUserData.ClockStarttime = StartTime;
    localStorage.setItem('Userdata', JSON.stringify(GetUserData));
  }

  public ClockWaveType(WaveTypeId) {
    var GetUserData = JSON.parse(localStorage.getItem("Userdata"));
    GetUserData.WaiveTypeId = WaveTypeId;
    localStorage.setItem('Userdata', JSON.stringify(GetUserData));
  }

  public ClockWaveStatus(Status) {
    var GetUserData = JSON.parse(localStorage.getItem("Userdata"));
    GetUserData.BackWaveType = Status;
    localStorage.setItem('Userdata', JSON.stringify(GetUserData));
  }


  public SetDrillInUserDataArray(ParentDrillAccess) {
    var GetUserData = JSON.parse(localStorage.getItem("Userdata"));
    GetUserData.ParentDrillAccess = ParentDrillAccess;
    localStorage.setItem('Userdata', JSON.stringify(GetUserData));
  }

  public SetApplyUserType(ApplyUserType) {
    var GetUserData = JSON.parse(localStorage.getItem("Userdata"));
    GetUserData.ApplyUserType = ApplyUserType;
    localStorage.setItem('Userdata', JSON.stringify(GetUserData));
  }


  public SetMultipleStudentId(data: any, academyId: any) {
    var GetUserData = JSON.parse(localStorage.getItem("Userdata"));
    GetUserData.ParentMultiSelectUserId = parseInt(data);
    GetUserData.AcademyId = parseInt(academyId);
    GetUserData.StudentId = parseInt(data);
    localStorage.setItem('Userdata', JSON.stringify(GetUserData))
  }
  public SetAllowFeedback(allowFeedback: any) {
    var GetUserData = JSON.parse(localStorage.getItem("Userdata"));
    GetUserData.AllowAssessmentFeedback = allowFeedback;
    localStorage.setItem('Userdata', JSON.stringify(GetUserData))
  }
  public SetStudentName(data: any) {
    var GetUserData = JSON.parse(localStorage.getItem("Userdata"));
    GetUserData.StudentName = data;
    localStorage.setItem('Userdata', JSON.stringify(GetUserData))
  }

  public SetDeviceId(data: any) {
    var GetUserData = JSON.parse(localStorage.getItem("Userdata"));
    if (GetUserData != null && GetUserData != undefined && GetUserData != "") {
      GetUserData.DeviceId = data;
      localStorage.setItem('Userdata', JSON.stringify(GetUserData))
    }

  }

  public SetAcademyCurrency(currencyData) {
    var GetUserData = JSON.parse(localStorage.getItem("Userdata"));
    if (currencyData.CurrencySymbol != null) {
      GetUserData.CountryCurrencySymbol = currencyData.CurrencySymbol;
    }
    GetUserData.CountryCurrency = currencyData.Currency;
    GetUserData.CountryShortName = currencyData.ShortName;
    GetUserData.CountryName = currencyData.Name;
    GetUserData.CountryPhoneCode = currencyData.PhoneCode;
    localStorage.setItem('Userdata', JSON.stringify(GetUserData))
  }

  public removeLastComma(stringvalue) {
    var n = stringvalue.lastIndexOf(",");
    var a = stringvalue.substring(0, n)
    return a;
  }
  public getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          navigator.permissions.query({ name: 'geolocation' })
            .then(function (permissionStatus) {
              if (permissionStatus.state == "denied") {
                resolve({ lng: 0, lat: 0 });
              }
              else {
                permissionStatus.onchange = function () {
                  navigator.geolocation.getCurrentPosition(resp => {
                    resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
                  });
                };
              }
            });
        });
    });
  }


  public MustOldMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustOldMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustOldMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  public MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
  public removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  public removeDuplicatesBy(keyFn, array) {
    var mySet = new Set();
    return array.filter(function (x) {
      var key = keyFn(x), isNew = !mySet.has(key);
      if (isNew) mySet.add(key);
      return isNew;
    });
  }

  public removeDuplicateSingleValue(arr) {
    var tmp = [];
    for (var i = 0; i < arr.length; i++) {
      if (tmp.indexOf(arr[i]) == -1) {
        tmp.push(arr[i]);
      }
    }
    return tmp;
  }

}
