<div id="page">
    <div class="header header-fixed header-logo-center">
        <span (click)="back()" class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <h2 class="header-title textcolor">Batch Drills Planning</h2>
        <span (click)="SaveBatchPlanning()" class="header-icon header-icon-4 textcolor right15">Done</span>
        <div class="titlearea">
            <p class="textcolor m-0">Step 3: Select Categories/Drills</p>
        </div>
    </div>
    <div class="page-content header-clear-medium planning">
        <div class="content">
            <div class="inputsection">
                <span class="input-title font-14">Date<span class="requird-field">*</span></span>
                <div class="input-style input-required dob-input">
                    <input id="ScheduleDate" class="form-control" type="text" mwlFlatpickr (change)="ChangeDate($event)"
                        dateFormat="m/d/Y" ref="flatpickr">
                </div>
            </div>

            <div class="inputsection">


                <div class="planinglist" *ngIf="Mode=='Add'">
                    <strong>Category</strong>
                    <div class="card batchlist card-style" *ngFor="let item of CategoryList; index as i">
                        <label class="content">
                            <div class="d-flex align-items-center justify-content-between"
                                (click)="openBatchPlanningModal(item)">
                                {{item.Name}}
                                <div>
                                    <span class="mr-2" id="spn_{{item.Id}}">

                                    </span>
                                    <i><img src="../../assets/images/KridApp-Image/grayarrow.png" alt=""
                                            class="w-img-8"></i>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>

                <div class="planinglist" *ngIf="Mode=='Edit'">
                    <strong>Category</strong>
                    <div class="card batchlist card-style" *ngFor="let item of CategoryList; index as i">
                        <label class="content">
                            <div class="d-flex align-items-center justify-content-between">
                                {{item.Name}}
                                <div>
                                    <span class="mr-2" id="spn_{{item.Id}}">
                                    </span>
                                    <i><img src="../../assets/images/KridApp-Image/grayarrow.png" alt=""
                                            class="w-img-8"></i>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
                <div class="planinglist">
                    <strong>Venue</strong>
                    <div class="card batchlist card-style" *ngFor="let item of VenueList; index as i">
                        <label class="content">
                            <div class="d-flex align-items-center">
                                <input type="checkbox" class="getVenueAllcheckBoxValue" name="{{item.VenueName}}"
                                    value={{item.Id}} id="schchchk_{{item.Id}}" />
                                {{item.VenueName}}
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-block loginheadercolor textcolor"
                (click)="SaveBatchPlanning()">Save</button>
        </div>
    </div>
</div>