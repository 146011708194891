import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../service/notification.service';
import { CommonFunction } from '../service/commonfunction';
import { GetListService } from '../service/getlist.service';
import { PostService } from '../service/post.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changepasswordForm: UntypedFormGroup;
  submitted = false;
  getoldPassword: any;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public notifyService: NotificationService,
    public changePasswordGetService: GetListService,
    public changePasswordPostService: PostService,
    public commonFunction: CommonFunction
  ) { }

  ngOnInit(): void {
    this.onremoveclick();
    this.initform();
    this.getbyUserId();
  }

  onclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.add('menu-active');
  }
  onremoveclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.remove('menu-active');
  }

  initform() {
    this.changepasswordForm = this.formBuilder.group(
      {
        GetOldPassword: [''],
        oldpassword: new UntypedFormControl('',),
        newpassword: ['', [Validators.required]],
        confirmpassword: ['', [Validators.required]]

      },
      {
        validator: [this.commonFunction.MustMatch('newpassword', 'confirmpassword'), this.commonFunction.MustOldMatch('GetOldPassword', 'oldpassword')]
      },

    );
  }
  get f() { return this.changepasswordForm.controls; }

  back() {
    this.router.navigate(['/dashboard'])
  }

  getbyUserId() {
    this.changePasswordGetService
      .getUserById()
      .subscribe((response: any) => {
        this.changepasswordForm.controls['GetOldPassword'].setValue(response.Password);
        this.getoldPassword = response.Password;
      },
        (error) => {
        });
  }

  changepasswordSubmit() {
    this.submitted = true;
    if (this.changepasswordForm.invalid) {
      this.notifyService.showError("Unable to Changed Password. Please try again later. Contact the admin if problem persists.", "");
      return;
    }
    else {
      var newPassword = this.changepasswordForm.controls['newpassword'].value;
      this.changePasswordPostService
        .UpdatePassword(newPassword, this.getoldPassword)
        .subscribe((response: any) => {
          if (response == 1) {
            this.notifyService.showSuccess("Password Changed Successfully!", "");
            this.router.navigate(['/dashboard'])
              .then(() => {
                window.location.reload();
              });
          }
        },
          (error) => {
            this.notifyService.showError("Unable to Changed Password. Please try again later. Contact the admin if problem persists.", "");
          });
    }
  }
}
