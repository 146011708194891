import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from "ngx-spinner";
import { PrivateattendancestdRoutingModule } from './privateattendancestd-routing.module';
import { PrivateattendancestdComponent } from './privateattendancestd.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { PrivateattenstudentlistComponent } from './privateattenstudentlist/privateattenstudentlist.component';


@NgModule({
  declarations: [PrivateattendancestdComponent, PrivateattenstudentlistComponent],
  imports: [
    CommonModule,
    PrivateattendancestdRoutingModule,
    NgxSpinnerModule,
    FlatpickrModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,


  ]
})
export class PrivateattendancestdModule { }
