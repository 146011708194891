<div class="popup-header header-logo-center loginheadercolor">
    <div class="header-title textcolor noback">View Payments</div>
  </div>
  <div class="page-content pb-0">
    <div class="content">
      <div class="datalisting" *ngFor="let item of AacdemyFeesTypeList; index as i" (click)="FeestypeChange(item)">
        <div class="w-100 mb-2 d-flex align-items-center">
          <input type="radio" class="RadioButton mr-2" name="AacdemyFeesType" value={{item.Id}} />
          {{item.FeesType}}
        </div>
      </div>
    </div>
  </div>