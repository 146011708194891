<div id="page">
    <div class="header header-fixed header-logo-center">
        <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <h2 class="header-title textcolor">{{UserName}}</h2>
        <span class="header-icon header-icon-3 textcolor" (click)="openpopupfilterfeesdetails()">
            <img width="16" height="16" src="../../../assets/images/icons/filter.png" alt="" />
        </span>
        <span (click)="onclick(menuopenstatus)" data-menu="menu-sidebar-left-1"
            class="header-icon header-icon-4 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
        </span>
    </div>

    <div class="page-content header-clear-medium">
        <div class="content mt-3 mb-3">
            <button type="button" class="btn w-100 loginheadercolor textcolor" id="btn_AddWithoutListed" (click)="AddExtraPayment()">Add Payment</button>
        </div>
        <div class="fees-details" *ngIf="!NoFound">
            <div class="title main" style="height: 80px; line-height: 50px">
                <span>
                    Fees Details
                </span>
                <span class="pull-right">
                    <button style="width: 100px; height: 40px;" type="button" id="payall" (click)="payall()"
                        class="btn loginheadercolor textcolor">Pay All</button></span><span class="pull-right">
                    <button style="width: 100px; height: 40px;" id="clear" type="button" (click)="clear()"
                        class="btn loginheadercolor textcolor mr-1"
                        hidden>Clear</button>
                    <button style="width: 100px; height: 40px;" id="pay" type="button" (click)="pay()"
                        class="btn loginheadercolor textcolor" hidden>Pay</button>
                </span>
            </div>

            <div class="content">
                <div class="inputsection" *ngFor="let item of userFeesDetailsList;index as i">
                    <ng-Container *ngIf="item.AppOutstanding>0">
                        <div class="select">
                            <input type="checkbox" class="checkboxsize geencheckbox" name="feesdetailscheckbox"
                                value="{{item.AppOutstanding}}" hidden />
                            <div class="row lightred">
                                <div class="col-12 pb-0" (click)="feesinfo(item)">
                                    <span>{{item.FeesType}}</span>
                                    <div class="pricebar d-flex align-items-center justify-content-end">
                                        <div class="p-0 pull-left mr-1" [innerHTML]="currencysymbol"></div>{{item.AppOutstanding}}
                                    </div>
                                    <button type="button" class="btn loginheadercolor textcolor">Proceed to pay</button>
                                </div>
                                <ng-Container class="paidfees d-flex w-100" *ngIf="item.AppPaid>0">
                                    <div class="col-6">
                                        <span>Paid:<b class="font-18">{{item.AppPaid}}</b></span>
                                    </div>
                                    <div class="col-6 text-right">
                                        <span>Total:<b class="font-18">{{item.AppTotalFees}}</b></span>
                                    </div>
                                </ng-Container>
                            </div>
                        </div>
                    </ng-Container>
                </div>
    
                <div class="inputsection" *ngFor="let item of userFeesDetailsList;index as i">
                    <ng-Container *ngIf="item.AppOutstanding==0">
                        <div class="select" (click)="feesinfo(item)">
                            <input type="checkbox" class="checkboxsize" name="feesdetailscheckbox" id="chk_{{i}}" hidden
                                disabled />
                            <div class="row lightgreen">
                                <div class="col-6">
                                    <span class="col-12 title mb-0">{{item.FeesType}}</span>
                                    <span>Tax({{item.TaxPercent}}%):<b class="font-18">{{item.AppTaxAmount}}</b> </span>
                                    <span>Outstanding:<b class="font-18">{{item.AppOutstanding}}</b></span>
                                </div>
                                <div class="col-6 rightside">
                                    <div class="pricebar d-flex align-items-center justify-content-end">
                                        <div class="p-0 pull-left mr-1" [innerHTML]="currencysymbol"></div> {{item.AppAmount}}
                                    </div>
                                    <span class="date">{{item.PaymentDate|date:'dd MMM yyyy'}}</span>
                                </div>
                                <div class="amountdisplay">
                                    <span>Amount: <b class="d-flex align-items-center"><span [innerHTML]="currencysymbol"></span> {{item.AppAmount}}</b></span>
                                    <span>Paid: <b class="d-flex align-items-center"><span [innerHTML]="currencysymbol"></span> {{item.AppPaid}}</b></span>
                                </div>
                            </div>
                        </div>
                    </ng-Container>
                </div>
                <div class="parameter_report_info eventdate" *ngIf="NoFound">
                    <ul>
                        <li class="notificationfound">
                            <div class="student-list">No records found.</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>