import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonFunction } from '../service/commonfunction';
import { Observable } from 'rxjs';
const API_URL = environment.appUrl;
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: 'root'
})
export class GetListService {
  apiURL: string = environment.appUrl;
  private headers = new HttpHeaders(
    { 'Content-Type': 'application/x-www-form-urlencoded' }
  );
  constructor(public http: HttpClient, public commonFunction: CommonFunction) { }



  /* GetAcademyPackageExpire Start*/
  public getAcademyPackageExpire(parentAcademyId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyPackage/GetAcademyPackageExpire?parentAcademyId=" + parentAcademyId,
    );
  }
  /*GetAcademyPackageExpire end */

  /* Login Service Start */
  public doLogin(data) {
    const paramdata = data;
    return this.http.get<any>(
      `${this.apiURL}` + "anonymoususer/GetLoginforuser?email=" + data.email + "&password=" + data.password + "&_serviceUrl=" + `${this.apiURL}`,
      { headers: this.headers });
  }

  public getchartList() {
    return this.http.get(
      `${this.apiURL}` + "Dashboard/GetStudentSubscriptionCount?academyId=0&userId=2371&date=2020-08-03&parentAcademyId=1",
      { headers: this.headers }
    );
  }
  public getSettingData(academyId) {
    return this.http.get(
      `${this.apiURL}` + "Academy/GetAcademySettings?academyId=" + academyId + "",
      { headers: this.headers }
    );
  }
  /* Login Service End */

  /* Select Academy Service End */
  public getAcademicList(data: any) {
    return this.http.get(
      `${this.apiURL}` + "Academy/GetUserAcademy?&userId=" + data.UserId + "&parentAcademyId=0", //+ data.ParentAcademyId + "",
      { headers: this.headers }
    );
  }

  public getRoleList(data: any) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetAllRolesOfUser?&userID=" + data.UserId + "&parentAcademyId=" + data.ParentAcademyId + "",
      { headers: this.headers }
    );
  }
  /* Select Academy Service End */

  /* AttendanceService Service Start */
  public getBatchesList(data: any) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBaches?academyId=" + data.academyId + "&userId= " + data.userId + " &isAccessAllBatches=" + data.isAccessAllBatches + "",
      { headers: this.headers }
    );
  }

  public getScheduleList(data: any) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetAcademySubBatchListByBatchIds?academyId=" + data.academyId + "&academyBatchId=" + data.batchIds + "&time=''&dayofWeek=" + data.dayofWeek + "&userId=" + data.userId + "&roleId=" + data.roleId + "",
      { headers: this.headers }
    );
  }

  public getSportDrilllist(data: any) {
    return this.http.get(
      `${this.apiURL}` + "SportParameter/GetBatchesSportDrilllist?batchIds=" + data.batchIds + "&scheduleDate=" + data.scheudleDate + "",
      { headers: this.headers }
    );
  }

  public getStudentList(data: any) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBatchesUsers?academyId=" + data.academyId + "&batchIds=" + data.batchIds + "&attendanceDate=" + data.scheudleDate + "&slot=" + data.slot,
      { headers: this.headers }
    );
  }

  public GetBatchesUsersTraining(data: any) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBatchesUsersTraining?academyId=" + data.academyId + "&batchIds=" + data.batchIds + "&setNo=" + data.setNo + "&drillId=" + data.drillId + "&attendanceDate=" + data.scheudleDate + "&slot=" + data.slot,
      { headers: this.headers }
    );
  }

  public GetMultipleBatchSchedule(AcademyId: any) {
    return this.http.get(
      `${this.apiURL}` + "Academy/GetMultipleBatchSchedule?academyId=" + AcademyId,
      { headers: this.headers }
    );
  }

  public getLeaveTypeList(data: any) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetLeaveType?academyId=" + data.AcademyId,
      { headers: this.headers }
    );
  }

  public LeaveStatusUpdate(id: number, statusId: number, userId: number, comment: string, academyId: number, parentAcademyId: number) {
    return this.http.get(
      `${this.apiURL}` + "LeaveApplication/LeaveStatusUpdate?id=" + id + "&statusId=" + statusId + "&UserId=" + userId + "&coachComments=" + comment + "&academyId=" + academyId + "&parentAcademyId=" + parentAcademyId,
      { headers: this.headers }
    )
  }

  public SendLeaveNotificationForDiscussion(id: number, userId: number, academyId: number, parentAcademyId: number) {
    return this.http.get(
      `${this.apiURL}` + "LeaveApplication/SendLeaveNotificationForDiscussion?leaveId=" + id + "&academyId=" + academyId + "&userId=" + userId + "&parentAcademyId=" + parentAcademyId,
      { headers: this.headers }
    )
  }
  /* AttendanceService Service End */

  /* Common Service Start */
  public GetBachesListbyAcademyId(academyId) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBatchByAcademyId?academyId=" + academyId + "",
      { headers: this.headers }
    );
  }
  getAcademySubBatchList(data: any) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetAcademySubBatchList?academyBatchId=" + data.academyBatchId + "&userId= " + data.userId + " &roleId=" + data.roleId + "",
      { headers: this.headers }
    );
  }
  /* Common Service End */

  /**************** Dashboard Service Start  ********************/
  public MultipleStudentList(userId) {
    return this.http.get(`${this.apiURL}` + "AcademyUser/GetUserListByGuardianId?guardianId=" + userId + "",
      { headers: this.headers });
  }


  public MarqueeMessageList(guardianUserId) {
    return this.http.get(`${this.apiURL}` + "Dashboard/GetChildsFeesUpdate?guardianUserId=" + guardianUserId + "",
      { headers: this.headers });
  }

  public GetCurrencyOfAcademy(academyId, userId) {
    return this.http.get(`${this.apiURL}` + "Academy/GetAcademyCurrency?academyId=" + academyId + '&userId=' + userId,
      { headers: this.headers });
  }

  public GetAdminDashboardCount(academyId, userId, reportDate) {
    return this.http.get(`${this.apiURL}` + "Dashboard/GetNewDashBoardDetails?academyId=" + academyId + '&userId=' + userId + '&reportDate=' + reportDate,
      { headers: this.headers });
  }


  public GetAcademySportForDashboardByUserId(userid, academyId) {
    return this.http.get(`${this.apiURL}` + "Sport/GetAcademySportForDashboardByUserId?userid=" + userid + '&academyId=' + academyId + '',
      { headers: this.headers });
  }

  public GetBestPerformingStudents(academySportId, academyId) {
    return this.http.get(`${this.apiURL}` + "Dashboard/GetBestPerformingStudents?academySportId=" + academySportId + '&academyId=' + academyId + '',
      { headers: this.headers });
  }

  public GetLeastPerformingStudents(academySportId, academyId) {
    return this.http.get(`${this.apiURL}` + "Dashboard/GetLeastPerformingStudents?academySportId=" + academySportId + '&academyId=' + academyId + '',
      { headers: this.headers });
  }

  /**************** Dashboard Service End  ********************/

  public GetAcademyDetails(academyId) {
    return this.http.get(`${this.apiURL}` + "Academy/GetAcademy?academyId=" + academyId + "",
      { headers: this.headers });
  }
  /**************** Common Service Start  ********************/
  public GetAcademyData(domain) {
    return this.http.get(`${this.apiURL}` + "AnonymousUser/GetAcademyByHostName?hostName=" + domain,
      { headers: this.headers });
  }
  public GetCountryList() {
    return this.http.get(`${this.apiURL}` + "AnonymousUser/GetCountryList", { headers: this.headers });
  }
  public GetAcademicList(data: any) {
    return this.http.get(
      `${this.apiURL}` + "Academy/GetUserAcademy?&userId=" + data.UserId + "&parentAcademyId=" + data.ParentAcademyId + "",
      { headers: this.headers }
    );
  }
  public GetRoleList(data: any) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetAllRolesOfUser?&userID=" + data.UserId + "&parentAcademyId=" + data.ParentAcademyId + "",
      { headers: this.headers }
    );
  }

  public createOrder(price: any, feesTypeIds:any,academyId:any,academyUserId:any) {
    return this.http.get(
      `${this.apiURL}` + "RazorPay/createOrderForRazorpay?price=" + price + "&feesTypeIds=" + feesTypeIds + "&academyId=" + academyId + "&academyUserId=" + academyUserId,
      { headers: this.headers }
    );
  }

  public createOrdervenue(price: any, academyId : any) {
    return this.http.get(
      `${this.apiURL}` + "RazorPay/createOrderRazorpayForVenue?price=" + price + "&academyId=" + academyId,
      { headers: this.headers }
    );
  }

  public checkRazorpayPayment(PaymentId: any,FeesheaderId: any,price: any) {
    return this.http.get(
      `${this.apiURL}` + "RazorPay/checkRazorpayPayment?PaymentId=" + PaymentId + "&FeesheaderId=" + FeesheaderId + "&price=" + price,
      { headers: this.headers }
    );
  }

  public GetChartList() {
    return this.http.get(
      `${this.apiURL}` + "Dashboard/GetStudentSubscriptionCount?academyId=0&userId=2371&date=2020-08-03&parentAcademyId=1",
      { headers: this.headers }
    );
  }
  public GetBatchListbyAcademyUserId(userId, academyId) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBatchListbyAcademyUserId?academyUserId=" + userId + '&academyId=' + academyId,
      { headers: this.headers }
    );
  }

  public GetDrillListOnDateForTraining(batchId, scheduleDate) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetDrillListOnDateForTraining?batchId=" + batchId + '&scheduleDate=' + scheduleDate,
      { headers: this.headers }
    );
  }

  public GetFeesdetails(userId, academyId, studentId, bId) {
    return this.http.get(
      `${this.apiURL}` + 'Dashboard/GetFeesdetails?userId=' + userId + '&academyId=' + academyId + '&academyUserStudentId=' + studentId + '&batchId=' + bId,
      { headers: this.headers }
    );
  }

  public GetUserDetailsForPaymentOnline(academyuserId) {
    return this.http.get(
      `${this.apiURL}` + 'AcademyUser/GetUserDetailsForPaymentOnline?academyUserId=' + academyuserId,
      { headers: this.headers }
    );
  }

  public GetUserDetailsByFeesHeaderId(FeesHeaderId) {
    return this.http.get(
      `${this.apiURL}` + 'AcademyUser/GetUserDetailsByFeesHeaderId?FHID=' + FeesHeaderId,
      { headers: this.headers }
    );
  }

  public GetAcademyUseridByUserIdAngular(userId) {
    return this.http.get(
      `${this.apiURL}` + 'AcademyUser/GetAcademyUseridByUserIdAngular?UserId=' + userId,
      { headers: this.headers }
    );
  }

  ViewFeesTransaction(feesHeaderId, feesDetailId) {
    return this.http.get(
      `${this.apiURL}` + 'AcademyFees/GetFeesTransactionsDetail?feesHeaderId=' + feesHeaderId + '&feesDetailsId=' + feesDetailId,
      { headers: this.headers }
    );
  }
  public GetCoachDashboardCharts(academyId, userId) {
    return this.http.get(
      `${this.apiURL}` + 'Dashboard/GetCoachDashboardChart?academyId=' + academyId + '&userId=' + userId,
      { headers: this.headers }
    );

  }
  public GetNotificationCount(userId, lastId) {
    return this.http.get(
      `${this.apiURL}` + 'Pushnotification/GetNotificationCount?userId=' + userId + '&lastId=' + lastId,
      { headers: this.headers }
    );
  }
  public GetUpcomingBatchForCoach(academyId, userId) {
    return this.http.get(
      `${this.apiURL}` + 'Dashboard/GetUpcomingBatchForCoach?academyId=' + academyId + '&academyUserId=' + userId,
      { headers: this.headers }
    );

  }
  public GetMenuList(data: any) {
    return this.http.get(
      `${this.apiURL}` + "AnonymousUser/GetUserRoleRightsList?&userID=" + data.UserId + "&parentAcademyId=" + data.ParentAcademyId + "&roleId=" + data.RoleType + "",
      { headers: this.headers }
    );
  }

  public EmailInvoice(data: any) {
    return this.http.get(
      `${this.apiURL}` + "AcademyFees/EmailInvoice?&userId=" + data.userId + "&email=" + data.email + "&parentAcademyId=" + data.parentAcademyId + "&feesHeaderID=" + data.feesHeaderID + "",
      { headers: this.headers }
    );
  }
  /**************** Common Service End  ********************/

  /**************** User Profile Service Start  ********************/
  public GetUserInfo(data: any) {
    var id = (data.StudentId != undefined) ? data.StudentId : data.UserId;
    return this.http.get(
      `${this.apiURL}` + "User/GetProfileForApp?userId=" + id + "&roleId=" + data.RoleType,
      { headers: this.headers }
    );
  }

  public SubmitUserInfo(model: any) {
    return this.http.post(`${this.apiURL}AcademyUser/UpdateUserProfileApp`, model);
  }

  public GetStudentProfile(academyUserId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetStudentProfileDetails?academyUserId=" + academyUserId,
      { headers: this.headers }
    );
  }
  /**************** User Profile Service End  ********************/

  public GetFeesPayedOrNot(feesHeaderId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetFeesPayedOrNot?feesHeaderId=" + feesHeaderId,
      { headers: this.headers }
    );
  }

  public sendstriprequestforsession(priceId, name, email, feesheaderid, batchname, Reference) {
    return this.http.get(
      `${this.apiURL}` + "RazorPay/createSession?price=" + priceId + "&name=" + name + "&email=" + email + "&feesHeaderId=" + feesheaderid + "&batchName=" + batchname + "&reference=" + Reference,
      { headers: this.headers }
    );
  }

  /**************** Attendance Service Start ********************/
  public GetAttendanceBatchesList(data: any) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBaches?academyId=" + data.academyId + "&userId= " + data.userId + " &isAccessAllBatches=" + data.isAccessAllBatches + "",
      { headers: this.headers }
    );
  }

  public GetAttendanceScheduleList(data: any) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetAcademySubBatchListByBatchIds?academyId=" + data.academyId + "&academyBatchId=" + data.batchIds + "&time=''&dayofWeek=" + data.dayofWeek + "&userId=" + data.userId + "&roleId=" + data.roleId + "",
      { headers: this.headers }
    );
  }

  public GetAttendanceSportDrilllist(data: any) {
    return this.http.get(
      `${this.apiURL}` + "SportParameter/GetBatchesSportDrilllist?batchIds=" + data.batchIds + "&scheduleDate=" + data.scheudleDate + "",
      { headers: this.headers }
    );
  }

  public GetAttendanceStudentList(data: any) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBatchesUsers?academyId=" + data.academyId + "&batchIds=" + data.batchIds + "&attendanceDate=" + data.scheudleDate + "",
      { headers: this.headers }
    );
  }

  GetCoachesForAttendanceAPP(batchId) {
    return this.http.get(
      `${this.apiURL}` + "Academy/GetCoachesForAttendanceAPP?batchId=" + batchId + "",
      { headers: this.headers }
    );
  }

  /**************** Attendance Service End ********************/

  /**************** Player and Inquiry Both Service Start ********************/
  GetCustomFields(parentAcademyId, _academyUserId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyCustomField/GetAcademyCustomFieldsByModule?academyId=" + parentAcademyId + '&module=Student' + '&parentId=' + _academyUserId,
      { headers: this.headers }
    );
  }

  GetCustomFieldsEditUser(academyId, _academyUserId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyCustomField/GetAcademyCustomFieldsByModule?academyId=" + academyId + '&module=Student' + '&parentId=' + _academyUserId,
      { headers: this.headers }
    );
  }

  public GetInquiryList(academyId, userId, batchId, shortingBy, InqListId) {
    if (batchId == null || batchId == undefined) {
      batchId = 0
    }

    return this.http.get(
      `${this.apiURL}` + "AcademyInquiry/GetInquiry?academyId=" + academyId + "&userId=" + userId + "&batchId=" + batchId + "&shortingBy=" + shortingBy + "&filterBy=" + InqListId,
      { headers: this.headers }
    );
  }

  public GetStudentList(data: any) {
    var url = '';
    if (data.serchtext == "") {
      url = "AcademyUser/GetUserListByAcademyIdByIndexBBFS?academyId=" + data.academyId + "&userId=" + data.userId + "&startIndex=" + data.pageno + "&batchId=" + data.batchId + "&StatusId=" + data.StatusId + "";
    }
    else {
      url = "AcademyUser/GetUserListByAcademyIdByIndexBBFS?academyId=" + data.academyId + "&userId=" + data.userId + "&searchText=" + data.serchtext + "&startIndex=" + data.pageno + "&batchId=" + data.batchId + "&StatusId=" + data.StatusId + "";
    }

    return this.http.get(
      `${this.apiURL}` + url,
      { headers: this.headers }
    );
  }

  public GetPreferredCetnerList(userId, parentacademyId) {
    return this.http.get(
      `${this.apiURL}` + "Academy/GetUserAcademy?userId=" + userId + "&parentAcademyId=" + parentacademyId + "",
      { headers: this.headers }
    );
  }

  public GetInquirySourceList(academyId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyInquiry/GetInquirySourceList?academyId=" + academyId + "",
      { headers: this.headers }
    );
  }

  public GetAcademySportList(userId, academyId) {
    return this.http.get(
      `${this.apiURL}` + "Sport/GetAcademySportList?userID=" + userId + "&academyId=" + academyId + "",
      { headers: this.headers }
    );
  }

  public GetAgeGroupsByAcademyId(academyId) {
    return this.http.get(
      `${this.apiURL}` + "AgeGroup/GetAgeGroupsByAcademyId?academyId=" + academyId + "",
      { headers: this.headers }
    );
  }

  public GetUserIdAgeProofList(academyId) {
    return this.http.get(
      `${this.apiURL}` + "Academy/GetAcademyProofType?academyId=" + academyId + "",
      { headers: this.headers }
    );
  }

  public GetBachesList(academyId, sportId) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBatchByAcademyId?academyId=" + academyId + "&sportId=" + sportId + "",
      { headers: this.headers }
    );
  }

  public GetScheduleList(data: any) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetAcademySubBatchList?academyBatchId=" + data.batchId + "&userId=" + data.userId + "&roleId=" + data.RoleType + "",
      { headers: this.headers }
    );
  }

  public GetAcademyUserTypeList(academyId, subbatchId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetAcademyUserTypeListByBatchId?academyId=" + academyId + "&academyBatchId=" + subbatchId + "",
      { headers: this.headers }
    );
  }

  public GetAcademyInquiryStatuslist(parentacademyId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetAcademyInquiryStatuslist?academyId=" + parentacademyId + "",
      { headers: this.headers }
    );
  }

  public GetMonthList(schedueleId, userTypeId) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBatchFeesSchedule?batchId=" + schedueleId + "&userType=" + userTypeId + "",
      { headers: this.headers }
    );
  }
  /**
   * name
   */
  public GetAcademyFeesType(academyId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyFeesType/GetAcademyFeesType?academyId=" + academyId,
      { headers: this.headers }
    );
  }
  public GetAcademyFeesTypeInPWA(academyId, academyUserId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyFeesType/GetAcademyFeesTypeInPWA?academyId=" + academyId + "&academyUserId=" + academyUserId,
      { headers: this.headers }
    );
  }

  public GetTaxDetails(parentacademyId) {
    return this.http.get(
      `${this.apiURL}` + "Account/GetDefaultTax?academyId=" + parentacademyId + "",
      { headers: this.headers }
    );

  }
  public CheckUserExist(email, phoneNumber, academyId, userId) {
    return this.http.get(
      `${this.apiURL}` + 'AcademyUser/CheckUserExist?email=' + email + "&phoneNumber=" + phoneNumber + "&academyId=" + academyId + "&userId=" + userId + '',
      { headers: this.headers }
    );

  }
  public CheckUserExistApp(stringcheckexist) {
    return this.http.get(
      `${this.apiURL}` + 'AcademyUser/CheckUserExistApp?CheckUserExistString=' + stringcheckexist + "",
      { headers: this.headers }
    );

  }

  public GetAcademyLeaveReason(academyId) {
    return this.http.get(
      `${this.apiURL}` + 'AcademyUser/GetAcademyLeaveReason?academyId=' + academyId + "",
      { headers: this.headers }
    );

  }

  public GetAcademyUserLastPaidFeesDetail(academyUserId) {
    return this.http.get(
      `${this.apiURL}` + 'AcademyFees/GetAcademyUserLastPaidFeesDetail?academyUserId=' + academyUserId + "",
      { headers: this.headers }
    );
  }
  /**************** Player and Inquiry Both Service End ********************/

  /**************** Assessment Start ********************/

  public GetReportFeedbackLabel(academyId) {
    return this.http.get(
      `${this.apiURL}` + 'Report/GetReportFeedbackLabel?academyId=' + academyId,
      { headers: this.headers }
    );
  }

  public GetAssessmentType(batchId, assessmentId) {
    return this.http.get(
      `${this.apiURL}` + 'Report/GetReportDateListByBatchId?batchId=' + batchId + '&assessmentId=' + assessmentId,
      { headers: this.headers }
    );
  }

  public GetAssessmentTyprAndReportDateList(batchId, assessmentId) {
    return this.http.get(
      `${this.apiURL}` + 'Report/GetReportDateListByBatchId?batchId=' + batchId + '&assessmentId=' + assessmentId,
      { headers: this.headers }
    );
  }

  public GetAssessmentUserList(batchId, reportDate, assessmentTypeId) {
    return this.http.get(
      `${this.apiURL}` + 'Report/GetUserlist?batchId=' + batchId + '&reportDate=' + reportDate + '&assessmentTypeId=' + assessmentTypeId,
      { headers: this.headers }
    );
  }

  public GetUserDetails(userId) {
    return this.http.get(
      `${this.apiURL}` + 'AcademyUser/GetAcademyUserDetails?userId=' + userId,
      { headers: this.headers }
    );

  }

  public GetUserReportDetails(userId, batchId, academySportId, reportDate, assessmentTypeId, IsAdd) {
    var url = "";
    if (IsAdd == true) {
      url = "Report/GetSportParameterDetails?batchId=" + batchId + '&userId=' + userId + '&assessmentTypeId=' + assessmentTypeId
    }
    else {
      url = "Report/GetUserReportDetails?userId=" + userId + '&batchId=' + batchId + '&acdemySportId=' + academySportId + '&reportDate=' + reportDate + '&assessmentTypeId=' + assessmentTypeId
    }

    return this.http.get(
      `${this.apiURL}` + url,
      { headers: this.headers }
    );
  }

  public GetUniqueArray(array) {
    var flags = [], output = [], l = array.length, i;
    for (i = 0; i < l; i++) {
      if (flags[array[i].CategoryName]) continue;
      flags[array[i].CategoryName] = true;
      var obj = {
        'CategoryName': array[i].CategoryName,
        'CategoryComments': array[i].CategoryComments,
        'CategoryId': array[i].CategoryId

      };
      output.push(obj);
    }
    return output;
  }
  /**************** Assessment End ********************/

  /**************** Inquiry Filter Service Start ********************/
  public GetUserBatches(academyId, userId, isAccessAllBatches) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBaches?academyId=" + academyId + "&userId= " + userId + " &isAccessAllBatches=" + isAccessAllBatches + "",
      { headers: this.headers }
    );
  }

  public GetUserScheduleList(batchId, userId, RoleType) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetAcademySubBatchList?academyBatchId=" + batchId + "&userId=" + userId + "&roleId=" + RoleType + "",
      { headers: this.headers }
    );
  }

  public GetFilterInquiryStatus(parentacademyId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetAcademyInquiryStatuslist?academyId=" + parentacademyId + "",
      { headers: this.headers }
    );
  }

  public GetProrateAmount(academyId, finalamount, monthplanchangeId, fromdate, duration, academyUserId, subbatchId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetProrateAmount?academyId=" + academyId + "&quarterAmount=" + finalamount + "&fromDate=" + fromdate + "&duration=" + duration + "&feesMode=" + monthplanchangeId + "&academyUserId=" + academyUserId + "&academyBatchId=" + subbatchId + "",
      { headers: this.headers }
    );
  }
  public GetProrateAmountAnnualFees(academyId, finalamount, monthplanchangeId, fromdate, duration, academyUserId, subbatchId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetProrateAmountAnnualFees?academyId=" + academyId + "&quarterAmount=" + finalamount + "&feesMode=" + monthplanchangeId + "&fromDate=" + fromdate + "&duration=" + duration + "&academyUserId=" + academyUserId + "&academyBatchId=" + subbatchId + "",
      { headers: this.headers }
    );
  }

  /**************** Inquiry Filter Service End ********************/

  /**************** User Messages Service Start ********************/
  public GetUserMessages(userId, roleId, batchId,) {
    return this.http.get(
      `${this.apiURL}` + "Messages/GetUserMessages?userId=" + userId + "&roleId=" + roleId + "&academyBatchId=" + batchId + "",
      { headers: this.headers }
    );
  }
  public GetAllMessages(id, convid) {
    return this.http.get(
      `${this.apiURL}` + "Messages/GetAllMessages?userId=" + id + "&&conversationId=" + convid + "",
      { headers: this.headers }
    );
  }

  /**************** User Messages Service End ********************/
  /**************** User Leavee Service Start ********************/

  public GetAllLeaveApplicationByUserId(academyUserId) {
    return this.http.get(
      `${this.apiURL}` + "LeaveApplication/GetAllLeaveApplicationByUserId?academyUserId=" + academyUserId,
      { headers: this.headers }
    );
  }

  public GetInjuryListForStudent(academyUserId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetUserInjury?academyUserId=" + academyUserId,
      { headers: this.headers }
    );
  }

  public DeleteUserInjury(id) {
    return this.http.delete(
      `${this.apiURL}` + "AcademyUser/DeleteUserInjury?id=" + id,
      { headers: this.headers }
    );
  }

  public DeleteLeaveApplication(id) {
    return this.http.get(
      `${this.apiURL}` + "LeaveApplication/DeleteLeaveApplication?id=" + id,
      { headers: this.headers }
    );
  }
  public GetUserListOfCoach(UserId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetUserListOfCoach?UserId=" + UserId,
      { headers: this.headers }
    );
  }
  public GetLeaveList(data) {
    return this.http.get(
      `${this.apiURL}` + "LeaveApplication/GetLeaveApplications?academyId=" + data.academyId + "&parentAcademyId=" + data.parentAcademyId + "&loginUserId=" + data.loginUserId + "&roleType=" + data.roleType, { headers: this.headers }
    );
  }

  public GetDetailLeave(leaveId, userId, academyId, parentAcademyId){
    return this.http.get(
      `${this.apiURL}` + "LeaveApplication/GetStudentLeaveApp?studentId=" + leaveId + "&userId=" + userId + "&AcademyId=" + academyId + "&parentAcademyId=" + parentAcademyId, { headers: this.headers }
    );
  }

  public DownloadLeaveFile(filename, angular) {
    return this.http.get(
      `${this.apiURL}` + "LeaveApplication/GetLeaveDocFilePath?LeaveFileName=" + filename + "&angular=" + angular, { headers: this.headers }
    );
  }

  public DownloadLeaveConfirm(id) {
    return this.http.get(
      `${this.apiURL}` + "LeaveApplication/ExportLeaveApplicationPlayerReport?LeaveId=" + id, { headers: this.headers }
    );
  }

  /**************** User Leavee Service End ********************/
  /**************** Drills Service Start ********************/

  GetDriilsOnDate(batchId, planingdate, nextprev_drills, academyUserId) {
    return this.http.get(
      `${this.apiURL}` + "SportDrill/GetTodaysDrills?batchId=" + batchId + "&planingdate=" + planingdate + "&nextprev_drills=" + nextprev_drills + "&academyUserId=" + academyUserId,
      { headers: this.headers }
    );
  }
  public GetUniqueArrayDrills(array) {
    var flags = [], output = [], l = array.length, i;
    for (i = 0; i < l; i++) {
      if (flags[array[i].Text1]) continue;
      flags[array[i].Text1] = true;
      output.push(array[i].Text1);
    }
    return output;
  }
  /**************** Drills Service End ********************/


  public CreateInquiry(academyuserView: any) {
    return this.http.post(`${this.apiURL}AcademyInquiry/CreateInquiry`, academyuserView, { headers: this.headers });
  }

  /**************** Player Service Start ********************/
  public CreateStudent(academyuserView: any, userAcademyId: any) {
    if (userAcademyId == 0) {
      return this.http.post(`${this.apiURL}AcademyUser/CreateUser`, academyuserView, { headers: this.headers });
    }
    else {
      return this.http.post(`${this.apiURL}AcademyUser/EditUser`, academyuserView, { headers: this.headers });
    }
  }

  public GetStudentById(UserAcademyId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetAcademyUser?academyUserId=" + UserAcademyId + "",
      { headers: this.headers }
    );
  }
  /**************** Player Service End ********************/

  /**************** Change Password Service Start ********************/
  public getUserById() {
    let userId = this.commonFunction.GetUserId();
    return this.http.get(
      `${this.apiURL}` + "User/GetUserbyId?UserId=" + userId + "",
      { headers: this.headers }
    );
  }

  /**************** Change Password Service End ********************/

  /**************** Coach Attendance Service Start ********************/
  public GetBatchListbyCoachAttendance(userId, attendanceDate) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBatchListbyCoachAttendance?userId=" + userId + "&attendanceDate=" + attendanceDate,
      { headers: this.headers }
    );
  }
  /**************** Coach Attendance Service End ********************/

  public GetUserAcademy(attendanceDate, userId) {
    return this.http.get(
      `${this.apiURL}` + "Academy/GetUserAcademy?userId=" + userId + "&parentAcademyId=" + attendanceDate,
      { headers: this.headers }
    );
  }
  public GetBatchByTimeApp(attendanceDate, acadeMyId, time, dayofWeek, userId, isAccessAllBatches) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBatchByTimeApp?attendanceDate=" + attendanceDate + "&acadeMyId=" + acadeMyId + "&time=" + time + "&dayofWeek=" + dayofWeek + "&userId=" + userId + "&isAccessAllBatches=" + isAccessAllBatches + "",
      { headers: this.headers }
    );
  }
  public GetAcademySubBatchList(batchId, userId, roleId) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetAcademySubBatchList?academyBatchId=" + batchId + "&userId=" + userId + "&roleId=" + roleId,
      { headers: this.headers }
    );
  }
  public GetCoachAttendance(academyId, batchId, userId, dayofWeek, month, year) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetCoachAttendance?academyId=" + academyId + "&batchId=" + batchId + "&userId=" + userId + "&month=" + month + "&year=" + year + "",
      { headers: this.headers }
    );
  }

  public GetStudentAttendanceList(_academyUserStudentId, _academyId, _UserIdAttendance, _fromDate, _toDate, _academyBatchId) {
    if (_academyUserStudentId != undefined) {
      return this.http.get(
        `${this.apiURL}` + 'Dashboard/GetUserAttendanceListForDashboard?userId=' + _UserIdAttendance + '&academyId=' + _academyId + '&academyUserStudentId=' + _academyUserStudentId + '&fromDate=' + _fromDate + '&toDate=' + _toDate + '&academyBatchId=' + _academyBatchId + "",
        { headers: this.headers }
      );
    }
    else {
      return this.http.get(
        `${this.apiURL}` + 'Dashboard/GetUserAttendanceListForDashboard?userId=0&academyId=' + _academyId + '&academyUserStudentId=' + _UserIdAttendance + '&fromDate=' + _fromDate + '&toDate=' + _toDate + '&academyBatchId=' + _academyBatchId + "",
        { headers: this.headers }
      );
    }

  }
  /**************** Coach Assement Service End ********************/


  public GetSportsByAcedemyID(academyId) {
    return this.http.get(
      `${this.apiURL}` + 'Sport/GetSportsByAcedemyID?academyId=' + academyId + "",
      { headers: this.headers }
    );

  }
  public GetAll(academyId, academySportId) {
    return this.http.get(
      `${this.apiURL}` + 'CoachAssessment/GetAll?academyId=' + academyId + '&academySportId=' + academySportId + '&isSuperAdmin=false' + "",
      { headers: this.headers }
    );

  }
  public GetAcademyCoachAssessmentUserList(academyId, academySportId, _reportDate) {
    return this.http.get(
      `${this.apiURL}` + 'CoachAssessment/GetAcademyCoachAssessmentUserList?academyId=' + academyId + '&academySportId=' + academySportId + '&reportDate=' + _reportDate + "",
      { headers: this.headers }
    );

  }
  public GetUserbyId(userId) {
    return this.http.get(
      `${this.apiURL}` + 'User/GetUserbyId?userId=' + userId,
      { headers: this.headers }
    );

  }
  public GetAcademyCoachAssessmentReport(userId, _academyId, sportId, reportDate) {
    return this.http.get(
      `${this.apiURL}` + 'CoachAssessment/GetAcademyCoachAssessmentReport?userId=' + userId + '&academyId=' + _academyId + '&sportId=' + sportId + '&reportDate=' + reportDate + "",
      { headers: this.headers }
    );
  }
  /**************** Coach Assement Service End ********************/

  /**************** Event Service End ********************/
  public GetEvent(_serviceUrl) {
    return this.http.get(
      `${this.apiURL}` + _serviceUrl + "",
      { headers: this.headers }
    );
  }
  public GetEventbyId(evntId) {
    return this.http.get(
      `${this.apiURL}` + 'Event/GetEventById?eventId=' + evntId,
      { headers: this.headers }
    );
  }

  /**************** Event Service End ********************/


  /* Online Payment Start */

  public GetIsEnableOnlinePayment(parentAcademyId) {
    return this.http.get(
      `${this.apiURL}` + 'Academy/GetIsEnableOnlinePayment?parentAcademyId=' + parentAcademyId,
      { headers: this.headers }
    );
  }

  public GetPaymentRulesDetails(parentAcademyId, date, feesHeaderId) {
    return this.http.get(
      `${this.apiURL}` + 'AcademyFeesType/GetDiscountOrPaneltyDetails?parentAcademyId=' + parentAcademyId + '&date=' + date + '&feesHeaderId=' + feesHeaderId,
      { headers: this.headers }
    );
  }
  public GetUserDisplayAmount(amount, penalty, parentAcademyId) {
    return this.http.get(
      `${this.apiURL}` + 'RazorPay/UserDisplayAmountCustom?amount=' + amount + '&penalty=' + penalty + '&parentAcademyId=' + parentAcademyId,
      { headers: this.headers }
    );
  }
  public GetConstantList() {
    return this.http.get(
      `${this.apiURL}` + 'RazorPay/GetConstantList',
      { headers: this.headers }
    );
  }
  public GetGatewayList(c) {
    return this.http.get(
      `${this.apiURL}` + 'RazorPay/GetGatewayList?constantValue=' + c,
      { headers: this.headers }
    );
  }

  public GetCapturepayment(payment_id, amount, amountTransfer, feesPaymentPaneltyAmount, parentAcademyId, academyId) {
    return this.http.get(
      `${this.apiURL}` + 'razorpay/GetCapturepayment?Payment_id=' + payment_id + '&amount=' + amount + '&amountTransfer=' + ((amountTransfer * 100) + (feesPaymentPaneltyAmount * 100)) + '&parentAcademyId=' + parentAcademyId + '&academyId=' + academyId,
      { headers: this.headers }
    );
  }
  /* Online Payment End*/

  /* Push Notification Start */
  public Pushnotification(userId, lastPushId) {
    return this.http.get(
      `${this.apiURL}` + "Pushnotification/GetNotificationMessagesByUser?userId=" + userId + "&lastPushId=" + lastPushId + "",
      { headers: this.headers }
    );
  }
  GetNotificationDetailsForPage(pushId, notificationId, notificationTypeId) {
    return this.http.get(
      `${this.apiURL}` + "PushNotification/GetNotificationDetailsForPage?pushId=" + pushId + "&notificationId=" + notificationId + "&notificationTypeId=" + notificationTypeId + "",
      { headers: this.headers }
    );
  }
  /* Push Notification End */

  /*  Requisition Module Start */
  public GetRequisitionList(academyId, userId, parentAacdemyId) {
    return this.http.get(
      `${this.apiURL}` + "Requisition/GetAllRequisitionList?academyId=" + academyId + "&userId=" + userId + "&parentAcademyId=" + parentAacdemyId,
      { headers: this.headers }
    );
  }
  GetAllItemCategoryList(academyId) {
    return this.http.get(
      `${this.apiURL}` + "ItemCategory/GetAllItemCategoryList?academyId=" + academyId,
      { headers: this.headers }
    );
  }

  GetRequisitionItems(academyId, categoryId) {
    return this.http.get(
      `${this.apiURL}` + "Item/GetItemListByItemCategory?academyId=" + academyId + "&itemCategoryId=" + categoryId,
      { headers: this.headers }
    );
  }

  GetReportDamage(academyId, userId) {
    return this.http.get(
      `${this.apiURL}` + "ReportDamage/GetAllReportDamagesList?academyId=" + academyId + "&userId=" + userId,
      { headers: this.headers }
    );
  }

  GetInventoryReportofCurrentDate(academyId, parentAcademyId) {
    return this.http.get(
      `${this.apiURL}` + "Inventory/GetInventoryReportOfCurrentDate?academyId=" + academyId + '&parentAcademyId=' + parentAcademyId,
      { headers: this.headers }
    );
  }
  /*  Requisition Module End*/
  /* User Analytics Page  Start  */
  GetAllAssessmentType(parentAcademyId, studentId) {
    return this.http.get(
      //`${this.apiURL}` + "Assessment/GetAllAssessmentTypeWithBatchAssessment?academyId=" + parentAcademyId,
      `${this.apiURL}` + "Assessment/GetAllAssessmentTypeWithBatchAssessmentApp?academyId=" + parentAcademyId + "&userId=" + studentId,
      { headers: this.headers }
    );
  }
  GetUserAssessmentData(userId, userSportId, assessmentTypeId) {
    return this.http.get(
      `${this.apiURL}` + "Dashboard/GetUserDataDashboard?userId=" + userId + "&userSportId=" + userSportId + '&isWeb=false' + "&assessmentTypeId=" + assessmentTypeId,
      { headers: this.headers }
    );
  }

  GetAssessmentReportList(academyId, userId, studentId) {
    return this.http.get(
      `${this.apiURL}` + "Dashboard/GetAssessmentReport?academyId=" + academyId + '&userId=' + userId + '&academyUserStudentId=' + studentId,
      { headers: this.headers }
    );
  }
  /* User Analytics Page  eND */

  /* Manage Fees Start */
  public GetAcademyStudentlist(batchId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyFees/GetAcademyStudentlist?batchId=" + batchId,
      { headers: this.headers }
    );
  }

  public GetUserAdmissionDetails(academyUserId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetUserAdmissionDetails?academyUserId=" + academyUserId + "",
      { headers: this.headers }
    );
  }

  public GetStudentFeesDetailNew(academyUserId, batchId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyFees/GetStudentFeesDetailNew?userId=" + academyUserId + "&batchId=" + batchId + "",
      { headers: this.headers }
    );
  }

  public GetAcademyFeesList(academyId, parentAcademyId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyFees/GetAcademyFeesList?academyId=" + academyId + "&parentAcademyId=" + parentAcademyId + "",
      { headers: this.headers }
    );
  }

  public GetRecentPaymentsDetails(academyUserId, feesTypeId) {
    var url = '';
    url = "AcademyFees/GetRecentPaymentsDetails?academyUserId=" + academyUserId + "&feesTypeId=" + feesTypeId + "";
    return this.http.get(
      `${this.apiURL}` + url,
      { headers: this.headers }

    );
  }

  public ViewFeesTransactionDetails(feesHeaderId, feesDetailsId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyFees/GetFeesTransactionsDetail?feesHeaderId=" + feesHeaderId + "&feesDetailsId=" + feesDetailsId,
      { headers: this.headers }
    );
  }

  public GetFeesDetailsByFeesTypeId(academyUserId, feesTypeId, batchId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyFees/GetFeesDetailsByFeesTypeId?academyUserId=" + academyUserId + "&feesTypeId=" + feesTypeId + "&batchId=" + batchId + "",
      { headers: this.headers }
    );
  }

  GetFeesInfoDetailsByFeesTypeId(academyUserId, headerId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyFees/GetFeesDetailsByHeaderId?academyUserId=" + academyUserId + "&headerId=" + headerId,
      { headers: this.headers }
    );
  }

  GetFeesHeaderDetails(headerId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyFees/GetFeesHeaderDetails?headerId=" + headerId,
      { headers: this.headers }
    );
  }

  GetCollectionReport(data) {
    return this.http.get(
      `${this.apiURL}` + "Report/GetCollectionReport?academyId=" + data.academyId + "&batchId=" + data.batchId + "&subBatchId=" + data.subBatchId + "&month=" + data.month +
      "&year=" + data.year + "&sportId=" + data.sportId + "&userType=" + data.userType + "&feesTypeId=" + data.feesTypeId,
      { headers: this.headers }
    );
  }


  GetUserFeesTypeListWithFees(academyUserId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyFeesType/GetUserFeesTypeListWithFees?academyUserId=" + academyUserId,
      { headers: this.headers }
    );
  }
  /* Manage Fees End */

  /* Renew Fees Start */
  GetUserDetailsAppbyAcademyUserId(academyUserId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetUserDetailsAppbyAcademyUserId?academyUserId=" + academyUserId,
      { headers: this.headers }
    );
  }

  GetSportList(roleId, _academyId, userId) {
    var url = "";
    if (roleId == 2) {
      url = "Sport/GetAcademySportList?userID=" + userId + "&academyId=" + _academyId;
    } else if (roleId == 3) {
      url = "Sport/GetAcademySportList?userID=" + userId + "&academyId=" + _academyId;
    } else {
      url = "Sport/GetSportsByAcedemyID?academyId=" + _academyId;
    }
    return this.http.get(
      `${this.apiURL}` + url,
      { headers: this.headers }

    );
  }
  /* Renew Fees End */

  /* renew parent start*/
  public GetBatchByAcademyId(data: any) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBatchByAcademyId?academyId=" + data.academyId + "",
      { headers: this.headers }
    );
  }

  GetAcademyUserId(data) {
    return this.http.get(
      `${this.apiURL}` + "academyUser/GetAcademyUserId?academyId=" + data.AcademyId + '&userId=' + data.ParentMultiSelectUserId,
      { headers: this.headers }
    );
  }

  /* renew parent end*/

  /* Transfer Batch Start*/
  GetUserAcademyForTransferBatch(userId, parentAcademyId) {
    return this.http.get(
      `${this.apiURL}` + "Academy/GetUserAcademy?userId=" + userId + '&parentAcademyId=' + parentAcademyId,
      { headers: this.headers }
    );
  }

  GetBatchesByAcademySportId(academySportId) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBatchListForTransferBatch?academySportId=" + academySportId + '',
      { headers: this.headers }
    );
  }

  GetBatchFeesSchedule(batchId, userType) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBatchFeesSchedule?batchId=" + batchId + "&userType=" + userType + '',
      { headers: this.headers }
    );
  }

  GetBatchFeesScheduleByBatchId(batchId) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBatchFeesSchedule?batchId=" + batchId + '',
      { headers: this.headers }
    );
  }

  GetPastPaymetDetails(academyUserId) {
    return this.http.get(
      `${this.apiURL}` + "academyUser/GetBatchTransferData?academyUserId=" + academyUserId + '',
      { headers: this.headers }
    );
  }

  GetCommonTaxDetails(id) {
    return this.http.get(
      `${this.apiURL}` + "Account/GetDefaultTax?academyId=" + id + '',
      { headers: this.headers }
    );
  }
  /* Transfer Batch end*/

  /* Batch Planning Start*/
  GetBatchesforBatchPlanning(academyId, parentAcademyId, userId, isAccessAllBatches) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBaches?academyId=" + academyId + '&parentAcademyId=' + parentAcademyId + '&userId=' + userId + '&isAccessAllBatches=' + isAccessAllBatches + '',
      { headers: this.headers }
    );
  }
  GetScheduleforBatchPlanning(academyBatchId) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetAcademySubBatchList?academyBatchId=" + academyBatchId + '',
      { headers: this.headers }
    );
  }

  GetBatchSlotTime(batchId, dayOfWeek) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetBatchSlotTime?batchId=" + batchId + '&dayOfWeek=' + dayOfWeek,
      { headers: this.headers }
    );
  }

  GetLookups(academyid) {
    return this.http.get(
      `${this.apiURL}` + "Academy/GetLookUpList?academyId=" + academyid,
      { headers: this.headers }
    );
  }

  GetDrillList(batchId, fromDate, toDate, slotId) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetDrillList?batchId=" + batchId + '&fromDate=' + fromDate + '&toDate=' + toDate + '&slotName=' + slotId + '',
      { headers: this.headers }
    );
  }

  GetCategoryList(academyId, batchId) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetCategoryList?academyId=" + academyId + '&batchId=' + batchId + '',
      { headers: this.headers }
    );
  }

  GetDrillListByBatchId(batchId) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetDrillListByBatchId?batchId=" + batchId + '',
      { headers: this.headers }
    );
  }

  GetVenueByAcademyId(academyId, userId) {
    return this.http.get(
      `${this.apiURL}` + "Venue/GetVenueByAcademyId?academyId=" + academyId + '&userId=' + userId + '',
      { headers: this.headers }
    );
  }

  GetVenueScheduleByDate(venueId, Date) {
    return this.http.get(
      `${this.apiURL}` + "Venue/GetVenueScheduleByDate?venueId=" + venueId + '&Date=' + Date + '',
      { headers: this.headers }
    );
  }

  GetDrillListOnDate(batchId, scheduleDate, categoryId, slotName) {
    return this.http.get(
      `${this.apiURL}` + "Batch/GetDrillListOnDate?batchId=" + batchId + '&scheduleDate=' + scheduleDate + '&categoryId=' + categoryId + '&slotName=' + slotName + '',
      { headers: this.headers }
    );
  }
  /* Batch Planning end*/

  /* Dropout Start*/
  GetAcademyLeaveReasonList(ParentacademyId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetAcademyLeaveReasonList?academyId=" + ParentacademyId + '',
      { headers: this.headers }
    );
  }
  /* Dropout End*/

  /* VenueBooking Start*/
  GetVenueSportsByVenueId(venueId) {
    return this.http.get(
      `${this.apiURL}` + "Venue/GetVenueSportsByVenueId?venueId=" + venueId + '',
      { headers: this.headers }
    );
  }
  /*  VenueBooking End*/

  /*  Add Extra Payment Start*/

  GetSMSCountDetails(parentAcademyId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyFees/GetSMSCountDetails?academyId=" + parentAcademyId + '',
      { headers: this.headers }
    );
  }

  /*  Add Extra Payment End*/

  /*  PunchIn/out Start*/
  GetAcademyCoachClockIn(userId, academyId) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetAcademyCoachClockIn?userId=" + userId + "&academyId=" + academyId + '',
      { headers: this.headers }
    );
  }
  /* PunchIn/out End*/

  /*  PunchIn/out Report Start*/
  GetCoachWiseClockInOutReport(academyId, userId, parentAcademyId, fromDate, toDate, loggedInUserId, limit) {
    return this.http.get(
      `${this.apiURL}` + "AcademyUser/GetCoachWiseClockInOutReportForAngular?academyId=" + academyId + "&userId=" + userId + "&parentAcademyId=" + parentAcademyId + "&fromDate=" + fromDate + "&loggedInUserId=" + loggedInUserId + "&toDate=" + toDate  + "&batchId=" + 0 + "&subBatchId=" + 0 + "&offset=" + 0 + "&searchText=" + "" + "&limit=" + limit,
      { headers: this.headers }
    );
  }
  /* PunchIn/out Report End*/
}
