import { Component, OnInit, Injectable, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CommonFunction } from '../service/commonfunction';
import { GetListService } from './../service/getlist.service'
import { PostService } from './../service/post.service'
import $ from 'jquery'
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DashboarddotpopupComponent } from '../common/dashboarddotpopup/dashboarddotpopup.component';
import { DashboardpersonalpopupComponent } from '../common/dashboardpersonalpopup/dashboardpersonalpopup.component';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { StudentFees } from '../_models/user.model';
import { DatePipe } from '@angular/common';
import { environment } from "./../../environments/environment";
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertComponent } from '../AlertPopup/alert/alert.component';
import { NotificationService } from '../service/notification.service';
import { ToastrService } from 'ngx-toastr';
import { IdbService } from '../service/idb.service';
import { StudentModel } from '../_models/user.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  strrequired = "";
  MarqueeResponse: any;
  webURL: string = environment.webUrl;
  DisplayacademyName: any;
  AcademyImage: any;
  RedmarqueeForPayment = false;
  Redmarquee: boolean = false;
  Yellowmarquee: boolean = false;
  msg = "";
  isCoach = false;
  isParent = false;
  isAdmin = false;
  userdata;
  userId;
  @Input() setstudentFeesmodel: StudentFees;
  selectionoption: boolean = false;
  barchart = [];
  barchartdata = [];
  upcomingBatchdata = [];
  upcomingBatchdataNotfound: boolean = false;
  Ischart: boolean = false;
  dashBoardData;
  sportList;
  stdacademyId: any;
  isMultipleStudent: boolean = false;
  StudentList: any[];
  academyList: any[];
  StudentId: any;
  studentAssessmentReportList: any;
  AcademyId;
  academyName;
  academynameSplite;
  imageURL;
  pendingcount = 0;
  pendingcount1 = 0;
  imageURLParent;
  webicon: boolean = false;
  twittericon: boolean = false;
  youtubeicon: boolean = false;
  facebookicon: boolean = false;
  instagramicon: boolean = false;
  userFessdetailsList;
  feesCount;
  deviceIdModel;
  noti_Count;
  batchlist: any;
  StudentLeaveList = [];
  pendingClockOut;
  StudentFormGroup: UntypedFormGroup;
  Collection: boolean = false;
  FeesMenu: boolean = false;
  DrillsRights: boolean = false;
  Attendence: boolean = false;
  Inquiry: boolean = false;
  Student: boolean = false;
  Overdue: boolean = false;
  Subsciption: boolean = false;
  Dropout: boolean = false;
  AcademySportList: any;
  dashboardTagLine: any = "";
  BestPerformingStudentList: any = [];
  RequiringAttentionList: any = [];

  // Dashboardcount param Start
  totalstudentsCount: number = 0;
  attendanceandMarked: string = "";
  collectedFees: number = 0;
  OverdueCount: number = 0;
  OpenLeads: number = 0;
  dropOuts: number = 0;
  currencysymbol: any;
  setstudentmodel: StudentModel;
  RoleType: any;
  // Dashboardcount param End

  academyData:any;
  constructor(
    public router: Router,
    public commonFunction: CommonFunction,
    public getListService: GetListService,
    public postService: PostService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    public fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public idbService: IdbService,
    private notifyService: NotificationService,
    public SimpleModalService: SimpleModalService,
    private spinner: NgxSpinnerService,
  ) {
    this.StudentFormGroup = this.fb.group({
      MultipleStudentId: new UntypedFormControl('')
    })
  }

  ngOnInit(): void {
    
    this.spinner.show();
    // this.toastr.success('Hello world!', 'Toastr fun!');
    this.userdata = this.commonFunction.GetUserData();
    // console.log(this.userdata);
    this.GetAcademyCoachClockIn();

    // console.log(this.userdata);
    this.academyData = JSON.parse(localStorage.getItem("academyData"));
    // console.log(this.academyData.OneSignalId);
    this.RoleType = this.userdata.RoleType;

    this.DisplayacademyName = this.userdata.SelectAcademyName;
    this.userId = this.userdata.UserId;
    this.stdacademyId = this.userdata.StudentId;
    let GetAcademyData = this.commonFunction.GetAcademyData();
    // console.log(GetAcademyData);

    if (GetAcademyData != null || GetAcademyData != undefined) {
      // if (this.academyData.Id == 41) {
      //   // this.logo = "assets" + this.academyData.AcademyDarkLogoImage;
      //   this.logo = this.webURL + this.academyData.AcademyDarkLogoImage;
      // }
      // else {
      //   this.logo = this.webURL + this.academyData.AcademyLightLogoImage;
      // }

      this.AcademyImage = this.webURL + GetAcademyData.ImageUrl;
    }
    this.dashboardTagLine = GetAcademyData.TagLine
    if (window.navigator.onLine == false) {
      if (this.userdata.RoleTypeId == 1) {
        this.isAdmin = true;
        this.GetAdminCount(this.userdata.AcademyId, this.userdata.UserId);
      }
      else if (this.userdata.RoleTypeId == 2) {
        this.isCoach = true;
        this.Inquiry = true;
        this.Subsciption = true;
        this.Attendence = true;
        this.GetNotificationCount();
      }
      else if (this.userdata.RoleTypeId == 3 || this.userdata.RoleTypeId == 3) {
        $("#pagepagedash").addClass("parentdashboard");
        this.isParent = true;
        this.isMultipleStudent = false;
        this.MultipleStudentList();
        this.GetNotificationCount();
        this.GetAssessmentReport(this.userdata.AcademyId, this.userdata.UserId, this.userdata.StudentId);
      }
    }
    else {
      this.getMenuList();
      this.CheckDeviceId(this.userdata.DeviceId, this.userdata.UserId);
      this.GetCurrencyOfAcademy(this.userdata.AcademyId, this.userdata.UserId);
      this.GetAcademyDetails();

      if (this.userdata.RoleTypeId == 1) {
        this.isAdmin = true;
        this.GetAdminCount(this.userdata.AcademyId, this.userdata.UserId);
        this.GetAcademySportForDashboardByUserId();
        this.GetNotificationCount();
      }
      else if (this.userdata.RoleTypeId == 2) {
        this.isCoach = true;
        this.GetNotificationCount();
      }
      else if (this.userdata.RoleTypeId == 3 || this.userdata.RoleTypeId == 3) {
        $("#pagepagedash").addClass("parentdashboard");
        this.isParent = true;
        this.isMultipleStudent = false;
        this.MultipleStudentList();
        this.GetNotificationCount();
        this.GetAssessmentReport(this.userdata.AcademyId, this.userdata.UserId, this.userdata.StudentId);
      }
      else {

      }
      this.onremoveclick();
      if (this.userdata.RoleType == 2) { /* Coach dashboard methods*/
        this.GetCoachDashboardCharts(this.userdata.AcademyId, this.userdata.UserId);
        this.GetCoachDashboardUpcoming(this.userdata.AcademyId, this.userdata.UserId);
        //this.GetUserListOfCoach();
      }
    }
    this.LeaveList();
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 100);
    
  }

  onclick() {
    $("body").addClass("modal-open");
    $('#menu-sidebar-left-1').addClass('menu-active')
  }
  onremoveclick() {
    $("body").removeClass("modal-open");
    $('#menu-sidebar-left-1').removeClass('menu-active')
  }

  LeaveList() {

    var data = {
      academyId: this.userdata.AcademyId,
      parentAcademyId: this.userdata.ParentAcademyId,
      loginUserId: this.userdata.UserId,
      AcademyBatchId: this.userdata.AcademyBatchId, 
      roleType: this.RoleType
    }
    // console.log(data);

    this.getListService.GetLeaveList(data).subscribe((response: any) => {
      // console.log(response)
      this.StudentLeaveList = response;
      // console.log(this.StudentLeaveList);
      
      for (let i = 0; i < this.StudentLeaveList.length; i++) {
        if (this.StudentLeaveList[i].StatusId == 0) {
          this.pendingcount = this.pendingcount + 1;
        }
        else if (this.StudentLeaveList[i].StatusId == 1 || this.StudentLeaveList[i].StatusId == 2 || this.StudentLeaveList[i].StatusId == 3) {
          this.pendingcount1 = this.pendingcount1 + 1;
        }
      }
      // console.log(this.pendingcount);
      // console.log(this.pendingcount1);
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 300);
  }

  leavedetail(item) {
    this.router.navigate(['/leaveapplication/playerleavedetail'],
      { state: { example: item } })
  }

  batchesList() {
    var data = {
      academyId: this.userdata.AcademyId,
      userId: this.userdata.UserId,
    };

    this.getListService
      .getBatchesList(data)
      .subscribe((response: any) => {
        let idstring = "";
        for (let i = 0; i < response.length; i++) {

          // this.GetBatchesList.push({
          //   Id: response[i].Id,
          //   BatchName: response[i].BatchName,
          //   checked: false
          // });
        }
      },
        (error) => {

        });
  }
  notification() {
    this.router.navigate(['/notification']);
  }
  GetAssessmentReport(academyId, userId, studentId) {
    this.getListService
      .GetAssessmentReportList(academyId, userId, studentId)
      .subscribe((response: any) => {
        this.studentAssessmentReportList = response;
      })
  }

  CheckDeviceId(deviceId, userId) {
    this.postService
      .CheckDeviceId(deviceId, userId)
      .subscribe((response: any) => {
        this.deviceIdModel = response;
        if(this.deviceIdModel.IsExist){
          if(this.deviceIdModel.deviceId == null || this.deviceIdModel.deviceId == ''){
            this.UpdateDeviceId(this.userdata.DeviceId, this.userdata.UserId);
            this.UpdateOneSingleId(this.userdata.UserId,this.userdata.DeviceId,this.academyData.OneSignalId)
          }
        }
        else{
          if(this.deviceIdModel.deviceId != null || this.deviceIdModel.deviceId != ''){
            
          }
        }        
      })

      // this.UpdateDeviceId(this.userdata.DeviceId, this.userdata.UserId);
  }

  UpdateDeviceId(deviceId, userId) {
    this.postService
      .UpdateDeviceId(deviceId, userId)
      .subscribe((response: any) => {
        this.studentAssessmentReportList = response;
      })
  }
  UpdateOneSingleId(userId,deviceId,oneSignalId){
    this.postService
      .InsertOneSignalId(userId, deviceId, oneSignalId)
      .subscribe((response: any) => {},
        (error) => {});
  }
  StudentClick(value) {
    if (value == 0) {
      this.router.navigate(['/studentmodule/list'],
        {
          state: { Filter: 0 }
        });
    }
    else if (value == 1) {
      this.router.navigate(['/studentmodule/list'],
        {
          state: { Filter: 1 }
        });
    }
    else if (value == 2) {
      this.router.navigate(['/studentmodule/list'],
        {
          state: { Filter: 2 }
        });
    }
    else {
      this.router.navigate(['/studentmodule/list'],
        {
          state: { Filter: 3 }
        });
    }

  }

  ReportCollection() {
    this.router.navigate(['/collectionreport']);
  }


  GetCurrencyOfAcademy(academyId, userId) {
    this.getListService
      .GetCurrencyOfAcademy(academyId, userId)
      .subscribe((response: any) => {
        let academyCurrency = response;
        this.commonFunction.SetAcademyCurrency(academyCurrency);
        this.userdata = this.commonFunction.GetUserData();
        this.currencysymbol = this.userdata.CountryCurrencySymbol;
      })
  }
  GetAdminCount(academyId, userId) {
    if (window.navigator.onLine == false) // Ofline
    {
      let that = this;
      this.idbService.getAllData("dashboard").then((item: any) => {
        let response = item[0];
        that.Student = true;
        that.Overdue = true;
        that.Dropout = true;
        that.Inquiry = true;
        that.Collection = true;
        that.Attendence = true;

        that.dashBoardData = response;
        that.totalstudentsCount = this.dashBoardData.TotalStudents;
        that.attendanceandMarked = (this.dashBoardData.MarkedAttendance + '/' + this.dashBoardData.TotalAttendance);
        that.collectedFees = this.kFormatter(this.dashBoardData.TotalCollection);
        that.OverdueCount = this.dashBoardData.Overdue;
        that.OpenLeads = this.dashBoardData.Leads;
        that.dropOuts = this.dashBoardData.Dropouts;

        $('#spn_AttendanceCoachPending').text(this.dashBoardData.CoachAttendancePending);
        $('#spn_AttendanceBatchPending').text(this.dashBoardData.BatchAttendancePending);
        $('#spn_StudentOverDue').text(this.dashBoardData.Overdue);
        $('#spn_StudentUpcoming').text(this.dashBoardData.UpcomingRenewal);
        $('#spn_AbsentPastSession').text(this.dashBoardData.AbsentPastSession);
        $('#spn_OpenLeadsBottom').text(this.dashBoardData.Leads);
      })
    }
    else {
      let date = this.datePipe.transform(new Date(), 'd MMM yyyy');
      this.getListService
        .GetAdminDashboardCount(academyId, userId, date)
        .subscribe((response: any) => {
          this.dashBoardData = response;
          this.totalstudentsCount = this.dashBoardData.TotalStudents;
          this.attendanceandMarked = (this.dashBoardData.MarkedAttendance + '/' + this.dashBoardData.TotalAttendance);
          this.collectedFees = this.kFormatter(this.dashBoardData.TotalCollection);
          this.OverdueCount = this.dashBoardData.Overdue;
          this.OpenLeads = this.dashBoardData.Leads;
          this.dropOuts = this.dashBoardData.Dropouts;

          /* Bottom */
          $('#spn_AttendanceCoachPending').text(this.dashBoardData.CoachAttendancePending);
          $('#spn_AttendanceBatchPending').text(this.dashBoardData.BatchAttendancePending);
          $('#spn_StudentOverDue').text(this.dashBoardData.Overdue);
          $('#spn_StudentUpcoming').text(this.dashBoardData.UpcomingRenewal);
          $('#spn_AbsentPastSession').text(this.dashBoardData.AbsentPastSession);
          $('#spn_OpenLeadsBottom').text(this.dashBoardData.Leads);
        })
    }

  }

  GetAcademySportForDashboardByUserId() {
    let data = this.commonFunction.GetUserData();

    this.getListService
      .GetAcademySportForDashboardByUserId(data.UserId, data.AcademyId)
      .subscribe((response: any) => {
        this.AcademySportList = response
        this.SportChange(response[0].AcademySportId);
        this.SportChangeForRequiring(response[0].AcademySportId);
      })
  }

  SportChange(event) {
    var academySportId = event.target != undefined ? event.target.value : event;
    let data = this.commonFunction.GetUserData();
    this.getListService
      .GetBestPerformingStudents(academySportId, data.AcademyId)
      .subscribe((response: any) => {
        this.BestPerformingStudentList = response;
      })
  }

  SportChangeForRequiring(event) {
    var academySportId = event.target != undefined ? event.target.value : event;
    let data = this.commonFunction.GetUserData();
    this.getListService
      .GetLeastPerformingStudents(academySportId, data.ParentAcademyId)
      .subscribe((response: any) => {
        this.RequiringAttentionList = response;
      })
  }

  RedirecBatchAssessment(item) {
    this.userdata = this.commonFunction.GetUserData();
    this.getListService
      .GetAcademySportList(item.Id, this.userdata.AcademyId)
      .subscribe((response: any) => {
        this.sportList = response;
        if (this.sportList.length > 0) {
          if (this.sportList.length == 1) {
            this.router.navigate(['/studentmodule/useranalytics'], {
              state: { userId: item.Id, userSportId: this.sportList[0].AcademySportId, isAssessmentPage: true, example: window.history.state.example }
            });
          }
        }
      },
        (error) => {
        });
  }

  kFormatter(num) {
    return num > 999 ? (num / 1000).toFixed(1) + 'k' : num;
  }
  GetFeesDetails(batchId, studentId, academyId) {
    this.getListService
      .GetFeesdetails(this.userdata.UserId, academyId, studentId, batchId)
      .subscribe((response: any) => {
        var count = 0;
        this.userFessdetailsList = response;
        for (let index = 0; index < this.userFessdetailsList.length; index++) {
          if (this.userFessdetailsList[index].OutStandingAmount > 0) {
            count = count + 1;
          }
        }
        if (count > 0) {
          $(".feesnotifiction").show();
          this.feesCount = count;
          this.Redmarquee = false;
          this.Yellowmarquee = false;
          this.RedmarqueeForPayment = true;
          this.msg = "You have remaining fees payment. Click here to pay now.";
        }
        else {
          $(".feesnotifiction").hide();
          this.MarqueeMessage(studentId)
        }
      })

  }
  GetNotificationCount() {
    this.getListService
      .GetNotificationCount(this.userdata.UserId, 0)
      .subscribe((response: any) => {
        let _notificationcount = response;
        if (_notificationcount > 0) {
          this.noti_Count = _notificationcount;
        }
        else {
          this.noti_Count = _notificationcount;
        }
      })
  }
  GetAcademyDetails() {
    this.AcademyId = this.userdata.ParentAcademyId;
    this.getListService.GetAcademyDetails(this.AcademyId)
      .subscribe((response: any) => {
        if (response != null && response != "null" && response != "" && response != "[]") {
          this.academyList = response;
          if (response.Website != null && response.Website != "" && response.Website != undefined) {
            this.webicon = true;
          }
          if (response.Facebook != null && response.Facebook != "" && response.Facebook != undefined) {
            this.facebookicon = true;
          }
          if (response.Twitter != null && response.Twitter != "" && response.Twitter != undefined) {
            this.twittericon = true;
          }
          if (response.Youtube != null && response.Youtube != "" && response.Youtube != undefined) {
            this.youtubeicon = true;
          }
          if (response.Instagram != null && response.Instagram != "" && response.Instagram != undefined) {
            this.instagramicon = true;
          }

          this.academyName = response.ShortName;
          var str2 = "";
          this.academynameSplite = this.academyName.split(" ");
          for (var i = 0; i < this.academynameSplite.length; i++) {
            if (i == 0)
              continue
            else
              str2 = str2 + this.academynameSplite[i] + ' ';
          }
          if ((response.ParentAcademyId == 41 || response.Id == 41) && this.userdata.RoleTypeId != 3) {
            this.imageURL = this.webURL + response.AcademyDarkLogoImage;
            this.imageURLParent = this.webURL + response.AcademyDarkLogoImage;
          }
          else {
            this.imageURL = this.webURL + response.AcademyLightLogoImage;
            this.imageURLParent = this.webURL + response.AcademyLightLogoImage;
          }

          $('#loginLogo').attr('src', this.imageURL);
          $('#divDashboard_logo').html('<img alt="" class="divDashboard_logo" src=' + response.imageURL + '>');
          $('#divDashboard_logo_parent').html('<img alt="" class="divDashboard_logo_parent" src=' + response.imageURL + '>');
          $('#divDashboard_navbar').text(response.Name);
          $('#dashboardLogo').html('<img alt="" class="divDashboard_logo" src=' + response.imageURL + '>');
        }
      },
        (error) => {
        }
      );

  }

  socialIconClick(event) {
    var id = event.currentTarget.id;
    var url = $("#" + id + " img").attr("alt");
    window.open(url, '_system');
    setTimeout(function () {
      //kridappAcademyApp.hideIndicator();
    }, 3000);
  }

  MultipleStudentList() {
    this.stdacademyId = this.userdata.UserId;
    this.getListService.MultipleStudentList(this.stdacademyId)
      .subscribe((response: any) => {
        if (response.length > 0) {
          if (response.length > 1) {
            this.isMultipleStudent = true;
            this.StudentList = response;
            var GetUserData = this.commonFunction.GetUserData();
            var GetSelectedStudentId = GetUserData.ParentMultiSelectUserId;
            var allowFeedback = GetUserData.AllowAssessmentFeedback;
            this.setChileName(response[0]);
            if (GetSelectedStudentId > 0) {
              this.commonFunction.SetMultipleStudentId(GetSelectedStudentId, GetUserData.AcademyId);
              this.commonFunction.SetAllowFeedback(allowFeedback);
              this.MultipleStudentChange(GetSelectedStudentId);
              this.StudentFormGroup.controls['MultipleStudentId'].setValue(GetSelectedStudentId);
            }
            else {
              this.commonFunction.SetMultipleStudentId(response[0].Id, response[0].AcademyId);
              this.commonFunction.SetAllowFeedback(response[0].AllowAssessmentFeedback);
              this.StudentFormGroup.patchValue({
                MultipleStudentId: response[0].Id
              })
              this.GetFeesDetails(0, response[0].Id, response[0].AcademyId);
            }
          }
          else {
            this.isMultipleStudent = false;
            this.commonFunction.SetMultipleStudentId(response[0].Id, response[0].AcademyId);
            this.commonFunction.SetAllowFeedback(response[0].AllowAssessmentFeedback);
            this.setChileName(response[0])
            this.StudentFormGroup.patchValue({
              MultipleStudentId: response[0].Id
            })
            this.GetFeesDetails(0, response[0].Id, response[0].AcademyId);
          }
        }
      },
        (error) => {
        }
      );
  }

  MultipleStudentChange(event) {
    let acid = 0;
    this.StudentId = (event.target != undefined) ? event.target.value : event;
    if (this.StudentList.length > 0) {
      for (let index = 0; index < this.StudentList.length; index++) {
        const element = this.StudentList[index];
        if (element.Id == this.StudentId) {
          acid = element.AcademyId
          this.commonFunction.SetMultipleStudentId(this.StudentId, element.AcademyId);
          this.GetFeesDetails(0, this.StudentId, acid);
        }

      }
    }





    //this.MarqueeMessage(this.StudentId);

  }
  setChileName(studentdata) {
    this.commonFunction.SetStudentName(studentdata.FirstName);

  }

  MarqueeMessage(StudentId) {
    var Userdata = this.commonFunction.GetUserData();
    var studentId = Userdata.ParentMultiSelectUserId;
    var guardianUserId = (StudentId == undefined) ? Userdata.UserId : StudentId;
    this.getListService.MarqueeMessageList(studentId)
      .subscribe((response: any) => {
        var messageString = "",
          lstString = "";
        this.msg = "";
        if (response != null || response != "" || response.length > 0) {
          var userMessage = response;
          if (userMessage.length > 0) {
            var outputArray: any = [];
            for (let i = 0; i < userMessage.length; i++) {
              outputArray.push(userMessage[i].Message);
            }

            if (outputArray.length > 1) {
              var val = "";
              for (var i = 0; i < userMessage.length; i++) {
                if (i < userMessage.length - 1) {

                  val = val + userMessage[i].UserName + ' and ';
                  this.MarqueeResponse = response[i];
                } else {
                  val = val + userMessage[i].UserName;
                  this.MarqueeResponse = response[i];
                }
              }
              this.msg = outputArray[0].replace("@@name", val);
              this.Redmarquee = true;
              this.RedmarqueeForPayment = false;
            }
            else {
              var val = "";
              for (var i = 0; i < userMessage.length; i++) {
                if (i < userMessage.length - 1) {

                  val = val + userMessage[i].UserName + ' and ';
                  this.MarqueeResponse = response[i];
                } else {
                  val = val + userMessage[i].UserName;
                  this.MarqueeResponse = response[i];
                }
              }
              this.msg = outputArray[0].replace("@@name", val);
              this.RedmarqueeForPayment = false;
              for (var i = 0; i < userMessage.length; i++) {
                if (new Date(userMessage[i].ToDate).toDateString() <= new Date().toDateString()) {
                  if (userMessage[i].UserId == studentId) {
                    this.Yellowmarquee = true;
                    break;
                  } else {
                    this.Yellowmarquee = true;
                  }
                } else {

                  if (userMessage[i].UserId == studentId) {
                    this.Redmarquee = true;
                    break;
                  } else {
                    this.Redmarquee = true;
                  }
                }
              }

            }
          }
        }
      },
        (error) => {
        }
      )

  }
  renew() {
    this.router.navigate(['/renew-parent'], {
      state: { example: this.MarqueeResponse }
    });
  }

  UserMessage() {
    this.router.navigate(['/usermessages'])
      .then(() => {
        window.location.reload();
      });
  }
  GetCoachDashboardCharts(academyid, userid) {
    this.getListService
      .GetCoachDashboardCharts(academyid, userid)
      .subscribe((response: any) => {
        let data = 'AttendanceDate' + "," + 'BatchStrength' + "," + 'AverageStrength' + "," + 'DailyStrength';
        this.barchartdata.push(data);
        for (let i = 0; i < response.length; i++) {
          const element = response[i][0];
          this.barchart.push(element);
          if (element.DailyStrength != 0) {
            for (let j = 0; j < response[0].length; j++) {

              let val = response[0][i].AttendanceDate + "," + parseInt(response[0][i].BatchStrength) + "," + parseInt(response[0][i].AverageStrength) + "," +
                parseInt(response[0][i].DailyStrength);

              this.barchartdata.push(val)
            }
            this.Ischart = true;
          }
        }
      },
        (error) => {
        });
  }

  GetCoachDashboardUpcoming(academyid, userid) {
    this.getListService
      .GetUpcomingBatchForCoach(academyid, userid)
      .subscribe((response: any) => {
        if (response.length > 0) {
          this.upcomingBatchdata = response;
          this.upcomingBatchdataNotfound = false;
        }
        else {
          this.upcomingBatchdata = response;
          this.upcomingBatchdataNotfound = true;
        }
      })
  }


  inquiry() {
    this.router.navigate(['/inquiry']);
  }
  attendance() {
    var getUserDatathis = this.commonFunction.GetUserData();
    this.getListService.getAcademyPackageExpire(getUserDatathis.ParentAcademyId).subscribe(
      (response: any) => {
        if (response.PackageErrorMessage != "") {
          this.onremoveclick();
          this.strrequired = response.PackageErrorMessage;
          if (this.strrequired != "") {
            this.SimpleModalService.addModal(AlertComponent, { title: getUserDatathis.SelectAcademyName, message: this.strrequired });
          }
        }
        else {
          this.router.navigate(['/attendance'])
        }
      },
      (error) => {

      }
    );
  }

  toggleShowselection(event) {
    if (event.isTrusted == true) {
      this.selectionoption = !this.selectionoption;
    }
    else {
      this.selectionoption = false;
    }
  }
  opendotpopup(param) {

    const dialogRef = this.dialog.open(DashboarddotpopupComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'custom-modalbox',
      data: {
        val: param, popuprights: {
          Attendance: this.Attendence,
          CoachAttendance: this.Attendence,
          Inquiry: this.Inquiry,
          Student: this.Student,
          Fees: this.FeesMenu,
          Drills: this.DrillsRights

        }
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  opendotpopupForPrivateTraining(item) {

    const dialogRef = this.dialog.open(DashboardpersonalpopupComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'custom-modalbox',
      data: {
        val: item, popuprights: {
          Student: this.Student,
          Drills: this.DrillsRights
        }
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  // ParentOption(val) {
  //   debugger
  //   var userdata = this.commonFunction.GetUserData();
  //   this.stdacademyId = userdata.StudentId;
  //   this.getListService
  //     .GetBatchListbyAcademyUserId(this.userdata.StudentId, this.userdata.AcademyId)
  //     .subscribe((response: any) => {
  //       this.batchlist = response;
  //       // if (this.batchlist.length > 1) {

  //       // }
  //       // else if (this.batchlist.length == 1) {
  //       if (val == 1) {
  //         this.router.navigate(['/studentmodule/attendancereport'], {
  //           state: { example: this.stdacademyId, val: this.StudentId }
  //         });
  //       }
  //       else if (val == 2) {
  //         // if (this.studentAssessmentReportList.length > 0) {
  //         this.GetUserAnalyticsAssessmentReport(0)
  //         // }
  //         // else {
  //         //   //ErrorDisplay("No assessment done as yet.");
  //         // }

  //       } else if (val == 3) {

  //       }
  //       else if (val == 4) {
  //         this.setstudentFeesmodel = this.batchlist[0].Id;
  //         this.router.navigate(['/studentfees/studentfeeslist'], {
  //           state: { example: this.batchlist[0].Id }
  //         });
  //       }
  //       else if (val == 5) {

  //       }
  //       else if (val == 6) {
  //         this.router.navigate(['/notification']);

  //       }
  //       // }
  //     })
  // }
  ParentOption(val) {
    var userdata = this.commonFunction.GetUserData();
    this.stdacademyId = userdata.StudentId;
    this.getListService.GetBatchListbyAcademyUserId(userdata.StudentId, this.userdata.AcademyId).subscribe((response: any) => {
      this.batchlist = response;
      // console.log(this.batchlist);
      
      if (this.batchlist.length > 1) {
        if (val == 1) {
          this.router.navigate(['/studentmodule/attendancereport'], {
            state: { example: this.stdacademyId, val: this.StudentId }
          });
        }
        else if (val == 2) {
          
          this.GetUserAnalyticsAssessmentReport()

        } else if (val == 3) {

        }
        else if (val == 4) {
          this.setstudentFeesmodel = this.batchlist[0];
          this.router.navigate(['/studentfees/studentfeeslist'], {
            state: { example: this.setstudentFeesmodel }
          });
        }
        else if (val == 5) {

        }
        else if (val == 6) {
          this.router.navigate(['/notification']);

        }
      }
      else if (this.batchlist.length == 1) {
        if (val == 1) {
          this.router.navigate(['/studentmodule/attendancereport'], {
            state: { example: this.stdacademyId, val: this.StudentId }
          });
        }
        else if (val == 2) {
          
          this.GetUserAnalyticsAssessmentReport()

        } else if (val == 3) {

        }
        else if (val == 4) {
          this.setstudentFeesmodel = this.batchlist[0];
          this.router.navigate(['/studentfees/studentfeeslist'], {
            state: { example: this.setstudentFeesmodel }
          });
        }
        else if (val == 5) {

        }
        else if (val == 6) {
          this.router.navigate(['/notification']);

        }
      }
    })
  }
  GetUserAnalyticsAssessmentReport() {
    this.userdata = this.commonFunction.GetUserData();
    // console.log(this.userdata);
    
    this.getListService
      .GetAcademySportList(this.userdata.StudentId, this.userdata.AcademyId)
      .subscribe((response: any) => {
        this.sportList = response;
        // console.log(this.sportList);
        
        if (this.sportList.length > 0) {
          if (this.sportList.length == 1) {
            this.router.navigate(['/studentmodule/useranalytics'], {
              state: { userId: this.userdata.StudentId, userSportId: this.sportList[0].AcademySportId, isAssessmentPage: true }
            });
          }
        }
      },
        (error) => {
        });

  }

  public getMenuList() {
    var data = this.commonFunction.GetUserData();

    if (data != null && data != undefined) {
      this.getListService
        .GetMenuList(data)
        .subscribe((response: any) => {
          for (let i = 0; i < response.length; i++) {
            if (response[i].Name == "Attendence") {
              this.Attendence = true;
            }

            else if (response[i].Name == "Student") {
              this.Student = true;
              this.Overdue = true;
              this.Subsciption = true;
              this.Dropout = true;

            }
            else if (response[i].Name == "AcademyInquiry") {
              this.Inquiry = true;
            }

            else if (response[i].Name == "AcademyFeesType") {
              this.Collection = true;
            }
            else if (response[i].Name == "AccountFees") {
              this.FeesMenu = true;
            }
            else if (response[i].Name == "SportDrillList") {
              this.DrillsRights = true;
            }
          }
        },
          (error) => {

          });
    }
  }

  GetuseracademyId() {
  }
  // GetUserListOfCoach() {
  //   this.getListService.GetUserListOfCoach(this.userId)
  //     .subscribe((response: any) => {
  //       this.StudentLeaveList = response;
  //     })
  // }

  GetAcademyCoachClockIn() {
    this.getListService
      .GetAcademyCoachClockIn(this.userdata.UserId, this.userdata.AcademyId)
      .subscribe((response: any) => {
        
        if(response.Id == 0){
          this.pendingClockOut=false;
        }
        else{
          this.pendingClockOut=true;
        }
        let clockLocalData = {
          Id: response.Id,
          ClockIn: response.ClockIn,
          ClockOut: response.ClockOut,
          PendingClock: this.pendingClockOut
        }
        localStorage.setItem("ClockData", (JSON.stringify(clockLocalData))); 
      },
        (error) => {

        });
  }
}
