<div id="page" class="graybg">
    <!-- Header -->
    <div class="header header-fixed header-logo-center">
        <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <!-- Sidebar Menu Icon-->
        <h2 class="header-title textcolor">Fees collection report</h2>
        <span [routerLink]="['/userlist']" class="header-icon header-icon-4 textcolor newplusbtntext align-items-center d-flex">
            <span [innerHTML]="currencysymbol"></span>
            </span> 
        <div class="titlearea">
            <p class="textcolor m-0">Batch-wise summary of total fees collected, active students and outstanding</p>
        </div>
    </div>
    <!-- Header -->
    <div class="page-content header-clear-medium">
        <div class="Requisition-table">
            <table class="table bg-white">
                <tr>
                    <td></td>
                    <td class="text-center"><strong>Active</strong></td>
                    <td class="text-center"><strong>Fees</strong></td>
                    <td class="text-center"><strong>Due</strong></td>
                </tr>
                <ng-container *ngFor="let item of CollectionReportList;index as i">
                    <tr>
                        <ng-container *ngIf="item.BatchName!=''">
                            <td colspan="4" class="text-left graybg" *ngIf="i==0">{{item.BatchName}}</td>
                        </ng-container>
                    </tr>
                    <tr class="font-14">
                        <td>{{item.SubBatchName}}</td>
                        <ng-container *ngIf="item.ActiveStudents!=0">
                            <td class="text-center">{{item.ActiveStudents}}</td>
                        </ng-container>
                        <ng-container *ngIf="item.ActiveStudents==0">
                            <td class="text-center"></td>
                        </ng-container>
                        <ng-container *ngIf="item.TotalCollected>0">
                            <td class="text-center">{{item.TotalCollected}}</td>
                        </ng-container>
                        <ng-container *ngIf="item.TotalCollected==0">
                            <td class="text-center"></td>
                        </ng-container>
                        <ng-container *ngIf="item.OutstandingAmount!=0">
                            <td class="text-center">{{item.OutstandingAmount}}</td>
                        </ng-container>
                        <ng-container *ngIf="item.OutstandingAmount==0">
                            <td class="text-center"></td>
                        </ng-container>
                    </tr>
                </ng-container>
            </table>
        </div>
        <div class="Requisition-table" *ngFor="let item1 of CollectionReportListTotal;">
            <table class="table bg-white">
                <tr>
                    <td class="statusopen"><strong>Students</strong> </td>
                    <td><strong>{{item1.ActiveStudents}}</strong></td>
                </tr>
                <tr>
                    <td class="statusopen"><strong>Outstanding Amount</strong></td>
                    <td><strong>{{item1.OutstandingAmount}}</strong></td>
                </tr>
                <tr>
                    <td class="statusopen"><strong>Total Fees Collected</strong></td>
                    <td><strong>{{item1.TotalCollected}}</strong></td>
                </tr>
            </table>
        </div>
    </div>
</div>