import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-popupinfo',
  templateUrl: './popupinfo.component.html',
  styleUrls: ['./popupinfo.component.scss']
})
export class PopupinfoComponent implements OnInit {
  ParameterName:any;
  Min:any;
  Max:any;
  Ideal:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<PopupinfoComponent>) 
  {}

  ngOnInit(): void {
    this.ParameterName=this.data.Info.ParameterName;
    this.Min=this.data.Info.Min;
    this.Max=this.data.Info.Max;
    this.Ideal=this.data.Info.Ideal;
  }
  Close()
  {
    this.dialogRef.close();
  }
}
