<div id="page" class="graybg">
    <!-- Header -->
    <div class="header header-fixed header-logo-center loginheadercolor">
        <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <h2 class="header-title textcolor">Payment</h2>
        <span data-menu="menu-sidebar-left-1" class="header-icon header-icon-4 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
        </span>
    </div>
    <!-- Header -->

    <div class="page-content header-clear-medium">
        <div class="graybg h-auto pb-2" *ngIf="IsStripe==true">
            <div class="bg-white Headertitle">
                <b>Stripe</b>
            </div>
            <div class="clear">
                <app-stripe [payamount]="amount"></app-stripe>
            </div>
        </div>
        <div class="graybg h-auto pb-2" *ngIf="IsRazorpay==true">
            <div class="bg-white Headertitle">
                <b>Razorpay</b>
            </div>
            <div class="clear">
                <div class="content">
                    <div class="inputsection">
                        <label>Phone Number</label>
                        <input class="form-control" type="text" v alue="{{phone}}" placeholder="Phone Number"
                            formControlName="">
                    </div>
                    <div class="inputsection">
                        <label>Email</label>
                        <input class="form-control" type="text" placeholder="Email" value="{{email}}"
                            formControlName="">
                    </div>
                </div>
                <div class="card card-style paymentaccordion">
                    <div class="accordion" id="accordion-1">

                        <div class="mb-0">
                            <button class="btn accordion-btn" data-toggle="collapse" data-target="#Debitcard">
                                <img class="mr-2 w-img-35" src="../../assets/images/KridApp-Image/debit-card.png"
                                    alt="" />
                                Debit Card
                                <i class="accordion-icon"><img width="10" height="10"
                                        src="../../../assets/images/icons/chevron-down.png" alt=""></i>
                                <span class="pull-right mr-2" id="Perct_D"></span>
                            </button>
                            <div id="Debitcard" class="collapse accordion-control" data-parent="#accordion-1">
                                <div class="clear mb-2">
                                    <img class="mr-2 w-img-50" src="../../assets/images/KridApp-Image/Visa-icon.png"
                                        alt="" />
                                    <img src="../../assets/images/KridApp-Image/mastercard.png" class="w-img-50"
                                        alt="" />
                                </div>
                                <div class="inputsection">
                                    <input class="form-control" type="number" id="txt_CardNumber"
                                        placeholder="Card Number" formControlName="">
                                </div>
                                <div class="inputsection">
                                    <input class="form-control" type="text" id="txt_NameOnCard"
                                        placeholder="Name on Card" formControlName="">
                                </div>
                                <div class="row mb-0">
                                    <div class="col-4">
                                        <div class="inputsection">
                                            <select class="form-control" id="ddl_CardExpiryMonth">
                                                <option value="0">Month</option>
                                                <option value="01">1</option>
                                                <option value="02">2</option>
                                                <option value="03">3</option>
                                                <option value="04">4</option>
                                                <option value="05">5</option>
                                                <option value="06">6</option>
                                                <option value="07">7</option>
                                                <option value="08">8</option>
                                                <option value="09">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="inputsection">
                                            <select class="form-control" id="ddl_CardExpiryYear"></select>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="inputsection">
                                            <input class="form-control" type="number" id="txt_CVV" placeholder="CVV"
                                                formControlName="">
                                        </div>
                                    </div>
                                </div>
                                <button type="button" (click)="PayOnline(1)"
                                    class="btn btn-m btn-block shadow-l text-uppercase font-900 text-center loginheadercolor textcolor">PAY:
                                    <span id="spn_PayableAmountRazorpayCard"></span></button>
                                <div class="row paydetails" id="display_DebitCard">
                                </div>
                            </div>
                        </div>
                        <div class="divider divider-margins"></div>

                        <div class="mb-0">
                            <button class="btn accordion-btn" data-toggle="collapse" data-target="#creditcard">
                                <img class="mr-2 w-img-35" src="../../assets/images/KridApp-Image/credit-card.png"
                                    alt="" />
                                Credit Card
                                <i class="accordion-icon"><img width="10" height="10"
                                        src="../../../assets/images/icons/chevron-down.png" alt=""></i>
                                <span class="pull-right mr-2" id="Perct_C"></span>
                            </button>
                            <div id="creditcard" class="collapse accordion-control" data-parent="#accordion-1">
                                <div class="clear mb-2">
                                    <img class="mr-2 w-img-50" src="../../assets/images/KridApp-Image/Visa-icon.png"
                                        alt="" />
                                    <img src="../../assets/images/KridApp-Image/mastercard.png" class="w-img-50"
                                        alt="" />
                                </div>
                                <div class="inputsection">
                                    <input class="form-control" ttype="number" id="txt_CreditCardNumber"
                                        placeholder="Card Number" formControlName="">
                                </div>
                                <div class="inputsection">
                                    <input class="form-control" type="text" id="txt_CreditNameOnCard"
                                        placeholder="Name on Card" formControlName="">
                                </div>
                                <div class="row mb-0">
                                    <div class="col-4">
                                        <div class="inputsection">
                                            <select class="form-control" id="ddl_CreditCardExpiryMonth">
                                                <option value="0">Month</option>
                                                <option value="01">1</option>
                                                <option value="02">2</option>
                                                <option value="03">3</option>
                                                <option value="04">4</option>
                                                <option value="05">5</option>
                                                <option value="06">6</option>
                                                <option value="07">7</option>
                                                <option value="08">8</option>
                                                <option value="09">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="inputsection">
                                            <select class="form-control" id="ddl_CreditCardExpiryYear"></select>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="inputsection">
                                            <input class="form-control" type="number" id="txt_CreditCVV"
                                                placeholder="CVV" formControlName="">
                                        </div>
                                    </div>
                                </div>
                                <button type="button" (click)="PayOnline(2)"
                                    class="btn btn-m btn-block shadow-l text-uppercase font-900 text-center loginheadercolor textcolor">PAY:
                                    <span id="spn_CreditPayableAmountRazorpayCard"></span></button>
                                <div class="row paydetails" id="display_CreditCard">

                                </div>
                            </div>
                        </div>
                        <div class="divider divider-margins"></div>

                        <div class="mb-0">
                            <button class="btn accordion-btn" data-toggle="collapse" data-target="#Netbanking">
                                <img class="mr-2 w-img-35" src="../../assets/images/KridApp-Image/global.png" alt="" />
                                Netbanking
                                <i class="accordion-icon"><img width="10" height="10"
                                        src="../../../assets/images/icons/chevron-down.png" alt=""></i>
                                <span class="pull-right mr-2" id="Perct_N"></span>
                            </button>
                            <div id="Netbanking" class="collapse accordion-control" data-parent="#accordion-1">
                                <div class="inputsection">
                                    <select class="form-control" id="ddl_NetBankingBankList">
                                        <option value=0>Select Bank</option>
                                        <option *ngFor="let item of banklist" value="{{item.PassValue}}">
                                            {{item.DisplayValue}} ({{item.PassValue}})
                                        </option>
                                    </select>
                                </div>
                                <button type="button" (click)="PayOnline(3)"
                                    class="btn btn-m btn-block shadow-l text-uppercase font-900 text-center loginheadercolor textcolor">PAY:
                                    <span id="spn_PayableAmountRazorpayNet"></span></button>
                                <div class="row paydetails" id="display_Netbanking">

                                </div>
                            </div>
                        </div>
                        <div class="divider divider-margins"></div>

                        <div class="mb-0">
                            <button class="btn accordion-btn" data-toggle="collapse" data-target="#upi">
                                <img class="mr-2 w-img-35" src="../../assets/images/KridApp-Image/UPI.png" alt="" />
                                UPI
                                <i class="accordion-icon"><img width="10" height="10"
                                        src="../../../assets/images/icons/chevron-down.png" alt=""></i>
                                <span class="pull-right mr-2" id="Perct_U"></span>
                            </button>
                            <div id="upi" class="collapse accordion-control" data-parent="#accordion-1">
                                <div class="inputsection">
                                    <input class="form-control" type="text" id="txt_VPA" (change)="ValidateVPA()"
                                        placeholder="Enter VPA">
                                </div>
                                <small class="d-block font-line-height">You must have a Virtual Payment
                                    Address. (VPA ending with @upi).</small>
                                <div class="row mb-0 mt-3">
                                    <div class="col-6 mb-3" *ngFor="let item of upilist">
                                        <div class="d-flex align-items-center">
                                            <input type="radio" name="upi" class="ml-1 mr-2"
                                                value="{{item.PassValue}}" />
                                            {{item.DisplayValue}}
                                        </div>
                                    </div>
                                </div>
                                <button type="button" (click)="PayOnline(4)"
                                    class="btn btn-m btn-block shadow-l text-uppercase font-900 text-center loginheadercolor textcolor">PAY:
                                    <span id="spn_PayableAmountRazorpayUPI"></span></button>
                                <div class="row paydetails" id="display_UPI">

                                </div>
                            </div>
                        </div>
                        <div class="divider divider-margins"></div>

                        <div class="mb-0">
                            <button class="btn accordion-btn" data-toggle="collapse" data-target="#Wallets">
                                <img class="mr-2 w-img-35" src="../../assets/images/KridApp-Image/wallet.png" alt="" />
                                Wallets
                                <i class="accordion-icon"><img width="10" height="10"
                                        src="../../../assets/images/icons/chevron-down.png" alt=""></i>
                                <span class="pull-right mr-2" id="Perct_W"></span>
                            </button>
                            <div id="Wallets" class="collapse accordion-control" data-parent="#accordion-1">
                                <div class="row mb-0">
                                    <div class="col-6 mb-3" *ngFor="let item of walletlist">
                                        <div class="d-flex align-items-center">
                                            <input type="radio" name="wallet" class="ml-1 mr-2"
                                                value="{{item.PassValue}}" />
                                            {{item.DisplayValue}}
                                        </div>
                                    </div>
                                </div>
                                <button type="button" (click)="PayOnline(5)"
                                    class="btn btn-m btn-block shadow-l text-uppercase font-900 text-center loginheadercolor textcolor">PAY:
                                    <span id="spn_PayableAmountRazorpayWall"></span></button>
                                <div class="row paydetails" id="display_Wallets">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<script src="https://checkout.razorpay.com/v1/razorpay.js"></script>