<div id="page" class="graybg">
    <a class="skip-link" href="#maincontent">Skip to main</a>
    <div class="header header-fixed header-logo-center">
        <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <h2 class="header-title textcolor">{{UserName}}</h2>
        <span (click)="onclick()" data-menu="menu-sidebar-left-1" class="header-icon header-icon-4 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
        </span>
        <div class="profile-section" *ngFor="let item of FeesInfoDetailsList" (click)="onremoveclick()">
            <div class="feesinfo-header text-center">
                <h2 id="lblFeesName">{{item.FeesType}}</h2>
                <div class="lightblack btnmargin">
                    <span id="displayDate">{{item.FromDate|date:'dd MMM yyyy'}} - {{item.ToDate|date:'dd MMM yyyy'}}</span>
                    <h2 id="displayTotalFees" class="d-flex align-items-center justify-content-center"><span class="mr-1 border-0" [innerHTML]="currencysymbol"></span> {{item.TotalFees}}</h2>
                    <div class="row m-0">
                        <div class="col-6 d-flex align-items-center justify-content-center">Outstanding<strong id="spanOutstanding" class="d-flex align-items-center justify-content-center"><span class="border-0" [innerHTML]="currencysymbol"></span> {{item.Outstanding}}</strong></div>
                        <div class="col-6 d-flex align-items-center justify-content-center">Paid<strong id="spanPaid" class="d-flex align-items-center justify-content-center"><span class="border-0" [innerHTML]="currencysymbol"></span> {{item.Paid}}<span (click)="opendotpopup(item)" *ngIf="item.Paid>0">(View)</span></strong></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-content header-clear-medium graybg" *ngFor="let item of FeesInfoDetailsList" (click)="onremoveclick()">
        <div class="content">
            <span class="row m-0" *ngIf="item.Outstanding>0">
                <button class="col-12 btn text-center text-white loginheadercolor" (click)="AcceptPayment(item)">Accept Payment</button>
            </span>
        </div>
        <div class="feesinfo-fees">
            <span class="row" *ngIf="item.FeesTypeOptions!=''">
                <div class="col-12 text-center">
                    <h1><strong>{{item.FeesTypeOptions}}</strong></h1>
                </div>
            </span>
            <ng-container *ngFor="let item1 of FeesHeaderTaxList">
                <div class="row">
                    <div class="col-6">Fees</div>
                    <div class="col-6 text-right"><b class="d-flex align-items-center justify-content-end"><span [innerHTML]="currencysymbol"></span> {{item1.Amount| number: '1.0-0'}}</b></div>
                </div>
                <div class="row">
                    <div class="col-6">Tax</div>
                    <div class="col-6 text-right"><b>{{item1.Tax}}%</b></div>
                </div>
                <div class="row">
                    <div class="col-6">Taxable Amount</div>
                    <div class="col-6 text-right"><b class="d-flex align-items-center justify-content-end"><span [innerHTML]="currencysymbol"></span> {{item1.TaxAmount}}</b></div>
                </div>
                <div class="row">
                    <div class="col-6">Total Payable</div>
                    <div class="col-6 text-right"><b class="d-flex align-items-center justify-content-end"><span [innerHTML]="currencysymbol"></span> {{item1.Fees| number: '1.0-0'}}</b></div>
                </div>
            </ng-container>
        </div>
    </div>
</div>