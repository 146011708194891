import { Component, OnInit } from '@angular/core';
import $ from 'jquery'
import { ActivatedRoute, Router } from '@angular/router';
import { UserReportInfos } from '../../_models/user.model';
import { GetListService } from '../../service/getlist.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonFunction } from 'src/app/service/commonfunction';
import { PostService } from 'src/app/service/post.service';
import { PopupcommentsComponent } from '../popupcomments/popupcomments.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NotificationService } from 'src/app/service/notification.service';
import { PopupinfoComponent } from '../popupinfo/popupinfo.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-feedbackreport',
  templateUrl: './feedbackreport.component.html',
  styleUrls: ['./feedbackreport.component.scss']
})
export class FeedbackreportComponent implements OnInit {
  parameterevaltiuon = 0;
  Eval3DrpSelectedValue: any;
  Eval4DrpSelectedValue: any;
  counter = Array;
  studentDetails: any;
  lstCategory: any;
  lstReport: any;
  setuserreportinfomodel: UserReportInfos;
  userdata;
  userdetails;
  stdName;
  stdAge;
  stdGender;
  stdContact;
  date: any;
  isAdd: boolean = false;
  userimg = "../../assets/images/KridApp-Image/No-Image-User.png";
  constructor(
    public commonFunction: CommonFunction,
    public postService: PostService,
    public dialog: MatDialog,
    public getListService: GetListService,
    private spinner: NgxSpinnerService,
    public route: ActivatedRoute,
    public router: Router,
    public notifyService: NotificationService,
    public datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.onremoveclick();
    this.setuserreportinfomodel = window.history.state.example;
    this.isAdd = (window.history.state.IsAdd != undefined ? window.history.state.IsAdd : false)
    this.userdata = this.commonFunction.GetUserData();
    this.date = this.datePipe.transform(this.setuserreportinfomodel.ReportDate, 'MMM dd, yyyy');
    this.Eval3DrpSelectedValue = 0;
    this.Eval4DrpSelectedValue = 0;
    this.GetUserDetails(this.setuserreportinfomodel.Id, this.setuserreportinfomodel.AcademySportId, this.setuserreportinfomodel.BatchId, this.setuserreportinfomodel.IsAdd);
  }
  onclick() {
    $("body").addClass("modal-open");
    $('#menu-sidebar-left-1').addClass('menu-active')
  }
  onremoveclick() {
    $("body").removeClass("modal-open");
    $('#menu-sidebar-left-1').removeClass('menu-active')
  }

  back() {
    if (this.setuserreportinfomodel != undefined) {
      this.router.navigate(['/studentmodule/attendancereport'], {
        state: { example: this.setuserreportinfomodel.UserId, val: this.setuserreportinfomodel.UserId }
      });
    }
    else {
      this.router.navigate(['/dashboard'], {

      });
    }

  }

  GetUserDetails(userId, academySportId, batchId, isAdd) {
    this.userdata = this.commonFunction.GetUserData();
    this.spinner.show();
    this.getListService
      .GetUserDetails(userId)
      .subscribe((response: any) => {
        this.userdata = response;
        if (this.userdata.AcademyId > 0) {

          this.userdetails = response;
          if (this.userdetails.AcademyId > 0) {
            this.GetSportParameter();
          }
          else {
            if (isAdd == false) {
            }
          }
        }
      },
        (error) => {
          this.spinner.hide();
        });
  }
  GetSportParameter() {
    this.getListService
      .GetUserReportDetails(
        (this.isAdd == true) ? this.setuserreportinfomodel.UserId : this.setuserreportinfomodel.Id,
        this.setuserreportinfomodel.BatchId,
        this.setuserreportinfomodel.AcademySportId,
        this.datePipe.transform(this.setuserreportinfomodel.ReportDate, 'yyyy-MM-dd'),
        (this.setuserreportinfomodel.AssessmentTypeId != undefined ? this.setuserreportinfomodel.AssessmentTypeId : 0),
        this.isAdd
      )
      .subscribe((response: any) => {
        this.studentDetails = response;
        response.reportModel.AssessmentTypeId = -1;
        response.reportModel.BatchId = this.setuserreportinfomodel.BatchId;
        response.reportModel.reportdate = this.datePipe.transform(this.setuserreportinfomodel.ReportDate, 'yyyy-MM-dd');
        this.lstReport = this.studentDetails.lstReport
        if (this.studentDetails.reportModel != null) {
          this.userimg = this.studentDetails.reportModel.Pic
        }
        this.stdName = this.studentDetails.reportModel.UserName;
        this.stdAge = this.studentDetails.reportModel.Age;
        this.stdGender = this.studentDetails.reportModel.Gender;
        this.stdContact = this.studentDetails.reportModel.PhoneNumber;

        this.lstReport.forEach(element => {
          if (element.StringValue != "") {
            element.Value = element.StringValue;
            element.EvaluationCustom = element.StringValue;
          }
        });
        this.lstReport.forEach(element => {
          if (element.EvaluationTypeId == "4") {
            if (element.Value == 1) {
              this.Eval4DrpSelectedValue = 1;
            }
            if (element.Value == 2) {
              this.Eval4DrpSelectedValue = 2;
            }
            if (element.Value == 3) {
              this.Eval4DrpSelectedValue = 3;
            }
          }
        });
        this.lstReport.forEach(element => {
          if (element.EvaluationTypeId == "3") {
            if (element.Value == 1) {
              this.Eval3DrpSelectedValue = 1;
            }
            if (element.Value == 2) {
              this.Eval3DrpSelectedValue = 2;
            }
          }
        });


        this.lstReport.forEach(element => {
          if (element.EvaluationTypeId == "2") {
            if (element.StringValue != '') {
              element.StringValue = element.StringValue;
              element.SportDrillValue = null
            }
          }
        });
        this.lstCategory = this.getListService.GetUniqueArray(this.studentDetails.lstReport)
      },
        (error) => {
          this.spinner.hide();
        });
  }
  GetUserReport(id, academySportId) {

  }
  ChangeRating(id, index, maxValue, isAdd) {
    var startIndex = index + 1;
    $("#hdn" + 'rating_' + id).val(startIndex);
    $("#ul_" + 'rating_' + id + " > li:lt(" + (index + 1) + ")").removeClass("blank");
    $("#ul_" + 'rating_' + id + " > li:lt(" + (index + 1) + ")").addClass("fill");
    $("#ul_" + 'rating_' + id + " > li").slice(startIndex, maxValue).removeClass("fill");
    $("#ul_" + 'rating_' + id + " > li").slice(startIndex, maxValue).addClass("blank");

    for (let i = 0; i < this.studentDetails.lstReport.length; i++) {
      if (this.studentDetails.lstReport[i].SportParameterId == id) {
        this.studentDetails.lstReport[i].Value = startIndex;
      }
    }
  }
  ChangeText(id, val, min, max) {
    if (min == 0 && max == 0) {
      for (let i = 0; i < this.studentDetails.lstReport.length; i++) {
        if (this.studentDetails.lstReport[i].SportParameterId == id) {
          this.studentDetails.lstReport[i].StringValue = val.target.value;
        }
      }
    }
    else if (val.target.value <= max && val.target.value >= min) {
      for (let i = 0; i < this.studentDetails.lstReport.length; i++) {
        if (this.studentDetails.lstReport[i].SportParameterId == id) {
          this.studentDetails.lstReport[i].Value = val.target.value;
        }
      }
    }
    else {
      this.notifyService.showWarning('Entered value must between ' + min + ' and ' + max, "");
      $('#txt_' + id).val('');
    }
  }
  ChangeDropdown(id, val) {
    for (let i = 0; i < this.studentDetails.lstReport.length; i++) {
      if (this.studentDetails.lstReport[i].SportParameterId == id) {
        this.studentDetails.lstReport[i].Value = val.target.value;
      }
    }
  }

  ChangeDropdownEvalution3(id, val) {
    for (let i = 0; i < this.studentDetails.lstReport.length; i++) {
      if (this.studentDetails.lstReport[i].SportParameterId == id) {
        if (val.target.value == "1") {
          this.studentDetails.lstReport[i].Value = "1.0";
        }
        if (val.target.value == "2") {
          this.studentDetails.lstReport[i].Value = "2.0";
        }
      }
    }
  }

  ChangeDropdownEvalution4(id, val) {
    for (let i = 0; i < this.studentDetails.lstReport.length; i++) {
      if (this.studentDetails.lstReport[i].SportParameterId == id) {
        if (val.target.value == "1") {
          this.studentDetails.lstReport[i].Value = "1.0";
        }
        if (val.target.value == "2") {
          this.studentDetails.lstReport[i].Value = "2.0";
        }
        if (val.target.value == "3") {
          this.studentDetails.lstReport[i].Value = "3.0";
        }
      }
    }
  }



  ChangeDropdownEvalution2(id, val) {
    for (let i = 0; i < this.studentDetails.lstReport.length; i++) {
      if (this.studentDetails.lstReport[i].SportParameterId == id) {
        this.studentDetails.lstReport[i].StringValue = val.target.value;
      }
    }
  }


  ChangeDropdownEvalution5(id, val) {
    for (let i = 0; i < this.studentDetails.lstReport.length; i++) {
      if (this.studentDetails.lstReport[i].SportParameterId == id) {
        this.studentDetails.lstReport[i].Value = val.target.value;
        this.studentDetails.lstReport[i].EvaluationCustom = val.target.value;
      }
    }
  }


  ChangeDrills(id, val) {

    for (let i = 0; i < this.studentDetails.lstReport.length; i++) {
      if (this.studentDetails.lstReport[i].SportParameterId == id) {
        this.studentDetails.lstReport[i].SportDrillValue = val.target.value;
      }
    }
  }


  OpenCommentPopup(param, type) {
    const dialogRef = this.dialog.open(PopupcommentsComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'custom-modalbox',
      disableClose: true,
      data: { val: param }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (type == 2) {
        for (let i = 0; i < this.studentDetails.lstReport.length; i++) {
          if (this.studentDetails.lstReport[i].SportParameterId == param.SportParameterId) {
            this.studentDetails.lstReport[i].Comments = result;
          }
        }
      }
    });
  }

  SaveUserReport() {
    this.spinner.show();
    this.postService.ManageUserReport(this.studentDetails).subscribe(
      (response: any) => {
        this.notifyService.showSuccess("Report saved successfully!", "");
        if (this.setuserreportinfomodel != undefined) {
          this.router.navigate(['/studentmodule/attendancereport'], {
            state: { example: this.setuserreportinfomodel.UserId, val: this.setuserreportinfomodel.UserId }
          });
        }
        else {
          this.router.navigate(['/dashboard'], {

          });
        }

        this.spinner.hide();
      },
      () => {
        this.notifyService.showError("Unable to save report. Please try again later. Contact the admin if problem persists.", "");
      }
    );
  }
  openFeedbackModal() {
    this.dialog.open(PopupinfoComponent, {
      data: {
        animal: 'panda'
      }
    });
  }
  openInfoModal(info) {
    this.dialog.open(PopupinfoComponent, {
      data: {
        Info: info
      }
    });
  }
}
