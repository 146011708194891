<div id="page">
    <div class="header header-fixed header-logo-center">
        <span (click)="back()" class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <h2 class="header-title textcolor">Batch Drills Planning</h2>
        <span class="header-icon header-icon-4 textcolor right15" (click)="openCreateBatchPlanning()">Next</span>
        <div class="titlearea">
          <p class="textcolor m-0">Step 2: Select day/date from the calender</p>
        </div>
    </div>

    <div class="page-content header-clear-medium">
        <div class="assessment">
            <mat-calendar #calendar 
            [dateClass]="isSelected" 
                (selectedChange)="onSelect($event)">
            </mat-calendar>
        </div>
        <div class="report-list mt-3" *ngIf="DrillListHideShow">
            <div *ngFor="let item of  DrillListCateGory">
                <div class="card card-style datalist">
                    <div class="content">
                        <ng-container *ngIf="item.FromDate!='' && item.FromDate !=null">
                            <div class="d-flex align-items-center m-2 justify-content-between">
                                <b>{{item.FromDate}}
                                </b>
                                <i (click)="Edit(item)"><img width="16" height="16" src="../../../assets/images/icons/grayedit.png" alt="" /></i>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="item.CategoryName!='' && item.CategoryName !=null">
                            <b class="d-flex align-items-center m-2 ">{{item.CategoryName}}
                            </b>
                        </ng-container>
                        <div *ngFor="let item1 of  DrillNameList">
                            <ng-container *ngIf="item1.DrillName!='' && item1.DrillName !=null&&item1.FromDate==item.FromDate">
                                <p class="d-flex align-items-center m-2">{{item1.DrillName}}
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>        
        </div>
        <div class="content">
            <button type="submit" (click)="openCreateBatchPlanning()" class="btn btn-block loginheadercolor textcolor">Next</button>
        </div>
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
            <p class="color-white"> Loading... </p>
        </ngx-spinner>
    </div> 
</div>