import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeedbackreportComponent } from './feedbackreport/feedbackreport.component';
import { UserreportComponent } from "./userreport/userreport.component";
import { UserreportinfoComponent } from "./userreportinfo/userreportinfo.component";
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'userreport', 
        component: UserreportComponent
      },
      {
        path: 'userreportinfo',
        component:UserreportinfoComponent
      },
      {
        path: 'feedbackreport',
        component:FeedbackreportComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserassessmentRoutingModule { }
