import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from '../../service/commonfunction';
import { GetListService } from '../../service/getlist.service'
declare var google: any;
import $ from 'jquery';
@Component({
  selector: 'app-chartdashboard',
  templateUrl: './chartdashboard.component.html',
  styleUrls: ['./chartdashboard.component.scss'],
})
export class ChartdashboardComponent implements OnInit {
  @Input() chartobj;
  chartDataList: any;
  data = [];
  data2 = [];
  userdata: any;
  batchIdnew: any;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public commonFunction: CommonFunction,
    public getListService: GetListService,
  ) { }

  ngOnInit(): void {
    this.userdata = this.commonFunction.GetUserData();
    this.getChartList(this.userdata.AcademyId, this.userdata.UserId);
  }

  getChartList(academyid, userid) {
    this.getListService
      .GetCoachDashboardCharts(academyid, userid)
      .subscribe((data: any) => {
        if (data != null && data != "null" && data != "" && data != "[]") {
          var coachDashboardChart = data;
          google.charts.load("current", {
            packages: ["corechart", "timeline"]
          });

          $.each(coachDashboardChart, function (key, obj) {
            var parentBatchId = obj[0].ParentBatchId;
            var batchId = obj[0].BatchId;
            var parentBatchName = obj[0].ParentBatchName;
            var batchName = obj[0].BatchName;
            var _academyId = obj[0].AcademyId;
            var _academyName = obj[0].AcademyName;
            var DailyStrength = parseInt(obj[0].DailyStrength);
            var BatchStrength = parseInt(obj[0].BatchStrength);
            this.batchIdnew = batchId;
            if (DailyStrength != 0) {
              $("#div_CoachChart").append(
                '<div id="coachChartData_' + batchId + '">' +
                '</div>' +
                '<ul class="strength">' + '<li><span class="green"></span>BatchStrength</li>' + '<li><span class="red"></span>AverageStrength</li>' + '<li><span class="orange"></span>DailyStrength</li>' + '</ul>')
            }
            // Load chart
            google.charts.setOnLoadCallback(function () {

              var data = new google.visualization.DataTable();

              data.addColumn('string', 'AttendanceDate');
              data.addColumn('number', 'BatchStrength');
              data.addColumn('number', 'AverageStrength');
              data.addColumn('number', 'DailyStrength');

              for (var i = 0; i < obj.length; i++) {
                var _date = new Date(obj[i].AttendanceDate);
                var month = _date.getMonth() + 1;
                var day = _date.getDate();
                var year = _date.getFullYear();
                var formattedDate = day + '-' + month;
                data.addRow([formattedDate,
                  parseInt(obj[i].BatchStrength),
                  parseInt(obj[i].AverageStrength),
                  parseInt(obj[i].DailyStrength)
                ]);
              }
              var barOptions = {
                width: "100%",
                height: "220",
                chartArea: {
                  width: '85%',
                  left: 30,
                  bottom: 40
                },
                hAxis: {
                  gridlines: {
                    color: 'transparent'
                  },
                  textStyle: {
                    fontSize: 9
                  },
                  slantedText: true,
                  /* Enable slantedText for horizontal axis */
                  slantedTextAngle: 90
                },
                vAxis: {
                  gridlines: {
                    color: 'transparent'
                  },

                },
                seriesType: 'bars',
                series: {
                  0: {
                    type: 'line',
                    color: '#a4c627',
                    visibleInLegend: false
                  },
                  1: {
                    type: 'line',
                    color: '#f15a25',
                    visibleInLegend: false
                  },
                  2: {
                    visibleInLegend: false
                  }
                },
                legend: {
                  position: 'bottom',
                }
              };
              var chart = new google.visualization.ColumnChart(document.getElementById('coachChartData_' + batchId));
              if(chart!=undefined)
              {
                chart.draw(data, barOptions);
              }
              
            });
          });
        }
      },
        (error) => { }
      );
  }
  GetFormattedMonthDate(date) {
    var _date = new Date(date);
    var month = _date.getMonth() + 1;
    var day = _date.getDate();
    var year = _date.getFullYear();
    var formattedDate = day + '-' + month;
    return formattedDate;
  }
  dynamichtml(DailyStrength, batchId) {
    if (DailyStrength != null && DailyStrength != 0 && DailyStrength != "") {
      $("#div_CoachChart").append(
        '<div id="coachChartData_' + batchId + '">')
    } else {
    }
  }
}

