<div class="header header-fixed header-logo-center" id="popup2">
    <span (click)="closemodel()" class="header-icon header-icon-1 textcolor">
        <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
    </span>
    <h2 class="header-title textcolor">Players</h2>
    <span class="header-icon header-icon-4 textcolor right15" (click)="save()">Save</span>
</div>
<div class="page-content header-clear-medium popup-mainarea">
    <div class="content multicheckbox p-0 border-0">
        <div class="list-group list-custom demo-color border-0">
            <div class="row mb-0">
                <div class="col-4">
                    <span class="checkboxtextcss badge">Default</span>
                </div>
                <div class="col-6 text-center">
                    <div class="row mb-0">
                        <span class="col-5 checkboxtextcss badge m-0 text-left">
                            <input type="text" (keyup)="Weight($event)" class="form-control" placeholder="Weight">
                        </span>
                        <span class="col-7 checkboxtextcss badge m-0 text-left">
                            <input type="text" (keyup)="Repetition($event)" class="form-control"
                                placeholder="Repetition">
                        </span>
                    </div>
                </div>
                <div class="col-2">
                    <span class="badge bg-red2 font-14 count">{{studentCount}}</span>
                </div>
            </div>
            <!-- <a class="d-flex align-items-center">
                <span class="checkboxtextcss badge">Default</span>
                <span class="checkboxtextcss badge">Weight(Kg)
                    <input type="text" (keyup)="Weight($event)" class="form-control" placeholder="Weight">
                </span>
                <span class="checkboxtextcss badge">Repetition
                    <input type="text" (keyup)="Repetition($event)" class="form-control" placeholder="Repetition">
                </span>
                <span class="badge bg-red2 font-14 count">{{studentCount}}</span>
            </a> -->
        </div>
    </div>
    <div class="content multicheckbox d-block">
        <div class="list-group demo-color" *ngFor="let item of GetStudentList; index as i">
            <div class="row mb-0">
                <div class="col-4">
                    <label class="mb-0 d-flex align-items-center" style="position: relative;">
                        <ng-container>
                            <input type="checkbox" checked
                                class="mr-0 checkboxsize studentcheckboxList studentcheckboxcount"
                                (click)="ChangeIcon($event,item.AcademyUserId);" id="stuhchk_{{item.AcademyUserId}}" />
                        </ng-container>
                        <span id="{{item.AcademyUserId}}" class="userid checkboxtextcss AllStudentCheckboxvalue"
                            value={{item.AcademyUserId}}>{{item.Name}}</span>
                    </label>
                </div>
                <div class="col-6 text-center">
                    <label class="row m-0">
                        <span class="col-6 weight checkboxtextcss badge m-0"
                            id="weight_{{item.AcademyUserId}}">{{item.weight}}</span>
                        <span class="col-6 repetition checkboxtextcss badge m-0"
                            id="repetition_{{item.AcademyUserId}}">{{item.repetition}}</span>
                        <span type="hidden" class="col-6 comment badge m-0" id="comment_{{item.AcademyUserId}}"
                            value="{{item.Comment}}">{{item.Comment}}</span>
                    </label>
                </div>
                <div class="col-1" >
                    <ng-container>
                        <span  hidden id="CommentBtn_{{item.AcademyUserId}}" class="badge bg-red2 font-11  commentbtn">
                            <img width="20px" alt="" src="../../../assets/comment//comment-bubble-icon-19.jpg">
                        </span>
                    </ng-container>
                </div>
                <div class="col-1">
                    <ng-container>
                        <span (click)="OpenEditeModel(item.AcademyUserId,item.Name)"
                            class="badge bg-red2 font-11 AllIsStudentStatus"
                            id="IsStudentStatus_{{item.AcademyUserId}}">
                            <img class="editiconbtn" alt="" src="../../../assets/images/icons/angle-right.png">
                        </span>
                    </ng-container>
                </div>
            </div>
        </div>
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
            <p class="color-white"> Loading... </p>
        </ngx-spinner>
    </div>
</div>  