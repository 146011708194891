import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetListService } from '../../service/getlist.service';
import { CommonFunction } from '../../service/commonfunction';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserReportInfos } from '../../_models/user.model';
import { SimpleModalService } from 'ngx-simple-modal';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AlertComponent } from 'src/app/AlertPopup/alert/alert.component';
@Component({
  selector: 'app-userreport',
  templateUrl: './userreport.component.html',
  styleUrls: ['./userreport.component.scss']
})
export class UserreportComponent implements OnInit {
  strrequired:"";
  data;
  assessmentForm: UntypedFormGroup;
  userdata;
  batchList: any;
  scheduleList: any;
  assessmentTypeList: any;
  dateList: any;
  userList: any;
  isassessment: boolean = false;
  academySportId;
  batchId;
  subBatchId;
  reportDate;
  assessmentTypeId;

  setuserreportinfomodel: UserReportInfos;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public route: ActivatedRoute,
    public commonFunction: CommonFunction,
    public router: Router,
    private spinner: NgxSpinnerService,
    public getListService: GetListService,
    public SimpleModalService:SimpleModalService
  ) { }

  ngOnInit(): void {
    if(window.history.state.example!=undefined)
    {
      this.forminit();
      let getReportinfo=window.history.state.example;
      this.assessmentForm.patchValue({
        BatchId:getReportinfo.MainBatchId,
        SubBatchId:getReportinfo.BatchId,
        AssessmentTypeId:getReportinfo.AssessmentTypeId,
        ReportDate:getReportinfo.ReportDate
      });
      this.onremoveclick();
      this.GetBatchList();
      this.BatchChangeEvent(getReportinfo.MainBatchId);
      this.ScheduleChangeEvent(getReportinfo.BatchId);
      this.AssessmentTypeChangeEvent(getReportinfo.AssessmentTypeId);
      this.ReportDateChangeEvent(getReportinfo.ReportDate);
    }
    else
    {
      this.forminit();
      this.userdata = this.commonFunction.GetUserData();
      this.onremoveclick();
      this.GetBatchList();
    }
    
  }

  forminit()
  {
    this.assessmentForm = this.formBuilder.group({
      BatchId: new UntypedFormControl(''),
      SubBatchId: new UntypedFormControl(''),
      AssessmentTypeId: new UntypedFormControl(''),
      ReportDate: new UntypedFormControl(''),
    });
  }
  onclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.add('menu-active');
  }
  onremoveclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.remove('menu-active');
  }
  back()
  {
    this.router.navigate(['/dashboard'])
  }
  GetBatchList() {
    this.userdata = this.commonFunction.GetUserData();
    this.getListService
      .GetUserBatches(this.userdata.AcademyId, this.userdata.UserId, this.userdata.isAccessAllBatches)
      .subscribe((response: any) => {
        this.batchList = response;
        
      },
        (error) => {
        
        });
  }
  BatchChangeEvent(val) {
    this.userdata = this.commonFunction.GetUserData();
    this.batchId=(val.target!=undefined)?val.target.value:val;
    this.getListService
      .GetUserScheduleList(this.batchId, this.userdata.UserId, this.userdata.RoleType)
      .subscribe((response: any) => {
        this.scheduleList = response;
        this.assessmentTypeList=[];
        this.dateList=[];
        if (this.scheduleList.length > 0) {
          this.academySportId = this.scheduleList[0]['AcademySportId'];
        }

      },
        (error) => {
          
        });
  }
  ScheduleChangeEvent(val) {
    this.subBatchId = (val.target!=undefined)?val.target.value:val;
    this.getListService
      .GetAssessmentTyprAndReportDateList(this.subBatchId, 0)
      .subscribe((response: any) => {
        if (response.AssessmentTypeModel.length > 0) {
          this.isassessment = true;
          this.assessmentTypeList = response.AssessmentTypeModel;
          this.dateList=[];
        }
        else {
          this.isassessment = false;
          this.NoAssessmentType();
        }
      },
        (error) => {
        });
  }
  NoAssessmentType() {
    var batchId = this.assessmentForm.value.SubBatchId;
    this.getListService
      .GetAssessmentTyprAndReportDateList(batchId, 0)
      .subscribe((response: any) => {

        if (response.AssessmentTypetDate.length > 0) {
          this.dateList = response.AssessmentTypetDate;
        }
        else {
          this.dateList = response.AssessmentDates;
        }
      },
        (error) => {
        });
  }
  AssessmentTypeChangeEvent(val) {
    var batchId = this.assessmentForm.value.SubBatchId;
    this.assessmentTypeId = (val.target!=undefined)?val.target.value:val;
    this.getListService
      .GetAssessmentTyprAndReportDateList(batchId, this.assessmentTypeId)
      .subscribe((response: any) => {
        
        if (response.AssessmentTypetDate.length > 0) {
          this.dateList = response.AssessmentTypetDate;
          this.assessmentForm.controls['ReportDate'].setValue( response.AssessmentTypetDate[0]);
          this.ReportDateChangeEvent(response.AssessmentTypetDate[0]);
        }
        else {
          this.dateList = response.AssessmentDates;
          this.assessmentForm.controls['ReportDate'].setValue( response.AssessmentDates[0]);
          this.ReportDateChangeEvent(response.AssessmentDates[0]);
        }
      },
        (error) => {
        });
  }
  ReportDateChangeEvent(val) {
    this.spinner.show();
    this.reportDate = (val.target!=undefined)?val.target.value:val;
    let batchId = this.assessmentForm.value.SubBatchId;
    let assessmentId = this.assessmentForm.value.AssessmentTypeId;
    if(assessmentId==undefined || assessmentId==null || assessmentId=="")
    {
      assessmentId=0;
    }
    this.getListService
      .GetAssessmentUserList(batchId, this.reportDate, assessmentId)
      .subscribe((response: any) => {                
        this.userList = response;
          this.spinner.hide();
      },
        (error) => {
        });
  }
  GetStudentReport(val) {

    var getUserDatathis = this.commonFunction.GetUserData();
    this.getListService.getAcademyPackageExpire(getUserDatathis.ParentAcademyId).subscribe(
      (response: any) => {
        
        if (response.PackageErrorMessage != "") {
          this.onremoveclick();
          this.strrequired = response.PackageErrorMessage;
          if (this.strrequired != "") {
            this.SimpleModalService.addModal(AlertComponent, { title: getUserDatathis.SelectAcademyName, message: this.strrequired });
          }
        }
        else {
          this.setuserreportinfomodel = val;
          this.setuserreportinfomodel.MainBatchId = this.batchId;
          this.setuserreportinfomodel.BatchId = this.subBatchId;
          this.setuserreportinfomodel.ReportDate = this.reportDate;
          this.setuserreportinfomodel.AcademySportId = this.academySportId;
          this.setuserreportinfomodel.AssessmentTypeId = this.assessmentTypeId;
          if (val.IsCompleted) {
            this.setuserreportinfomodel.IsAdd = false;
          }
          else {
            this.setuserreportinfomodel.IsAdd = true;
          }
          this.router.navigate(['/userassessment/userreportinfo'], {
            state: { example: this.setuserreportinfomodel }
          });
        }
      },
      (error) => {
      }
    );
  }
}

