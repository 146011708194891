import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonFunction } from '../service/commonfunction';
@Injectable({
  providedIn: 'root'
})
export class PostService {
  apiURL: string = environment.appUrl;
  constructor(public http: HttpClient, public commonFunction: CommonFunction) { }
  OtpGenerate(phoneNumber, academyId, isphonevalue) {
    return this.http.post(
      `${this.apiURL}` + "SmsVerification/SendSMSForVerificationPWA?academyId=" + academyId + "&phoneNumber=" + phoneNumber + "&userId=" + 0 + '&isphone=' + isphonevalue, ''
    );
  }

  PhonenumberVerification(phoneNumber) {
    return this.http.post(
      `${this.apiURL}` + "SmsVerification/PhonenumberVerificationForPWA?phoneNumber=" + phoneNumber, ''
    );
  }

  Otpverification(sessionId, otp, academyId, phoneNumber, isphonevalue) {
    return this.http.post(
      `${this.apiURL}` + "SmsVerification/GetVerificationSMS?academyId=" + academyId + "&phoneNumber=" + phoneNumber + "&sessionId=" + sessionId + "&Otp=" + otp + "&ServiceUrl=" + this.apiURL + '&userId=' + 0 + '&isphone=' + isphonevalue, ''
    );
  }
  SendOTPForChangeNumber(phoneNumber, academyId, isphonevalue) {
    return this.http.post(
      `${this.apiURL}` + "SmsVerification/SendOTPForChangeNumber?academyId=" + academyId + "&phoneNumber=" + phoneNumber + "&userId=" + 0 + '&isphone=' + isphonevalue, ''
    );
  }
  OtpverificationChangeNumber(sessionId, otp, academyId, phoneNumber, isphonevalue) {
    return this.http.post(
      `${this.apiURL}` + "SmsVerification/GetVerificationSMSChangeNumber?academyId=" + academyId + "&phoneNumber=" + phoneNumber + "&sessionId=" + sessionId + "&Otp=" + otp + "&ServiceUrl=" + this.apiURL + '&userId=' + 0 + '&isphone=' + isphonevalue, ''
    );
  }
  InsertOneSignalId(userId, deviceUUID, oneSignalId) {
    return this.http.post(
      `${this.apiURL}` + 'AnonymousUser/SetDeviceIdAndOneSignalId?userId=' + userId + '&deviceId=' + deviceUUID + '&oneSignalId=' + oneSignalId, ''
    );
  }
  CheckDeviceId(deviceId, userId) {
    if (deviceId != null && deviceId != "" && deviceId != undefined) {
      return this.http.post(
        `${this.apiURL}` + "AnonymousUser/CheckDeviceId?userId=" + userId + '&deviceId=' + deviceId, '',
      );
    }
  }

  UpdateDeviceId(deviceId, userId) {
    if (deviceId != null && deviceId != "" && deviceId != undefined) {
      return this.http.post(
        `${this.apiURL}` + "AnonymousUser/SetDeviceId?userId=" + userId + '&deviceId=' + deviceId, '',
      );
    }
  }

  sendMailToAdmin(userId, OgName,SndName) {
    if (userId != null && userId != "" && userId != undefined) {
      return this.http.post(
        `${this.apiURL}` + "AnonymousUser/sendMailToAdmin?userId=" + userId + '&OgName=' + OgName + "&SndName=" + SndName, '',
      );
    }
  }

  ManageUserReport(data) {
    return this.http.post(
      `${this.apiURL}` + "Report/ManageUserReport", data,

    );
  }
  SaveCoachAssessmentReport(data) {
    return this.http.post(
      `${this.apiURL}` + "CoachAssessment/SaveCoachAssessmentReport", data,
    );
  }

  /* trainning data submit Start*/
  public TrainingDataPost(playerdata) {
    return this.http.post(
      `${this.apiURL}` + 'Report/AddBatchPlanningdrillSets', playerdata,
    );
  }
  /*trainning data submit end*/

  public UpdatePassword(newPassword, oldpassword) {
    let userId = this.commonFunction.GetUserId();
    let model = {
      userId: userId,
      newPassword: newPassword,
      oldPassword: oldpassword
    };
    return this.http.post(

      `${this.apiURL}` + 'User/SaveUpdateUserPassword',
      model,
    );
  }
  /* Forget-Password Service Start */
  public forgotPassword(email: any) {
    return this.http.post(`${this.apiURL}` + "AcademyUser/ForgotPassword?email=" + email, '');
  }

  /* Forget-Password Service End */

  /* Attendance Service Start */
  public saveAttendance(userList: any) {
    return this.http.post(`${this.apiURL}AcademyUser/CreateUserAttendance`, userList);
  }
  /* Attendance Service End */

  /* Coach Attendance Service Start */
  public savecoachAttendance(userList: any) {
    return this.http.post(`${this.apiURL}AcademyUser/CreateCoachAttendance`, userList);
  }
  /* Coach Attendance Service End */

  /* Player Create Service Start */
  public CreateStudent(academyuserView: any, userAcademyId: any) {
    if (userAcademyId == 0) {
      return this.http.post(`${this.apiURL}AcademyUser/CreateUser`, academyuserView);
    }
    else {
      return this.http.post(`${this.apiURL}AcademyUser/EditUser`, academyuserView);
    }
  }
  /* Player Create Service End */

  /* Inquiry Create Service Start */
  public CreateInquiry(academyuserView: any, userAcademyId: any) {
    if (userAcademyId == 0) {
      return this.http.post(`${this.apiURL}AcademyInquiry/CreateInquiry`, academyuserView);
    }
    else {
      return this.http.post(`${this.apiURL}AcademyInquiry/EditInquiry`, academyuserView);
    }
  }
  public CancelAdmission(academyCancelAdmission: any) {
    return this.http.post(`${this.apiURL}AcademyUser/CancelAdmission`, academyCancelAdmission);
  }
  public UpdateInquiryStatus(statusId, academyUserId) {
    let model = {
      statusId: statusId,
      academyUserId: academyUserId,
    };
    return this.http.post(
      `${this.apiURL}` + 'AcademyInquiry/UpdateInquiryStatus',
      model,
    );
  }
  /* Inquiry Create End */

  /* User Messaage Send Start */
  public SendMessage(userId, roleId, txtmsg, convid, childUserId) {
    var model = {
      fromUserId: userId,
      roleId: roleId,
      message: txtmsg,
      conversationId: convid,
      childUserId: childUserId
    }
    return this.http.post(
      `${this.apiURL}` + "Messages/SendMessageApp", model,
    );
  }
  SaveRequisitionData(data) {
    return this.http.post(
      `${this.apiURL}` + "Requisition/SaveRequisition", data,
    );
  }
  SaveReportDamagesData(data) {
    return this.http.post(
      `${this.apiURL}` + "ReportDamage/SaveReportDamages", data,
    );
  }

  /* User Messaage Send End */
  public AddAcademyFeesDetail(model, academyId, userId) {
    return this.http.post(
      `${this.apiURL}` + 'AcademyFees/AddAcademyFeesDetailAngular',
      model,
    );
  }

  public AddMultipleAcademyFeesDetailAngular(model) {
    return this.http.post(
      `${this.apiURL}` + 'AcademyFees/AddMultipleAcademyFeesDetailAngular',
      model,
    );
  }

  public SaveVenueRentalPayment(model) {
    return this.http.post(
      `${this.apiURL}` + 'Venue/SaveVenueRentalPayment',
      model,
    );
  }
  /* User Messaage Send End */

  /* Manage Fees Start */
  SaveAllFeesOfUser(userFeesDetails) {
    return this.http.post(`${this.apiURL}AcademyFeesType/SaveAllFeesOfUser`, JSON.stringify(userFeesDetails));
  }
  SaveRenewsubscription(userRenewDetails) {
    return this.http.post(
      `${this.apiURL}` + "AcademyUser/SaveUserRenewSubscription", userRenewDetails,
    );
  }
  AddAcademyFeesDetailWithoutListed(academyId, academyUserId, sms, email, detail) {
    return this.http.post(
      `${this.apiURL}` + "AcademyFees/AddAcademyFeesDetailWithoutListed?academyId=" + academyId + "&academyUserId=" + academyUserId + "&sms=" + sms + "&email=" + email + "", detail,
    );
  }
  /* Manage Fees End */

  /* FeedBack Start */
  public saveFeedback(feedback: any) {
    return this.http.post(
      `${this.apiURL}` + "AnonymousUser/SendMail?to=" + feedback.to + "&from=" + feedback.from + "&subject=" + feedback.subject + "&body=" + feedback.body, ''
    );
  }
  /* FeedBack End */

  /* Transfer Batch Start */
  SaveTransferUserBatch(transferBatchModel) {
    return this.http.post(
      `${this.apiURL}` + "AcademyUser/TransferUserBatch", transferBatchModel,
    );
  }
  /* Transfer Batch End */

  /* Batch Planning Start */
  SaveBatchPlanning(batchId, scheduleDate, drillList, venueId, slotId, UserId, CreatedDate) {
    let batchplanningModel = {
      "drillList": drillList,
      "UserId": UserId,
      "batchPlanning": {
        "AcademyBatchId": batchId,
        "CreatedDate": CreatedDate,
        "scheduleDate": scheduleDate,
        "Slot": slotId,
        "venueId": venueId,
      },
    };
    return this.http.post(
      `${this.apiURL}` + "Batch/SaveBatchPlanning", batchplanningModel,
    );
  }
  /* Batch Planning Start */

  /* Venue Rental Start */
  SaveVenueRentals(venueRentals) {
    return this.http.post(
      `${this.apiURL}` + "Venue/SaveVenueRental", venueRentals,
    );
  }
  /* Venue Rental End */

  /* Create Venue  Start */
  SaveManageVenue(venue) {
    return this.http.post(
      `${this.apiURL}` + "Venue/ManageVenue", venue,
    );
  }
  sendstriprequest(data) {
    return this.http.post(
      `${this.apiURL}` + "RazorPay/SaveStripe", data,
    );
  }

  sendstriprequestforsession(data) {
    return this.http.post(
      `${this.apiURL}` + "RazorPay/createSession", data,
    );
  }
  /* Create Rental End */

  /* Start PunchIn/Out  Start */
  SaveCoachClockIn(modelData) {
    return this.http.post(
      `${this.apiURL}` + "AcademyUser/SaveCoachClockIn", modelData,
    );
  }
  /* Start PunchIn/Out End */



  /* Manage Fees  Start */
  AdminApproval(id, status) {
    return this.http.post(
      `${this.apiURL}` + "AcademyFees/AdminApproval?id=" + id + "&adminstatus=" + status, ''
    );
  }
  /* Manage Fees End */

  /* Leave Apllication Start */
  public SubmitUserInfo(model: any) {
    return this.http.post(`${this.apiURL}LeaveApplication/SaveLeaveApplication`, model);
  }

  public SubmitInjuryInfo(model: any) {
    return this.http.post(`${this.apiURL}AcademyUser/InsertUserInjuryAngular`, model);
  }
  /* Leave Apllication End*/
}

