import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-studenteditlist',
  templateUrl: './studenteditlist.component.html',
  styleUrls: ['./studenteditlist.component.scss']
})
export class StudenteditlistComponent implements OnInit {

  constructor(public route: ActivatedRoute,
              public router: Router) { }

  ngOnInit(): void {
  }
  previouspage(){
       this.router.navigate(['/Attendance']);
  }

}
