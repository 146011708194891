import { Component, HostListener, OnInit, VERSION  } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { GetListService } from '../service/getlist.service';
import { PostService } from './../service/post.service';
import { CommonFunction } from '../service/commonfunction';
import { environment } from "./../../environments/environment"
import $ from 'jquery'
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NotificationService } from '../service/notification.service';
import { LoginwithotpComponent } from '../../app/otplogin/loginwithotp/loginwithotp.component';
import { Platform } from '@angular/cdk/platform';
import { OneSignalService } from 'ngx-onesignal';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { IdbService } from '../service/idb.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeviceUUID } from 'device-uuid';
// declare var require: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  // version: string = require( '../../../package.json').version;

  Userdata: any;
  Userdatas: any;
  academyData;
  isFooter = '1';
  deferredPrompt: any;
  showButton = false;
  logo;
  Validation: boolean = false;
  loginForm: UntypedFormGroup;
  isAndroid: any = false;
  isIOS: any = false;
  webURL: string = environment.webUrl;
  tagline: any = "One Team One Dream";
  GetBatchesList: any = [];
  showpassword : boolean = false;
  deviceIdModel: any;
  // passwordshow = false;
  constructor(public route: ActivatedRoute,
    public dialog: MatDialog,
    private platform: Platform,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public postService: PostService,
    public notifyService: NotificationService,
    public readonly onesignal: OneSignalService,
    public idbService: IdbService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public getListService: GetListService, public commonFunction: CommonFunction) {
  }
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
    let displayMode = 'browser';
    const mqStandAlone = '(display-mode: standalone)';

  }
  ngOnInit(): void {
    // console.log(this.version);
    
    this.academyData = JSON.parse(localStorage.getItem("academyData"));
    //let acdata =  this.idbService.getItemObs("academy",1);
    this.idbService.getAllData("academy").then((item: any) => {
      let lessonObj = item;
      if (lessonObj.length == 0) {

      }
      else {

      }
    })
    this.tagline = this.academyData.TagLine;
    if (this.academyData.Id == 41) {
      this.logo = "assets" + this.academyData.AcademyDarkLogoImage;
    }
    else {
      this.logo = "assets" + this.academyData.AcademyLightLogoImage;
    }

    (window as any).ngxOnesignal = this.onesignal;
    this.onesignal.subscribe();
    this.initPwaPrompt();
    this.Userdata = this.commonFunction.GetUserData();
    // console.log(this.Userdata);

    if (this.Userdata != null) {
      if (this.Userdata.AuthKey != '') {
        this.loginsubmit();
      }
      this.SetLeavetype();
    }
    else {
      var isFooter = localStorage.getItem('footer');
      if (window.matchMedia('(display-mode: standalone)').matches) {
        this.isFooter = '0';
      }
      else {
        this.isFooter = '1';
      }
      this.academyData = JSON.parse(localStorage.getItem("academyData"));
      if (this.academyData != null || this.academyData != undefined) {
        if (this.academyData.Id == 41) {
          // this.logo = "assets" + this.academyData.AcademyDarkLogoImage;
          this.logo = this.webURL + this.academyData.AcademyDarkLogoImage;
        }
        else {
          this.logo = this.webURL + this.academyData.AcademyLightLogoImage;
        }


      } else {
        // this.logo = "../../assets/images/KridApp-Image/InvoiceLogoRSA.png";
      }
      this.initForm();
      this.onremoveclick();
    }
  }

  BacktoSignup() {
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }

  public initPwaPrompt() {
    if (this.platform.ANDROID || this.platform.isBrowser || this.platform.FIREFOX || this.platform.EDGE || this.platform.BLINK) {
      this.isAndroid = true;
      this.isIOS = false
    }
    if (this.platform.IOS || this.platform.SAFARI) {
      this.isAndroid = false;
      this.isIOS = true;
    }


  }

  openOTPpopup() {
    const dialogRef = this.dialog.open(LoginwithotpComponent, {
      width: '100%',
      height: '100%',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        // this.router.navigate(['/inquiry/createinquiry'], {
        //   state: {userAcademyId:result.data.val.Id,InquiryoptionData: result}
        // });
      }
    });
  }
  onclick() {
    $("body").addClass("modal-open");
    $('#menu-sidebar-left-1').addClass('menu-active')
  }
  onremoveclick() {
    $("body").removeClass("modal-open");
    $('#menu-sidebar-left-1').removeClass('menu-active')
  }
  initForm() {
    this.loginForm = this.formBuilder.group(
      {
        email: [null, [Validators.required]],
        password: [null, [Validators.required]],
      }
    );
  }

  lookpassword() {
    var x = <HTMLInputElement>document.getElementById('password');
    if (x.type === 'password') {
      x.type = 'text';
      this.showpassword = true;
    } else {
      x.type = 'password';
      this.showpassword = false;
    }
  }

  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          this.close();
        } else {
        }
        this.deferredPrompt = null;

      });
  }

  close() {
    this.isFooter = '0';
    localStorage.setItem('footer', '0');
  }

  RediectToForgetPassword() {
    const dialogRef = this.dialog.open(ForgetPasswordComponent, {
      width: '100%',
      height: '100%',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  loginsubmit() {
    // debugger;
    this.Userdata = this.commonFunction.GetUserData();
    // console.log(this.Userdata);
    this.onesignal.subscribe();
    if (this.Userdata != null) {
      if (this.Userdata.AuthKey != '') {

        this.router.navigate(['/selectacademy'])
          .then(() => {
            window.location.reload();
          });
      }
    }

    else {
      if (this.loginForm.invalid) {
        this.notifyService.showError("Please provide username and password to login.", "");
        return;

      }
      this.getListService.doLogin(this.loginForm.value).subscribe((response) => {
        if (response == "") {
          this.notifyService.showError("Invalid username or password.", "");
        }
        let array = [];
        let that = this;
        array.push(response);

        if (response.AuthKey != undefined) {
          var uuid = new DeviceUUID().get();
          this.commonFunction.SetLocalStorageData(response);
          this.idbService.getAllData("user").then((item: any) => {
            let getObj = item;
            if (getObj.length == 0) {
              array.forEach(function (item) {
                that.idbService.addItemsObs("user", item);
              });
            }
          })
          var data = {
            RoleTypeId: response.rolesList[0].RoleType,
            AcademyId: response.AcademyId
          };

          this.commonFunction.SetParentAcademyAndRoleTypeId(data);
          if (data.RoleTypeId != 3) {
            // console.log(response);
              this.postService
                .CheckDeviceId(uuid, response.Id)
                .subscribe((response: any) => {
                  this.deviceIdModel = response;
                  this.Userdatas = this.commonFunction.GetUserData();
                  if(this.deviceIdModel.IsExist){                    
                      this.StoreIndexDB(response);
                  }
                  else{
                   
                      var message = "This device is already registered for " + this.deviceIdModel.DiffUserFirstname + ". If you login with other user, admin will get notified. Are you sure you want to continue?";
                      if(confirm(message)){
                        this.postService
                          .sendMailToAdmin(this.deviceIdModel.DiffUserId,this.deviceIdModel.DiffUserFirstname, this.Userdatas.FirstName)
                          .subscribe((response: any) => {
                          })
                        this.StoreIndexDB(response);
                      }
                      else{
                        let academyData = JSON.parse(localStorage.getItem("academyData"));
                        localStorage.clear();
                        this.idbService.clear("academy");
                        this.idbService.clear("academybatch");
                        this.idbService.clear("academyuserattendance");
                        this.idbService.clear("dashboard");
                        this.idbService.clear("leavetype");
                        this.idbService.clear("notificationcount");
                        this.idbService.clear("rolemaster");
                        this.idbService.clear("subbatch");
                        this.idbService.clear("user");
                        this.idbService.clear("userlist");
                        localStorage.setItem("academyData", (JSON.stringify(academyData)));
                        this.router.navigate(['/']).then(() => {
                          window.location.reload();
                        });
                      }
                      // alert(message);
                    
                  }        
                })
          
                // this.UpdateDeviceId(this.userdata.DeviceId, this.userdata.UserId);
            
            
          }
          else {
            this.router.navigate(['/selectacademy'])
              .then(() => {
                window.location.reload();
              });
          }
          // if (response.rolesList[0].RoleType == 1 || response.rolesList[0].RoleType == 2) {
          if (response.MultipleAcademy == false && response.rolesList.length == 1 && data.RoleTypeId == 3) {
            this.router.navigate(['/dashboard'])
              .then(() => {
                window.location.reload();
              });
          }
          else if (data.RoleTypeId == 3) {
            this.router.navigate(['/selectacademy'])
              .then(() => {
                window.location.reload();
              });
          }

          //  this.idbService.addItemsObs("academy", response);


        }
        else {
          this.notifyService.showError("Invalid username or password.", "");
          this.router.navigate(['/login']);
        }
      },
        (error) => {
          this.loginForm.reset();
        });
    }
  }
  SetLeavetype() {
    var data = this.commonFunction.GetUserData();
    let that = this;
    this.getListService
      .getLeaveTypeList(data)
      .subscribe((responseleavetype: any) => {
        this.idbService.getAllData("leavetype").then((item1: any) => {
          let getObjleave = item1;
          if (getObjleave.length == 0) {
            if (responseleavetype.length > 0) {
              responseleavetype.forEach(function (item1) {
                that.idbService.addItemsObs("leavetype", item1);
              });
            }
          }
        })

      },
        (error) => {
        });
  }
  StoreIndexDB(response) {
    this.spinner.show();
    // Get Academy List and Store in IndexDB Database
    var data = this.commonFunction.GetUserData();
    this.SetLeavetype();
    this.getListService
      .getAcademicList(data)
      .subscribe((response: any) => {
        let GetAcademicList = response;

        //Get Role List

        // this.getListService
        //   .getRoleList(data)
        //   .subscribe((response1: any) => {
        //     let GetRoleList = response1;
        //     let idlist = ""

        //     if (GetAcademicList.length > 0) {
        //       for (let index = 0; index < GetAcademicList.length; index++) {
        //         const element = GetAcademicList[index];
        //         idlist = idlist + element.Id + ',';
        //       }
        //       idlist = idlist.replace(/,\s*$/, "");
        //       var batchobj: any = {
        //         academyId: idlist,
        //         userId: data.UserId,
        //         isAccessAllBatches: data.isAccessAllBatches
        //       };
        //       // Get all batchlist
        //       this.getListService
        //         .getBatchesList(batchobj)
        //         .subscribe((response: any) => {
        //           let idstring = "", batchids = "";

        //           for (let i = 0; i < response.length; i++) {
        //             batchids = batchids + response[i].Id + ',';
        //             this.GetBatchesList.push({
        //               Id: response[i].Id,
        //               BatchName: response[i].BatchName,
        //               checked: false
        //             });
        //           }
        //           batchids = batchids.replace(/,\s*$/, "");
        //           // acid = data.AcademyId.replace(/,\s*$/, "");
        //           let Dayofweek = new Date();
        //           var datasubobj = {
        //             academyId: idlist,
        //             batchIds: batchids,
        //             userId: data.UserId,
        //             dayofWeek: Dayofweek.getDay(),
        //             roleId: data.RoleType
        //           };
        //           // Get all sub batchlist

        //           this.getListService
        //             .getScheduleList(datasubobj)
        //             .subscribe((responsesubbatch: any) => {
        //               let subbatchlist = "";
        //               if (responsesubbatch.length > 0) {
        //                 for (let index = 0; index < responsesubbatch.length; index++) {
        //                   const element = responsesubbatch[index];
        //                   subbatchlist = subbatchlist + element.Id + ','
        //                 }
        //               }
        //               //Get all Player list by batchid
        //               subbatchlist = subbatchlist.replace(/,\s*$/, "");
        //               var dataobjuser = {
        //                 academyId: data.AcademyId,
        //                 batchIds: subbatchlist,
        //                 scheudleDate: this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        //               };
        //               this.getListService
        //                 .getStudentList(dataobjuser)
        //                 .subscribe((responsestudlist: any) => {
        //                   this.spinner.hide();
        //                   let todaydate = this.datePipe.transform(new Date(), 'd MMM yyyy');
        //                   let dashBoardData = [];
        //                   // this.getListService
        //                   //   .GetAdminDashboardCount(2, data.UserId, todaydate)
        //                   //   .subscribe((responsedashboard: any) => {

        //                   //     dashBoardData.push(responsedashboard)

        //                   //   });


        //                   let that = this;
        //                   this.idbService.getAllData("academy").then((item: any) => {
        //                     let getObj = item;
        //                     if (getObj.length == 0) {
        //                       if (GetAcademicList.length > 0) {
        //                         GetAcademicList.forEach(function (item) {
        //                           that.idbService.addItemsObs("academy", item);
        //                         });
        //                       }
        //                     }
        //                   })
        //                   this.idbService.getAllData("rolemaster").then((item: any) => {
        //                     let getObj = item;
        //                     if (getObj.length == 0) {
        //                       if (GetRoleList.length > 0) {
        //                         GetRoleList.forEach(function (item) {
        //                           that.idbService.addItemsObs("rolemaster", item);
        //                         });
        //                       }
        //                     }
        //                   })
        //                   this.idbService.getAllData("academybatch").then((item: any) => {
        //                     let getObj = item;
        //                     if (getObj.length == 0) {
        //                       if (this.GetBatchesList.length > 0) {
        //                         this.GetBatchesList.forEach(function (item) {

        //                           that.idbService.addItemsObs("academybatch", item);
        //                         });
        //                       }
        //                     }
        //                   })
        //                   this.idbService.getAllData("subbatch").then((item: any) => {
        //                     let getObj = item;
        //                     if (getObj.length == 0) {
        //                       if (responsesubbatch.length > 0) {
        //                         responsesubbatch.forEach(function (item) {
        //                           that.idbService.addItemsObs("subbatch", item);
        //                         });
        //                       }
        //                     }
        //                   })
        //                   this.idbService.getAllData("userlist").then((item: any) => {
        //                     let getObj = item;
        //                     if (getObj.length == 0) {
        //                       if (responsestudlist.length > 0) {
        //                         // responsestudlist.forEach(function (item) {
        //                         that.idbService.addItemsObs("userlist", responsestudlist);
        //                         // });
        //                       }
        //                     }
        //                     // this.StoreDashboardData()
        //                   })

        //                   this.router.navigate(['/selectacademy'])
        //                     .then(() => {
        //                       window.location.reload();
        //                     });

        //                 });
        //             });




        //           // this.idbService.inserttablebyarray(GetAcademicList, GetRoleList, this.GetBatchesList);
        //         },
        //           (error) => {
        //             this.spinner.hide();
        //           });
        //     }
        //     else {
        //       this.spinner.hide();
        //     }
        //     // this.router.navigate(['/selectacademy'])
        //     //   .then(() => {
        //     //     window.location.reload();
        //     //   });
        //   },
        //     (error) => {
        //       this.spinner.hide();
        //     });
        // let that = this;
        // response.forEach(function (item) {
        //   that.idbService.addItemsObs("academy", item);
        // });
        // Get Role List and Store in IndexDB Database

        // this.router.navigate(['/selectaca  demy'])
        // .then(() => {
        //   window.location.reload();
        // });

        this.router.navigate(['/selectacademy'])
          .then(() => {
            window.location.reload();
          });

      },
        (error) => {
          this.spinner.hide();
        });

  }

  StoreDashboardData() {
    let date = this.datePipe.transform(new Date(), 'd MMM yyyy');
    this.getListService
      .GetAdminDashboardCount(this.Userdata.AcademyId, this.Userdata.UserId, date)
      .subscribe((response: any) => {
        let dashBoardData = [];
        dashBoardData.push(response)
        let that = this;
        this.idbService.getAllData("dashboardcount").then((item: any) => {
          let getObj = item;
          if (getObj.length == 0) {
            if (dashBoardData.length > 0) {
              dashBoardData.forEach(function (item) {
                that.idbService.addItemsObs("dashboardcount", item);
              });
            }
          }
        })
        // this.totalstudentsCount = this.dashBoardData.TotalStudents;
        // this.attendanceandMarked = (this.dashBoardData.MarkedAttendance + '/' + this.dashBoardData.TotalAttendance);
        // this.collectedFees = this.kFormatter(this.dashBoardData.TotalCollection);
        // this.OverdueCount = this.dashBoardData.Overdue;
        // this.OpenLeads = this.dashBoardData.Leads;
        // this.dropOuts = this.dashBoardData.Dropouts;

      })
  }
}




