import { Component, HostListener, OnInit } from '@angular/core';
import $ from 'jquery';
let deferredPrompt;
@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {
  isFooter = '1';
  deferredPrompt: any;
  showButton = false;
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }
  constructor() {
    if (this.isFooter == '1') {
      $(window).scroll(function (event) {
        var scroll = $(window).scrollTop();
        if (scroll > 20) {
          $('.footer-nav').fadeIn('slow').addClass('show');
        } else {
          $('.footer-nav').fadeOut('slow').removeClass('show');
        }
      });
    }
  }

  ngOnInit(): void {
    var isFooter = localStorage.getItem('footer');
    if (isFooter == '0') {
      this.isFooter = '0';
    }
    this.onremoveclick();
  }
  onclick() {
     $('body').addClass('modal-open');
     $('#menu-sidebar-left-1').addClass('menu-active');
  }

  onremoveclick() {
    $('body').removeClass('modal-open');
    $('#menu-sidebar-left-1').removeClass('menu-active');
  }


  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // console.log('User accepted the A2HS prompt');
          this.close();
        } else {
          // console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;

      });
  }

  close() {
    this.isFooter = '0';
    localStorage.setItem('footer', '0');
  }
}
