import { PopupresonlistComponent } from '../popupresonlist/popupresonlist.component';
import { PopupcommonfeedbackComponent } from '../popupcommonfeedback/popupcommonfeedback.component';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GetListService } from '../../service/getlist.service';
import { CommonFunction } from '../../service/commonfunction';
import { PopupfeedbackComponent } from '../popupfeedback/popupfeedback.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { IdbService } from 'src/app/service/idb.service';
import { Pipe, PipeTransform } from '@angular/core';


@Component({
  selector: 'app-popupstudentlist',
  templateUrl: './popupstudentlist.component.html',
  styleUrls: ['./popupstudentlist.component.scss']
})
export class PopupstudentlistComponent implements OnInit {
  seletddateforattendance: any;
  public GetStudentList: any[];
  public studentCount: any;
  StudentFeedBackArray: any = [];
  LeaveTypeListSArray: any = [];
  LeaveShortName: any;
  GetData: any;
  searchText: string;
  filterTerm: string;

  constructor(
    public dialogRef: MatDialogRef<PopupstudentlistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public getListService: GetListService,
    public commonFunction: CommonFunction,
    private spinner: NgxSpinnerService,
    public idbService: IdbService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.GetData = this.data;
    this.studentList(this.GetData.BatchId, this.GetData.AttendanceDate);
    this.seletddateforattendance = this.datePipe.transform(this.GetData.AttendanceDate, 'MMM dd,yy');
  }

  public studentList(id: any, attendanceDate: any) {
    var userdata = this.commonFunction.GetUserData();
    if (window.navigator.onLine == false) // Ofline
    {
      this.idbService.getAllData("userlist").then((response: any) => {
        let userlist = response[0];
        let myuserlist = [];
        for (let index = 0; index < userlist.length; index++) {
          const element = userlist[index];
          for (let j = 0; j < id.length; j++) {
            const elementj = id[j];
            if (element.AcademyBatchId == elementj) {
              myuserlist.push(element)
            }
          }
        }
        //this.GetStudentList = myuserlist;
        this.studentCount = myuserlist.length;
        let count = 0;
        this.idbService.getAllData("academyuserattendance").then((responseattendance: any) => {
          let userlistattendance = responseattendance;
          if (userlistattendance.length > 0) {
            for (let index = 0; index < userlistattendance.length; index++) {

              let curdate = this.datePipe.transform(userlistattendance[index].userAttendance.AttendanceDate, 'MMM dd,yy')
              if (this.seletddateforattendance == curdate) {
                const leavelist = userlistattendance[index].leaveTypeList;
                for (let i = 0; i < myuserlist.length; i++) {
                  const element1 = myuserlist[i];
                  for (let j = 0; j < leavelist.length; j++) {
                    const element = leavelist[j];
                    if (element.UserId == element1.UserId) {
                      myuserlist[i].LeaveTypeId = element.Id;
                      myuserlist[i].LeaveShortName = element.Id > 0 ? (element.LeaveShortName == "" ? "A" : element.LeaveShortName) : "";
                      break;
                    }
                    else {
                      myuserlist[i].LeaveTypeId = 0;
                      myuserlist[i].LeaveShortName = "";
                    }
                  }
                }
                this.GetStudentList = myuserlist;
              }
            }
            if (this.GetStudentList == undefined) {
              this.GetStudentList = myuserlist;
            }
            for (let i = 0; i < myuserlist.length; i++) {
              if (myuserlist[i].LeaveTypeId == 0) {
                count++;
              }
            }
            if (this.studentCount == count) {
              var element = <HTMLInputElement>document.getElementById("selectAllCheck");
              element.checked = true;
            }
            else {
              var element = <HTMLInputElement>document.getElementById("selectAllCheck");
              element.checked = false;
            }
            for (let i = 0; i < myuserlist.length; i++) {
              this.StudentFeedBackArray.push({
                BatchId: myuserlist[i].AcademyBatchId,
                UserId: myuserlist[i].UserId,
                Homework: myuserlist[i].PrivateHomework,
                Diet: myuserlist[i].PrivateDiet,
                Comments: myuserlist[i].PrivateComments,
                Temperature: myuserlist[i].Temperature,

                PrivateHomework: myuserlist[i].PrivateHomework,
                PrivateDiet: myuserlist[i].PrivateDiet,
                PrivateComments: myuserlist[i].PrivateComments,


              });

              this.LeaveTypeListSArray.push({
                UserId: myuserlist[i].UserId,
                Id: myuserlist[i].LeaveTypeId,
                LeaveShortName: myuserlist[i].LeaveShortName
              });
            }



          }
          else { // Ofline and No data
            this.GetStudentList = myuserlist;
            for (let i = 0; i < myuserlist.length; i++) {
              if (myuserlist[i].LeaveTypeId == 0) {
                count++;
              }
            }

            if (this.studentCount == count) {
              var element = <HTMLInputElement>document.getElementById("selectAllCheck");
              element.checked = true;
            }
            else {
              var element = <HTMLInputElement>document.getElementById("selectAllCheck");
              element.checked = false;
            }
            for (let i = 0; i < myuserlist.length; i++) {
              this.StudentFeedBackArray.push({
                BatchId: myuserlist[i].AcademyBatchId,
                UserId: myuserlist[i].UserId,
                Homework: myuserlist[i].PrivateHomework,
                Diet: myuserlist[i].PrivateDiet,
                Comments: myuserlist[i].PrivateComments,
                Temperature: myuserlist[i].Temperature,

                PrivateHomework: myuserlist[i].PrivateHomework,
                PrivateDiet: myuserlist[i].PrivateDiet,
                PrivateComments: myuserlist[i].PrivateComments,


              });

              this.LeaveTypeListSArray.push({
                UserId: myuserlist[i].UserId,
                Id: myuserlist[i].LeaveTypeId,
                LeaveShortName: myuserlist[i].LeaveShortName
              });
            }
          }
        });


        this.spinner.hide();
      })
    }
    else {

      var data = {
        academyId: userdata.AcademyId,
        batchIds: id,
        scheudleDate: this.datePipe.transform(attendanceDate, 'MM/dd/yyyy'),
        slot: this.GetData.slot
      };
      this.spinner.show();
      this.getListService
        .getStudentList(data)
        .subscribe((response: any) => {
          this.GetStudentList = response;
          this.studentCount = response.length;
          let count = 0;
          for (let i = 0; i < response.length; i++) {
            if (response[i].LeaveTypeId == 0) {
              count++;
            }
          }

          if (this.studentCount == count) {
            var element = <HTMLInputElement>document.getElementById("selectAllCheck");
            element.checked = true;
          }
          else {
            var element = <HTMLInputElement>document.getElementById("selectAllCheck");
            element.checked = false;
          }

          for (let i = 0; i < response.length; i++) {
            this.StudentFeedBackArray.push({
              BatchId: response[i].AcademyBatchId,
              UserId: response[i].UserId,
              Homework: response[i].PrivateHomework,
              Diet: response[i].PrivateDiet,
              Comments: response[i].PrivateComments,
              Temperature: response[i].Temperature,

              PrivateHomework: response[i].PrivateHomework,
              PrivateDiet: response[i].PrivateDiet,
              PrivateComments: response[i].PrivateComments,


            });

            this.LeaveTypeListSArray.push({
              UserId: response[i].UserId,
              Id: response[i].LeaveTypeId,
              LeaveShortName: response[i].LeaveShortName
            });
          }
          this.spinner.hide();
        },
          (error) => {
            this.spinner.hide();
          });
    }


  }

  public CheckOrUnCheck(e) {
    ;
    var GetAllcheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("studentcheckboxList"));
    if (e.target.checked == true) {
      for (let i = 0; i < GetAllcheckbox.length; i++) {
        GetAllcheckbox[i].checked = true;
      }
      let x = document.querySelectorAll('.AllIsStudentStatus')
      for (let j = 0; j < x.length; j++) {

        x[j].removeAttribute('hidden');
      }

      let y = document.querySelectorAll('.AllIsReasonStatus')
      for (let k = 0; k < y.length; k++) {
        y[k].setAttribute('hidden', 'true');
      }
      for (let i = 0; i < this.LeaveTypeListSArray.length; i++) {
        this.LeaveTypeListSArray[i] = {
          UserId: this.LeaveTypeListSArray[i].UserId,
          Id: 0,
          LeaveShortName: ""
        };

      }

    }
    else {
      e.target.checked = false;
      for (let i = 0; i < GetAllcheckbox.length; i++) {
        GetAllcheckbox[i].checked = false;
      }
      let x = document.querySelectorAll('.AllIsStudentStatus')
      for (let j = 0; j < x.length; j++) {
        x[j].setAttribute('hidden', 'true');
      }

      let y = document.querySelectorAll('.AllIsReasonStatus')
      for (let k = 0; k < y.length; k++) {
        y[k].removeAttribute('hidden');
      }

      for (let i = 0; i < this.StudentFeedBackArray.length; i++) {
        this.StudentFeedBackArray[i] = {
          BatchId: this.StudentFeedBackArray[i].BatchId,
          UserId: this.StudentFeedBackArray[i].UserId,
          Homework: "",
          Diet: "",
          Comments: "",
          Temperature: this.StudentFeedBackArray[i].Temperature
        };
      }
      for (let i = 0; i < this.LeaveTypeListSArray.length; i++) {
        this.LeaveTypeListSArray[i] = {
          UserId: this.LeaveTypeListSArray[i].UserId,
          Id: 1,
          LeaveShortName: "A"
        };
      }

    }
  }

  ChangeIcon(id, event) {
    var studentcheckedcheckboxcount = (<HTMLInputElement[]><any>document.getElementsByClassName("studentcheckboxcount"));
    let count = 0;
    for (var i = 1; i < studentcheckedcheckboxcount.length; i++) {
      if (studentcheckedcheckboxcount[i].type == "checkbox" && studentcheckedcheckboxcount[i].checked == true) {
        count++;
      }
    }

    var studentCount = this.studentCount;
    let eventtruefalse = (event.checked == true || event.checked == false) ? event.checked : event.target.checked;
    if (eventtruefalse == true) {
      //event.target.checked = true;
      document.getElementById("IsReasonStatus_" + id).hidden = true;
      document.getElementById("IsStudentStatus_" + id).hidden = false;
      if (count == studentCount) {
        var element = <HTMLInputElement>document.getElementById("selectAllCheck");
        element.checked = true;
      }
      else {
        var element = <HTMLInputElement>document.getElementById("selectAllCheck");
        element.checked = false;
      }

      for (let i = 0; i < this.LeaveTypeListSArray.length; i++) {
        if (this.LeaveTypeListSArray[i].UserId == id) {
          this.LeaveTypeListSArray[i] = {
            UserId: this.LeaveTypeListSArray[i].UserId,
            Id: 0,
            LeaveShortName: ""
          };
        }
      }
    }
    else {
      //event.target.checked = false;
      document.getElementById("IsReasonStatus_" + id).hidden = false;
      document.getElementById("IsStudentStatus_" + id).hidden = true;
      if (count != studentCount) {
        var element = <HTMLInputElement>document.getElementById("selectAllCheck");
        element.checked = false;
      }
      else {
        var element = <HTMLInputElement>document.getElementById("selectAllCheck");
        element.checked = true;
      }
      for (let i = 0; i < this.StudentFeedBackArray.length; i++) {
        if (this.StudentFeedBackArray[i].UserId == id) {
          this.StudentFeedBackArray[i] = {
            BatchId: this.StudentFeedBackArray[i].BatchId,
            UserId: this.StudentFeedBackArray[i].UserId,
            Homework: "",
            Diet: "",
            Comments: "",
            Temperature: this.StudentFeedBackArray[i].Temperature
          };
        }
      }
      for (let i = 0; i < this.LeaveTypeListSArray.length; i++) {
        if (this.LeaveTypeListSArray[i].UserId == id) {
          this.LeaveTypeListSArray[i] = {
            UserId: this.LeaveTypeListSArray[i].UserId,
            Id: 1,
            LeaveShortName: "A"
          };
        }
      }
    }
  }
  closemodel() {
    this.dialogRef.close();
    this.spinner.hide();
  }

  done() {
    let StudentModel = {
      StudentFeedBackArray: this.StudentFeedBackArray,
      LeaveTypeListSArray: this.LeaveTypeListSArray
    }
    this.dialogRef.close({ data: StudentModel, studentCount: this.studentCount })
    this.spinner.hide();
  }
  openresonModal(UserId: any): void {
    const dialogRef = this.dialog.open(PopupresonlistComponent, {
      width: '100%',
      height: '100%',
      data: {
        UserId: UserId,
        LeaveTypeListSArray: this.LeaveTypeListSArray
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.LeaveTypeListSArray = result.data;
      this.LeaveShortName = result.data.LeaveShortName;
      let GetUserIdByDom = (<HTMLInputElement>document.getElementById('IsReasonButton_' + result.Id));
      GetUserIdByDom.value = result.LeaveShortName;
    });
  }

  openFeedbackModal(UserId: any, name: any): void {
    const dialogRef = this.dialog.open(PopupfeedbackComponent, {
      width: '100%',
      height: '100%',
      data: {
        UserId: UserId,
        PlayerName: name,
        StudentFeedBackArray: this.StudentFeedBackArray
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.StudentFeedBackArray = result.data;
    });
  }
  opencommonfeedbackModal(): void {
    let StudentModel = {
      StudentFeedBackArray: this.StudentFeedBackArray,
      LeaveTypeListSArray: this.LeaveTypeListSArray
    }
    this.spinner.hide();

    const dialogRef = this.dialog.open(PopupcommonfeedbackComponent, {
      width: '100%',
      height: '100%',
      data: {
        studentList: StudentModel,
        studentCount: this.studentCount,
        AttendanceDate: this.GetData.AttendanceDate,
        BatchId: this.GetData.BatchId,
        Latitude: this.GetData.Latitude,
        Longitude: this.GetData.Longitude,
        slot: this.GetData.slot

      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  // studentDivclick(id)
  // {
  //   let GetStuByDom=(<HTMLInputElement>document.getElementById('stuhchk_'+id));
  //   if(GetStuByDom.checked==true)
  //   {
  //     GetStuByDom.checked=true;
  //     this.ChangeIcon(id,GetStuByDom);
  //   }
  //   else
  //   {
  //     GetStuByDom.checked=false;
  //     this.ChangeIcon(id,GetStuByDom);
  //   }
  // }

}
