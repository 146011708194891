import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  StripeService,
  StripeCardComponent
} from 'ngx-stripe';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  StripeElementsOptions,
  PaymentRequestPaymentMethodEvent,
  PaymentIntent,
  PaymentRequestShippingAddressEvent,
  StripeCardElementOptions,
} from '@stripe/stripe-js';
import { CommonFunction } from '../service/commonfunction';
import { PostService } from '../service/post.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GetListService } from '../service/getlist.service';
@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})
export class StripeComponent implements OnInit {
  @Input() payamount: any;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  paymentForm: UntypedFormGroup;
  customStripeForm: UntypedFormGroup;
  stripeCardValid: boolean = false;
  userdata: any;
  email: any;
  phone: any;
  user: any;
  currencysymbol;
  detail: any;
  submitted: boolean;
  formProcess: boolean;
  message: string;
  get validForm() {
    return this.paymentForm.valid;
  }
  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private stripeService: StripeService,
    private commonFunction: CommonFunction,
    private postservice: PostService,
    private GetListService: GetListService,
    public router: Router,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) { }
  handler: any = null;
  ngOnInit() {
    this.paymentForm = this.fb.group({
      name: ['', [Validators.required]],
      Email: ['', [Validators.required]],
      Reference: ['', [Validators.required]],
    });
   
    this.userdata = this.commonFunction.GetUserData();
    if (this.userdata != null || this.userdata != undefined) {
      this.email = this.userdata.UserEmail;
      this.phone = this.userdata.PhoneNumber;
      this.user = this.userdata.FirstName;
    }
    
    if (this.email != undefined || this.email != null || this.email != "") {
      this.paymentForm.patchValue({
        Email: this.userdata.UserEmail,
      });
    }
    this.currencysymbol = this.userdata.CountryCurrencySymbol;
  }
  onChange({ type, event }) {
    if (type === 'change') {
      this.stripeCardValid = event.complete;
    }
  }
  // buy(event) {

  //   event.target.disabled = true;
  //   this.spinner.show();
  //   const name = this.paymentForm.get('name').value;
  //   const useremail = this.paymentForm.get('Email').value;
  //   this.stripeService
  //     .createToken(this.card.element, { name })
  //     .subscribe((result) => {
  //       if (result.token) {
  //         // Use the token
  //         let data = {
  //           token: result.token.id,
  //           email: useremail,
  //           user: name,
  //           amount: this.payamount.OutStandingAmount * 100,
  //           description: 'Payment accept of player        ' + name + ', and amount is ' + this.payamount.OutStandingAmount,
  //           academyId: this.userdata.AcademyId,
  //           parentAcademyId: this.userdata.ParentAcademyId
  //         }
  //         event.target.disabled = false;
  //         const dialogRef = this.dialog.open(OtpConfirmrComponent, {
  //           panelClass: 'attendancesave',
  //           data: {
  //             message: 'Are you sure you want to proceed with the payment?',
  //             buttonText: {
  //               ok: 'Yes',
  //               cancel: 'Cancel'
  //             }
  //           }
  //         });

  //         dialogRef.afterClosed().subscribe((confirmed: boolean) => {
  //           if (confirmed) {
  //             event.target.disabled = true;
  //             this.postservice.sendstriprequest(data)
  //               .subscribe((response: any) => {
  //                 let today = new Date();
  //                 this.detail = {
  //                   amount: this.payamount.OutStandingAmount,
  //                   ParentAcademyId: this.userdata.ParentAcademyId,
  //                   Sms: true,
  //                   Email: true,
  //                   academyId: this.userdata.AcademyId,
  //                   userId: this.userdata.UserId,
  //                   sms: true,
  //                   email: true,
  //                   academyFeesDetails: {
  //                     Id: 0,
  //                     FeesHeaderId: this.payamount.FeesHeaderId,
  //                     Amount: this.payamount.OutStandingAmount,
  //                     ModeOfPayment: 2,
  //                     PaymentDate: today,
  //                     TransactionId: response.id,
  //                     CreatedBy: this.userdata.UserId,
  //                   }
  //                 }

  //                 this.postservice
  //                   .AddAcademyFeesDetail(this.detail, this.userdata.AcademyId, this.userdata.UserId)
  //                   .subscribe((response: any) => {
  //                     this.router.navigate(['/studentfees/studentfeeslist'], {
  //                       state: { example: this.payamount.BatchId }
  //                     })
  //                       .then(() => {
  //                         window.location.reload();
  //                       });
  //                   })

  //               },
  //                 (error) => {
  //                   alert("Error: Your transaction could not be completed. Please again later.")
  //                   //alert("Error:" + error.statusText + " Code:" + error.status);
  //                   event.target.disabled = false;
  //                 }
  //               );
  //             this.spinner.hide();
  //           }
  //         });

  //       } else if (result.error) {
  //         alert("please enter valid details")
  //         // Error creating the token
  //         event.target.disabled = false;
  //       }
  //     });
  // }

  buys(price) {

    let today = new Date();
    let feesheaderid = this.payamount.FeesHeaderId;
    let batchname = this.payamount.Batchname;

    const formvalue = this.paymentForm.value;
    this.GetListService.sendstriprequestforsession(price, formvalue.name, formvalue.Email, feesheaderid, batchname, formvalue.Reference).subscribe((session: any) => { window.location.href = session; })
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  pay(form) {
    if (!window['Stripe']) {
      alert('Oops! Stripe did not initialize properly.');
      return;
    }

    this.submitted = true;

    if (this.customStripeForm.invalid) {
      return;
    }

    this.formProcess = true;
    if (!window['Stripe']) {
      alert('Oops! Stripe did not initialize properly.');
      return;
    }
    (<any>window).Stripe.card.createToken({
      number: form.cardNumber,
      exp_month: form.expMonth,
      exp_year: form.expYear,
      cvc: form.cvc
    }, (status: number, response: any) => {
      this.submitted = false;
      this.formProcess = false;
      if (status === 200) {
        this.message = `Success! Card token ${response.card.id}.`;
      } else {
        this.message = response.error.message;
      }
    });
  }
}
