<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css">

<div id="page">
    <a class="skip-link" href="#maincontent">Skip to main</a>
    <div class="header header-fixed header-logo-center">
        <span (click)="BacktoSignup()" class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
    </div>
    <div class="page-content header-clear-medium">
        <form [formGroup]="loginForm" (ngSubmit)="loginsubmit()">
            <div class="titlearea">
                <div class="logoimg mb-4">
                    <img width="110" src="{{logo}}" alt="">
                </div>
                <span class="textcolor">Login</span>
                <p class="textcolor">{{tagline}}</p>
            </div>
            <p class="text-center text-white pt-3 m-0"></p>
            <div class="content login">
                <div class="input-style has-icon input-required pb-1">
                    <input type="name" placeholder="Email Address/Phone Number" formControlName="email">
                </div>
                <div class="formGroup input-style has-icon input-required pb-3">
                    <input type="password" placeholder="Password" id="password" formControlName="password">
                    <span (click)="lookpassword()">
                        <span *ngIf="showpassword" class="fa-solid fa-eye" style="margin-left: -30px; cursor: pointer;"></span>
                        <span *ngIf="!showpassword" class="fa-solid fa-eye-slash" style="margin-left: -30px; cursor: pointer;"></span>
                    </span>
                    
                </div>
                <span class="w-100 text-left d-block text-theme-color mb-3" (click)="RediectToForgetPassword()">Forgot
                    password?</span>
                <button type="submit" class="btn-block loginheadercolor textcolor">Sign in</button>
            </div>
            <!-- <div class="Continuebtn">
                <button type="submit" class="btn-block loginheadercolor textcolor">Sign in</button>
            </div>  -->
        </form>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
        <p class="color-white"> Loading... </p>
    </ngx-spinner>
</div>