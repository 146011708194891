<div id="page">
    <div class="header header-fixed header-logo-center">
        <span [routerLink]="['/dashboard']" data-back-button class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <h2 class="header-title textcolor">Coach Attendance Report</h2>
        <span (click)="onclick()" data-menu="menu-sidebar-left-1" class="header-icon header-icon-4 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
        </span>
    </div>
    <div class="page-content header-clear-medium" (click)="onremoveclick()">
        <div class="content">
            <div class="inputsection">
                <span class="input-title font-14">Select Center<span class="requird-field">*</span></span>
                <div class="input-style input-required">
                    <select [(ngModel)]="centerId" class="form-control" (change)=CenterChangeEvent($event)>
                        <option value="0" selected>Select</option>
                        <option *ngFor="let item of centerTypeList" value="{{item.Id}}">{{item.Name}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="inputsection">
                <span class="input-title font-14">Select Batch<span class="requird-field">*</span></span>
                <div class="input-style input-required">
                    <select class="form-control" [(ngModel)]="batchId" (change)='BatchChangeEvent($event)'>
                        <option value="0" selected>Select</option>
                        <option *ngFor="let item of batchList" value="{{item.Id}}">{{item.BatchName}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="inputsection">
                <span class="input-title font-14">Select Schedule<span class="requird-field">*</span></span>
                <div class="input-style input-required">
                    <select [(ngModel)]="scheduleId" class="form-control" (change)=ScheduleChangeEvent($event)>
                        <option value="0" selected>Select</option>
                        <option *ngFor="let item of scheduleList" value="{{item.Id}}">{{item.BatchName}}
                        </option>
                    </select>
                </div>
            </div>


            <div class="inputsection">
                <span class="input-title font-14">Select Coach<span class="requird-field">*</span></span>
                <div class="input-style input-required">
                    <select class="form-control" (change)=CoachChangeEvent($event)>
                        <option value="0">Select</option>
                        <option *ngFor="let item of coachList" value="{{item.UserId}}">{{item.UserName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="assessment">
            <mat-calendar #calendar [dateClass]="isSelected">
            </mat-calendar>
        </div>
    </div>
</div>