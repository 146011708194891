import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PopupinboxComponent } from './popupinbox/popupinbox.component';
import { CommonFunction } from '../service/commonfunction';
import { GetListService } from '../service/getlist.service';
import { PopupchatboxComponent } from './popupchatbox/popupchatbox.component';
@Component({
  selector: 'app-usermessages',
  templateUrl: './usermessages.component.html',
  styleUrls: ['./usermessages.component.scss']
})
export class UsermessagesComponent implements OnInit {
  UserMessageList: any = [];
  userdata: any;
  userId;
  academyId;
  batchId;
  roleId;
  isEditIcon: boolean = false;

  constructor(public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public formBuilder: UntypedFormBuilder,
    public commonFunction: CommonFunction,
    public getlistservice: GetListService,) { }

  ngOnInit(): void {
    this.userdata = this.commonFunction.GetUserData();
    if (this.userdata.RoleTypeId == 3) {
      this.isEditIcon = true;
    }  
    this.getUserMessageList();
    this.onremoveclick();
  }
  onclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.add('menu-active');
  }
  onremoveclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.remove('menu-active');
  }
  back() {
    this.router.navigate(['/dashboard'])
  }

  openPopupInbox(item) {

    const dialogRef = this.dialog.open(PopupinboxComponent, {
      width: '100%',
      height: '100%',
      data: { item }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public getUserMessageList() {
   
    this.spinner.show();
    this.academyId = this.userdata.AcademyId;
    this.userId = this.userdata.UserId;
    this.batchId = null;
    this.roleId = this.userdata.RoleTypeId;
    this.getlistservice
      .GetUserMessages(this.userId, this.roleId, this.batchId)
      .subscribe((response: any) => {
        this.UserMessageList = [];
        this.UserMessageList = response;
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
        });
  }

  profilepage(item) {
    const dialogRef = this.dialog.open(PopupchatboxComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'inboxmassage',
      data: { item }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
