import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { GetListService } from './service/getlist.service';
import { PostService } from './service/post.service';
import { CommonFunction } from './service/commonfunction';
import { BehaviorSubject, fromEvent, interval, Observable, Subscription } from 'rxjs'
import { environment } from 'src/environments/environment';
import { DeviceUUID } from 'device-uuid';
import { OneSignalService } from 'ngx-onesignal';
import { NotificationService } from 'src/app/service/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import $ from 'jquery'
import { Router } from '@angular/router';
import { openDB, deleteDB, wrap, unwrap } from 'idb';
import { IdbService } from './service/idb.service';
import { SwUpdate } from '@angular/service-worker';
import { Platform } from '@angular/cdk/platform';
import { Capacitor } from '@capacitor/core';
import { style } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;

  private readonly tagsSubscribe$ = new BehaviorSubject<{
    [key: string]: string;
  }>({});
  public readonly tags$ = this.tagsSubscribe$.asObservable();
  userdata: any;

  constructor(
    private loc: Location,
    private swupdate: SwUpdate,
    private platform: Platform,
    private idbService: IdbService,
    public getListService: GetListService,
    public postService: PostService,
    public commonfunction: CommonFunction,
    public readonly onesignal: OneSignalService,
    private notifyService: NotificationService,
    private spinner: NgxSpinnerService,
    public router: Router,
    @Inject(DOCUMENT) private document: Document

  ) {
    (window as any).ngxOnesignal = this.onesignal;
    this.onesignal.subscribe();
    this.swupdate.available.subscribe((event: any) => {
      // reload / refresh the browser
      this.swupdate.activateUpdate().then(() => document.location.reload());
    });
    // this.platform.backButton.subscribeWithPriority(10, () => {
    // });
  }

  hostName: string;
  data;
  hasUpdate = false;
  isnativeplatform
  ngOnInit() {
    this.isnativeplatform = Capacitor.isNativePlatform()
    // this.isnativeplatform=true;
    if(this.isnativeplatform){
      this.loadTheme('style-App.css');
    }
    else{
      this.loadTheme('style-Website.css');
    }
    
    let isadd = false;
    this.userdata = this.commonfunction.GetUserData();
    this.idbService.connectToIDB();
    let val = $('#userId').val();
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
    // check for platform update
    if (this.swupdate.isEnabled) {
      interval(60000).subscribe(() => this.swupdate.checkForUpdate().then(() => {
        // checking for updates
      }));
    }
    this.swupdate.available.subscribe(() => {
      this.hasUpdate = true;
      // this.notifyService.showInfo("New version available. Load New Version?", "");
      location.reload();
    });

    


    if (!val) {
      setInterval(() => {
        this.onesignal.subscribe();
        if ($('#userId').val() != undefined && $('#userId').val() != null && $('#userId').val() != "") {
          if (isadd == false) {
            if (this.userdata != null && this.userdata.UserId > 0 && window.navigator.onLine == true) {
              this.postService
                .InsertOneSignalId(this.userdata.UserId, this.userdata.DeviceId, $('#userId').val())
                .subscribe(
                  (response: any) => {
                  },
                  (error) => {
                  }
                );
            }
          }
          isadd = true;

        }
      }, 10000);
    }

    const angularRoute = this.loc.path();
    const url = window.location.href;
    const domainAndApp = url.replace(angularRoute, '');
    let myurl = domainAndApp.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];

    var uuid = new DeviceUUID().get();
    if (uuid != undefined && uuid != null && uuid != "") {
      this.commonfunction.SetDeviceId(uuid);
    }

    /**
       * Get the online/offline status from browser window
       */
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.idbService.getAllData("academyuserattendance").then((attendance: any) => {
        let userattendance = attendance;
        let todayattendance: any;
        if (userattendance.length > 0) {
          for (let index = 0; index < userattendance.length; index++) {
            const element = userattendance[index];

            this.backgroundsyncattendance(element, element.id);
          }
          // this.router.navigate(['/dashboard'])
          // setTimeout(() => {
          //   this.router.navigate(['/dashboard'])
          //     .then(() => {
          //       window.location.reload();
          //     });
          // }, 1000);
        }
        else {
          window.location.reload();
        }
      });
      // var AttendanceData = this.commonfunction.GetAttendanceStorageData();
      // if (AttendanceData != undefined) {
      //   this.backgroundsyncattendance();
      // }
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
    }));


  }
  loadTheme(cssFile:string){
    const headEl = this.document.getElementsByTagName('head')[0];


    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = cssFile;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${cssFile}`;

      headEl.appendChild(style);
    }
  }
  ngOnDestroy(): void {
    /**
    * Unsubscribe all subscriptions to avoid memory leak
    */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  backgroundsyncattendance(data, offlineid) {
    // var data = this.commonfunction.GetAttendanceStorageData();
    if (data != undefined) {
      this.spinner.show();
      this.postService
        .saveAttendance(data)
        .subscribe((response: any) => {
          if (response == null || response != null || response == undefined) {
            this.notifyService.showSuccess("Sync Attendance saved successfully!", "");
            this.idbService.deleteItems("academyuserattendance", offlineid);

            this.spinner.hide();
            // this.commonfunction.RemoveAttendanceData();
          }
          else {
            this.notifyService.showError("Unable to save attendance. Please try again later. Contact the admin if problem persists.", "");
          }
        },
          (error) => {
            if (window.navigator.onLine == false) {
              this.notifyService.showError("NO INTERNET CONNECTION DETECTED.", "");
              this.commonfunction.SetAttendanceStorageData(data);
            }
            else {
              this.notifyService.showError("Unable to save attendance. Please try again later. Contact the admin if problem persists.", "");
            }
            this.spinner.hide();
          });
    }
  }

  GetAcademyData(domain) {
    if (domain == 'localhost:4200') {
      domain = 'pwa.kridapp.com'
    }
    this.getListService
      .GetAcademyData(domain)
      .subscribe((response: any) => {
        this.data = response[0];
        localStorage.setItem("academyData", (JSON.stringify(this.data)));
      },
        (error) => {
        });
  }
}

