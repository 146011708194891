import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-popupfeedback',
  templateUrl: './popupfeedback.component.html',
  styleUrls: ['./popupfeedback.component.scss']
})
export class PopupfeedbackComponent implements OnInit {
  playername: any;
  attendanceFeedbackForm: UntypedFormGroup;
  StudentFeedBackArray: any = [];
  constructor(public dialogRef: MatDialogRef<PopupfeedbackComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.StudentFeedBackArray = this.data.StudentFeedBackArray;
    this.playername = this.data.PlayerName;
    this.forminit();
  }

  forminit() {
    this.attendanceFeedbackForm = this.formBuilder.group(
      {
        userid: ["", new UntypedFormControl],
        PrivateHomework: ["", new UntypedFormControl],
        PrivateDiet: ["", new UntypedFormControl],
        PrivateComments: ["", new UntypedFormControl],
        Temperature: ["", new UntypedFormControl]
      }
    );
    for (let i = 0; i < this.StudentFeedBackArray.length; i++) {
      if (this.StudentFeedBackArray[i].UserId == this.data.UserId) {
        ;
        this.attendanceFeedbackForm.setValue({
          "userid": this.data.UserId,
          "PrivateHomework": this.StudentFeedBackArray[i].Homework,
          "PrivateDiet": this.StudentFeedBackArray[i].Diet,
          "PrivateComments": this.StudentFeedBackArray[i].Comments,
          "Temperature": this.StudentFeedBackArray[i].Temperature
        });
      }
    }
  }

  closemodel() {
    this.dialogRef.close()
  }

  attendanceFeedbackFormSubmit() {
    for (let i = 0; i < this.StudentFeedBackArray.length; i++) {
      if (this.StudentFeedBackArray[i].UserId == this.attendanceFeedbackForm.value.userid) {
        this.StudentFeedBackArray[i] = {
          UserId: this.attendanceFeedbackForm.value.userid,
          Homework: this.attendanceFeedbackForm.value.PrivateHomework,
          Diet: this.attendanceFeedbackForm.value.PrivateDiet,
          Comments: this.attendanceFeedbackForm.value.PrivateComments,
          Temperature: this.attendanceFeedbackForm.value.Temperature,

          PrivateHomework: this.attendanceFeedbackForm.value.PrivateHomework,
          PrivateDiet: this.attendanceFeedbackForm.value.PrivateDiet,
          PrivateComments: this.attendanceFeedbackForm.value.PrivateComments,
        };
      }
    }
    this.dialogRef.close({ data: this.StudentFeedBackArray })
  }
}
