<div id="page">
    <!-- Header -->
    <div class="header header-fixed header-logo-center">
        <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <h2 class="header-title textcolor">Manage fees</h2>
        <span (click)="onclick(menuopenstatus)" data-menu="menu-sidebar-left-1"
            class="header-icon header-icon-4 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
        </span>
    </div>
    <!-- Header -->

    <!-- Main Content -->
    <div class="page-content header-clear-medium">
        <form [formGroup]="UserlistFormGroup">
            <div class="content">
                <div class="inputsection">
                    <span class="input-title font-14">Batch<span class="requird-field">*</span></span>
                    <select class="form-control" formControlName="BatchesId" (change)="BatchNameChange($event);">
                        <option value="">Select Batch</option>
                        <option *ngFor="let item of BatchesList" value="{{item.Id}}">{{item.BatchName}}</option>
                    </select>
                </div>
                <div class="inputsection">
                    <span class="input-title font-14">Schedule<span class="requird-field">*</span></span>
                    <select class="form-control" formControlName="ScheduleId" (change)="GetAcademyStudentlist($event);">
                        <option value="">Select Schedule</option>
                        <option *ngFor="let item of ScheduleList" value="{{item.Id}}">{{item.BatchName}}</option>
                    </select>
                </div>
            </div>
        </form>
        <div class="assessment" *ngIf="UserListCount>0">
            <ul class="formview pb-0">
                <li class="loginheadercolor text-white">
                    <div class="row">
                        <div class="col-50 light-gray">Name ({{UserListCount}})</div>
                        <div class="col-50 text-right">Outstanding Fees</div>
                    </div>
                </li>
                <li class="p-0">
                    <input type="text" [(ngModel)]="searchText" name="searchtext" class="userlist-search"
                        placeholder="Search">
                </li>
            </ul>
            <ul class="formview pt-0">
                <li *ngFor="let item of UserList| filter:searchText;index as i">
                    <div class="row" (click)="UserFeesDetails(item)">
                        <div class="col-75 light-gray">{{item.Name}} ({{item.Registrationnumber}})</div>
                        <ng-container *ngIf="item.OutStandingAmount!=0">
                        <div class="col-25 text-right red">{{item.OutStandingAmount}}</div>
                       </ng-container>
                       <ng-container *ngIf="item.OutStandingAmount==0">
                        <div class="col-25 text-right">{{item.OutStandingAmount}}</div>
                       </ng-container>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
        <p class="color-white"> Loading... </p>
    </ngx-spinner>
</div>