import { Component, OnInit } from '@angular/core';
import { GetListService } from 'src/app/service/getlist.service';
import { PostService } from 'src/app/service/post.service';
import { CommonFunction } from 'src/app/service/commonfunction';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Moment } from 'moment/moment';
import * as moment from 'moment';
import $ from 'jquery'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PopupbatchplanningdrillsComponent } from '../../popupbatchplanningdrills/popupbatchplanningdrills.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
  selector: 'app-create-batch-planning',
  templateUrl: './create-batch-planning.component.html',
  styleUrls: ['./create-batch-planning.component.scss']
})
export class CreateBatchPlanningComponent implements OnInit {
  daysSelected: any[] = [];
  displaydatalist: any[] = [];
  DrillNameList: any = [];
  DrillListCategory: any = [];
  CategoryList: any = [];
  VenueList: any = [];
  DrillList: any = [];
  scheduleDate: any;
  TempDrillList: any = [];
  PreventDrillList = [];
  GetData: any;
  Mode: any;
  selecteddatecheck: any;
  minDate: Moment;
  currentYear: any;
  selecteddrillsArray: any = [];
  val: any;
  id: any;
  element: any;
  catarray: any = [];
  catele: any;
  showcount: any = [];
  constructor(
    public getListService: GetListService,
    public commonFunction: CommonFunction,
    public postService: PostService,
    private datePipe: DatePipe,
    public router: Router,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService
  ) {

  }
  ngOnInit(): void {
    this.currentYear = moment().year
    this.minDate = moment([this.currentYear - 1, 0, 1]);
    if (window.history.state != undefined) {
      this.GetData = window.history.state;
      this.Mode = this.GetData.mode;
    }
    var dateselected = this.datePipe.transform(this.GetData.clickeddate, 'MM/dd/yyyy').toString();
    $('#ScheduleDate').val(dateselected);
    var date = new Date(this.GetData.clickeddate);
    var startDate = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
    this.GetDrillList(this.GetData.batchId, startDate, endDate, this.GetData.slotId);
    this.GetCategoryList();
    this.GetDrillListByBatchId();
    this.GetVenueByAcademyId();
  }

  GetDrillList(batchId, fromDate, toDate, slotId) {
    // debugger;
    slotId = (slotId != "") ? slotId : "";
    slotId = (slotId != undefined) ? slotId : "";
    batchId = (batchId != undefined) ? batchId : 0;
    this.selecteddatecheck = this.datePipe.transform(this.GetData.clickeddate, 'yyyy-MM-dd').toString()
    this.daysSelected = [];
    this.displaydatalist = [];
    this.getListService
      .GetDrillList(batchId, fromDate, toDate, slotId)
      .subscribe(
        (response: any) => {
          if (response.length > 0) {
            this.DrillListCategory = this.commonFunction.removeDuplicatesBy(x => x.FromDate, response);
            for (let j = 0; j < response.length; j++) {
              if (response[j].FromDate == this.selecteddatecheck) {
                this.DrillNameList.push({
                  DrillName: response[j].DrillName,
                  Sequence: j,
                  DrillId: response[j].DrillId,
                  CategoryId: response[j].CategoryId
                });
              }
              this.DrillNameList.sort((a, b) => 0 - (a.Sequence > b.Sequence ? -1 : 1));
            }
            if (this.DrillNameList.length > 0) {
              // console.log(this.DrillNameList);

              for (let i = 0; i < this.DrillNameList.length; i++) {

                this.element = this.DrillNameList[i].DrillId;
                // console.log(this.element);
                this.catele = this.DrillNameList[i].CategoryId;
                for (let k = 0; k < this.catarray.length; k++) {
                  if (this.catele != this.catarray[k]) {
                    this.catarray = []
                    this.showcount = [];
                  }
                }
                this.catarray.push(this.catele)
                // console.log(this.catarray);

                this.showcount.push(this.element)

                this.selecteddrillsArray.push(this.element);

                $('#spn_' + this.DrillNameList[i].CategoryId).text(this.showcount.length);
              }

            }
          }
        },
        (error) => {
        }
      );
  }
  getvalue(val) {
  }
  back() {
    this.router.navigate(['batch-planning-dates'], {
      state: {
        batchId: this.GetData.batchId,
        slotId: this.GetData.slotId
      }
    });
  }

  ChangeDate(e) {
    this.scheduleDate = e.target.value;
  }

  GetCategoryList() {
    var GetUserData = this.commonFunction.GetUserData();
    this.getListService
      .GetCategoryList(GetUserData.ParentAcademyId, this.GetData.batchId)
      .subscribe(
        (response: any) => {
          this.CategoryList = response;
          // console.log(this.CategoryList);

        },
        (error) => {
        }
      );
  }

  GetDrillListByBatchId() {
    var GetUserData = this.commonFunction.GetUserData();
    this.getListService
      .GetDrillListByBatchId(this.GetData.batchId)
      .subscribe(
        (response: any) => {
          this.DrillList = response;
          this.PreventDrillList = response;
        },
        (error) => {
        }
      );
  }
  GetVenueByAcademyId() {
    var GetUserData = this.commonFunction.GetUserData();
    this.getListService
      .GetVenueByAcademyId(GetUserData.ParentAcademyId, GetUserData.UserId)
      .subscribe(
        (response: any) => {
          this.VenueList = response;
        },
        (error) => {
        }
      );
  }
  openBatchPlanningModal(model): void {
    this.id = model.Id;
    const dialogRef = this.dialog.open(PopupbatchplanningdrillsComponent, {
      width: '100%',
      height: '100%',
      data: {
        drillslist: this.DrillList,
        preentdrills: this.PreventDrillList,
        category: model,
        selectedarrayfill: this.selecteddrillsArray
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result.drillsarryselected);
      // console.log(this.id);


      $('#spn_' + this.id).text(result.drillsarryselected.length);
      if (result != undefined) {
        let data = result.drillsarryselected;
        // console.log("data" + data);

        for (let i = 0; i < data.length; i++) {
          let b = parseInt(data[i]);
          if (this.selecteddrillsArray.length > 0) {
            if (this.selecteddrillsArray.indexOf(b) === -1) {
              this.selecteddrillsArray.push(b)
            }

          }
          else {
            this.selecteddrillsArray.push(b)
          }
        }

        let data1 = result.nodrillsarryselected;
        for (let i = 0; i < this.selecteddrillsArray.length; i++) {

          for (let j = 0; j < data1.length; j++) {
            let b = parseInt(data1[j]);
            if (this.selecteddrillsArray.length > 0) {
              if (this.selecteddrillsArray[i] === b) {
                this.selecteddrillsArray.splice(this.selecteddrillsArray.indexOf(b), 1);
                break;
              }

            }
            else {
              this.selecteddrillsArray.push(b)
            }
          }
        }
      }
    });

  }

  GetDrillListOnDate() {
    this.getListService
      .GetDrillListOnDate(this.GetData.batchId, this.GetData.items.FromDate, 0, this.GetData.slotId)
      .subscribe(
        (response: any) => {
          if (response.length > 0) {
            var element = <HTMLInputElement>document.getElementById("ScheduleDate");
            element.value = this.datePipe.transform(this.GetData.items.FromDate, 'MM/dd/yyyy').toString();
            this.scheduleDate = this.datePipe.transform(this.GetData.items.FromDate, 'MM/dd/yyyy').toString();
          }
        },
        (error) => {
        }
      );
  }

  CategoryChange(item) {
    var element = <HTMLInputElement>document.getElementById("Category_" + item.Id);
    if (element.checked == true) {

      if (this.TempDrillList.length > 0) {
        var FilterData = this.PreventDrillList.filter(x => x.DrillCategoryId == item.Id);
        for (let i = 0; i < FilterData.length; i++) {
          this.TempDrillList.push(FilterData[i]);
        }
      }
      else {
        var FilterData = this.PreventDrillList.filter(x => x.DrillCategoryId == item.Id);
        for (let i = 0; i < FilterData.length; i++) {
          this.TempDrillList.push(FilterData[i]);
        }

      }
      this.DrillList = [];
      this.DrillList = this.TempDrillList;
    }
    else {
      let count = 0;
      var GetCategoryAllcheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("getCategoryAllcheckBoxValue"));
      for (let i = 0; i < GetCategoryAllcheckbox.length; i++) {
        if (GetCategoryAllcheckbox[i].checked == true) {
          count = count + 1;
        }
      }

      if (count > 0) {
        var GetTempListArrayData = this.PreventDrillList;

        while (GetTempListArrayData.findIndex(e => e.DrillCategoryId === item.Id) >= 0) {
          GetTempListArrayData.splice(GetTempListArrayData.findIndex(f => f.DrillCategoryId === item.Id), 1);
        }
        this.DrillList = [];
        this.DrillList = GetTempListArrayData;
      }
      else {
        this.GetDrillListByBatchId();
      }
    }
  }
  spancall() {
    return 0;
  }
  CategoryChangeForEdit(item) {
    var element = <HTMLInputElement>document.getElementById("Category_" + item.Id);
    if (element.checked == true) {
      this.GetDrillListByBatchId();
      var FilterData = this.PreventDrillList.filter(x => x.DrillCategoryId == item.Id);
      for (let i = 0; i < FilterData.length; i++) {
        this.TempDrillList.push(FilterData[i]);
      }
      this.DrillList = [];
      this.DrillList = this.TempDrillList;
    }
    else {
      let count = 0;
      var GetCategoryAllcheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("getCategoryAllcheckBoxValue"));
      for (let i = 0; i < GetCategoryAllcheckbox.length; i++) {
        if (GetCategoryAllcheckbox[i].checked == true) {
          count = count + 1;
        }
      }


      if (count > 0) {
        var GetTempListArrayData = this.PreventDrillList;

        while (GetTempListArrayData.findIndex(e => e.DrillCategoryId === item.Id) >= 0) {
          GetTempListArrayData.splice(GetTempListArrayData.findIndex(f => f.DrillCategoryId === item.Id), 1);
        }
        this.DrillList = [];
        this.DrillList = GetTempListArrayData;
      }
      else {
        this.GetDrillListByBatchId();
      }
    }
  }

  SaveBatchPlanning() {
    var element = <HTMLInputElement>document.getElementById("ScheduleDate");
    this.scheduleDate = this.datePipe.transform(element.value, 'MM/dd/yyyy').toString();
    var GetUserData = this.commonFunction.GetUserData();
    let SelectedDrillsCheckBoxValue = "";
    SelectedDrillsCheckBoxValue = this.selecteddrillsArray.join(',');
    // console.log(this.selecteddrillsArray);

    let SelectedVenueCheckBoxValue = "";
    var GetVenueAllcheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("getVenueAllcheckBoxValue"));
    for (let i = 0; i < GetVenueAllcheckbox.length; i++) {
      if (GetVenueAllcheckbox[i].checked) {
        SelectedVenueCheckBoxValue += GetVenueAllcheckbox[i].value + ',';
      }
    }
    SelectedVenueCheckBoxValue = this.commonFunction.removeLastComma(SelectedVenueCheckBoxValue);
    var CreatedDate = new Date(this.datePipe.transform(new Date(), 'MM/dd/yyyy'));
    this.spinner.show();
    this.postService
      .SaveBatchPlanning(this.GetData.batchId, this.scheduleDate, SelectedDrillsCheckBoxValue, SelectedVenueCheckBoxValue, this.GetData.slotId, GetUserData.UserId, CreatedDate)
      .subscribe(
        (response: any) => {
          if (response > 0) {
            this.notifyService.showSuccess("Batch planning saved successfully.", "");
            setTimeout(() => {
              this.router.navigate(['batch-planning-dates'], {
                state: {
                  batchId: this.GetData.batchId,
                  slotId: this.GetData.slotId
                }
              });
            }, 1000);
            this.spinner.hide();
          }
        },
        (error) => {
          this.notifyService.showError("Unable to save batch planning. Please try again later. Contact the admin if problem persists.", "");
        }
      );
  }
}