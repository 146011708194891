import { PopupresonlistComponent } from 'src/app/attendance/popupresonlist/popupresonlist.component';
import { PopupcommonfeedbackComponent } from 'src/app/attendance/popupcommonfeedback/popupcommonfeedback.component';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GetListService } from '../../service/getlist.service';
import { CommonFunction } from '../../service/commonfunction';
import { PopupfeedbackComponent } from 'src/app/attendance/popupfeedback/popupfeedback.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { IdbService } from 'src/app/service/idb.service';

@Component({
  selector: 'app-privateattenstudentlist',
  templateUrl: './privateattenstudentlist.component.html',
  styleUrls: ['./privateattenstudentlist.component.scss']
})
export class PrivateattenstudentlistComponent implements OnInit {
  seletddateforattendance: any;
  public GetStudentList: any[];
  public studentCount: any;
  StudentFeedBackArray: any = [];
  LeaveTypeListSArray: any = [];
  LeaveShortName: any;
  GetData: any;
  userdata: any;
  userId: any;

  constructor(
    public dialogRef: MatDialogRef<PrivateattenstudentlistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public getListService: GetListService,
    public commonFunction: CommonFunction,
    private spinner: NgxSpinnerService,
    public idbService: IdbService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.GetData = this.data;
    this.userdata = this.commonFunction.GetUserData();
    this.userId = this.userdata.UserId;
    this.GetStudentListForAttendance();
    this.seletddateforattendance = this.datePipe.transform(this.GetData.AttendanceDate, 'MMM dd,yy');

  }

  closemodel() {
    this.dialogRef.close();
    this.spinner.hide();
  }
  opencommonfeedbackModal(): void {
    let StudentModel = {
      StudentFeedBackArray: this.StudentFeedBackArray,
      LeaveTypeListSArray: this.LeaveTypeListSArray
    }
    this.spinner.hide();

    const dialogRef = this.dialog.open(PopupcommonfeedbackComponent, {
      width: '100%',
      height: '100%',
      data: {
        studentList: StudentModel,
        studentCount: this.studentCount,
        AttendanceDate: this.GetData.AttendanceDate,
        BatchId: this.GetData.BatchId,
        Latitude: this.GetData.Latitude,
        Longitude: this.GetData.Longitude

      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  public CheckOrUnCheck(e) {
    ;
    var GetAllcheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("studentcheckboxList"));
    if (e.target.checked == true) {
      for (let i = 0; i < GetAllcheckbox.length; i++) {
        GetAllcheckbox[i].checked = true;
      }
      let x = document.querySelectorAll('.AllIsStudentStatus')
      for (let j = 0; j < x.length; j++) {

        x[j].removeAttribute('hidden');
      }

      let y = document.querySelectorAll('.AllIsReasonStatus')
      for (let k = 0; k < y.length; k++) {
        y[k].setAttribute('hidden', 'true');
      }

    }
    else {
      e.target.checked = false;
      for (let i = 0; i < GetAllcheckbox.length; i++) {
        GetAllcheckbox[i].checked = false;
      }
      let x = document.querySelectorAll('.AllIsStudentStatus')
      for (let j = 0; j < x.length; j++) {
        x[j].setAttribute('hidden', 'true');
      }

      let y = document.querySelectorAll('.AllIsReasonStatus')
      for (let k = 0; k < y.length; k++) {
        y[k].removeAttribute('hidden');
      }

    }
  }

  openFeedbackModal(UserId: any, name: any): void {
    const dialogRef = this.dialog.open(PopupfeedbackComponent, {
      width: '100%',
      height: '100%',
      data: {
        UserId: UserId,
        PlayerName: name,
        StudentFeedBackArray: this.StudentFeedBackArray
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.StudentFeedBackArray = result.data;
    });
  }

  openresonModal(UserId: any): void {
    const dialogRef = this.dialog.open(PopupresonlistComponent, {
      width: '100%',
      height: '100%',
      data: {
        UserId: UserId,
        LeaveTypeListSArray: this.LeaveTypeListSArray
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.LeaveTypeListSArray = result.data;
      this.LeaveShortName = result.data.LeaveShortName;
      let GetUserIdByDom = (<HTMLInputElement>document.getElementById('IsReasonButton_' + result.Id));
      GetUserIdByDom.value = result.LeaveShortName;
    });
  }

  ChangeIcon(id, event) {
    var studentcheckedcheckboxcount = (<HTMLInputElement[]><any>document.getElementsByClassName("studentcheckboxcount"));
    let count = 0;
    for (var i = 1; i < studentcheckedcheckboxcount.length; i++) {
      if (studentcheckedcheckboxcount[i].type == "checkbox" && studentcheckedcheckboxcount[i].checked == true) {
        count++;
      }
    }

    var studentCount = this.studentCount;
    let eventtruefalse = (event.checked == true || event.checked == false) ? event.checked : event.target.checked;
    if (eventtruefalse == true) {
      //event.target.checked = true;
      document.getElementById("IsReasonStatus_" + id).hidden = true;
      document.getElementById("IsStudentStatus_" + id).hidden = false;
      if (count == studentCount) {
        var element = <HTMLInputElement>document.getElementById("selectAllCheck");
        element.checked = true;
      }
      else {
        var element = <HTMLInputElement>document.getElementById("selectAllCheck");
        element.checked = false;
      }

      for (let i = 0; i < this.LeaveTypeListSArray.length; i++) {
        if (this.LeaveTypeListSArray[i].UserId == id) {
          this.LeaveTypeListSArray[i] = {
            UserId: this.LeaveTypeListSArray[i].UserId,
            Id: 0,
            LeaveShortName: ""
          };
        }
      }
    }
    else {
      //event.target.checked = false;
      document.getElementById("IsReasonStatus_" + id).hidden = false;
      document.getElementById("IsStudentStatus_" + id).hidden = true;
      if (count != studentCount) {
        var element = <HTMLInputElement>document.getElementById("selectAllCheck");
        element.checked = false;
      }
      else {
        var element = <HTMLInputElement>document.getElementById("selectAllCheck");
        element.checked = true;
      }
      for (let i = 0; i < this.StudentFeedBackArray.length; i++) {
        if (this.StudentFeedBackArray[i].UserId == id) {
          this.StudentFeedBackArray[i] = {
            BatchId: this.StudentFeedBackArray[i].BatchId,
            UserId: this.StudentFeedBackArray[i].UserId,
            Homework: "",
            Diet: "",
            Comments: "",
            Temperature: this.StudentFeedBackArray[i].Temperature
          };
        }
      }
      for (let i = 0; i < this.LeaveTypeListSArray.length; i++) {
        if (this.LeaveTypeListSArray[i].UserId == id) {
          this.LeaveTypeListSArray[i] = {
            UserId: this.LeaveTypeListSArray[i].UserId,
            Id: 1,
            LeaveShortName: "A"
          };
        }
      }
    }
  }

  GetStudentListForAttendance() {
    this.spinner.show();
    this.getListService
      .GetUserListOfCoach(this.userId)
      .subscribe((response: any) => {
        this.GetStudentList = response;
        console.warn(this.GetStudentList)
        this.studentCount = response.length;
        let count = 0;
        for (let i = 0; i < response.length; i++) {
          if (response[i].LeaveTypeId == 0) {
            count++;
          }
        }

        if (this.studentCount == count) {
          var element = <HTMLInputElement>document.getElementById("selectAllCheck");
          element.checked = true;
        }
        else {
          var element = <HTMLInputElement>document.getElementById("selectAllCheck");
          element.checked = false;
        }

        for (let i = 0; i < response.length; i++) {
          this.StudentFeedBackArray.push({
            BatchId: response[i].AcademyBatchId,
            UserId: response[i].UserId,
            Homework: response[i].PrivateHomework,
            Diet: response[i].PrivateDiet,
            Comments: response[i].PrivateComments,
            Temperature: response[i].Temperature,

            PrivateHomework: response[i].PrivateHomework,
            PrivateDiet: response[i].PrivateDiet,
            PrivateComments: response[i].PrivateComments,


          });

          this.LeaveTypeListSArray.push({
            UserId: response[i].UserId,
            Id: response[i].LeaveTypeId,
            LeaveShortName: response[i].LeaveShortName
          });
        }
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
        });
  }
}
