import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonFunction } from '../../service/commonfunction';
import { GetListService } from '../../service/getlist.service';
import { PostService } from '../../service/post.service';

@Component({
  selector: 'app-popupchatbox',
  templateUrl: './popupchatbox.component.html',
  styleUrls: ['./popupchatbox.component.scss']
})
export class PopupchatboxComponent implements OnInit {
  UserName;
  ConversationId;
  Id;
  DateSent;
  UserAllMessageList: any;
  ChatFormGroup: UntypedFormGroup;
  compose;
  isCompose = true;
  childUserId = 0;
  hdn_StudentUserId;
  drp_studentList;
  userId;
  roleId;
  txtmsg;
  convid;
  userdata;
  menuopenstatus: boolean = true;
  constructor(public dialogRef: MatDialogRef<PopupchatboxComponent>,
    public route: ActivatedRoute,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public datePipe: DatePipe,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public formBuilder: UntypedFormBuilder,
    public commonFunction: CommonFunction,
    public getlistservice: GetListService,
    public postservice: PostService,
    public fb: UntypedFormBuilder,) { }

  ngOnInit(): void {
    this.initForm();
    this.ConversationId = this.data.item.ConversationId;
    this.Id = this.data.item.Id;
    this.DateSent = this.data.item.DateSent;
    this.userdata = this.commonFunction.GetUserData();
    this.roleId = this.userdata.RoleTypeId;
    this.userId = this.userdata.UserId;
    this.AllMessages();
  }

  onclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.add('menu-active');
  }
  onremoveclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.remove('menu-active');
  }
  initForm() {
    this.ChatFormGroup = this.formBuilder.group(
      {
        textmessage: new UntypedFormControl(''),
      });
  }
  get f() { return this.ChatFormGroup.controls; }

  public AllMessages() {
    this.UserAllMessageList = []
    this.ConversationId;// = this.data.item.ConversationId;
    this.Id;// = this.data.item.Id;
    // if (this.Id != undefined && this.Id != null && this.Id != "" && this.ConversationId != undefined && this.ConversationId != null && this.ConversationId != "") {

      this.getlistservice
        .GetAllMessages(this.Id, this.ConversationId)
        .subscribe((response: any) => {

          this.UserAllMessageList = response;
          this.UserName = this.data.item.FirstName;
          this.spinner.hide();
        },
          (error) => {
            this.spinner.hide();
          });
    // }
  }

  closemodel() {
    this.router.navigate(['/usermessages'])
      .then(() => {
        window.location.reload();
      });
    this.dialogRef.close();
  }

  MessagesSubmit(compose) {
    this.isCompose = compose;
    this.txtmsg = this.ChatFormGroup.controls['textmessage'].value;
    this.ChatFormGroup.setValue({ textmessage: '' });
    if (this.isCompose) {
      if (this.txtmsg != '') {
        this.postservice
          .SendMessage(this.userId, this.roleId, this.txtmsg, this.ConversationId, this.childUserId)
          .subscribe((response: any) => {
            this.ConversationId = response;
            this.ngOnInit()
           // this.AllMessages();
            this.txtmsg = "";
          },
            (error) => {
              this.spinner.hide();
            });
      }
    }
    else {
      if (this.txtmsg != '') {
        this.postservice
          .SendMessage(this.userId, this.roleId, this.txtmsg, this.ConversationId, this.childUserId)
          .subscribe((response: any) => {
            this.ConversationId = response;
            this.spinner.hide();
            this.AllMessages();
          },
            (error) => {
              this.spinner.hide();
            });
      }
    }
  }
}

