import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GetListService } from '../../service/getlist.service';
import { CommonFunction } from '../../service/commonfunction';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { EditTrainingModelComponent } from '../edit-training-model/edit-training-model.component';
import { PostService } from 'src/app/service/post.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/service/notification.service';
@Component({
  selector: 'app-player-training',
  templateUrl: './player-training.component.html',
  styleUrls: ['./player-training.component.scss']
})
export class PlayerTrainingComponent implements OnInit {
  seletddateforattendance: any;
  GetStudentList: any = [];
  studentCount: any;
  StudentTrainingArray: any = [];
  LeaveTypeListSArray: any = [];
  studenttraining: any = [];
  GetPlayersData: any = [];
  test: any = [];
  icon: any = [];
  LeaveShortName: any;
  comment: any;
  GetData: any;
  show;
  user;
  constructor(
    public dialogRef: MatDialogRef<PlayerTrainingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public getListService: GetListService,
    public commonFunction: CommonFunction,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private postservice: PostService,
    public router: Router,
    private notifyService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.studentlist();
  }


  studentlist() {
    this.spinner.show();

    var data = {
      academyId: this.data.AcademyId,
      batchIds: this.data.batchId,
      scheudleDate: this.datePipe.transform(this.data.Date, 'MM/dd/yyyy'),
      slot: this.data.slot,
      setNo: this.data.set,
      drillId: this.data.drillId
    };
    // console.log(data);
    
    this.getListService
      .GetBatchesUsersTraining(data)
      .subscribe((response: any) => {
        // console.log(response);
        this.GetStudentList = response;
        this.studentCount = response.length;


        for (let i = 0; i < response.length; i++) {
          this.StudentTrainingArray.push({
            BatchId: response[i].AcademyBatchId,
            AcademyUserId: response[i].AcademyUserId,
            weight: response[i].weight,
            repetition: response[i].repetition,
            setNo: response[i].setNo,
            comment: response[i].Comment
          });

          setInterval(() => {
            this.timer(response[i].AcademyUserId);
          }, 50)
        }
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
        });


  }

  // editmode(data []) {

  //   setTimeout(function () {
  //     debugger
  //     this.test = (<HTMLInputElement[]><any>document.getElementsByClassName("studentcheckboxcount"));
  //     console.log(this.test)
  //     for (var i = 0; i < this.test.length; i++) {
  //       if (this.GetStudentList[i].Id > 0 && this.GetStudentList[i].weight == 0) {
  //         this.test[i].checked = false;
  //       }
  //     }
  //   }, 2000);
  // }

  // public CheckOrUnCheck(e) {

  //   var GetAllcheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("studentcheckboxList"));
  //   if (e.target.checked == true) {
  //     for (let i = 0; i < GetAllcheckbox.length; i++) {
  //       GetAllcheckbox[i].checked = true;
  //     }
  //     let x = document.querySelectorAll('.AllIsStudentStatus')
  //     for (let j = 0; j < x.length; j++) {
  //       x[j].setAttribute('hidden', 'true');
  //     }

  //   }
  //   else {
  //     e.target.checked = false;
  //     for (let i = 0; i < GetAllcheckbox.length; i++) {
  //       GetAllcheckbox[i].checked = false;
  //     }
  //     let x = document.querySelectorAll('.AllIsStudentStatus')
  //     for (let j = 0; j < x.length; j++) {
  //       x[j].removeAttribute('hidden');
  //     }

  //   }
  // }

  ChangeIcon(event, id) {


    var player = document.getElementById("weight_" + id);
    player.textContent = "0";
    var player1 = document.getElementById("repetition_" + id);
    player1.textContent = "0";
  }

  OpenEditeModel(UserId, weight, repetition, name) {
    const dialogRef = this.dialog.open(EditTrainingModelComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'custom-modalbox',
      data: {
        userId: UserId,
        player: name,
        set: this.data.set,
        weight: document.getElementById("weight_" + UserId).innerHTML,
        repetition: document.getElementById("repetition_" + UserId).innerHTML,
        Comment: (<HTMLInputElement>document.getElementById('comment_' + UserId)).innerHTML,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updateRowData(result);
    });
  }
  updateRowData(data) {
    var player = document.getElementById("weight_" + data.userId);
    player.textContent = data.Weight;
    var player1 = document.getElementById("repetition_" + data.userId);
    player1.textContent = data.Repetition;
    var player2 = document.getElementById("comment_" + data.userId);
    player2.textContent = data.Comment;

    this.comment = player2.textContent;

    var getallcmt = document.getElementsByClassName("commentbtn");
    var cmtbtn = document.getElementById("CommentBtn_" + data.userId)
    if (this.comment != ' ') {
      cmtbtn.hidden = false;
    }
    else {
      cmtbtn.hidden = true;
    }
  }
  closemodel() {
    this.dialogRef.close();
  }

  Weight(event) {

    for (var i = 0; i < this.studentCount; i++) {
      (<HTMLInputElement[]><any>document.getElementsByClassName("weight"))[i].innerHTML = event.target.value;
    }
    // console.log(event.target.value)
  }

  Repetition(event) {
    for (var i = 0; i < this.studentCount; i++) {
      (<HTMLInputElement[]><any>document.getElementsByClassName("repetition"))[i].innerHTML = event.target.value;
    }
  }

  save() {
    var todayDate = this.datePipe.transform(this.data.Date, 'MM/dd/yyyy');
    var GetUserData = this.commonFunction.GetUserData();

    for (var i = 0; i < this.studentCount; i++) {
      this.GetPlayersData.push({
        academyUserId: (<HTMLInputElement[]><any>document.getElementsByClassName("userid"))[i].value,
        weight: (<HTMLInputElement[]><any>document.getElementsByClassName("weight"))[i].innerHTML,
        repetition: (<HTMLInputElement[]><any>document.getElementsByClassName("repetition"))[i].innerHTML,
        isChecked: (<HTMLInputElement[]><any>document.getElementsByClassName("studentcheckboxcount"))[i].checked,
        setNo: this.data.set,
        createdOn: todayDate,
        modifiedBy: null,
        modifiedOn: null,
        academybatchplanningdrillsId: this.GetStudentList[i].Id,
        batchPlanningDrillSetId: null,
        AcademySportsDrillId: this.data.drillId,
        Createdby: GetUserData.UserId,
        Comment: (<HTMLInputElement[]><any>document.getElementsByClassName("comment"))[i].innerHTML
      });
    }

    // console.log(this.GetPlayersData);


    for (let i = 0; i < this.GetPlayersData.length; i++) {
      if (this.GetPlayersData[i].isChecked == false) {
        this.GetPlayersData.splice(i--, 1);
      }
    }

    this.postservice.TrainingDataPost(this.GetPlayersData).subscribe((response: any) => { })

    this.GetPlayersData.length = 0

    this.notifyService.showSuccess("Set saved successfully!", "")

    this.dialogRef.close();
  }

  timer(id) {
    var cmtbtn = document.getElementById("CommentBtn_" + id)
    var comment = (<HTMLInputElement>document.getElementById("comment_" + id)).innerHTML
    // console.log(this.StudentTrainingArray.Comment);

    if (comment != '') {
      cmtbtn.hidden = false;
    }
    else {
      cmtbtn.hidden = true;
    }
  }

}

