<div id="page">
    <!-- Header -->
    <div class="header header-fixed header-logo-center">
        <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <h2 class="header-title textcolor">Select Batch For Training</h2>
        <span class="header-icon header-icon-4 textcolor right15" (click)="opendrillpopup()">Next</span>
    </div>
    <!-- Header -->
    <div class="page-content detail header-clear-medium">
        <div class="card batchlist card-style" *ngFor="let item1 of GetBatchesListNew; index as i">
            <div class="content title relative">
                <a data-menu="ad-timed-2" class="w-100"><strong>{{item1.BatchName}}</strong></a>
            </div>
            <ng-container *ngFor="let item of GetScheduleList; index as i">
                <ng-container *ngIf="item1.Id==item.ParentBatchId">
                    <label class=" pb-3 content" (click)="scheduleDivclick(item.Id)">
                        <div class="d-flex align-items-center" id="checkboxdiv">
                            <ng-container *ngIf="item.checked==true">
                                <input type="checkbox" checked class="getAllcheckBoxValue" name="{{item.BatchName}}"
                                    value={{item.Id}} id="schchchk_{{item.Id}}" />
                                {{item.BatchName}}
                            </ng-container>
                            <ng-container *ngIf="item.checked==false">
                                <div class="container w-100">
                                    <div class="d-flex align-items-center">
                                        <input type="checkbox" class="mr-2 getAllcheckBoxValue" name="{{item.BatchName}}"
                                            value={{item.Id}} id="schchchk_{{item.Id}}" />
                                        {{item.BatchName}}
                                    </div>
                                    <div id="DropDown_{{item.Id}}" class="dropdown" hidden style="padding-left: 30px;">
                                        <select name="Batch" id="slotvalue" (change)="selectChangeHandler($event)"
                                            class="form-control">
                                            <option *ngFor="let item of slotlist" value="{{item}}">
                                                {{item}}</option>
                                        </select>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </label>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>