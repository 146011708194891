import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-sortfilter',
  templateUrl: './sortfilter.component.html',
  styleUrls: ['./sortfilter.component.scss']
})
export class SortfilterComponent implements OnInit {

  sortfiltervalue:any;
  constructor( public dialogRef: MatDialogRef<SortfilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
  }

  AtoZclick(event){
    this.sortfiltervalue = event.target.value;
    this.dialogRef.close({ data: this.sortfiltervalue })
  }

  Inquirydateclick(event){
    this.sortfiltervalue = event.target.value;
    this.dialogRef.close({ data: this.sortfiltervalue })

  }

  Inquirystatusclick(event){
    this.sortfiltervalue = event.target.value;
    this.dialogRef.close({ data: this.sortfiltervalue })
  }

}
