import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonFunction } from 'src/app/service/commonfunction';
import { GetListService } from 'src/app/service/getlist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import $ from 'jquery'
import { PopupviewtransactionComponent } from '../popupviewtransaction/popupviewtransaction.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PopupfilterfeesdetailsComponent } from '../popupfilterfeesdetails/popupfilterfeesdetails.component';
import { AlertComponent } from 'src/app/AlertPopup/alert/alert.component';
import { SimpleModalService } from 'ngx-simple-modal';
@Component({
  selector: 'app-userfeesdetails',
  templateUrl: './userfeesdetails.component.html',
  styleUrls: ['./userfeesdetails.component.scss']
})
export class UserfeesdetailsComponent implements OnInit {
  strrequired: "";
  menuopenstatus: boolean = true;
  UserDetails: any;
  UserName: any = "";
  diffDays: any;
  /*Next Page Parameter Start */
  AcademyUserId: any;
  FeesTypeId: any;
  FeesName: any;
  DisplayUserName: any = "";
  Options: any;
  BathchId: any;
  ScheduleId: any;
  StatusId: any;
  /* Next Page Parameter End*/
  newPaymentButton = false;
  payButton = false;
  OutStandingAmount: any;
  OutStadning = false;
  isViewAll = false;
  feesTypeId: string = '-1';
  DefaultFeesTypeId: string = '-1';
  FilterStatus: boolean = false;
  counter = 0;
  RecentPaymentsDetails: any[];
  NoFound: boolean = false;
  currencysymbol: any;
  constructor(
    public getlistservice: GetListService,
    public commonFunction: CommonFunction,
    public route: ActivatedRoute,
    public router: Router,
    public datePipe: DatePipe,
    public dialog: MatDialog,
    public SimpleModalService: SimpleModalService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    // console.log(window.history.state);
    
    if (window.history.state.UserListData != undefined) {
      this.UserDetails = window.history.state.UserListData;
      this.GetStudentFeesDetailNew(this.UserDetails.UserId, window.history.state.scheduleId);
      this.UserName = this.UserDetails.Name;
      this.GetUserAdmissionDetails();
      /* Fill Next page param */
      this.AcademyUserId = this.UserDetails.UserId;
      this.DisplayUserName = this.UserDetails.Name;
      this.BathchId = window.history.state.batchId;
      this.ScheduleId = window.history.state.scheduleId;
      var UserData = this.commonFunction.GetUserData();
      this.currencysymbol = UserData.CountryCurrencySymbol;
    }
  }
  onclick(menuopenstatus) {
    if (menuopenstatus == true) {
      $("body").addClass("modal-open");
      $('#menu-sidebar-left-1').addClass('menu-active')
      this.menuopenstatus = false;
    }
    else {
      $("body").removeClass("modal-open");
      $('#menu-sidebar-left-1').removeClass('menu-active');
      this.menuopenstatus = true;
    }

  }



  onremoveclick() {
    $("body").removeClass("modal-open");
    $('#menu-sidebar-left-1').removeClass('menu-active')
  }

  GetStudentFeesDetailNew(academyUserId, scheduleId) {
    this.getlistservice
      .GetStudentFeesDetailNew(academyUserId, scheduleId)
      .subscribe((response: any) => {
        if (response.length > 0) {
          var totalOutStandingAmt = 0;
          var totalPaidAmount = 0;
          for (let i = 0; i < response.length; i++) {
            var _paidAmount = response[i].PaidAmount;
            var _totalOutStanding = response[i].OutStandingAmount;
            if (_paidAmount != null && _paidAmount != "")
              totalPaidAmount = totalPaidAmount + parseInt(_paidAmount);
            totalOutStandingAmt = totalOutStandingAmt + Math.round(_totalOutStanding);
          }

          if (totalOutStandingAmt > 0) {
            this.payButton = true;
            this.OutStandingAmount = totalOutStandingAmt;
            this.OutStadning = true;
          } else {
            this.newPaymentButton = true;
          }
        }
        else {
          this.payButton = true;
        }
        this.GetRecentPaymentsDetails();

      },
        (error) => {
        });

  }

  GetUserAdmissionDetails() {
    this.getlistservice
      .GetUserAdmissionDetails(this.UserDetails.UserId)
      .subscribe((response: any) => {

        if (response != null) {
          if (response.ToDate != null && response.ToDate != "" && response.ToDate != undefined) {
            var oneDay = 24 * 60 * 60 * 1000;
            var firstDate = new Date();
            var secondDate = new Date(response.ToDate);
            if (firstDate > secondDate) {
              this.diffDays = 0;
            }
            else {
              this.diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
            }

          }
        }

        this.cdr.detectChanges()



      },
        (error) => {
        });
  }

  ViewPaymentsChange(feesTypeId) {
    this.feesTypeId = feesTypeId;
    this.FilterStatus = true;
    this.GetRecentPaymentsDetails();
  }

  GetRecentPaymentsDetails() {
    this.getlistservice
      .GetRecentPaymentsDetails(this.UserDetails.UserId, this.DefaultFeesTypeId)
      .subscribe((response: any) => {
        this.RecentPaymentsDetails = response;
        if (response.length > 0) {
          if (this.FilterStatus == true) {
            // if (this.feesTypeId == "0") {
              var RecentPaymentsDetails = this.RecentPaymentsDetails.filter(x => x.AcademyFeesTypeId == this.feesTypeId);
              this.RecentPaymentsDetails = [];
              this.RecentPaymentsDetails = RecentPaymentsDetails;
              if (RecentPaymentsDetails.length > 0) {
                this.NoFound = false;
              }
              else {
                this.NoFound = true;
              }
            // }
            // else if (this.feesTypeId == "853") {
            //   var RecentPaymentsDetails = this.RecentPaymentsDetails.filter(x => x.FeesName == "Registration fees");
            //   this.RecentPaymentsDetails = [];
            //   this.RecentPaymentsDetails = RecentPaymentsDetails;
            //   if (RecentPaymentsDetails.length > 0) {
            //     this.NoFound = false;
            //   }
            //   else {
            //     this.NoFound = true;
            //   }
            // }
            // else {
            //   var RecentPaymentsDetails = this.RecentPaymentsDetails.filter(x => x.FeesName == "Kit fees");
            //   this.RecentPaymentsDetails = [];
            //   this.RecentPaymentsDetails = RecentPaymentsDetails;
            //   if (RecentPaymentsDetails.length > 0) {
            //     this.NoFound = false;
            //   }
            //   else {
            //     this.NoFound = true;
            //   }
            // }
          }
          else {
            this.RecentPaymentsDetails = [];
            this.RecentPaymentsDetails = response;
          }
        }
        else {
          this.RecentPaymentsDetails = response;
          this.isViewAll = false;
          this.NoFound = true;
        }

      },
        (error) => {
        });
  }

  back() {
    if (window.history.state.Redirect == "userlist") {
      this.router.navigate(['userlist'], {
        state: { batchId: window.history.state.batchId, scheduleId: window.history.state.scheduleId, Redirect: window.history.state.Redirect }
      });
    }
    else {
      this.router.navigate(['studentprofile'], {
        state: {
          example: window.history.state.UserListData,
          val: window.history.state
        }
      });
    }
  }

  ViewClick(Status) {
    this.isViewAll = Status;
    this.GetRecentPaymentsDetails();
  }

  opendotpopup(param) {
    const dialogRef = this.dialog.open(PopupviewtransactionComponent, {
      width: '100%',
      height: '100%',
      data: { val: param }
    });
  }

  openpopupfilterfeesdetails() {
    const dialogRef = this.dialog.open(PopupfilterfeesdetailsComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'custom-modalbox',
      data: { val: "" }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ViewPaymentsChange(result.Id);
    });

  }
  OnclickSelectdFees() {
    var getUserDatathis = this.commonFunction.GetUserData();
    this.getlistservice.getAcademyPackageExpire(getUserDatathis.ParentAcademyId).subscribe(
      (response: any) => {
        if (response.PackageErrorMessage != "") {
          this.onremoveclick();
          this.strrequired = response.PackageErrorMessage;
          if (this.strrequired != "") {
            this.SimpleModalService.addModal(AlertComponent, { title: getUserDatathis.SelectAcademyName, message: this.strrequired });
          }
        }
        else {
          this.router.navigate(['studentfeestype'], {
            state: {
              BathchId: this.BathchId,
              ScheduleId: this.ScheduleId,
              DisplayUserName: this.DisplayUserName,
              AcademyUserId: this.AcademyUserId,
              batchId: window.history.state.batchId, 
              scheduleId: window.history.state.scheduleId,
              Redirect: window.history.state.Redirect,
              UserListData: window.history.state.UserListData
            }
          });
        }
      },
      (error) => {
      }
    );
  }
}
