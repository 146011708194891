<div id="page">
    <div class="page-content header-clear-medium pt-0" *ngIf="IsBooking">
        <a class="skip-link" href="#maincontent">Skip to main</a>
        <div class="titlearea">
            <div class="logoimg mr-3">
                <img width="100" id="logomain" src="{{logo}}" alt="">
            </div>
            <span class="textcolor">{{academyName}}</span>
            <!-- <p class="textcolor mb-0">{{tagline}}</p> -->
        </div>
        <div class="titlebar">
            <h1 class="m-0">Pay & Play</h1>
        </div>
        <div class="titlebar boxsport">
            <span>
                <p class="font20">Facilities are available for booking:</p>
                <ul>
                    <li *ngFor="let item1 of VenueList1">
                        <div (click)="BookingScreen(item1)">
                            <span>{{item1.VenueName}}</span>
                            <div class="overlay"></div>
                            <div *ngIf="item1.VenueImg == null">
                                <img src="../../../assets/images/ground.png" width="55" alt="" />
                            </div>
                            <div *ngIf="item1.VenueImg != null">
                                <img src="{{item1.VenueImg}}" width="55" alt="" />
                            </div>
                        </div>
                    </li>
                </ul>
                <ul>
                    <li *ngFor="let item2 of VenueList2">
                        <div (click)="BookingScreen(item2)">
                            <span>{{item2.VenueName}}</span>
                            <div class="overlay"></div>
                            <div *ngIf="item2.VenueImg == null">
                                <img src="../../../assets/images/ground.png" width="55" alt="" />
                            </div>
                            <div *ngIf="item2.VenueImg != null">
                                <img src="{{item2.VenueImg}}" width="55" alt="" />
                            </div>     
                        </div>
                    </li>
                </ul>
                <!-- <p *ngIf='VenueListcount>4' class="font20 mt-3 mb-0"><a (click)="openVenueBooking()">Click here for other facilities...</a>
                </p> -->
                <div>
                    <button type="button" (click)="openVenueBooking()" class="btn btn-primary fullbtncss" mdbRipple>Click here to see All Venues</button>
                </div>
            </span>
        </div>
        <div class="content">
            <div class="space">
                <!-- <div class="multioption">
                    <div></div><span class="text-center">Or</span>
                    <div></div>
                </div> -->

                <button type="button" (click)="openLogin()"
                    class="mb-3 btn-block loginheadercolor textcolor">Login</button>
                <button type="button" (click)="openOTPpopup()" *ngIf="isloginwithOTP"
                    class="mb-3 btn-block loginheadercolor textcolor mt-0">Login with OTP</button>

                <!-- <button type="button" (click)="openVenueBooking()" *ngIf="IsPlayAndPlay"
                    class="mb-3 btn-block loginheadercolor textcolor mt-0">Pay & Play</button> -->

                <div class="contact-prowered mb-2" *ngIf="!IsReseller">
                    <a href="https://www.kridapp.com/" target="_blank"><span class="pull-left font-14">Contact
                            us</span></a>
                    <span class="pull-right font-14">Powered by Kridapp</span>
                </div>
            </div>
            <div *ngIf="!isNativePlatform">
                <div *ngIf="isAndroid">
                    <div class="footer-nav" *ngIf="isFooter=='1'">
                        <p (click)="addToHomeScreen()" id="img_homescreen" [innerHTML]="androidhtml"></p> <span
                            (click)="close()"><img width="12" height="12"
                                src="../../../assets/images/icons/hometime.png" alt="" /></span>
                    </div>
                </div>
                <div *ngIf="isIOS">
                    <div class="footer-nav" *ngIf="isFooter=='1'">
                        <p id="img_homescreenIOS" [innerHTML]="ioshtml"> </p>
                        <span (click)="close()"><img width="12" height="12"
                                src="../../../assets/images/icons/hometime.png" alt="" /></span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="page-content header-clear-medium pt-0" *ngIf="!IsBooking">
        <a class="skip-link" href="#maincontent">Skip to main</a>
        <div class="titlearea">
            <div class="titlearea">
                <div class="logoimg mr-3">
                    <img width="100" id="logomain" src="{{logo}}" alt="">
                </div>
                <span class="textcolor">{{academyName}}</span>
                <!-- <p class="textcolor mb-0">{{tagline}}</p> -->
            </div>
            <!-- <p class="textcolor mb-0">{{tagline}}</p> -->
        </div>
        <div class="titlebar">
            <span>Sign in
                <span *ngIf="isloginwithOTP"><small class="d-block">or</small> Login with OTP</span></span>
        </div>
        <div class="content">
            <div class="space">
                <!-- <div class="multioption">
                    <div></div><span class="text-center">Or</span>
                    <div></div>
                </div> -->
                <button type="button" (click)="openLogin()"
                    class="mb-3 btn-block loginheadercolor textcolor">Login</button>
                <button type="button" (click)="openOTPpopup()" *ngIf="isloginwithOTP"
                    class="mb-3 btn-block loginheadercolor textcolor mt-0">Login with OTP</button>
                <button type="button" (click)="openVenueBooking()" *ngIf="IsPlayAndPlay"
                    class="mb-3 btn-block loginheadercolor textcolor mt-0">Pay & Play</button>
                <div class="contact-prowered mb-2" *ngIf="!IsReseller">
                    <a href="https://www.kridapp.com/" target="_blank"><span class="pull-left font-14">Contact
                            us</span></a>
                    <span class="pull-right font-14">Powered by Kridapp</span>
                </div>
            </div>
            <div *ngIf="!isNativePlatform">
                <div *ngIf="isAndroid">
                    <div class="footer-nav" *ngIf="isFooter=='1'">
                        <p (click)="addToHomeScreen()" id="img_homescreen" [innerHTML]="androidhtml"></p> <span
                            (click)="close()"><img width="12" height="12" src="../../../assets/images/icons/hometime.png"
                                alt="" /></span>
                    </div>
                </div>
                <div *ngIf="isIOS">
                    <div class="footer-nav" *ngIf="isFooter=='1'">
                        <p id="img_homescreenIOS" [innerHTML]="ioshtml"> </p>
                        <span (click)="close()"><img width="12" height="12" src="../../../assets/images/icons/hometime.png"
                                alt="" /></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                Please provide username and password to login.
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>