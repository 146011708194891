<div id="page">
    <!-- Header -->
    <div class="header header-fixed header-logo-center">
        <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <h2 class="header-title textcolor">Inquiry</h2>
        <span class="header-icon header-icon-3 textcolor" (click)="createinquiry();">New</span>
        <span (click)="openPopupFilter()" class="header-icon header-icon-4 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/ellipsis-v-white.png" alt="" />
        </span>
        <div class="titlearea">
            <p class="textcolor mb-3">List of inquiries for new admission</p>
        </div>
        <!--Search-->
        <div class="search-box search-fixed search-color">
            <i class="search"><img width="16" height="16" src="../../../assets/images/icons/search.png" alt="" /></i>
            <input type="text" [(ngModel)]="searchText" class="border-0" placeholder="Search">
        </div>
    </div>
    <!-- Header -->

    <!-- Main Content -->
    <div class="page-content header-clear-medium" (click)="onremoveclick()">
        <div class="card studentListCard" *ngIf="isNoData">
            <div class="content m-0">
                <div class="student-list text-center">No open inquiries!</div>
            </div>
        </div>
        <div class="studentlistview">
            <div class="content" *ngFor="let item of inquiryList | filter:searchText">
                <ng-container *ngIf="item!=0">
                    <div class="student-list">
                        <div class="allimage">
                            <div class="contact-img">
                                <img src="{{item.UserPic}}" class="preload-img"
                                    *ngIf="item.UserPic!=null" alt="" />
                                <img src="../../assets/images/KridApp-Image/user.png"
                                    class="preload-img" alt="" *ngIf="item.UserPic==null" />
                            </div>
                        </div>
                        <div class="mt-2 d-flex align-items-center">
                            <div class="w-100 bd-highlight" (click)="profilepage(item)">
                                <h2 class="item-title">{{item.UserName}}</h2>
                                <p>{{item.PhoneNumber}}</p>
                                <p class="date" *ngIf="item.TrialDate !=null">{{item.TrialDate | date:'MMM dd,yyyy'}}</p>
                                <p>{{item.BatchName}}</p>
                                <p class="statustext1" *ngIf="item.Status==1">Assessment</p>
                                <p class="statustext2" *ngIf="item.Status==2">Success</p>
                                <p class="statustext3" *ngIf="item.Status==3">Rejected</p>
                                <p class="statustext4" *ngIf="item.Status==4">Assessment Done</p>
                                <p class="statustext5" *ngIf="item.Status==5">Inquiry</p>
                            </div>
                            <span (click)="opendotpopup(item)" class="selectdot">
                                <img width="16" height="16" src="../../../assets/images/icons/ellipsis-v.png" alt="" />
                            </span>
                        </div>
                    </div>
                    <!-- <div class="divider m-0"></div> -->
                </ng-container>
            </div>
        </div>
    </div>

    <!-- Player List-->
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
        <p class="color-white"> Loading... </p>
    </ngx-spinner>
</div>
<!-- Main page div -->