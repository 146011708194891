import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from '../service/commonfunction';
import { PostService } from '../service/post.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertComponent } from '../AlertPopup/alert/alert.component';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  constructor(public commonFunction: CommonFunction,
    public dialogRef: MatDialogRef<ForgetPasswordComponent>,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public postService: PostService,
    public router: Router,
    public fb: UntypedFormBuilder,
    public SimpleModalService: SimpleModalService) { }
  ForgetPasswordFormGroup: UntypedFormGroup;
  ngOnInit(): void {
    this.forminit();
  }
  forminit() {
    this.ForgetPasswordFormGroup = this.fb.group({
      Email: new UntypedFormControl('', [Validators.required]),
    });
  }
  forgetpasswordSubmit() {
    if (this.ForgetPasswordFormGroup.invalid) {
      let strrequired = "Please Provide username and password to login.";
      if (this.ForgetPasswordFormGroup.value.Email == "") {
        strrequired = strrequired;
      }
      if (strrequired != "") {
        this.SimpleModalService.addModal(AlertComponent, { title: " ", message: strrequired });
        return;
      }
    }
    else {
      this.postService.forgotPassword(this.ForgetPasswordFormGroup.value.Email).subscribe(
        (response: any) => {
          if (response == true) {
            let strrequired = "You will receive an email on your registered email address with the instructions.";
            this.SimpleModalService.addModal(AlertComponent, { title: " ", message: strrequired });
          } else {
            let strrequired = "Specified email address is not registered. Please enter correct email address.";
            this.SimpleModalService.addModal(AlertComponent, { title: " ", message: strrequired });
          }
        },
        (error) => {
          let strrequired = "Please Provide username and password to login.";
          this.SimpleModalService.addModal(AlertComponent, { title: " ", message: strrequired });
        }
      );
    }
  }
  closemodel() {
    this.dialogRef.close({});
  }
}
