import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserlistComponent } from './userlist/userlist.component'
import { UserfeesdetailsComponent } from './userfeesdetails/userfeesdetails.component';
import { StudentfeestypeComponent } from './studentfeestype/studentfeestype.component';
import { FeesinfoComponent } from './feesinfo/feesinfo.component';
import { CollectionreportComponent} from './collectionreport/collectionreport.component';
import { AddfeesComponent} from './addfees/addfees.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'userlist',
        component: UserlistComponent
      },
      {
        path: 'userfeesdetails',
        component: UserfeesdetailsComponent
      },
      {
        path: 'studentfeestype',
        component: StudentfeestypeComponent
      },
      {
        path: 'feesinfo',
        component: FeesinfoComponent
      },
      {
        path: 'collectionreport',
        component: CollectionreportComponent
      },
      {
        path: 'addpayment',
        component: AddfeesComponent
      }
      

    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManagefeesRoutingModule { }
