import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reasonlist',
  templateUrl: './reasonlist.component.html',
  styleUrls: ['./reasonlist.component.scss']
})
export class ReasonlistComponent implements OnInit {

  constructor(
    public route: ActivatedRoute,
    public router: Router) { }

  ngOnInit(): void {
  }

  previouspage(){
   this.router.navigate(['/Attendance']);
  }

}
