import { Component, OnInit, Injectable, Inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from '../service/commonfunction';
import { GetListService } from '../service/getlist.service';
import $ from 'jquery'
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MenupopupComponent } from './menupopup/menupopup.component';
import { DotpopupComponent } from './dotpopup/dotpopup.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { StudentModel } from '../_models/user.model';
import { DashboardParam } from '../_models/user.model';
import { Pipe, PipeTransform } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertComponent } from '../AlertPopup/alert/alert.component';
@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent implements OnInit {
  strrequired:"";
  items1 = ["Kyle", "Eric", "Bailey", "Deborah", "Glenn", "Jaco", "Joni", "Gigi"]
  term1: string;
  inquiryList: any;
  @Input() setstudentmodel: StudentModel;
  e: any;
  Usertypeid;
  selectionoption: boolean = false;
  userdata: any;
  userId: any;
  academyId: any;
  batchId: any = null;
  shortingBy: any = 2;
  InqListId: any = '5,1,4';
  searchText: string;
  filterTerm: string;
  isNoData: boolean = false;
  dashboardparam: DashboardParam
  constructor(public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public getlistservice: GetListService,
    public commonFunction: CommonFunction,
    public SimpleModalService:SimpleModalService) { }
  ngOnInit(): void {
    this.userdata = this.commonFunction.GetUserData();

    this.dashboardparam = window.history.state.example;

    if (window.history.state.example == undefined) {
      this.batchId;//= this.dashboardparam.batchId;
    }
    else {
      this.batchId = window.history.state.example.batchId;
    }
    this.getinquiryList();
    this.onremoveclick();
    this.dashboardparam = window.history.state.example;
  }

  onclick() {
    $("body").addClass("modal-open");
    $('#menu-sidebar-left-1').addClass('menu-active')
  }
  onremoveclick() {
    $("body").removeClass("modal-open");
    $('#menu-sidebar-left-1').removeClass('menu-active')
  }
  openactionbutton() {
    $('#menu-language').addClass('menu-active')

  }
  actionmodel() {
    $("body").addClass("modal-open");
    $('#menu-tour-1').addClass('menu-active');
  }
  onKey(event: any) { // without type info
    this.e = event.target.value;
    this.getinquiryList();
  }

  back()
  {
    this.router.navigate(['/dashboard']);
  }

  public getinquiryList() {
    this.spinner.show();
    this.academyId = this.userdata.AcademyId,
      this.userId = this.userdata.UserId,
      // this.batchId,
      this.shortingBy,
      this.InqListId,

      this.getlistservice
        .GetInquiryList(this.academyId, this.userId, this.batchId, this.shortingBy, this.InqListId)
        .subscribe((response: any) => {
          // console.log(response);
          
          if (response.length > 0) {
            this.inquiryList = response;
            this.isNoData = false;
          }
          else {
            this.inquiryList = response;
            this.isNoData = true;
          }
          this.spinner.hide();
        },
          (error) => {
            this.spinner.hide();
          });
  }

  createinquiry() {
    var getUserDatathis=this.commonFunction.GetUserData();
    this.getlistservice.getAcademyPackageExpire(getUserDatathis.ParentAcademyId).subscribe(
      (response: any) => {
        if(response.PackageErrorMessage!="")
        {
          this.onremoveclick();
          this.strrequired=response.PackageErrorMessage;
          if (this.strrequired != "") {
            this.SimpleModalService.addModal(AlertComponent, { title: getUserDatathis.SelectAcademyName, message: this.strrequired });
          }
        }
        else
        {
          this.router.navigate(['/inquiry/createinquiry']);
        }
      },
      (error) => {
      }
    );
  }

  openPopupFilter(): void {
    const dialogRef = this.dialog.open(MenupopupComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'custom-modalbox',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 1 || result == 3) {
        this.shortingBy = (result == 1 || result == 3) ? result : this.shortingBy;
      }
      if (result.ScheduleListId > 0 || result.batchesListId) {
        this.shortingBy = (result == 1 || result == 3) ? result : this.shortingBy;
        this.batchId = (result.ScheduleListId > 0) ? result.ScheduleListId : null;
        this.InqListId = (result.batchesListId == undefined) ? null : result.batchesListId;
      }
      if (result == 2) {
        this.shortingBy = 2;
      }
      this.getinquiryList();
    });


  }
  toggleShowselection(event) {
    if (event.isTrusted == true) {
      this.selectionoption = !this.selectionoption;
    }
    else {
      this.selectionoption = false;
    }
  }
  opendotpopup(param) {
    const dialogRef = this.dialog.open(DotpopupComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'custom-modalbox',
      data: { val: param }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.router.navigate(['/inquiry/createinquiry'], {
          state: { userAcademyId: result.data.val.Id, InquiryoptionData: result,dotpopup:true }
        });
      }
    });
  }

  profilepage(item) {
    this.setstudentmodel = item.UserName;
    // console.log(item);
    
    this.router.navigate(['/inquiry/inquiryprofile'], {
      state: { example: item, isInquiryEdit: true }
    });
  }
}
