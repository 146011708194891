<div id="page">
    <!-- Header -->
    <div class="header header-fixed header-logo-center">
        <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span>
        <h2 class="header-title textcolor">Training</h2>
        <span (click)="onclick()" data-menu="menu-sidebar-left-1" class="header-icon header-icon-4 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
        </span>
    </div>
    <!-- Header -->
    <form (click)="onremoveclick()">
        <div class="page-content detail header-clear-medium">
            <form>
                <div class="Requisition-table">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>PLANNING</th>
                                <th style="padding:15px 0; max-width: 150px;"><input type="date" class="form-control" name="Tdate" id="DateOfTrain"
                                        dateFormat="m/d/Y" (change)="ChangeDate($event)" ref="flatpickr"></th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of Trainingbatchdata">
                            <tr class="bg-white">
                                <td>{{item.Name}}</td>
                                <td style="padding:15px 0;"></td>
                                <td (click)="opentrapopup(item.Id)"><i><img width="20px" height="20px"
                                            src="../../../assets/images/icons/angle-right.png" alt=""></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>
        </div>
    </form>
</div>