<!-- Main Page -->
<div id="page">
    <!-- Header -->
    <div class="header header-fixed header-logo-center">
        <!-- Sidebar Menu Icon-->
        <span  class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span> 
        <h2 class="header-title textcolor">Reason</h2>
        <!-- Sidebar Menu Icon-->
        <span class="close-menu header-icon header-icon-4 textcolor right15" (click)="previouspage();"> Done </span>
    </div>
    <!-- Header -->
    <!-- new Player Edit model popup-->
    <div id="ad-timed-studentedit" class="menu menu-box-modal menu-box-detached round-large" data-menu-width="100vw" data-menu-height="100vh" 
 data-menu-effect="menu-over">
    <!-- Header -->
    <div class="header-clear-medium">
     <div class="content">
         <h1>hello</h1>
     </div>
    </div>
    </div>
    <!-- new Player List model popup-->
</div>
<!-- Main Page -->
