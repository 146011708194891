import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoachattendanceadminComponent } from './coachattendanceadmin/coachattendanceadmin.component';
import { CoachattendancecoachComponent } from './coachattendancecoach/coachattendancecoach.component';
import {PopupcoachattendanceComponent } from './popupcoachattendance/popupcoachattendance.component';
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'adminview',
        component: CoachattendanceadminComponent
      },
      {
        path: 'coachview',
        component: CoachattendancecoachComponent
      },
      {
        path: 'popupattendance',
        component: PopupcoachattendanceComponent
      },



    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoachattendanceRoutingModule { }
