import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GetListService } from '../../service/getlist.service';
import { CommonFunction } from '../../service/commonfunction';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-popupscedule',
  templateUrl: './popupscedule.component.html',
  styleUrls: ['./popupscedule.component.scss']
})
export class PopupsceduleComponent implements OnInit {
  Dayofweek:Date;
  public GetScheduleList: any=[];
  constructor(
    public dialogRef: MatDialogRef<PopupsceduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public getListService: GetListService,
    public commonFunction: CommonFunction,
    private spinner: NgxSpinnerService, 
  ) { }

  ngOnInit(): void {
    let id = (this.data.GetBatchesList!=undefined)?this.data.GetBatchesList:this.data.parentBatchId;
    this.scheduleList(id);
  }

  public scheduleList(id: any) {
    this.spinner.show();
     this.Dayofweek=new Date(this.data.attendDate);
    var userdata = this.commonFunction.GetUserData();
    var data = {
      academyId: userdata.AcademyId,
      batchIds:id,
      userId: userdata.UserId,
      dayofWeek: this.Dayofweek.getDay(),
      roleId: userdata.RoleType
    };
    this.getListService
      .getScheduleList(data)
      .subscribe((response: any) => {
        for (let i = 0; i < response.length; i++) {
          if (this.data.GetScheduleList != undefined) {
            for (let j = 0; j < this.data.GetScheduleList.length; j++) {
                if(response[i].Id==this.data.GetScheduleList[j])
                {
                  this.GetScheduleList[i]={
                    Id:response[i].Id,
                    BatchName:response[i].BatchName,
                    checked:true
                  };
                  break;
                }
                else
                {
                  this.GetScheduleList[i]={
                    Id:response[i].Id,
                    BatchName:response[i].BatchName,
                    checked:false
                  };
                }
            }
          }
          else
                {
                  if(this.data.parentBatchId>0){
                    this.GetScheduleList.push({
                      Id:response[i].Id,
                      BatchName:response[i].BatchName,
                      checked:true
                    });
                  }
                  else{
                    this.GetScheduleList.push({
                      Id:response[i].Id,
                      BatchName:response[i].BatchName,
                      checked:false
                    });

                  }
                  
                }
        }
        this.spinner.hide();
      },
        (error) => {

        });
  }

  closemodel() {
    this.dialogRef.close()
  }

  done() {

    var ScheduleListId = new Array();
    var GetAllcheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("getAllcheckBoxValue"));
    let SelectedCheckboxName="";
    for (let i = 0; i < GetAllcheckbox.length; i++) {
      if (GetAllcheckbox[i].checked) {
        ScheduleListId.push(Number(GetAllcheckbox[i].value));
        SelectedCheckboxName += GetAllcheckbox[i].name + ',';
      }
    }
    SelectedCheckboxName=this.commonFunction.removeLastComma(SelectedCheckboxName);
      this.dialogRef.close({ data: ScheduleListId,SelectedCheckboxName:SelectedCheckboxName});
  }

  scheduleDivclick(id)
  {
    let GetSchByDom=(<HTMLInputElement>document.getElementById('schchchk_'+id));
    if(GetSchByDom.checked==true)
    {
      GetSchByDom.checked=true;
    }
    else
    {
      GetSchByDom.checked=false;
    }
  }

}
