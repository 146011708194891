import { Injectable } from '@angular/core';
import { openDB, deleteDB, wrap, unwrap } from 'idb';
import { Observable, Subject } from 'rxjs';
//import {Schedule} from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class IdbService {
  private _dataChange: Subject<any> = new Subject<any>();
  private _dbPromise: any;

  constructor() {
  }

  // res.forEach(function (lesson) {
  //   that.idbService.addItems('lessons', lesson)
  // });
  connectToIDB() {
    this._dbPromise = openDB('kridapp', 2, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('academy')) {
          db.createObjectStore('academy', { keyPath: 'id', autoIncrement: true });

        }
        if (!db.objectStoreNames.contains('user')) {
          db.createObjectStore('user', { keyPath: 'id', autoIncrement: true });

        }
        if (!db.objectStoreNames.contains('rolemaster')) {
          db.createObjectStore('rolemaster', { keyPath: 'id', autoIncrement: true });

        }
        if (!db.objectStoreNames.contains('academybatch')) {
          db.createObjectStore('academybatch', { keyPath: 'id', autoIncrement: true });
        }

        if (!db.objectStoreNames.contains('subbatch')) {
          db.createObjectStore('subbatch', { keyPath: 'id', autoIncrement: true });
        }
        if (!db.objectStoreNames.contains('userlist')) {
          db.createObjectStore('userlist', { keyPath: 'id', autoIncrement: true });
        }
        if (!db.objectStoreNames.contains('leavetype')) {
          db.createObjectStore('leavetype', { keyPath: 'id', autoIncrement: true });
        }

        if (!db.objectStoreNames.contains('dashboard')) {
          db.createObjectStore('dashboard', { keyPath: 'id', autoIncrement: true });
        }
        if (!db.objectStoreNames.contains('notificationcount')) {
          db.createObjectStore('notificationcount', { keyPath: 'id', autoIncrement: true });
        }


        if (!db.objectStoreNames.contains('academyuserattendance')) {
          db.createObjectStore('academyuserattendance', { keyPath: 'id', autoIncrement: true });
        }
        // db.createObjectStore('favourite-number');
        // const productStore = db.createObjectStore('products', {
        //   keyPath: 'productCode',
        // });
        // productStore.createIndex('by-price', 'price');
      },
    });
  }


  clear(target: string) {

    this._dbPromise.then((db: any) => {
      const tx = db.transaction(target, 'readwrite');
      tx.objectStore(target).clear();

      return tx.complete;
    });

  }
  inserttablebyarray(academyarray, rolearray, batcharray) {
    if (academyarray.length > 0) {
      academyarray.forEach(function (item) {
        this.addItemsObs("academy", item);
      });
    }
    if (rolearray.length > 0) {
      academyarray.forEach(function (item) {
        this.addItemsObs("rolemaster", item);
      });
    }
    if (batcharray.length > 0) {
      academyarray.forEach(function (item) {

        this.addItemsObs("academybatch", item);
      });
    }
  }
  addItems(target: string, value: any) {
    this._dbPromise.then((db: any) => {
      const tx = db.transaction(target, 'readwrite');
      // tx.objectStore(target).put(value);
      tx.objectStore(target).put({
        time: value.time,
        subject: value.subject,
        location: value.location,
        description: value.description
      });
      // this._dataChange.next(this.getItem(target, value.id));
      this.getItem(target, value).then((item: any) => {
        this._dataChange.next(item);
      });

      return tx.complete;
    });
  }

  public addItemsObs(target: string, value: any) {
    return this._dbPromise.then((db: any) => {
      const tx = db.transaction(target, 'readwrite');
      tx.objectStore(target).put(value);
      // tx.objectStore(target).put({
      //   City: value.City,
      //   FirstName: value.FirstName,
      //   Email: value.Email,
      //   PhoneNumber: value.PhoneNumber
      // });
      // tx.objectStore(target).put({
      //   time: 15,
      // });

      // this._dataChange.next(this.getItem(target, value.id));
      this.getAllData(target).then((item: any) => {
        this._dataChange.next(item);
      });

      return tx.complete;
    });
  }

  getItem(target: string, key: any) {
    return this._dbPromise.then((db: any) => {
      const tx = db.transaction(target, 'readwrite');
      //tx.objectStore(target).put(value);
      //this._dataChange.next(tx.objectStore(target).get(key))
      return tx.objectStore(target).get(key)
    });
  }

  async getItemObs(target: string, key: any): Promise<Observable<any>> {

    let item: Subject<any> = new Subject<any>();

    await this._dbPromise.then((db: any) => {
      const tx = db.transaction(target, 'readwrite');
      //tx.objectStore(target).put(value);
      //this._dataChange.next(tx.objectStore(target).get(key))
      item.next(tx.objectStore(target).get(key))
    });

    return item
  }


  deleteItems(target: string, value: any) {
    this._dbPromise.then((db: any) => {
      const tx = db.transaction(target, 'readwrite');
      const store = tx.objectStore(target);
      store.delete(value);
      this.getAllData(target).then((items: any) => {
        this._dataChange.next(items);
      });
      return tx.complete;
    });
  }

  async getAllData(target: string) {
    return this._dbPromise.then((db: any) => {
      const tx = db.transaction(target, 'readonly');
      const store = tx.objectStore(target);
      return store.getAll();
    });
  }

  dataChanged(): Observable<any> {
    return this._dataChange;
  }
}