import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from 'src/app/service/commonfunction';
import { GetListService } from 'src/app/service/getlist.service';
import { DashboardParam, StudentModel } from '../../_models/user.model';

@Component({
  selector: 'app-dashboardpersonalpopup',
  templateUrl: './dashboardpersonalpopup.component.html',
  styleUrls: ['./dashboardpersonalpopup.component.scss']
})
export class DashboardpersonalpopupComponent implements OnInit {

  userdata: any;
  dashboardParam: DashboardParam;
  Attendance;
  CoachAttendance;
  Drills;
  Inquiry;
  Student;
  studentlistmodel;
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DashboardpersonalpopupComponent>,
    public route: ActivatedRoute,
    public getlistservice: GetListService,
    public commonFunction: CommonFunction,
    public getListService: GetListService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userdata = this.commonFunction.GetUserData();
    this.studentlistmodel = this.data.val;
  }

  clickOption(val) {
    if (val == 1) {
      this.router.navigate(['/privateattendancestd'], {
        state: { example: this.dashboardParam }
      });
      this.close();
      //For get data
    } else if (val == 2) {
      //Student       
      this.router.navigate(['/studentmodule/studentprofile'], {
        state: { example: this.studentlistmodel }

      });
      this.close();
    } else if (val == 4) {
      //Drills
      this.router.navigate(['/drills/drillview'], {
        state: { example: this.dashboardParam }
      });
      this.close();
    } else if (val == 6) {
      var stateparam = {
        academyId: this.dashboardParam.academyId,
        academyName: this.dashboardParam.academyName,
        batchId: this.dashboardParam.parentBatchId,
        batchName: this.dashboardParam.batchName,
        scheduleId: this.dashboardParam.batchId,
        parentBatchName: this.dashboardParam.parentBatchName
      };
      this.router.navigate(['/userlist'], {
        state: stateparam,
      });
      this.close();
    }
  }
  close() {
    this.dialogRef.close();
  }
}
