import { Component, OnInit } from '@angular/core';
import { GetListService } from 'src/app/service/getlist.service';
import { CommonFunction } from 'src/app/service/commonfunction';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import $ from 'jquery';
import { TrainingPopupComponent } from '../training-popup/training-popup.component';


@Component({
  selector: 'app-select-drill-popup',
  templateUrl: './select-drill-popup.component.html',
  styleUrls: ['./select-drill-popup.component.scss']
})
export class SelectDrillPopupComponent implements OnInit {
  pipe = new DatePipe('en-US');
  result;
  userdata;
  CurrentDate;
  DateOfTrain: any;
  date: any;
  batchId;
  Trainingbatchdata;
  scheduleDate;
  scdate;
  slot;
  trainingdate;
  trainingbatchdata;

  constructor(
    public commonFunction: CommonFunction,
    public getListService: GetListService,
    private spinner: NgxSpinnerService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.onremoveclick();
    this.userdata = this.commonFunction.GetUserData();
    this.trainingbatchdata = window.history.state.example;
    this.date = new Date();
    this.batchId = this.trainingbatchdata.batchIds[0];
    this.slot = this.trainingbatchdata.slot;
    var element = <HTMLInputElement>document.getElementById("DateOfTrain");
    element.value = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    this.Getbatchid();
  }

  onclick() {
    $("body").addClass("modal-open");
    $('#menu-sidebar-left-1').addClass('menu-active')
  }
  onremoveclick() {
    $("body").removeClass("modal-open");
    $('#menu-sidebar-left-1').removeClass('menu-active')
  }

  back() {
    this.router.navigate(['/Training'])
  }

  Getbatchid() {
    this.scdate = <HTMLInputElement>document.getElementById("DateOfTrain")
    this.getListService.GetDrillListOnDateForTraining(this.batchId, this.scdate.value)
      .subscribe((response: any) => {
        this.Trainingbatchdata = response;

      })
  }

  ChangeDate(e) {
    this.scheduleDate = e.target.value;
    this.getListService.GetDrillListOnDateForTraining(this.batchId, this.scheduleDate)
      .subscribe((response: any) => {
        this.Trainingbatchdata = response;
      })
  }

  opentrapopup(param) {

    if (this.scheduleDate == undefined) {
      this.trainingdate = this.scdate.value;
    }
    else {
      this.trainingdate = this.scheduleDate;
    }

    const dialogRef = this.dialog.open(TrainingPopupComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'custom-modalbox',
      data: {
        date: this.trainingdate,
        val: param,
        batchId: this.batchId,
        AcademyId: this.userdata.AcademyId,
        slot: this.slot
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
