<form [formGroup]="profileForm" (ngSubmit)="Save(profileForm)" style="width:100%;">
    <div class="popup-header header-logo-center loginheadercolor">
        <div class="header-title textcolor noback">Edit {{playername}}'s {{set}} Set</div>
        <input type="submit" value="Save" class="header-icon header-icon-4 textcolor right15">
    </div>
    <div class="page-content pb-0">
        <ul class="filter-popup">
            <ng-container>
                <div class="inputsection" style="width: 90%; margin-left: 5%; margin-right: 5%;padding-top: 10px;">
                    <span class="input-title font-14">Weight</span>
                    <div class="input-style  input-required">
                        <input type="text" class="form-control" rows="2" placeholder="Weight" formControlName="Weight">
                    </div>
                </div>
                <div class="inputsection" style="width: 90%; margin-left: 5%; margin-right: 5%;">
                    <span class="input-title font-14">Repetition</span>
                    <div class="input-style  input-required">
                        <input type="text" class="form-control" rows="2" placeholder="Repetition"
                            formControlName="Repetition">
                        <!-- <input type="hidden" class="form-control" value="{{userId}}" formControlName="userId"> -->
                    </div>
                </div>
                <div class="inputsection" style="width: 90%; margin-left: 5%; margin-right: 5%;padding-top: 10px;">
                    <span class="input-title font-14">Comment</span>
                    <div class="input-style  input-required">
                        <input id="comment" type="text" class="form-control" rows="2" placeholder="Comment"
                            formControlName="Comment">
                    </div>
                </div>
                <div style="display: flex;">
                    <li (click)="close()" class="col-12 text-center red"><img width="16" height="16"
                            src="../../../assets/images/KridApp-Image/close.png" alt=""></li>
                </div>
            </ng-container>
        </ul>
    </div>
</form>