<div id="page">
    <form [formGroup]="AddFeesForm" (ngSubmit)="AddFeesSubmit()">
        <!-- Header -->
        <div class="header header-fixed header-logo-center">
            <h2 class="header-title textcolor noback">Add Payment</h2>
            <span (click)="close()" class="header-icon header-icon-4 textcolor right15">Close</span>
        </div>
        <!-- Header -->
        <div class="page-content header-clear-medium payment-page">
            <div class="profile">
                <div class="content">
                    <div class="inputsection">
                        <span class="input-title font-14">Amount<span class="requird-field">*</span></span>
                        <div class="input-style input-required">
                            <input class="form-control" type="number" placeholder="" id="FeesAmount"
                                formControlName="FeesAmount">
                        </div>
                    </div>

                    <div class="Requisition-table addfees">
                        <table class="table">
                            <tr>
                                <td width="200" class="statusopen">Subscription Fees</td>
                                <td class="p-0"><input class="form-control pl-3 pr-3" type="text"
                                        formControlName="Subscription" id="Subscription" disabled></td>
                            </tr>
                            <tr>
                                <td width="200" class="statusopen" id="PenaltyName" hidden>Penalty</td>
                                <td class="p-0"><input class="form-control pl-3 pr-3" id="Penalty" type="text"
                                        formControlName="Penalty" (change)="PenaltyChange($event)" hidden></td>
                            </tr>
                            <tr>
                                <td width="200" class="statusopen">Payable Amount</td>
                                <td class="p-0"><input class="form-control pl-3 pr-3" type="text"
                                        formControlName="PayableAmount" id="PayableAmount" disabled></td>
                            </tr>
                        </table>
                    </div>

                    <div class="inputsection">
                        <span class="input-title font-14">Payment Date</span>
                        <div class="input-style input-required dob-input">
                            <input id="PaymentDate" class="form-control" type="text" mwlFlatpickr
                                formControlName="PaymentDate" dateFormat="M d, yy" ref="flatpickr">
                        </div>
                    </div>

                    <div class="inputsection">
                        <span class="input-title font-14">Fees Mode<span class="requird-field">*</span></span>
                        <div class="input-style input-required">
                            <select class="form-control" selected="1" (change)="FeesChange($event)"
                                formControlName="FeesModeId" required>
                                <option value="3">Cheque</option>
                                <option value="5">NEFT</option>
                                <option value="1">Cash</option>
                                <option value="4">Draft</option>
                                <option value="7">Bank Transfer</option>
                                <option value="8">Benefit Pay</option>
                            </select>
                        </div>
                    </div>

                    <div class="inputsection"
                        *ngIf="elementId==3||elementId==5||elementId==4||elementId==7||elementId==8">
                        <span class="input-title font-14">Bank Name</span>
                        <div class="input-style input-required">
                            <input class="form-control" type="text" placeholder="Bank Name" formControlName="BankName">
                        </div>
                    </div>
                    <div class="inputsection" *ngIf="elementId==3">
                        <span class="input-title font-14">Cheque No.</span>
                        <div class="input-style input-required">
                            <input class="form-control" type="number" placeholder="" formControlName="ChequeNo">
                        </div>
                    </div>
                    <div *ngIf="elementId==3">
                        <div class="row mb-3 align-items-center">
                            <div class="col-6">
                                Cheque Image
                            </div>
                            <div class="col-6">
                                <div class="image-upload">
                                    <label class="mb-0" for="file-input">
                                        <span (click)="selectImage.click()"
                                            class="btn btn-block loginheadercolor textcolor">Select</span>
                                    </label>
                                </div>
                                <input type="file" class="form-control" #selectImage formControlName="ChequeImageurl"
                                    [ngClass]="{ 'is-invalid': submitted && f.ChequeImageurl.errors }"
                                    (change)="uploadFile($event)" hidden>
                                <div *ngIf="submitted && f.ChequeImageurl.errors" class="invalid-feedback">
                                    <div class="font-13" *ngIf="f.ChequeImageurl.errors.required">This is required field
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="inputsection" *ngIf="elementId==4">
                        <span class="input-title font-14">Draft No.</span>
                        <div class="input-style input-required">
                            <input class="form-control" type="text" placeholder="" formControlName="DraftNo">
                        </div>
                    </div>

                    <div class="inputsection" *ngIf="elementId==4">
                        <span class="input-title font-14">Draft Date</span>
                        <div class="input-style input-required dob-input">
                            <input id="DraftDate" class="form-control" type="text" mwlFlatpickr
                                formControlName="DraftDate" dateFormat="M d, yy" ref="flatpickr">
                        </div>
                    </div>

                    <div class="inputsection" *ngIf="elementId==3">
                        <span class="input-title font-14">Cheque Date</span>
                        <div class="input-style input-required dob-input">
                            <input id="ChequeDate" class="form-control" type="text" mwlFlatpickr
                                formControlName="ChequeDate" dateFormat="M d, yy" ref="flatpickr">
                        </div>
                    </div>

                    <div class="inputsection" *ngIf="elementId==1||elementId==7||elementId==8">
                        <span class="input-title font-14">Received by</span>
                        <div class="input-style input-required">
                            <input class="form-control" type="text" placeholder="Received by"
                                formControlName="ReceivedBy">
                        </div>
                    </div>

                    <div class="inputsection" *ngIf="elementId==5||elementId==7||elementId==8">
                        <span class="input-title font-14">Transaction Id</span>
                        <div class="input-style input-required">
                            <input class="form-control" type="text" placeholder="Transaction Id"
                                formControlName="TransactionId">
                        </div>
                    </div>

                    <div class="inputsection">
                        <span class="input-title font-14">Transaction Details</span>
                        <div class="input-style input-required">
                            <input class="form-control" type="text" placeholder="Transaction Details"
                                formControlName="TransactionDetails">
                        </div>
                    </div>

                    <div class="d-flex align-items-center mb-3">
                        <input type="checkbox" id="SendSMS" class="mr-2" formControlName="SendSMS" hidden /> <span
                            id="displayName" hidden>SMS</span>
                    </div>

                    <div class="d-flex align-items-center">
                        <input type="checkbox" class="mr-2" formControlName="SendEmail" /> Send confirmation email
                    </div>
                    <div class="inputsection">
                        <button type="submit" class="btn mt-3 btn-block loginheadercolor textcolor">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>