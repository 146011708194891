import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { CommonFunction } from 'src/app/service/commonfunction';
import { GetListService } from 'src/app/service/getlist.service';
import {SortfilterComponent } from '../sortfilter/sortfilter.component';
import {PopupfilterComponent} from '../popupfilter/popupfilter.component';

@Component({
  selector: 'app-menupopup',
  templateUrl: './menupopup.component.html',
  styleUrls: ['./menupopup.component.scss']
})
export class MenupopupComponent implements OnInit {
  sortfiltervalue:any;
  constructor( public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SortfilterComponent,PopupfilterComponent>,
    public route: ActivatedRoute,
    public getlistservice :GetListService,
    public commonFunction:CommonFunction) { }
  ngOnInit(): void {
  }
  openSortFilter(): void {
    const dialogRef = this.dialog.open(SortfilterComponent, {
      width: '100%',
      height:'100%',
      panelClass: 'custom-modalbox',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.sortfiltervalue = result.data;
      this.dialogRef.close(result.data);
    });
  }

  openPopupFilter():void {
    const dialogRef = this.dialog.open(PopupfilterComponent, {
      width: '100%',
      height:'100%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialogRef.close(result);
    });
  }
}
