import { Component, OnInit, ViewChild } from '@angular/core';
import { StudentFees, OnlinePayment } from '../../_models/user.model';
import { GetListService } from 'src/app/service/getlist.service';
import { PostService } from 'src/app/service/post.service';
import { CommonFunction } from 'src/app/service/commonfunction';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import $ from 'jquery'
import { environment } from './../../../environments/environment';
import { NotificationService } from 'src/app/service/notification.service';
declare var Razorpay: any;
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { StripeComponent } from 'src/app/stripe/stripe.component';
@Component({
  selector: 'app-payoninestripe',
  templateUrl: './payoninestripe.component.html',
  styleUrls: ['./payoninestripe.component.scss']
})
export class PayoninestripeComponent {
  @ViewChild(StripeComponent) child;
  public payPalConfig?: IPayPalConfig;
  razorPay_Key: string = environment.razorpay_Key;
  webURL: string = environment.webUrl;
  banklist;
  upilist;
  walletlist;
  userdata;
  academyData;
  logo;
  email;
  phone;
  feesPaymentRulesId;
  feesPaymentPaneltyAmount;
  originalAmount;
  payId;
  batchId;
  detail: any;
  ISVPAValidate: boolean;
  pipe = new DatePipe('en-US');
  setOnlinePayment: OnlinePayment;
  IsPayPal: boolean = false;
  IsStripe: boolean = false;
  IsRazorpay: boolean = false;
  amount;
  constructor(
    public commonFunction: CommonFunction,
    public getListService: GetListService,
    public postService: PostService,
    private spinner: NgxSpinnerService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public notifyService: NotificationService,
  ) { }

  ngOnInit(): void {
    let GetAcademyData = this.commonFunction.GetAcademyData();
    if (GetAcademyData != null || GetAcademyData != undefined) {
      this.IsPayPal = GetAcademyData.PayPal;
      this.IsRazorpay = GetAcademyData.RazorPay;
      this.IsStripe = GetAcademyData.Stripe;
    }
    this.userdata = this.commonFunction.GetUserData();
    if (this.userdata != null || this.userdata != undefined) {
      this.email = this.userdata.UserEmail;
      this.phone = this.userdata.PhoneNumber;
    }
    this.setOnlinePayment = window.history.state.example;
    // console.log(this.setOnlinePayment);
    this.batchId = this.setOnlinePayment.BatchId;
    this.amount = this.setOnlinePayment;
    this.GetConstantList();
    this.GetOnlinePaymentDetails(this.setOnlinePayment.FeesHeaderId, this.setOnlinePayment.OutStandingAmount);
    var d = new Date();
    var _year = d.getFullYear();
    var _year = parseInt(new Date().getFullYear().toString().substr(-2));
    $('#ddl_CardExpiryYear').val("");
    $('#ddl_CardExpiryYear').empty();
    $('#ddl_CardExpiryYear').append('<option value=0>Year</option>');
    $('#ddl_CreditCardExpiryYear').val("");
    $('#ddl_CreditCardExpiryYear').empty();
    $('#ddl_CreditCardExpiryYear').append('<option value=0>Year</option>');
    for (var i = _year; i < _year + 15; i++) {
      $('#ddl_CardExpiryYear').append('<option value=' + i + '>' + i + '</option>');
      $('#ddl_CreditCardExpiryYear').append('<option value=' + i + '>' + i + '</option>');
    }
    this.initConfig();
  }
  private initConfig(): void {
    this.payPalConfig = {
      currency: 'GBP',
      clientId: 'Abj_NPHv-s0IQUfhQ6H8v5ieyJ3NWEPRHTgMMEES_tfyaP7W-r0lAX0zLvkqRl2dz4w4eKuZWboKQGjJ',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        // sandbox: 'access_token$sandbox$A4vkfl.keX34d-9fZU1xCEuXiOdHAEU.SiEcgo.DnqVmCyoJVZO.Q6FG',
        purchase_units: [
          {
            amount: {
              currency_code: 'GBP',
              value: '7.99',
              breakdown: {
                item_total: {
                  currency_code: 'GBP',
                  value: '7.99'
                }
              }
            },
            items: [
              {
                name: 'Enterprise Subscription',
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'GBP',
                  value: '7.99',
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        // console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          // console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
      },
      onCancel: (data, actions) => {
        // console.log('OnCancel', data, actions);
      },
      onError: err => {
        // console.log('OnError', err);
      },
      onClick: (data, actions) => {
        // console.log('onClick', data, actions);
      },
    };
  }
  back() {
    this.router.navigate(['/studentfees/studentfeeslist'], {
      state: { example: this.batchId }
    })
  }

  GetConstantList() {
    this.academyData = JSON.parse(localStorage.getItem("academyData"));
    if (this.academyData != null || this.academyData != undefined) {
      this.logo = this.webURL + this.academyData.ImageUrl;
    } else {
      //this.logo = "../../assets/images/KridApp-Image/InvoiceLogoRSA.png";
    }
    this.getListService
      .GetConstantList()
      .subscribe((response: any) => {
        let constantList = response;
        if (constantList != null && constantList.length > 0) {
          for (var i = 0; i < constantList.length; i++) {

            let c = constantList[i];

            this.getListService
              .GetGatewayList(c)
              .subscribe((responseConstant: any) => {
                let list = responseConstant;
                if (c == "CARDTYPE") {

                }
                else if (c == "NETBANKING") {
                  this.banklist = list;
                }
                else if (c == "UPI") {
                  this.upilist = list;
                }
                else if (c == "WALLET") {
                  this.walletlist = list;
                }

              })
          }

        }
      })
  }
  GetOnlinePaymentDetails(feesHeaderId, outStandingAmount) {
    let paymentrulesDetails, userCharges;
    this.getListService
      .GetIsEnableOnlinePayment(this.userdata.ParentAcademyId)
      .subscribe((response: any) => {
        let isEnable = response;
        if (isEnable) {

          // Get FeesPayment Rules Details
          let datePipeString = this.pipe.transform(Date.now(), 'yyyy-MM-dd');
          this.getListService
            .GetPaymentRulesDetails(this.userdata.ParentAcademyId, datePipeString, feesHeaderId)
            .subscribe((responsepaymentrulesDetails: any) => {
              paymentrulesDetails = responsepaymentrulesDetails;
              this.feesPaymentRulesId = paymentrulesDetails.id;
              this.feesPaymentPaneltyAmount = paymentrulesDetails.penalty;
              this.originalAmount = outStandingAmount
              this.getListService
                .GetUserDisplayAmount(this.originalAmount, paymentrulesDetails.penalty, this.userdata.ParentAcademyId)
                .subscribe((responseuserCharges: any) => {
                  userCharges = responseuserCharges;
                  if (userCharges != null && userCharges.length > 0) {
                    for (var i = 0; i < userCharges.length; i++) {
                      if (userCharges[i].PaymentMethod == "CreditCard") {
                        $('#spn_CreditPayableAmountRazorpayCard').text((userCharges[i].DisplayAmount).toFixed(2));
                        $('#display_CreditCard').html(userCharges[i].html);
                        $('#Perct_C').text(userCharges[i].Charge + '%');
                      }
                      else if (userCharges[i].PaymentMethod == "DebitCard") {
                        $('#spn_PayableAmountRazorpayCard').text((userCharges[i].DisplayAmount).toFixed(2));
                        $('#display_DebitCard').html(userCharges[i].html);
                        $('#Perct_D').text(userCharges[i].Charge + '%');
                      }
                      else if (userCharges[i].PaymentMethod == "Wallet") {
                        $('#spn_PayableAmountRazorpayWall').text((userCharges[i].DisplayAmount).toFixed(2));
                        $('#display_Wallets').html(userCharges[i].html);
                        $('#Perct_W').text(userCharges[i].Charge + '%');
                      }
                      else if (userCharges[i].PaymentMethod == "Upi") {
                        $('#spn_PayableAmountRazorpayUPI').text((userCharges[i].DisplayAmount).toFixed(2));
                        $('#display_UPI').html(userCharges[i].html);
                        $('#Perct_U').text(userCharges[i].Charge + '%');
                      }
                      else if (userCharges[i].PaymentMethod == "NetBanking") {
                        $('#spn_PayableAmountRazorpayNet').text((userCharges[i].DisplayAmount).toFixed(2));
                        $('#display_Netbanking').html(userCharges[i].html);
                        $('#Perct_N').text(userCharges[i].Charge + '%');
                      }
                    }
                  }
                })
            })
        }
      })
  }
  PayOnline(id) {
    if (this.phone != undefined && this.phone != null && this.phone != "" && this.email != undefined && this.email != null && this.email != "") {
      let payform;
      let data = {};
      let amount;
      if (id == 1) { // Debit Card
        amount = $('#spn_PayableAmountRazorpayCard').text() * 100;
        amount = parseInt(amount);
        let CVV = $("#txt_CVV");
        let cardNumber = $('#txt_CardNumber');
        let month = $("#ddl_CardExpiryMonth").val();
        let year = $("#ddl_CardExpiryYear").val();
        if (month == 0) {
          this.notifyService.showError("Please select card expiry month.", "");
          return false;
        }
        if (year == 0) {
          this.notifyService.showError("Please select card expiry year.", "");
          return false;
        }
        else {
          data = {
            amount: amount,
            currency: "INR",
            email: this.email,
            contact: this.phone,
            method: 'card',
            'card[name]': $('#txt_NameOnCard').val(),
            'card[number]': cardNumber,
            'card[cvv]': CVV,
            'card[expiry_month]': month,
            'card[expiry_year]': year
          };
          this.PayOnlineRazorPay(data, amount)
        }
      } else if (id == 2) {  // Credit card
        let payform;
        let data = {};
        let amount;
        amount = $('#spn_CreditPayableAmountRazorpayCard').text() * 100;
        amount = parseInt(amount);
        let CVV = $("#txt_CreditCVV");
        let cardNumber = $('#txt_CreditCardNumber');
        let month = $("#ddl_CreditCardExpiryMonth").val();
        let year = $("#ddl_CreditCardExpiryYear").val();
        if (month == 0) {
          this.notifyService.showError("Please select card expiry month.", "");
          return false;
        }
        if (year == 0) {
          this.notifyService.showError("Please select card expiry year.", "");
          return false;
        }
        else {
          data = {
            amount: amount,
            currency: "INR",
            email: this.email,
            contact: this.phone,
            method: 'card',
            'card[name]': $('#txt_CreditNameOnCard').val(),
            'card[number]': $('#txt_CreditCardNumber').val(),
            'card[cvv]': $('#txt_CreditCVV').val(),
            'card[expiry_month]': $('#ddl_CreditCardExpiryMonth').val(),
            'card[expiry_year]': $('#ddl_CreditCardExpiryYear').val()
          };
          this.PayOnlineRazorPay(data, amount)
        }

      } else if (id == 3) { // Netbanking
        let bankingval = $('#ddl_NetBankingBankList').val();
        if (bankingval != 0 && bankingval != "" && bankingval != null) {
          amount = $('#spn_PayableAmountRazorpayNet').text() * 100;
          amount = parseInt(amount);
          data = {
            amount: amount,
            currency: "INR",
            email: this.email,
            contact: this.phone,
            method: 'netbanking',
            bank: $('#ddl_NetBankingBankList').val()
          };
          this.PayOnlineRazorPay(data, amount)
        }
      } else if (id == 4) { // UPI Payment
        if (this.ISVPAValidate == false) {
          this.notifyService.showError("Please enter valid VPA.", "");
        }
        else {
          if ($('input[name="upi"]:checked').length == 0) {
            this.notifyService.showError("Please select UPI payment method.", "UPI");
            return false;
          }
          else {
            amount = $('#spn_PayableAmountRazorpayUPI').text() * 100;
            amount = parseInt(amount);
            data = {
              amount: amount,
              currency: "INR",
              email: this.email,
              contact: this.phone,
              method: 'upi',
              vpa: $('#txt_VPA').val(),
            };
            this.PayOnlineRazorPay(data, amount)
          }
        }
      } else if (id == 5) { // Wallet
        amount = $('#spn_PayableAmountRazorpayWall').text() * 100;
        amount = parseInt(amount);
        if ($('input[name="wallet"]:checked').length == 0) {
          this.notifyService.showError("Please select any one wallet payment method.", "");
          return false;
        }
        else {
          let walletName = "";
          $.each($('input[name="wallet"]:checked'), function () {
            walletName = $(this).val();
          });
          data = {
            amount: amount,
            currency: "INR",
            email: this.email,
            contact: this.phone,
            method: 'wallet',
            wallet: walletName
          };
          this.PayOnlineRazorPay(data, amount)
        }
      }
    }
  }

  public PayOnlineRazorPay(data, amount) {
    var razorpay = new Razorpay({
      key: this.razorPay_Key,
      image: this.logo,
    });
    const component = this;
    razorpay.once('ready', function (response) {
      razorpay.createPayment(data);

      razorpay.on('payment.success', function (resp) {
        this.payId = resp.razorpay_payment_id;
        component.InsertAcademyFeesDetails(this.payId, amount)
      });

      razorpay.on('payment.error', function (resp) {
        this.notifyService.showError(resp.error.description, "");
      });
    })
  }

  public InsertAcademyFeesDetails(payId, amount) {
    this.getListService
      .GetCapturepayment(payId, amount, this.originalAmount, this.feesPaymentPaneltyAmount, this.userdata.ParentAcademyId, this.userdata.AcademyId)
      .subscribe((response: any) => {
        let today = new Date();
        this.detail = {
          amount: amount / 100,
          PaneltyAmount: this.feesPaymentPaneltyAmount,
          ParentAcademyId: this.userdata.ParentAcademyId,
          Sms: true,
          Email: true,
          academyId: this.userdata.AcademyId,
          userId: this.userdata.UserId,
          sms: true,
          email: true,
          academyFeesDetails: {
            Id: 0,
            FeesHeaderId: this.setOnlinePayment.FeesHeaderId,
            Amount: this.originalAmount,
            ModeOfPayment: 2,
            PaymentDate: today,
            TransactionId: payId,
            CreatedBy: this.userdata.UserId,
            FeesPaymentRulesId: this.feesPaymentRulesId
          }
        }
        this.postService
          .AddAcademyFeesDetail(this.detail, this.userdata.AcademyId, this.userdata.UserId)
          .subscribe((response: any) => {
            this.router.navigate(['/studentfees/studentfeeslist'], {
              state: { example: this.batchId }
            })
              .then(() => {
                window.location.reload();
              });
          })
      })
  }

  ValidateVPA() {
    var _vpa = $('#txt_VPA').val();
    if (_vpa == undefined || _vpa == null || _vpa == "") {
      return false;
      $('#txt_VPA').val("");
    }
    else {
      var result = /^\w+@\w+$/.test(_vpa);
      this.ISVPAValidate = result;
    }
  }
}
