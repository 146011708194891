import { Component, OnInit, Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonFunction } from '../service/commonfunction';
import { GetListService } from '../service/getlist.service';
import $ from 'jquery'
import { DashboardComponent } from 'angular-google-charts';
import { DatePipe } from '@angular/common';
import { IdbService } from '../service/idb.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-select-academy',
  templateUrl: './select-academy.component.html',
  styleUrls: ['./select-academy.component.scss']
})
export class SelectAcademyComponent implements OnInit {
  ParentDrillAccess: any;
  MenuList: any = [];
  RoleTypeId: number;
  SelectAcademyForm: UntypedFormGroup;
  public GetAcademicList: any;
  public GetRoleList: any[];
  public GetMenuList: any[];
  AcademyLengthSize: number;
  userdata;
  isCoach = false;
  isParent = false;
  isAdmin = false;
  RoleLengthSize: number;
  SelectedAcademyName: any;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public commonFunction: CommonFunction,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    public idbService: IdbService,
    public getListService: GetListService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.onremoveclick();
    this.academyList();
    // console.log();
    // this.roleList();

  }
  onclick() {
    $("body").addClass("modal-open");
    $('#menu-sidebar-left-1').addClass('menu-active')
  }
  onremoveclick() {
    $("body").removeClass("modal-open");
    $('#menu-sidebar-left-1').removeClass('menu-active')
  }
  initForm() {
    this.SelectAcademyForm = this.formBuilder.group(
      {
        AcademyId: [2, [Validators.required]],
        RoleTypeId: [1, [Validators.required]],
      }
    );
  }

  AcademyChange(event) {
    let GetAcademyName = event.target.options[event.target.selectedIndex].text;
    this.SelectedAcademyName = GetAcademyName;
    this.getdashboarddata();
  }
  public getdashboarddata() {
    this.userdata = this.commonFunction.GetUserData();
    if (this.userdata.RoleTypeId == 1 || this.userdata.RoleType == 1) {
      this.isAdmin = true;
      this.GetAdminCount(this.userdata.AcademyId, this.userdata.UserId);
      this.GetNotificationCount();
    }
    else if (this.userdata.RoleTypeId == 2 || this.userdata.RoleType == 2) {
      this.isCoach = true;
      this.GetNotificationCount();
    }
  }
  GetAdminCount(academyId, userId) {
    let date = this.datePipe.transform(new Date(), 'd MMM yyyy');
    this.getListService
      .GetAdminDashboardCount(academyId, userId, date)
      .subscribe((response: any) => {
        let arraydash = [];
        let dashBoardData = response;
        arraydash.push(dashBoardData);
        this.idbService.clear("dashboard");
        let that = this;
        arraydash.forEach(function (item) {
          that.idbService.addItemsObs("dashboard", item);
        });
      })
  }
  GetNotificationCount() {
    this.getListService
      .GetNotificationCount(this.userdata.UserId, 0)
      .subscribe((response: any) => {
        let _notificationcount = response;
        let count = 0;
        if (_notificationcount > 0) {
          count = _notificationcount;
        }
        else {
          count = 0;
        }
        // this.idbService.getAllData("notificationcount").then((item: any) => {
        //   let getObj = item;
        //   let that = this;
        //   if (getObj.length == 0) {
        //     that.idbService.addItemsObs("notificationcount", count);
        //   }
        // })
      })
  }
  public academyList() {
    this.spinner.show();
    var data = this.commonFunction.GetUserData();
    this.getListService
      .getAcademicList(data)
      .subscribe((response: any) => {
        this.getListService
          .getRoleList(data)
          .subscribe((response1: any) => {
            this.spinner.hide();
            if (response.length > 1) {
              this.GetAcademicList = response;
              this.AcademyLengthSize = response.length;
              this.SelectedAcademyName = response[0].Name;
              this.SelectAcademyForm.controls['AcademyId'].setValue(response[0].Id);
            }
            else {
              this.GetAcademicList = response;
              this.SelectAcademyForm.controls['AcademyId'].setValue(response[0].Id);
              this.SelectedAcademyName = response[0].Name;
              this.AcademyLengthSize = response.length;
            }

            if (response1.length > 1) {
              this.GetRoleList = response1;
              this.RoleLengthSize = response1.length;
            }
            else {
              this.SelectAcademyForm.controls['RoleTypeId'].setValue(response1[0].RoleType);
              this.RoleLengthSize = response1.length;
              if (this.AcademyLengthSize == 1 && this.RoleLengthSize == 1) {
                this.SelectAcademySubmit();
              }
            }
          },
            (error) => {
              this.spinner.hide();
            });
      },
        (error) => {
          this.spinner.hide();
        });
  }

  public SelectAcademySubmit() {
    if (this.SelectAcademyForm.invalid) {
      return;
    }
    else {
      var data = {
        RoleTypeId: this.SelectAcademyForm.value.RoleTypeId,
        AcademyId: this.SelectAcademyForm.value.AcademyId
      };
      this.commonFunction.SetParentAcademyAndRoleTypeId(data);
      if (window.navigator.onLine == false) // Ofline
      {
        this.router.navigate(['/dashboard'])
          .then(() => {
            window.location.reload();
          });
      }
      else {
        this.getListService
          .getSettingData(data.AcademyId) //this.SelectAcademyForm.value.AcademyId
          .subscribe((response: any) => {
            this.commonFunction.SetAcademyNameandArray(this.SelectedAcademyName);
            if (response != null) {
              this.commonFunction.SetDrillInUserDataArray(response.ParentDrillAccess);
              this.commonFunction.SetApplyUserType(response.ApplyUserType);
              // this.getMenuList();
            }
            // else {

            // }
            this.getMenuList();
            // this.router.navigate(['/dashboard'])
            //   .then(() => {
            //     window.location.reload();
            //   });

          },
            (error) => {
            });
      }


    }

  }
  public getMenuList() {
    var data = this.commonFunction.GetUserData();
    if (data != null) {
      if (data.ParentDrillAccess != null) {
        this.ParentDrillAccess = data.ParentDrillAccess;
      }
    }
    if (data != null && data != undefined) {
      this.RoleTypeId = data.RoleType;
      this.getListService
        .GetMenuList(data)
        .subscribe((response: any) => {


          for (let i = 1; i < response.length; i++) {

            if (response[i].Name == "Attendence") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 0 });
            }
            else if (response[i].Name == "BatchPlanning") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 1 });
            }
            else if (response[i].Name == "CoachAttendance") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 2 });
            }
            else if (response[i].Name == "CoachEvalution") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 3 });
            }
            else if (response[i].Name == "Report") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 4 });
            }
            else if (response[i].Name == "Student") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 5 });
            }
            else if (response[i].Name == "AcademyInquiry") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 6 });
            }

            else if (response[i].Name == "Event") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 7 });
            }

            else if (response[i].Name == "AcademyFeesType                                                                                                                                                                                                                                                                                                                                                                                                        ") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 8 });
            }

            else if (response[i].Name == "Venue") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 9 });
            }

            else if (response[i].Name == "UserReport") {
              this.MenuList.push({ text: response[i].Name, value: response[i].Name, sequence: 10 });
            }
          }

          this.MenuList = this.MenuList.sort((a, b) => 0 - (a.sequence > b.sequence ? -1 : 1));
          this.router.navigate(['/dashboard'])
            .then(() => {
              window.location.reload();
            });
        },
          (error) => {
          });
    }
  }
}
