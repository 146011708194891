<div id="page">
  <a class="skip-link" href="#maincontent">Skip to main</a>
    <!-- Header -->
    <div class="header header-fixed header-logo-center">
      <span [routerLink]="['/inquiry']" *ngIf="!isSubscription" class="header-icon header-icon-1 textcolor">
        <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
      </span>
      <h2 class="header-title textcolor" *ngIf="setStudentProfileModel!=null">{{setStudentProfileModel.StudentName}}</h2>
      <span (click)="Edit(setStudentProfileModel.AcademyUserId)"
        class="header-icon header-icon-3 textcolor">Edit</span>
      <span *ngIf='actionbutton'  class="header-icon header-icon-4 textcolor">
        <img width="16" height="16" src="../../../assets/images/icons/ellipsis-v-white.png" alt="" />
      </span>
        <div class="profile-section">
            <div class="profilepage">
                <h3 class="student-sporttitle" id="txtAcademy" *ngIf="setStudentProfileModel!=null &&setStudentProfileModel.AcademyName!=undefined">{{setStudentProfileModel.AcademyName}}</h3>
                <h3 class="student-sporttitle Mg0" id="txtBatch" *ngIf="setStudentProfileModel!=null">{{setStudentProfileModel.BatchName}}</h3>
                <h3 class="student-sporttitle" *ngIf="setStudentProfileModel!=null &&setStudentProfileModel.InquiryDate!=undefined">{{setStudentProfileModel.InquiryDate|date:'dd MMM yyyy'}}</h3>
            </div>
        </div>
    </div>
    <div class="page-content header-clear-medium user_Details">
      <form id="user_Details">
        <ul class="formview pt-0">
          <li>
            <div class="row">
              <div class="col-4 font-14">Player type</div>
              <div class="col-8 font-18" *ngIf="setStudentProfileModel!=null">{{setStudentProfileModel.StudentType}}
              </div>
            </div>
          </li>
          <li *ngIf='isSubscription'>
            <div class="row">
              <div class="col-4 font-14">Subscription</div>
              <div class="col-8 font-18" *ngIf="setStudentProfileModel!=null">{{setStudentProfileModel.FromDate|
                date:"d MMM y" }} To {{setStudentProfileModel.ToDate| date:"d MMM y" }}
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-4 font-14">Birthday</div>
              <div class="col-8 font-18" *ngIf="setStudentProfileModel!=null">{{setStudentProfileModel.DateOfBirth|
                date:"d MMM y" }}</div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-4 font-14">E-mail</div>
              <div class="col-8 font-18" *ngIf="setStudentProfileModel!=null">{{setStudentProfileModel.Email}}</div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-4 font-14">Phone No.</div>
              <div class="col-8 font-18" *ngIf="setStudentProfileModel!=null">{{setStudentProfileModel.PhoneNumber}}</div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-4 font-14">Address</div>
              <div class="col-8 font-18" *ngIf="setStudentProfileModel!=null"> {{setStudentProfileModel.Address1}}
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-4 font-14">School Name</div>
              <div class="col-8 font-18" *ngIf="setStudentProfileModel!=null">{{setStudentProfileModel.School}}</div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-4 font-14">Comments</div>
              <div class="col-8 font-18" *ngIf="setStudentProfileModel!=null">{{setStudentProfileModel.Comments}}
              </div>
            </div>
          </li>
          <li *ngIf='isFatherExist' class="guardian">Guardian (Father)</li>
          <li class="border-0 pb-0" *ngIf='isFatherExist'>
            <div class="col-100 font-14"><b>{{fatherName}}</b></div>
            <div class="col-100"><img src="../../assets/images/KridApp-Image/phone-icon.png" alt="" class="w-img-16"><span class="graycolor">{{fatherPhone}}</span>
            </div>
            <div class="col-100"><img src="../../assets/images/KridApp-Image/email-icon.png" alt="" class="w-img-16"><span class="graycolor">{{fatherEmail}}</span>
            </div>
          </li>
          <li *ngIf='isMotherExist' class="guardian">Guardian (Mother)</li>
          <li class="border-0 pb-0" *ngIf='isMotherExist'>
            <div class="col-100 font-14"><b>{{motherName}}</b></div>
            <div class="col-100"><img src="../../assets/images/KridApp-Image/phone-icon.png" alt="" class="w-img-16"><span class="graycolor">{{motherPhone}}</span>
            </div>
            <div class="col-100"><img src="../../assets/images/KridApp-Image/email-icon.png" alt="" class="w-img-16"><span class="graycolor">{{motherEmail}}</span>
            </div>
          </li>
        </ul>
      </form>
      <!--Tab Content-->
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
      <p class="color-white"> Loading... </p>
    </ngx-spinner>
  </div>