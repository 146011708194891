<div class="header header-fixed header-logo-center">
    <span (click)="close()" class="header-icon header-icon-4 textcolor">
        <img width="16" height="16" src="../../../assets/images/icons/time.png" alt="" />
    </span>
    <h2 class="header-title textcolor noback">Reason</h2>
</div>
<div class="page-content header-clear-medium popup-mainarea">
    <div class="d-block">
        <div class="content border-0">
            <form [formGroup]="ReasonFormGroup" (ngSubmit)="ReasonSubmit()">
                <input type="hidden" formControlName="AcademyStudentId" value="">
                <input type="hidden" formControlName="FromDate" value="">
                <input type="hidden" formControlName="ToDate" value="">
                <input type="hidden" formControlName="UserId" value="">

                <div class="inputsection">
                    <span class="input-title font-14">Reason</span>
                    <div class="input-style input-required">
                        <select class="form-control" selected="0" formControlName="DropoutReasonId">
                            <option value="0">Select</option>
                            <option *ngFor="let item of GetAcademyLeaveReasonList" value="{{item.Id}}">{{item.Reason}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="inputsection">
                    <span class="input-title font-14">Reason Details</span>
                    <div class="input-style input-required">
                        <textarea class="form-control" rows="2" placeholder="LeaveReason"
                            formControlName="LeaveReason"></textarea>
                    </div>
                </div>
                <div class="inputsection">
                    <button type="submit" class="btn btn-block loginheadercolor textcolor">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>