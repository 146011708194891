import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from 'src/app/service/commonfunction';
import { GetListService } from 'src/app/service/getlist.service';
import { DashboardParam } from '../../_models/user.model';
import { PlayerTrainingComponent } from '../player-training/player-training.component';

@Component({
  selector: 'app-training-popup',
  templateUrl: './training-popup.component.html',
  styleUrls: ['./training-popup.component.scss']
})
export class TrainingPopupComponent implements OnInit {
  userdata: any;
  dashboardParam: DashboardParam;
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TrainingPopupComponent>,
    public route: ActivatedRoute,
    public getlistservice: GetListService,
    public commonFunction: CommonFunction,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  openStudentlistModal(param): void {
    const dialogRef = this.dialog.open(PlayerTrainingComponent, {
      width: '100%',
      height: '100%',
      data: {
        set: param,
        Date: this.data.date,
        drillId: this.data.val,
        batchId: this.data.batchId,
        AcademyId: this.data.AcademyId,
        slot: this.data.slot
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}

