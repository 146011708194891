<!-- Main Page -->
<div id="page">
    <!-- Header -->
    <div class="header header-fixed header-logo-center">
        <h2 class="header-title textcolor">StudentId</h2>
        <!-- Sidebar Menu Icon-->
        <a href="#"   class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </a> 
        <!-- Sidebar Menu Icon-->
        <a href="#" class="close-menu header-icon header-icon-4 textcolor right15" (click)="previouspage();"> Done </a>
    </div>
    <!-- Header -->

    <!-- Main Content Write Here... -->
    <div class="page-content header-clear-medium">
        <div class="card card-style">
            <div class="content mb-0">
                    <div class="input-style ">
                        <span class="color-highlight">Homework</span>
                        <textarea class="form-control"  placeholder="Homework"></textarea>
                    </div>  
                   
                    <div class="input-style ">
                        <span class="color-highlight">Diet</span>
                        <textarea class="form-control"  placeholder="Diet"></textarea>
                    </div>
                   
                    <div class="input-style ">
                        <span class="color-highlight">Comments</span>
                        <textarea class="form-control"  placeholder="Comments"></textarea>
                    </div>    
            </div> 
        </div>
    </div>
    <!-- Main Content Write Here... -->
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color="#fff" type = "ball-clip-rotate" [fullScreen] = "true"><p class="color-white"> Loading... </p></ngx-spinner>
</div>
<!-- Main Page -->
