<div class="header header-fixed header-logo-center">
  <span (click)="closemodel()" class="header-icon header-icon-1 textcolor">
    <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
  </span>
  <h2 class="header-title textcolor">Select Schedule</h2>
  <span (click)="done()" class="header-icon header-icon-4 textcolor right15">Done</span>
</div>
<div class="page-content header-clear-medium popup-mainarea">
  <label class="content" *ngFor="let item of GetScheduleList; index as i" (click)="scheduleDivclick(item.Id)">
    <div class="d-flex align-items-center">
      <ng-container *ngIf="item.checked==true">
        <input type="checkbox" checked class="getAllcheckBoxValue" name="{{item.BatchName}}" value={{item.Id}} id="schchchk_{{item.Id}}" />
        {{item.BatchName}}
      </ng-container>
      <ng-container *ngIf="item.checked==false">
        <input type="checkbox"class="getAllcheckBoxValue" name="{{item.BatchName}}" value={{item.Id}} id="schchchk_{{item.Id}}" />
        {{item.BatchName}}
      </ng-container>
    </div>
  </label>

</div>

