import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from 'src/app/service/commonfunction';
import { GetListService } from 'src/app/service/getlist.service';
import {SortfilterComponent } from '../sortfilter/sortfilter.component'

@Component({
  selector: 'app-popupfilter',
  templateUrl: './popupfilter.component.html',
  styleUrls: ['./popupfilter.component.scss']
})
export class PopupfilterComponent implements OnInit {

  FilterFormGroup: UntypedFormGroup;
  userdata:any;
  academyId;
  userId;
  isAccessAllBatches;
  BatchesList: any[];
  batchId;
  RoleType;
  subbatchId;
  parentacademyId;
  Batchnameid: any;
  ScheduleList: any[];
  GetFilterInquiryStatusList:any=[];
  ScheduleListId:any=[];

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopupfilterComponent>,
    public route: ActivatedRoute,
    public router: Router,
    public getlistservice :GetListService,
    public commonFunction:CommonFunction,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.FilterFormGroup = this.formBuilder.group({
      // BatchId: new FormControl(''),
      // ScheduleId: new FormControl('')
    });

  }

  ngOnInit(): void {
    this.userdata = this.commonFunction.GetUserData();

    this.BatchList();
    this.Inquirystatus();
  }


  openSortFilter(): void {
    const dialogRef = this.dialog.open(SortfilterComponent, {
      width: '100%',
      height:'100%',
      panelClass: 'custom-modalbox',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  closemodel(){
    this.dialogRef.close();
  }

  public BatchList(){
    this.academyId = this.userdata.AcademyId;
    this.userId = this.userdata.UserId;
    this.isAccessAllBatches = this.userdata.isAccessAllBatches;

    this.getlistservice
    .GetUserBatches( this.academyId, this.userId,this.isAccessAllBatches)
    .subscribe((response: any) => {
        this.BatchesList = response;
    },
      (error) => {
      });
  }

  BatchNameChange(event) {

    let value = event.target.value;
    this.Batchnameid = value;

    this.batchId = this.Batchnameid;
    this.userId = this.userdata.UserId,
    this.RoleType = this.userdata.RoleType,

    this.getlistservice
      .GetUserScheduleList(this.batchId,this.userId,this.RoleType)
      .subscribe((response: any) => {
        this.ScheduleList = response;
      },
        (error) => {
        });
  }

  schedulechange(event)
  {
    this.ScheduleListId=event.target.value;
  }
  public Inquirystatus(){
    this.parentacademyId = this.userdata.ParentAcademyId;

    this.getlistservice
    .GetFilterInquiryStatus(this.parentacademyId)
    .subscribe((response: any) => {
        this.GetFilterInquiryStatusList = response;
        this.GetFilterInquiryStatusList.push({
          AcademyId: 0,
          EncId: null,
          EncStatusValue: null,
          Id: 0,
          InquiryStatus: "Prospective",
          StatusValue: 6
        });
    },
      (error) => {
      });

  }

  done(){
    var batchesListId = new Array();
    var GetAllcheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("getAllcheckBoxValue"));

    for (let i = 0; i < GetAllcheckbox.length; i++) {
      if (GetAllcheckbox[i].checked) {
        batchesListId.push(Number(GetAllcheckbox[i].value));
      }
    }
    this.dialogRef.close({batchesListId:batchesListId,ScheduleListId:parseInt(this.ScheduleListId)});
  }
}
