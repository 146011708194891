import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BatchPlanningRoutingModule } from './batch-planning-routing.module';
import { BatchPlanningComponent } from './batch-planning.component';
import { BatchPlanningDatesComponent } from './BatchPlanningDates/batch-planning-dates/batch-planning-dates.component';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { CreateBatchPlanningComponent } from './create-batch-planning/create-batch-planning/create-batch-planning.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { ReactiveFormsModule } from '@angular/forms';
import { PopupbatchplanningdrillsComponent } from './popupbatchplanningdrills/popupbatchplanningdrills.component';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [BatchPlanningComponent, BatchPlanningDatesComponent, CreateBatchPlanningComponent, PopupbatchplanningdrillsComponent],
  imports: [
    CommonModule,
    BatchPlanningRoutingModule,
    MatDatepickerModule,
    FlatpickrModule.forRoot(),
    ReactiveFormsModule,
    NgxSpinnerModule
  ]
})
export class BatchPlanningModule { }
