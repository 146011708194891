import { Component, OnInit } from '@angular/core';
import { CommonFunction } from '../service/commonfunction';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../common/confirmation-dialog/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PostService } from '../service/post.service';
import { GetListService } from 'src/app/service/getlist.service';

@Component({
  selector: 'app-stripesuccess',
  templateUrl: './stripesuccess.component.html',
  styleUrls: ['./stripesuccess.component.scss']
})
export class StripesuccessComponent implements OnInit {

  userdata: any;
  feesheaderid: any;
  detail: any;
  amount: any;
  academyId;
  parentAcademyId;
  Userid;

  constructor(private commonFunction: CommonFunction,public getListService: GetListService, private route: ActivatedRoute, public dialog: MatDialog,
    public router: Router, private postservice: PostService,) { }

  ngOnInit(): void {
    this.userdata = this.commonFunction.GetUserData();
    this.route.queryParams.subscribe(params => {
      this.feesheaderid = params['f'];
      this.amount = params['a'];

     
        this.getListService
      .GetUserDetailsForPaymentOnline(this.feesheaderid)
      .subscribe((response: any) => {        
        this.userdata = response;
        this.academyId = this.userdata.academyId;
        this.parentAcademyId = this.userdata.parentAcademyId;
        this.Userid = this.userdata.userId;
      })   
      
 
      if (this.feesheaderid != null || this.feesheaderid != "") {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          panelClass: 'paymentsuccess',
          data: {
            message: 'Thank you for your payment! Your payment is successfully received.',
            buttonText: {
              ok: 'Continue'
            }
          }
        });

        let today = new Date();
        this.detail = {
          amount: this.amount,
          ParentAcademyId: this.parentAcademyId,
          Sms: true,
          Email: true,
          academyId: this.academyId,
          userId: this.Userid,
          sms: true,
          email: true,
          EncFeesHeaderId: this.feesheaderid,
          academyFeesDetails: {
            Id: 0,
            Amount: this.amount,
            ModeOfPayment: 2,
            PaymentDate: today,
            CreatedBy: this.Userid,
          }
        }

        this.postservice
          .AddAcademyFeesDetail(this.detail, this.academyId, this.Userid)
          .subscribe((response: any) => {

          })

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.router.navigate(['/dashboard'])
          }
          else {
            this.router.navigate(['/dashboard'])
          }
        });
      }
    });
  }
}
