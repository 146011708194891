import { Component, OnInit } from '@angular/core';
import { GetListService } from 'src/app/service/getlist.service';
import { CommonFunction } from 'src/app/service/commonfunction';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import $ from 'jquery';
import { SelectDrillPopupComponent } from './select-drill-popup/select-drill-popup.component';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})

export class TrainingComponent implements OnInit {
  pipe = new DatePipe('en-US');
  result;
  userdata;
  CurrentDate;
  public GetStudentList: any[];
  public GetBatchesList: any = [];
  public GetBatchesListNew: any = [];
  public slotlist: any = [];
  DateOfTrain: any;
  date: any;
  batchId;
  Trainingbatchdata;
  scheduleDate;
  scdate;
  trainingdate;
  Dayofweek: Date;
  public GetScheduleList: any = [];
  slotlistcount: any;
  slottime: any;

  constructor(
    public commonFunction: CommonFunction,
    public getListService: GetListService,
    private spinner: NgxSpinnerService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.onremoveclick();
    this.userdata = this.commonFunction.GetUserData();
    this.batchesList();
  }

  onclick() {
    $("body").addClass("modal-open");
    $('#menu-sidebar-left-1').addClass('menu-active')
  }

  onremoveclick() {
    $("body").removeClass("modal-open");
    $('#menu-sidebar-left-1').removeClass('menu-active')
  }

  back() {
    this.router.navigate(['/dashboard'])
  }

  batchesList() {
    this.spinner.show();
    var data = {
      academyId: this.userdata.AcademyId,
      userId: this.userdata.UserId,
      isAccessAllBatches: this.userdata.isAccessAllBatches
    };
    this.getListService
      .getBatchesList(data)
      .subscribe((response: any) => {
        let idstring = "";
        for (let i = 0; i < response.length; i++) {

          this.GetBatchesList.push({
            Id: response[i].Id,
            BatchName: response[i].BatchName,
            checked: false
          });
        }
        idstring = Array.prototype.map.call(this.GetBatchesList, s => s.Id).toString();
        this.spinner.hide();
        this.scheduleList(idstring)
      },
        (error) => {
        });
  }

  public scheduleList(id: any) {
    this.spinner.show();
    this.Dayofweek = new Date();
    var userdata = this.commonFunction.GetUserData();
    var data = {
      academyId: userdata.AcademyId,
      batchIds: id,
      userId: userdata.UserId,
      dayofWeek: this.Dayofweek.getDay(),
      roleId: userdata.RoleType
    };
    this.getListService
      .getScheduleList(data)
      .subscribe((response: any) => {
        for (let i = 0; i < response.length; i++) {
          this.GetScheduleList.push({
            Id: response[i].Id,
            BatchName: response[i].BatchName,
            checked: false,
            ParentBatchId: response[i].ParentBatchId,
            DayOfWeek: response[i].DayOfWeek
          });
        }
        let BatchIdArray = this.GetUniqueArrayDrills(this.GetScheduleList)
        this.GetBatchesListNew = [];
        for (let i = 0; i < BatchIdArray.length; i++) {
          for (let j = 0; j < this.GetBatchesList.length; j++) {
            if (BatchIdArray[i] == this.GetBatchesList[j].Id) {
              this.GetBatchesListNew.push(this.GetBatchesList[j])
            }
          }
        }
      },
        (error) => {

        });
    this.spinner.hide();
  }
  GetUniqueArrayDrills(array) {
    var flags = [], output = [], l = array.length, i;
    for (i = 0; i < l; i++) {
      if (flags[array[i].ParentBatchId]) continue;
      flags[array[i].ParentBatchId] = true;
      output.push(array[i].ParentBatchId);
    }
    return output;
  }
  scheduleDivclick(id) {

    var GetAllcheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("getAllcheckBoxValue"));
    let GetSchByDom = (<HTMLInputElement>document.getElementById('schchchk_' + id));
    for (var i = 0; i < GetAllcheckbox.length; i++) {
      GetAllcheckbox[i].checked = false;
    }
    GetSchByDom.checked = true;

    var Getalldrop = (<HTMLInputElement[]><any>document.getElementsByClassName("dropdown"));
    let dropbox = (<HTMLInputElement>document.getElementById('DropDown_' + id));

    this.getListService.GetBatchSlotTime(id, this.Dayofweek.getDay()).subscribe((data: any) => {
      this.slotlist = data;
      this.slotlistcount = data.length;
      if (this.slotlistcount > 0) {
        for (var i = 0; i < Getalldrop.length; i++) {
          Getalldrop[i].hidden = true;
        }
        dropbox.hidden = false;
      }
      else {
        for (var i = 0; i < Getalldrop.length; i++) {
          Getalldrop[i].hidden = true;
        }
      }
    })
  }

  selectChangeHandler(event: any) {
    this.slottime = event.target.value;
  }

  opendrillpopup() {
    var ScheduleListId = new Array();
    var GetAllcheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("getAllcheckBoxValue"));
    var slottt = (<HTMLInputElement>document.getElementById('slotvalue')).value;

    if (this.slottime == null) {
      this.slottime = slottt;
    }

    let SelectedCheckboxName = "";
    for (let i = 0; i < GetAllcheckbox.length; i++) {
      if (GetAllcheckbox[i].checked) {
        ScheduleListId.push(Number(GetAllcheckbox[i].value));
        SelectedCheckboxName += GetAllcheckbox[i].name + ',';
      }
    }

    var data = {
      academyId: this.userdata.AcademyId,
      batchIds: ScheduleListId,
      slot: this.slottime
    };

    this.router.navigate(['/SelectDrillTraining'], {
      state: { example: data }
    });
  }

  opentrapopup(param) {

    if (this.scheduleDate == undefined) {
      this.trainingdate = this.scdate.value;
    }
    else {
      this.trainingdate = this.scheduleDate;
    }

    const dialogRef = this.dialog.open(SelectDrillPopupComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'custom-modalbox',
      data: {
        date: this.trainingdate,
        val: param,
        batchId: this.batchId,
        AcademyId: this.userdata.AcademyId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
