<div class="header header-fixed header-logo-center">
    <span (click)="closemodel()" class="header-icon header-icon-1 textcolor">
      <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
    </span>
    <h2 class="header-title textcolor">Filter Inquiry</h2>
    <span (click)="done()" class="header-icon header-icon-4 textcolor right15">Done</span>
</div>

  <div class="page-content header-clear-medium popup-mainarea">
    <div class="d-block">
      <div class="content border-0">
        <div class="inputsection">
            <span class="input-title font-14">Batches</span>
            <div class="input-style input-required">
                <select class="form-control" selected="1" (change)="BatchNameChange($event);">
                    <option value="0">Select</option>
                    <option *ngFor="let item of BatchesList" value="{{item.Id}}">{{item.BatchName}}</option>
                </select>
            </div>
        </div>
        <div class="inputsection">
          <span class="input-title font-14">Schedule<span class="requird-field">*</span></span>
          <div class="input-style input-required">
              <select class="form-control" selected="1" (change)="schedulechange($event);">
                  <option value="0">All</option>
                  <option *ngFor="let item of ScheduleList" value="{{item.Id}}">{{item.BatchName}}</option>
              </select>
          </div>
        </div><div class="inputsection">
          <div class="accordion planinglist bg-white" id="accordion-1">
              <div>
                  <button type="button" class="btn accordion-btn" data-toggle="collapse" data-target="#Center">
                      <div class="row m-0 align-items-center">
                          <span class="col-6 p-0 font-weight-normal">Select Center</span>
                          <span class="col-6 p-0 text-right">
                              <span id="spn_SubscriptionFees"></span>
                              <i class="ml-2 accordion-icon"><img width="10" height="10" src="../../../assets/images/icons/chevron-down.png" alt="" /></i>
                          </span>
                      </div>
                  </button>
                  <div id="Center" class="accordion-control collapse" data-parent="#accordion-1">
                      <div class="fees">
                          <label class="w-100 ml-1 mr-1 mb-0">
                          <div class="d-flex align-items-center m-0" *ngFor="let item of GetFilterInquiryStatusList; index as i">
                            <input type="checkbox" class="getAllcheckBoxValue" name="{{item.InquiryStatus}}" value={{item.StatusValue}}/> {{item.InquiryStatus}}
                          </div>
                        </label>
                    </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
</div>