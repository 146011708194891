import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { GetListService } from '../service/getlist.service';
import { PostService } from '../service/post.service';
import { CommonFunction } from '../service/commonfunction';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PopupsceduleComponent } from './popupscedule/popupscedule.component';
import { PopupdrillComponent } from './popupdrill/popupdrill.component';
import { PopupstudentlistComponent } from './popupstudentlist/popupstudentlist.component';
import { PopupbatchesComponent } from './popupbatches/popupbatches.component';
import { NotificationService } from '../service/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardParam } from '../../app/_models/user.model';
import { DatePipe } from '@angular/common';
import { ConfirmationDialogComponent } from 'src/app/common/confirmation-dialog/confirmation-dialog.component';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {
  dashboardparam: DashboardParam;
  attendanceForm: UntypedFormGroup;
  public GetBatchesList: any[];
  public GetScheduleList: any[];
  public GetDrillList: any[];
  public GetStudentList: any[];
  attendanceDate: any;
  isShow = false;
  StudnetListArray: any = [];
  LeaveTypeListSArray: any = [];
  submitted = false;
  date: any;
  CurrentDate: any;
  MultipleBatchSchedule: boolean;
  slotname;
  HideShowSchedule = false;
  HideShowDrill = false;
  HideShowStudent = false;
  SelectedCheckboxNameBatch: string = "";
  SelectedCheckboxNameSchedule: string = "";
  SelectedStudentCount: string = "0";
  daysSelected: any[] = [];
  daysSelectedab: any[] = [];
  attendDate = new Date();
  Platform:any;
  isSelected = (event: any) => {
    var className = "";
    const date =
      event.getFullYear() +
      '-' +
      ('00' + (event.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + event.getDate()).slice(-2);
    if (this.daysSelected) {
      className = this.daysSelected.find((x) => x == date) ? 'selectedP' : null;
      if (className == "" || className == null) {
        className = this.daysSelectedab.find((x) => x == date) ? 'selectedA' : null
      }
      return className;
    }
    if (this.daysSelectedab) {
      ;
      return className;
    }
  };
  userdata: any;
  constructor(public route: ActivatedRoute,
    public formBuilder: UntypedFormBuilder,
    public router: Router,
    public getListService: GetListService,
    public postService: PostService,
    public commonFunction: CommonFunction,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.onremoveclick();
    this.userdata = this.commonFunction.GetUserData();
    this.GetMultipleBatchSchedule();
    this.dashboardparam = window.history.state.example;
    if (this.dashboardparam != undefined) {
      this.slotname = this.dashboardparam.SlotName;
      this.initForm();
      this.date = new Date();
      this.CurrentDate = this.date.getFullYear() + '-' + ('0' + (this.date.getMonth() + 1)).slice(-2) + '-' + ('0' + this.date.getDate()).slice(-2);

      this.openStudentlistModal();
    }
    else {
      this.initForm();
      this.CurrentDate = new Date(this.datePipe.transform(new Date(), 'MM/dd/yyyy'));
      this.attendanceForm.controls['AttendanceDate'].setValue(this.CurrentDate);
    }

  }

  GetMultipleBatchSchedule() {
    this.getListService
      .GetMultipleBatchSchedule(this.userdata.AcademyId)
      .subscribe((response: any) => {
        this.MultipleBatchSchedule = response.MultipleBatchSchedule;
      },
        (error) => {
        });
  }

  onclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.add('menu-active');
  }
  onremoveclick() {
    var element = <HTMLInputElement>document.getElementById("menu-sidebar-left-1");
    element.classList.remove('menu-active');
  }
  back() {
    this.router.navigate(['/dashboard'])
  }
  initForm() {
    this.attendanceForm = this.formBuilder.group(
      {
        AttendanceDate: ["", [Validators.required]],
        batchesListId: ["", [Validators.required]],
        scheduleListId: ["", [Validators.required]],
        drillListId: new UntypedFormControl(''),
        homework: new UntypedFormControl(''),
        diet: new UntypedFormControl(''),
        comments: new UntypedFormControl(''),
        Latitude: ["", [Validators.required]],
        Longitude: ["", [Validators.required]],
      }
    );

    this.attendanceForm.controls['AttendanceDate'].setValue(new Date(), 'MM/dd/yyyy');
    this.attendanceDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    var element = <HTMLInputElement>document.getElementById("AttendanceDate");
    element.value = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    this.getLocation()

  }
  onbatchDoneClick() {
    var batchesListId = new Array();
    var GetAllcheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("getAllcheckBoxValue"));

    for (let i = 0; i < GetAllcheckbox.length; i++) {
      if (GetAllcheckbox[i].checked) {
        batchesListId.push(Number(GetAllcheckbox[i].value));
      }
    }
    this.attendanceForm.controls['batchesListId'].setValue(batchesListId);
  }
  ChangeDate(e) {

    this.attendanceForm.controls['AttendanceDate'].setValue(e.target.value);
    this.attendanceDate = e.target.value;
  }
  public drillList(id: number) {
    var data = {
      batchIds: id,
      scheudleDate: this.attendanceDate
    };

    this.getListService
      .getSportDrilllist(data)
      .subscribe((response: any) => {
        this.GetDrillList = response;
      },
        (error) => {

        });
  }
  getLocation() {
    this.Platform = Capacitor.getPlatform();
    if(this.Platform == 'ios'){
      this.printCurrentPosition();
    }
    else{
      if (navigator.geolocation) {
        this.commonFunction.getPosition().then(pos => {
          if (pos.lat == 0 || pos.lat == 0) {
            // const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            //   panelClass: 'attendancesave',
            //   data: {
            //     message: 'If you wish to continue without sharing your location then click "Continue" else click "Cancel" to share your location. ',
            //     buttonText: {
            //       ok: 'Continue',
            //       cancel: 'Cancel'
            //     }
            //   }
            // });

            // dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            //   if (confirmed) {
            //     this.attendanceForm.controls['Latitude'].setValue(pos.lat);
            //     this.attendanceForm.controls['Longitude'].setValue(pos.lng);
            //     const a = document.createElement('a');
            //     a.click();
            //     a.remove();
            //     this.openBatchesModal();
            //   }
            // });
            // if(confirm("If you wish to continue without sharing your location then click 'Cancel' else click 'Ok' to share your location.")) {
            //   
            // }
          }
          else {
            this.attendanceForm.controls['Latitude'].setValue(pos.lat);
            this.attendanceForm.controls['Longitude'].setValue(pos.lng);
          }
  
        });
      }
    }
  }

  printCurrentPosition = async () => {
    let coordinates = await Geolocation.getCurrentPosition();
    if (coordinates.coords.latitude == 0 || coordinates.coords.longitude == 0) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        panelClass: 'attendancesave',
        data: {
          message: 'If you wish to continue without sharing your location then click "Continue" else click "Cancel" to share your location. ',
          buttonText: {
            ok: 'Continue',
            cancel: 'Cancel'
          }
        }
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.attendanceForm.controls['Latitude'].setValue(coordinates.coords.latitude);
          this.attendanceForm.controls['Longitude'].setValue(coordinates.coords.longitude);
          const a = document.createElement('a');
          a.click();
          a.remove();
          // this.openBatchesModal();
        }
      });
    }
    else {
      this.attendanceForm.controls['Latitude'].setValue(coordinates.coords.latitude);
      this.attendanceForm.controls['Longitude'].setValue(coordinates.coords.longitude);
    }
  }

  public attendanceSubmit() {
    this.submitted = true;
    if (this.dashboardparam != undefined) {
      this.attendanceForm.controls['batchesListId'].setValue(this.dashboardparam.batchId);
      this.attendanceForm.controls['scheduleListId'].setValue(this.dashboardparam.parentBatchId);
    }
    if (this.attendanceForm.invalid) {
      if (this.attendanceForm.controls['Latitude'].invalid == true || this.attendanceForm.controls['Longitude'].invalid == true) {
        this.commonFunction.getPosition().then(pos => {
          if (pos.lat == "" && pos.lng == "") {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
              panelClass: 'attendancesave',
              data: {
                message: 'If you wish to continue without sharing your location then click "Continue" else click "Cancel" to share your location. ',
                buttonText: {
                  ok: 'Continue',
                  cancel: 'Cancel'
                }
              }
            });

            dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                this.attendanceForm.controls['Latitude'].setValue(pos.lat);
                this.attendanceForm.controls['Longitude'].setValue(pos.lng);
                const a = document.createElement('a');
                a.click();
                a.remove();
              }
            });
          }
          else {
            this.attendanceForm.controls['Latitude'].setValue(pos.lat);
            this.attendanceForm.controls['Longitude'].setValue(pos.lng);
          }
        });
        // this.notifyService.showError("Please Enable Location!", "");
        this.getLocation()
      }
      else {
        this.notifyService.showError("Unable to save attendance. Please try again later. Contact the admin if problem persists.", "");
      }
      return;
    }

    var GetUserData = this.commonFunction.GetUserData();
    let BatchIdList = (this.attendanceForm.value.scheduleListId.length > 1) ? this.attendanceForm.value.scheduleListId.join(',') : this.attendanceForm.value.scheduleListId[0];
    var data =
    {
      BatchIdList: BatchIdList,
      userAttendance:
      {
        AcademyId: GetUserData.AcademyId,
        CreatedDate: new Date(),
        Createdby: GetUserData.UserId,
        Latitude: this.attendanceForm.value.Latitude,
        Longitude: this.attendanceForm.value.Longitude,
        AcademyBatchId: BatchIdList,
        AttendanceDate: this.datePipe.transform(this.attendanceForm.value.AttendanceDate, 'MM/dd/yyyy'),
        Homework: this.attendanceForm.value.homework,
        Diet: this.attendanceForm.value.diet,
        Comments: this.attendanceForm.value.comments
      },
      LoginAudit: {
        Latitude: this.attendanceForm.value.Latitude,
        Longitude: this.attendanceForm.value.Longitude
      },
      leaveTypeList: (this.LeaveTypeListSArray.length > 0) ? this.LeaveTypeListSArray : this.GetLeaveTypeListForDirectSubmit(),
      userList: (this.StudnetListArray.length > 0) ? this.StudnetListArray : this.GetStudentListForDirectSubmit(),
      academySetting: {
        AcademyId: 0,
        FeesTracking: false,
        LeaveReasonRequired: true
      }
    };
    this.spinner.show();
    this.postService
      .saveAttendance(data)
      .subscribe((response: any) => {

        if (response == null || response != null || response == undefined) {
          this.notifyService.showSuccess("Attendance saved successfully!", "");
          setTimeout(() => {
            this.router.navigate(['/dashboard']);
          }, 1000);

          this.spinner.hide();
        }
        else {
          this.notifyService.showError("Unable to save attendance. Please try again later. Contact the admin if problem persists.", "");
          this.spinner.hide();
        }

      },
        () => {
          this.submitted = false;
          this.notifyService.showError("Unable to save attendance. Please try again later. Contact the admin if problem persists.", "");
          this.spinner.hide();
        });


  }
  openBatchesModal(): void {
    if (this.attendanceForm.controls['Latitude'].value == '' || this.attendanceForm.controls['Longitude'].value == '') {
      this.commonFunction.getPosition().then(pos => {
        if (pos.lat == "" && pos.lng == "") {
        }
        else {
          this.attendanceForm.controls['Latitude'].setValue(pos.lat);
          this.attendanceForm.controls['Longitude'].setValue(pos.lng);
          this.OpenBatch();
        }
      });

      if(this.attendanceForm.controls['Latitude'].value == '' || this.attendanceForm.controls['Longitude'].value == ''){
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          panelClass: 'attendancesave',
          data: {
            message: 'Please turn on location from the notification and try again.',
            buttonText: {
              ok: 'Continue',
              cancel: 'Cancel'
            }
          }
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.router.navigate(['/dashboard']);
          }
          else{
            this.router.navigate(['/dashboard']);
          }
        });
      }
      //this.notifyService.showError("Please Enable Location!", "");

      // this.getLocation();
      
    }
    else {
      this.OpenBatch();
    }

  }
  OpenBatch() {
    this.HideShowSchedule = true;
    const dialogRef = this.dialog.open(PopupbatchesComponent, {
      width: '100%',
      height: '100%',
      data: {
        GetBatchesList: this.GetBatchesList,
        batchId: (this.dashboardparam != undefined) ? this.dashboardparam.batchId : 0,
        GetScheduleList: this.GetScheduleList,
        parentBatchId: (this.dashboardparam != undefined) ? this.dashboardparam.parentBatchId : 0,
        attendDate: this.attendanceDate,
        Latitude: this.attendanceForm.controls['Latitude'].value,
        Longitude: this.attendanceForm.controls['Longitude'].value,
        MultipleBatchSchedule: this.MultipleBatchSchedule
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openScheduleModal(): void {
    this.HideShowDrill = true;
    this.HideShowStudent = true;
    const dialogRef = this.dialog.open(PopupsceduleComponent, {
      width: '100%',
      height: '100%',
      data: {
        GetBatchesList: this.GetBatchesList,
        GetScheduleList: this.GetScheduleList,
        parentBatchId: (this.dashboardparam != undefined) ? this.dashboardparam.parentBatchId : 0,
        attendDate: this.attendanceDate
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.GetStudentList = result.data;
      this.attendanceForm.controls['scheduleListId'].setValue(result.data);
      this.GetScheduleList = result.data;
      this.SelectedCheckboxNameSchedule = result.SelectedCheckboxName;

    });
  }
  openStudentlistModal(): void {
    const dialogRef = this.dialog.open(PopupstudentlistComponent, {
      width: '100%',
      height: '100%',
      data: {
        AttendanceDate: (this.attendanceDate != undefined) ? this.attendanceDate : this.CurrentDate,
        BatchId: (this.dashboardparam != undefined) ? this.dashboardparam.batchId : this.GetStudentList,
        slot: (this.dashboardparam != undefined) ? this.dashboardparam.SlotName : this.slotname
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.StudnetListArray = result.data.StudentFeedBackArray,
          this.LeaveTypeListSArray = result.data.LeaveTypeListSArray,
          this.SelectedStudentCount = result.studentCount;
      }
      else {
        this.router.navigate(['/dashboard']);
      }

    });
  }
  openDrillModal(): void {
    const dialogRef = this.dialog.open(PopupdrillComponent, {
      width: '100%',
      height: '100%',
      data: {
        title: 'Test model'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  GetStudentListForDirectSubmit() {
    var userdata = this.commonFunction.GetUserData();
    var data = {
      academyId: userdata.AcademyId,
      batchIds: this.GetScheduleList,
      scheudleDate: this.attendanceDate
    };
    this.spinner.show();
    this.getListService
      .getStudentList(data)
      .subscribe((response: any) => {
        for (let i = 0; i < response.length; i++) {
          this.StudnetListArray.push({
            BatchId: response[i].AcademyBatchId,
            UserId: response[i].UserId,
            Homework: response[i].PrivateHomework,
            Diet: response[i].PrivateDiet,
            Comments: response[i].PrivateComments
          });
        }
        this.spinner.hide();
        return this.StudnetListArray;

      },
        (error) => {
          this.spinner.hide();
        });
  }
  GetLeaveTypeListForDirectSubmit() {
    var userdata = this.commonFunction.GetUserData();
    var data = {
      academyId: userdata.AcademyId,
      batchIds: this.GetScheduleList,
      scheudleDate: this.attendanceDate
    };

    this.getListService
      .getStudentList(data)
      .subscribe((response: any) => {
        for (let i = 0; i < response.length; i++) {
          this.LeaveTypeListSArray.push({
            UserId: response[i].UserId,
            Id: response[i].LeaveTypeId,
            LeaveShortName: response[i].LeaveShortName
          });
        }
        return this.LeaveTypeListSArray;
      },
        (error) => {

        });
  }
}
