import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonFunction } from 'src/app/service/commonfunction';
import { GetListService } from 'src/app/service/getlist.service';
import { NotificationService } from 'src/app/service/notification.service';
import { PostService } from 'src/app/service/post.service';
import $ from 'jquery';
@Component({
  selector: 'app-addextrafees',
  templateUrl: './addextrafees.component.html',
  styleUrls: ['./addextrafees.component.scss']
})
export class AddextrafeesComponent implements OnInit {

  @ViewChild('fileInput') el: ElementRef; 
  submitted = false;
  UploadimageUrl: any = "";
  AddFeesForm: UntypedFormGroup;
  elementId:number=3;
  GetlstfeesScreen:any=[];
  PenalityAmount:any;
  SelectKitFees:boolean=false;
  SelectRegistration:boolean=false;
  TaxPercentage: any;
  TaxDetailList: any;
  Taxname: any;
  academyFeesTypeList: any;
  KitfeesDropdown: any=[];
  getfeesdata:any=[];
  constructor(
    public route: ActivatedRoute,
    public formBuilder: UntypedFormBuilder,
    public router: Router,
    public commonFunction: CommonFunction,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddextrafeesComponent>,
    public getlistservice: GetListService,
    public postservice : PostService,
    private spinner: NgxSpinnerService,
    public notifyService:NotificationService
    ) { }

  ngOnInit(): void {
    this.initForm();
    if(this.data!=undefined)
    {
      this.AddFeesForm.patchValue({
        FeesAmount:this.data.Recurring,
        Penalty:0,
        Subscription:this.data.Recurring,
        PayableAmount:this.data.Recurring
      });
      
      if(this.data.SelectFilterFeesName=="Kit fees")
      {
          this.SelectKitFees=true;
          this.SelectRegistration=false;
          this.GetAcademyFeesType();
      }
      if(this.data.SelectFilterFeesName=="Registration fees")
      {
        $("#AmountTobePaid").attr('disabled', true);
        this.SelectRegistration=true;
        this.SelectKitFees=false;
        this.GetTaxDetails();
        this.AddFeesForm.controls['AmountTobePaid'].setValue(this.data.Recurring); 
      }
      this.GetSMSCountDetails();
    }
    
  }


  initForm() {
    this.AddFeesForm = this.formBuilder.group(
      {
        FeesAmount: new UntypedFormControl("",Validators.required) ,
        Subscription:new UntypedFormControl(""),
        PayableAmount:new UntypedFormControl(""),
        PaymentDate:new UntypedFormControl(""),
        Penalty:new UntypedFormControl(0),
        FeesModeId:new UntypedFormControl(3),
        BankName:new UntypedFormControl(""),
        ChequeNo:new UntypedFormControl(""),
        ChequeImageurl:new UntypedFormControl ("", Validators.required),
        ChequeDate:new UntypedFormControl(""),
        TransactionId:new UntypedFormControl(""),
        DraftNo:new UntypedFormControl(""),
        DraftDate:new UntypedFormControl(""),
        ReceivedOn:new UntypedFormControl(""),
        ReceivedBy:new UntypedFormControl(""),
        TransactionDetails:new UntypedFormControl(""),
        SendEmail:new UntypedFormControl(""),
        SendSMS:new UntypedFormControl(""),
        Type:new UntypedFormControl(""),
        AmountTobePaid:new UntypedFormControl("")
      }
    );

    var PaymentDate = <HTMLInputElement>document.getElementById("PaymentDate");
    PaymentDate.value = this.datePipe.transform(new Date(), 'MMM dd, yyyy');
    this.AddFeesForm.controls['PaymentDate'].setValue(PaymentDate.value);

    var ReceivedOn = <HTMLInputElement>document.getElementById("ReceivedOn");
    ReceivedOn.value = this.datePipe.transform(new Date(), 'MMM dd, yyyy');
    this.AddFeesForm.controls['ReceivedOn'].setValue(ReceivedOn.value);
  }

  get f() { return this.AddFeesForm.controls; }
  ngAfterViewChecked()
  {
    if(this.data.SelectFilterFeesName=="Kit fees")
    {
      $('#FeesAmount').attr('disabled',true);
    }
  }

  uploadFile(event) {
      var file: File = event.target.files[0];
      var myReader: FileReader = new FileReader();
      myReader.onloadend = (e) => {
        this.UploadimageUrl = myReader.result;
      }
      myReader.readAsDataURL(file);
  }

  FeesChange(event)
  {
    let SelectDropdownId=parseInt(event.target.value);
    switch(SelectDropdownId) {
      case 3: {
        this.elementId=3;
         break;
      }
      case 5: {
        this.elementId=5;
        this.AddFeesForm.controls['ChequeDate'].setValue("");
         break;
      }
      case 1: {
        this.elementId=1;
        this.AddFeesForm.controls['ChequeDate'].setValue("");
         break;
      }
      case 4: {
        this.elementId=4;
        this.AddFeesForm.controls['ChequeDate'].setValue("");
         break;
      }
      case 7: {
        this.elementId=7;
        this.AddFeesForm.controls['ChequeDate'].setValue("");
         break;
      }
      case 8: {
        this.elementId=8;
        this.AddFeesForm.controls['ChequeDate'].setValue("");
         break;
      }
      default: {
        this.elementId=3;
         break;
      }

    }

  }

  TypeChange(event)
  {
    var feesName=$("#Type  option:selected").text();
      this.getfeesdata=this.KitfeesDropdown.filter(x=>x.FeesType==feesName);
      this.GetTaxDetailsForKitFees();
    if (this.getfeesdata.length > 0) {
      this.AddFeesForm.patchValue({
        FeesAmount: this.getfeesdata[0].Recurring,
        Penalty: 0,
        Subscription: this.getfeesdata[0].Recurring,
        PayableAmount: this.getfeesdata[0].Recurring
      });
    }
    else
    {
      this.AddFeesForm.patchValue({
        FeesAmount:0,
        Penalty: 0,
        Subscription: 0,
        PayableAmount: 0
      });
    }
    this.TaxPercentage=0;
  }

  PenaltyChange(event) {
    this.PenalityAmount = parseInt(event.target.value);
    var AmountTobePaid = parseInt($('#AmountTobePaid').val());
    if (AmountTobePaid >= this.PenalityAmount) {
      var tax = (this.PenalityAmount * this.TaxPercentage) / 100;
      this.AddFeesForm.controls['Penalty'].setValue(tax);
      var PayableAmount = this.PenalityAmount + tax;
      this.AddFeesForm.controls['PayableAmount'].setValue(PayableAmount);
      this.AddFeesForm.controls['FeesAmount'].setValue(PayableAmount);
      this.AddFeesForm.controls['Subscription'].setValue(this.PenalityAmount);
    }
    else {
      this.AddFeesForm.controls['Subscription'].setValue(0);
      this.AddFeesForm.controls['PayableAmount'].setValue(0);
      this.AddFeesForm.controls['FeesAmount'].setValue(0);
      this.AddFeesForm.controls['Penalty'].setValue(0);
      this.notifyService.showError('Entered amount should not be more then' + AmountTobePaid, "");
    }
  }
    
  close() {
    this.dialogRef.close();
  }

  AddFeesSubmit()
  {
    this.submitted = true;
    if(this.AddFeesForm.value.FeesModeId==3)
    {
      if (this.AddFeesForm.invalid) {
        return;
      }
    }
    
    else
    {
      let Userdata = this.commonFunction.GetUserData();
      var feesHeader:any=[];
       if(this.data.SelectFilterFeesName=="Kit fees")
       {
        feesHeader={
          AcademyFeesOptionId:this.getfeesdata[0].AcademyFeesTypeOptionId,
          AcademyFeesTypeId: this.getfeesdata[0].Id,
          AcademyUserFeesId: 0,
          Amount: this.AddFeesForm.value.Subscription,
          Fees: this.AddFeesForm.value.Subscription,
          Tax: this.TaxPercentage,
          TaxAmount: this.AddFeesForm.value.Penalty,
          CreatedBy:Userdata.UserId,
        }
       }
       else 
       {
         feesHeader={
          AcademyFeesOptionId:0,
          AcademyFeesTypeId:this.data.FeesTypeId,
          AcademyUserFeesId: 0,
          Amount: this.AddFeesForm.value.Subscription,
          Fees: this.AddFeesForm.value.FeesAmount,
          Tax: this.TaxPercentage,
          TaxAmount: this.AddFeesForm.value.Penalty,
          CreatedBy:Userdata.UserId,
        }
       }


      let detail = {
        ParentAcademyId:Userdata.ParentAcademyId,
        feesHeader:feesHeader,
        academyFeesDetails: {
          Amount: parseFloat(this.AddFeesForm.value.FeesAmount),
          BankName: this.AddFeesForm.value.BankName,
          ChequeDate: this.datePipe.transform(this.AddFeesForm.value.ChequeDate, 'MM/dd/yyyy hh:mm:ss'),
          ChequeNumber:this.AddFeesForm.value.ChequeNo,
          ChequePic: this.UploadimageUrl,
          DraftDate: this.datePipe.transform(this.AddFeesForm.value.DraftDate=""?this.AddFeesForm.value.PaymentDate:this.AddFeesForm.value.DraftDate, 'MM/dd/yyyy hh:mm:ss'),
          DraftNumber: this.AddFeesForm.value.DraftNo,
          FeesHeaderId:0,
          Id:0,
          ModeOfPayment: this.AddFeesForm.value.FeesModeId,
          PaidBy:"",
          PaymentDate: this.datePipe.transform(this.AddFeesForm.value.PaymentDate, 'MM/dd/yyyy hh:mm:ss'),
          ReceivedBy: this.AddFeesForm.value.ReceivedBy,
          TransactionDetails: this.AddFeesForm.value.TransactionDetails,
          TransactionId: this.AddFeesForm.value.TransactionId,
          CreatedBy:Userdata.UserId
        },
      };
      var isSMS=this.AddFeesForm.value.SendSMS!=""?this.AddFeesForm.value.SendSMS:false;
      var IsEmail=this.AddFeesForm.value.SendEmail!=""?this.AddFeesForm.value.SendEmail:false;
      var academyUserId=(this.getfeesdata.length>0)? this.getfeesdata[0].Id:this.data.extrapaymentdata.AcademyUserId;
      var AcademyId=Userdata.AcademyId;
      
      this.spinner.show();
      this.postservice
        .AddAcademyFeesDetailWithoutListed(AcademyId,academyUserId,isSMS, IsEmail,detail)
        .subscribe((response: any) => {
          if (response > 0) {
            let data = window.history.state;
            this.notifyService.showSuccess("your payment has been successfully!", "");

            setTimeout(() => {
              this.router.navigate(['userfeesdetails'], {
                state: {
                  UserListData: {
                    UserId: data.AcademyUserId
                  },
                  scheduleId: data.ScheduleId,
                  batchId: data.BathchId,
                  Name: data.DisplayUserName,
                  Redirect: window.history.state.Redirect
                }

              }).then(() => {
              });
            }, 1000);
            
            this.dialogRef.close();
          }
          this.spinner.hide();
        },
          (error) => {
            this.spinner.hide();
          });
    }

  }


  public GetTaxDetails() {
    var userdata=this.commonFunction.GetUserData();
    var parentacademyId = userdata.ParentAcademyId;
    this.spinner.show();
    this.getlistservice.GetTaxDetails(parentacademyId).subscribe(
      (response: any) => {
        if (response != null) {
          this.TaxDetailList = response;
          this.TaxPercentage = response.TaxPercentage;
          this.Taxname = response.TaxName;
          var tax = (this.data.Recurring * this.TaxPercentage) / 100;
          this.AddFeesForm.controls['Penalty'].setValue(tax);
          var PayableAmount=this.data.Recurring+tax;
          this.AddFeesForm.controls['PayableAmount'].setValue(PayableAmount);
          this.AddFeesForm.controls['FeesAmount'].setValue(PayableAmount);
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  
  public GetTaxDetailsForKitFees() {
    var userdata=this.commonFunction.GetUserData();
    var parentacademyId = userdata.ParentAcademyId;
    this.spinner.show();
    this.getlistservice.GetTaxDetails(parentacademyId).subscribe(
      (response: any) => {
        if (response != null) {
          this.TaxDetailList = response;
          this.TaxPercentage = response.TaxPercentage;
          this.Taxname = response.TaxName;
          var tax = (this.data.Recurring * this.TaxPercentage) / 100;
           this.AddFeesForm.controls['Penalty'].setValue(tax);
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }


  public GetSMSCountDetails() {
    var userdata=this.commonFunction.GetUserData();
    var ParentAcademyId = userdata.ParentAcademyId;
      this.getlistservice.GetSMSCountDetails(ParentAcademyId).subscribe(
        (response: any) => {
            if(response!=null)
            {
              var SMS = <HTMLInputElement>document.getElementById("SendSMS");
              SMS.hidden = false;
              var displayName = <HTMLInputElement>document.getElementById("displayName");
              displayName.hidden = false;

            }
            else
            {
              var SMS = <HTMLInputElement>document.getElementById("SendSMS");
              SMS.hidden = true;
              var displayName = <HTMLInputElement>document.getElementById("displayName");
              displayName.hidden = true;
            }
         },
        (error) => {
          this.spinner.hide();
        }
      );
  }


  public GetAcademyFeesType() {
    var userdata=this.commonFunction.GetUserData();
    var AcademyId = userdata.AcademyId;
      this.getlistservice.GetAcademyFeesType(AcademyId).subscribe(
        (response: any) => {
          this.academyFeesTypeList = response;
          this.spinner.hide();
          for (let i = 0; i < this.academyFeesTypeList.length; i++) {
            if (this.academyFeesTypeList[i].IsSubFeesType == true) {
              this.KitfeesDropdown.push(this.academyFeesTypeList[i]);
            }
          }
         },
        (error) => {
          this.spinner.hide();
        }
      );
  }

}
