<div id="page">
    <div class="header header-fixed header-logo-center">
        <h2 class="header-title textcolor noback">Drills</h2>
        <span (click)="closemodel()" class="header-icon header-icon-4 textcolor right15">Done</span>
    </div>

    <div class="page-content header-clear-medium detail popup-mainarea">
        <ng-container>
            <ng-container *ngFor="let item of drillsarray">
                <ng-container *ngIf="item.DrillCategoryId==categoryId">
                    <div class="card batchlist card-style">
                        <label>
                        <span class="content pt-3 pb-3">
                            <input type="checkbox" [checked]="checkarray(item.Id)" class="getDrillsAllcheckBoxValue" name="{{item.Name}}"
                                value={{item.Id}} id="Drills_{{item.Id}}" /> {{item.Name}}
                        </span>
                    </label>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="show == 0">
                <div class="student-list text-center">
                    Drills Not Found
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>