<div class="header header-fixed header-logo-center">
  <span (click)="closemodel()" class="header-icon header-icon-1 textcolor">
    <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
  </span>
  <h2 class="header-title textcolor">Attendance</h2>
  <span (click)="done()" class="header-icon header-icon-4 textcolor right15">Done</span>
</div>

<div class="page-content header-clear-medium popup-mainarea">
  <div class="d-block">
    <div class="content border-0">
      <div class="inputsection">
        <span class="input-title font-14">Date<span class="requird-field">*</span></span>
        <div class="input-style input-required">
          <input type="text" (ngModelChange)="changedatefilter($event)" dateFormat="d.M.Y" [(ngModel)]="attendDate"
            class="form-control" mwlFlatpickr [convertModelValue]="true" maxDate="today">
        </div>
      </div>
      <div class="inputsection batchlistfound">
        <div *ngIf="isBatchFound" class="row m-0">
          <div class="col-6 p-0"></div>
          <div class="col-3 p-0 text-center"><b>Present</b></div>
          <div class="col-3 p-0 text-center"><b>Absent</b></div>
        </div>
        <div *ngIf="isBatchFound">
          <div class="row attandace-pa" *ngFor="let item of batchList;index as i">
            <div class="col-6 p-0">{{item.BatchName}}</div>
            <div class="col-3 p-0 text-center">
              <input type="checkbox" id="absentcoach_{{item.Id}}" [checked]="item.PresentId==1"
                (change)="chkevent(1,$event,item.Id)" class="{{item.Id}} getAllcheckBoxValue m-0 presentclass"
                [disabled]="item.PresentId==0 || item.PresentId==1?'disabled':''" />
            </div>
            <div class="col-3 p-0 text-center">
              <input type="checkbox" id="presentcoach_{{item.Id}}" [checked]="item.PresentId==0"
                (change)="chkevent(2,$event,item.Id)" class="{{item.Id}} getAllcheckBoxValue m-0 absentclass"
                [disabled]="item.PresentId==0 || item.PresentId==1 ?'disabled':''" />
            </div>
          </div>
        </div>
        <div *ngIf="isBatchNotFound" class="NotFound"> No batches today </div>
      </div>

      <div class="inputsection">
        <button *ngIf="SubmitHideShow" (click)="saveattendancecoach()" type="submit"
          class="btn mt-3 btn-block loginheadercolor textcolor">Submit</button>
      </div>
    </div>
  </div>

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
    <p class="color-white"> Loading... </p>
  </ngx-spinner>