<div>
  <form [formGroup]="attendanceFeedbackForm" >
    <div class="header header-fixed header-logo-center">
      <span (click)="closemodel()" class="header-icon header-icon-1 textcolor">
        <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
      </span>
      <h2 class="header-title textcolor">Player Feedback</h2>
       <button type="button"  (click)="attendanceFeedbackFormSubmit();" class="header-icon header-icon-4 textcolor right15">Done</button>
    </div>
    <div class="page-content header-clear-medium detail popup-mainarea">
      <div class="d-block">
        <div class="content border-0">
          <input type="hidden" formControlName="userid">
          <div class="inputsection">
            <span class="color-highlight input-title font-14">Homework</span>
            <textarea class="form-control" rows="2" placeholder="Homework" formControlName="PrivateHomework"></textarea>
          </div>
          <div class="inputsection">
            <span class="color-highlight input-title font-14">Diet</span>
            <textarea class="form-control" rows="2" placeholder="Diet" formControlName="PrivateDiet"></textarea>
          </div>
          <div class="inputsection">
            <span class="color-highlight input-title font-14">Comments</span>
            <textarea class="form-control" rows="2" placeholder="Comments" formControlName="PrivateComments"></textarea>
          </div>
          <div class="inputsection">
            <span class="color-highlight input-title font-14">Temperature</span>
            <input type="text" class="form-control" placeholder="Temperature" formControlName="Temperature">
          </div>
        </div>
      </div>
    </div>
  </form>
</div>