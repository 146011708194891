import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import $ from 'jquery'
import { CommonFunction } from 'src/app/service/commonfunction';
import { GetListService } from 'src/app/service/getlist.service';
import { AddextrafeesComponent } from '../addextrafees/addextrafees.component';
import { AddfeesComponent } from '../addfees/addfees.component';
import { PopupfilterfeesdetailsComponent } from '../popupfilterfeesdetails/popupfilterfeesdetails.component';
@Component({
  selector: 'app-studentfeestype',
  templateUrl: './studentfeestype.component.html',
  styleUrls: ['./studentfeestype.component.scss']
})
export class StudentfeestypeComponent implements OnInit {
  menuopenstatus: boolean = true;
  UserName: any;
  param: any;
  userFeesDetailsList: any = [];
  CheckedUserFeesDetailsList: any = [];
  AacdemyFeesTypeList: any = [];
  IsBackStudentList: boolean = false;
  CountOutstanding: any = 0;
  DefaultfeesTypeId: any = -1;
  feesTypeId: any = -1;
  Options: any;
  Recurring: any;
  AcademyId: any;
  PaymentFilter: boolean;
  NoFound: boolean = false;
  SelectFilterFeesName: any;
  currencysymbol: any;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public getlistservice: GetListService,
    public commonFunction: CommonFunction,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    // console.log(window.history.state);
    if (window.history.state != undefined) {
      // console.log(window.history.state.Filter);

      this.param = window.history.state;
      this.UserName = this.param.DisplayUserName;
      this.GetAacdemyFeesTypeList();
      this.GetFeesDetailsByFeesTypeId();
      this.IsBackStudentList = (window.history.state.BackStudentList != undefined) ? true : false;
      var btn_AddWithoutListed = <HTMLInputElement>document.getElementById("btn_AddWithoutListed");
      // btn_AddWithoutListed.disabled = true;
      var UserData = this.commonFunction.GetUserData();
      this.AcademyId = UserData.AcademyId;
      this.currencysymbol = UserData.CountryCurrencySymbol;
    }


  }

  onclick(menuopenstatus) {
    if (menuopenstatus == true) {
      $("body").addClass("modal-open");
      $('#menu-sidebar-left-1').addClass('menu-active')
      this.menuopenstatus = false;
    }
    else {
      $("body").removeClass("modal-open");
      $('#menu-sidebar-left-1').removeClass('menu-active');
      this.menuopenstatus = true;
    }

  }

  GetAacdemyFeesTypeList() {
    var UserData = this.commonFunction.GetUserData();
    this.getlistservice
      .GetAcademyFeesList(UserData.AcademyId, UserData.ParentAcademyId)
      .subscribe((response: any) => {
        this.AacdemyFeesTypeList = response;
      },
        (error) => {
        });
  }

  openpopupfilterfeesdetails() {
    if (this.PaymentFilter) {
      const dialogRef = this.dialog.open(PopupfilterfeesdetailsComponent, {
        width: '100%',
        height: '100%',
        panelClass: 'custom-modalbox',
        data: { val: "" }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.feesTypeId = (result != undefined) ? result.Id : -1;
        this.SelectFilterFeesName = result.FeesType;
        this.GetFeesDetailsByFeesTypeId();
        if (this.feesTypeId == 0 || this.feesTypeId == -1) {
          var btn_AddWithoutListed = <HTMLInputElement>document.getElementById("btn_AddWithoutListed");
          btn_AddWithoutListed.disabled = true;
        } else {
          var btn_AddWithoutListed = <HTMLInputElement>document.getElementById("btn_AddWithoutListed");
          btn_AddWithoutListed.disabled = false;
          this.Options = result.Options;
          this.Recurring = result.Recurring;
        }
      });
    }
  }

  GetFeesDetailsByFeesTypeId() {
    this.getlistservice
      .GetFeesDetailsByFeesTypeId(this.param.AcademyUserId, this.DefaultfeesTypeId, this.param.SubBatchId)
      .subscribe((response: any) => {
        // console.log(response);

        if (response != null) {
          this.userFeesDetailsList = response;
          if (this.PaymentFilter == true) {
            // if(this.feesTypeId=="0")
            // {
            var userFeesDetailsList = this.userFeesDetailsList.filter(x => x.AcademyFeesTypeId == this.feesTypeId);
            this.userFeesDetailsList = [];
            this.userFeesDetailsList = userFeesDetailsList;
            if (userFeesDetailsList.length > 0) {
              this.NoFound = false;
            }
            else {
              this.NoFound = true;
            }
            // }
            // else if(this.feesTypeId=="853")
            // {
            //   var userFeesDetailsList=this.userFeesDetailsList.filter(x=>x.FeesType=="Registration fees");
            //   this.userFeesDetailsList=[];
            //   this.userFeesDetailsList=userFeesDetailsList;
            //   if(userFeesDetailsList.length>0)
            //   {
            //       this.NoFound=false;
            //   }
            //   else
            //   {
            //     this.NoFound=true;
            //   }
            // }
            // else
            // {
            //   var userFeesDetailsList=this.userFeesDetailsList.filter(x=>x.FeesType=="Kit fees");
            //   this.userFeesDetailsList=[];
            //   this.userFeesDetailsList=userFeesDetailsList;
            //   if(userFeesDetailsList.length>0)
            //   {
            //       this.NoFound=false;
            //   }
            //   else
            //   {
            //     this.NoFound=true;
            //   }
            // }
          }
          for (let i = 0; i < this.userFeesDetailsList.length; i++) {
            if (this.userFeesDetailsList[i].AppOutstanding > 0) {
              this.CountOutstanding++;
            }
          }
          if (this.CountOutstanding > 0 && this.feesTypeId == -1) {
            this.PaymentFilter = false;

          }
          else {
            this.PaymentFilter = true;
            var btnpayshow = <HTMLInputElement>document.getElementById("pay");
            btnpayshow.disabled = true;
          }
        }
        else {
          this.NoFound = false;
        }

      },
        (error) => {
        });
  }

  back() {

    if (this.IsBackStudentList == true) {
      this.router.navigate(['studentmodule/list'], {
        state: { Filter: window.history.state.Filter }
      });


    }
    else {
      this.router.navigate(['userfeesdetails'], {
        state: {
          UserListData: { UserId: this.param.AcademyUserId, Name: this.UserName, Id: this.param.AcademyUserId },
          scheduleId: this.param.ScheduleId,
          batchId: this.param.BathchId,
          Redirect: window.history.state.Redirect,
        }
      });
    }
  }

  payall() {
    var btnpayallshow = <HTMLInputElement>document.getElementById("payall");
    btnpayallshow.hidden = true;
    var btnpayshow = <HTMLInputElement>document.getElementById("pay");
    btnpayshow.hidden = false;
    var btnclearshow = <HTMLInputElement>document.getElementById("clear");
    btnclearshow.hidden = false;
    var checkboxshow = (<HTMLInputElement[]><any>document.getElementsByName("feesdetailscheckbox"));

    for (let i = 0; i < checkboxshow.length; i++) {
      checkboxshow[i].hidden = false;
    }
  }
  clear() {

    var btnpayallshow = <HTMLInputElement>document.getElementById("payall");
    btnpayallshow.hidden = false;

    var btnpayshow = <HTMLInputElement>document.getElementById("pay");
    btnpayshow.hidden = true;
    var btnclearshow = <HTMLInputElement>document.getElementById("clear");
    btnclearshow.hidden = true;
    var checkboxshow = (<HTMLInputElement[]><any>document.getElementsByName("feesdetailscheckbox"));
    for (let i = 0; i < checkboxshow.length; i++) {
      checkboxshow[i].hidden = true;
    }

  }
  pay() {
    let TotalAmount = 0;
    var GetGreenCheckbox = (<HTMLInputElement[]><any>document.getElementsByClassName("geencheckbox"));
    for (let i = 0; i < GetGreenCheckbox.length; i++) {
      if (GetGreenCheckbox[i].checked == true) {
        TotalAmount = TotalAmount + parseInt(GetGreenCheckbox[i].value);

        for (let j = 0; j < this.userFeesDetailsList.length; j++) {
          if (GetGreenCheckbox[i].value == this.userFeesDetailsList[j].AppOutstanding) {
            this.CheckedUserFeesDetailsList.push(
              {
                "AcademyUserId": this.userFeesDetailsList[j].AcademyUserId,
                "AppAmount": this.userFeesDetailsList[j].AppAmount,
                "AppOutstanding": this.userFeesDetailsList[j].AppOutstanding,
                "AppPaid": this.userFeesDetailsList[j].AppPaid,
                "AppTaxAmount": this.userFeesDetailsList[j].AppTaxAmount,
                "AppTotalFees": this.userFeesDetailsList[j].AppTotalFees,
                "BatchId": this.userFeesDetailsList[j].BatchId,
                "FeesType": this.userFeesDetailsList[j].FeesType,
                "FeesTypeId": this.userFeesDetailsList[j].FeesTypeId,
                "FeesTypeOptions": this.userFeesDetailsList[j].FeesTypeOptions,
                "FromDate": this.userFeesDetailsList[j].FromDate,
                "HeaderId": this.userFeesDetailsList[j].HeaderId,
                "InvoiceId": this.userFeesDetailsList[j].InvoiceId,
                "IsButtonInside": this.userFeesDetailsList[j].IsButtonInside,
                "IsFeesPaid": this.userFeesDetailsList[j].IsFeesPaid,
                "Outstanding": this.userFeesDetailsList[j].Outstanding,
                "Paid": this.userFeesDetailsList[j].Paid,
                "PaymentDate": this.userFeesDetailsList[j].PaymentDate,
                "PenaltyOrDiscount": this.userFeesDetailsList[j].PenaltyOrDiscount,
                "ReceiptNumber": this.userFeesDetailsList[j].ReceiptNumber,
                "TaxPercent": this.userFeesDetailsList[j].TaxPercent,
                "ToDate": this.userFeesDetailsList[j].ToDate,
                "TotalAmount": this.userFeesDetailsList[j].TotalAmount,
                "TotalFees": this.userFeesDetailsList[j].TotalFees,
                "taxableAmount": this.userFeesDetailsList[j].taxableAmount,
                "SelectCheckBox": true
              }
            );
          }
        }
      }
    }

    const dialogRef = this.dialog.open(AddfeesComponent, {
      width: '100%',
      height: '100%',
      data: { item: TotalAmount, isSMS: true, CheckedUserFeesDetailsList: this.CheckedUserFeesDetailsList }
    });
  }

  AddExtraPayment() {
    const dialogRef = this.dialog.open(AddextrafeesComponent, {
      width: '100%',
      height: '100%',
      data: {
        extrapaymentdata: window.history.state,
        Options: this.Options,
        Recurring: this.Recurring,
        AcademyId: this.AcademyId,
        SelectFilterFeesName: this.SelectFilterFeesName,
        FeesTypeId: this.feesTypeId
      }
    });

  }

  feesinfo(item) {
    this.router.navigate(['feesinfo'], {
      state: {
        feesinfo: item,
        AcademyUserId: this.param.AcademyUserId,
        BathchId: this.param.BathchId,
        DisplayUserName: this.param.DisplayUserName,
        ScheduleId: this.param.ScheduleId,
        Redirect: window.history.state.Redirect,
        UserListData: window.history.state.UserListData
      }
    });
  }
}
