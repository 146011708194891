import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from 'src/app/service/commonfunction';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DatePipe } from '@angular/common';
import { GetListService } from 'src/app/service/getlist.service';
import { PostService } from '../../service/post.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/service/notification.service';
import { StudentfeestypeComponent } from '../studentfeestype/studentfeestype.component';
@Component({
  selector: 'app-addfees',
  templateUrl: './addfees.component.html',
  styleUrls: ['./addfees.component.scss']
})
export class AddfeesComponent implements OnInit {
  @ViewChild('fileInput') el: ElementRef;
  submitted = false;
  UploadimageUrl: any = "";
  AddFeesForm: UntypedFormGroup;
  elementId: number = 3;
  GetlstfeesScreen: any = [];
  PenalityAmount: any;
  userdata: any;
  filter: any;
  feedetail: any;

  constructor(
    public route: ActivatedRoute,
    public formBuilder: UntypedFormBuilder,
    public router: Router,
    public commonFunction: CommonFunction,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddfeesComponent>,
    public getlistservice: GetListService,
    public postservice: PostService,
    private spinner: NgxSpinnerService,
    public notifyService: NotificationService
  ) { }

  ngOnInit(): void {
    this.userdata = window.history.state;
    // console.log(window.history.state.AcademyUserId);

    this.filter = window.history.state.Filter;
    // console.log(this.userdata);

    // console.log(this.data);


    this.initForm();
    if (this.data != undefined) {
      this.AddFeesForm.patchValue({
        FeesAmount: (this.data.item.Outstanding != undefined) ? this.data.item.Outstanding : this.data.item,
        Penalty: 0,
        Subscription: (this.data.item.Outstanding != undefined) ? this.data.item.Outstanding : this.data.item,
        PayableAmount: (this.data.item.Outstanding != undefined) ? this.data.item.Outstanding : this.data.item
      });
    }
  }


  initForm() {
    this.AddFeesForm = this.formBuilder.group(
      {
        FeesAmount: new UntypedFormControl(""),
        Subscription: new UntypedFormControl(""),
        PayableAmount: new UntypedFormControl(""),
        PaymentDate: new UntypedFormControl(""),
        Penalty: new UntypedFormControl(0),
        FeesModeId: new UntypedFormControl(3),
        BankName: new UntypedFormControl(""),
        ChequeNo: new UntypedFormControl(""),
        ChequeImageurl: new UntypedFormControl("", Validators.required),
        ChequeDate: new UntypedFormControl(""),
        TransactionId: new UntypedFormControl(""),
        DraftNo: new UntypedFormControl(""),
        DraftDate: new UntypedFormControl(""),
        ReceivedBy: new UntypedFormControl(""),
        TransactionDetails: new UntypedFormControl(""),
        SendEmail: new UntypedFormControl(""),
        SendSMS: new UntypedFormControl("")
      }
    );

    var PaymentDate = <HTMLInputElement>document.getElementById("PaymentDate");
    PaymentDate.value = this.datePipe.transform(new Date(), 'MMM dd, yyyy');
    this.AddFeesForm.controls['PaymentDate'].setValue(PaymentDate.value);


    if (this.data.isSMS == true) {
      var SMS = <HTMLInputElement>document.getElementById("SendSMS");
      SMS.hidden = false;

      var displayName = <HTMLInputElement>document.getElementById("displayName");
      displayName.hidden = false;

      var Penalty = <HTMLInputElement>document.getElementById("Penalty");
      Penalty.hidden = false;

      var PenaltyName = <HTMLInputElement>document.getElementById("PenaltyName");
      PenaltyName.hidden = false;


    }
    else {
      var SMS = <HTMLInputElement>document.getElementById("SendSMS");
      SMS.hidden = true;
      var displayName = <HTMLInputElement>document.getElementById("displayName");
      displayName.hidden = true;

      var Penalty = <HTMLInputElement>document.getElementById("Penalty");
      Penalty.hidden = true;

      var PenaltyName = <HTMLInputElement>document.getElementById("PenaltyName");
      PenaltyName.hidden = true;

    }
  }

  get f() { return this.AddFeesForm.controls; }
  ngAfterViewChecked() {
    var ChequeDate = <HTMLInputElement>document.getElementById("ChequeDate");
    if (ChequeDate != null) {
      if (ChequeDate.value == "") {
        ChequeDate.value = this.datePipe.transform(new Date(), 'MMM dd, yyyy');
        this.AddFeesForm.controls['ChequeDate'].setValue(ChequeDate.value);

      }

    }
    var DraftDate = <HTMLInputElement>document.getElementById("DraftDate");
    if (DraftDate != null) {
      if (DraftDate.value == "") {
        DraftDate.value = this.datePipe.transform(new Date(), 'MMM dd, yyyy');
        this.AddFeesForm.controls['DraftDate'].setValue(DraftDate.value);
      }

    }
  }

  uploadFile(event) {
    var file: File = event.target.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.UploadimageUrl = myReader.result;
    }
    myReader.readAsDataURL(file);
  }

  FeesChange(event) {
    let SelectDropdownId = parseInt(event.target.value);
    switch (SelectDropdownId) {
      case 3: {
        this.elementId = 3;

        break;
      }
      case 5: {
        this.elementId = 5;
        break;
      }
      case 1: {
        this.elementId = 1;
        break;
      }
      case 4: {
        this.elementId = 4;
        break;
      }
      case 7: {
        this.elementId = 7;
        break;
      }
      case 8: {
        this.elementId = 8;
        break;
      }
      default: {
        this.elementId = 3;
        break;
      }
    }
  }


  PenaltyChange(event) {
    this.PenalityAmount = parseInt(event.target.value);
    let GetSubscriptionAmount = <HTMLInputElement>document.getElementById("Subscription");
    let SubscriptionAmount = parseInt(GetSubscriptionAmount.value);
    let GetPayableAmount = <HTMLInputElement>document.getElementById("PayableAmount");
    GetPayableAmount.value = (this.PenalityAmount + SubscriptionAmount).toString();
    let GetFeesAmount = <HTMLInputElement>document.getElementById("FeesAmount");
    GetFeesAmount.value = GetPayableAmount.value;

    this.AddFeesForm.patchValue({
      FeesAmount: GetPayableAmount.value,
      PayableAmount: GetPayableAmount.value
    });
  }
  close() {
    this.dialogRef.close();
  }

  AddFeesSubmit() {
    this.submitted = true;
    if (this.AddFeesForm.value.FeesModeId == 3) {
      if (this.AddFeesForm.invalid) {
        return;
      }
    }

    else {
      let Lastfeesscreen: any = [];
      this.GetlstfeesScreen = this.data.CheckedUserFeesDetailsList != undefined ? this.data.CheckedUserFeesDetailsList : window.history.state.feesinfo;
      if (this.GetlstfeesScreen.length != undefined) {
        for (let i = 0; i < this.GetlstfeesScreen.length; i++) {
          Lastfeesscreen.push({
            Amount: this.GetlstfeesScreen[i].AppOutstanding,
            HeaderId: this.GetlstfeesScreen[i].HeaderId
          });
        }
      }
      else {
        Lastfeesscreen.push({
          Amount: this.GetlstfeesScreen.AppOutstanding,
          HeaderId: this.GetlstfeesScreen.HeaderId
        });

      }
      let Userdata = this.commonFunction.GetUserData();
      let userFeesDetails = {
        PaneltyAmount: this.PenalityAmount == undefined ? 0 : this.PenalityAmount,
        ParentAcademyId: Userdata.ParentAcademyId,
        IsSms: this.AddFeesForm.value.SendSMS,
        IsEmail: this.AddFeesForm.value.SendEmail,
        AcademyId: Userdata.AcademyId,
        AcademyUserId: window.history.state.AcademyUserId,
        lstfeesScreen: Lastfeesscreen,
        academyFeesDetails: {
          Amount: parseFloat(this.AddFeesForm.value.FeesAmount),
          BankName: this.AddFeesForm.value.BankName,
          ModeOfPayment: this.AddFeesForm.value.FeesModeId,
          ChequeNumber: parseInt(this.AddFeesForm.value.ChequeNo),
          TransactionDetails: this.AddFeesForm.value.TransactionDetails,
          PaymentDate: this.datePipe.transform(this.AddFeesForm.value.PaymentDate, 'MM/dd/yyyy hh:mm:ss'),
          ChequeDate: this.datePipe.transform(this.AddFeesForm.value.ChequeDate, 'MM/dd/yyyy hh:mm:ss'),
          ReceivedBy: this.AddFeesForm.value.ReceivedBy,
          DraftNumber: this.AddFeesForm.value.DraftNo,
          DraftDate: this.datePipe.transform(this.AddFeesForm.value.DraftDate = "" ? this.AddFeesForm.value.PaymentDate : this.AddFeesForm.value.DraftDate, 'MM/dd/yyyy hh:mm:ss'),
          TransactionId: this.AddFeesForm.value.TransactionId,
          ChequePic: this.UploadimageUrl,
        },
      };

      this.spinner.show();
      this.postservice
        .SaveAllFeesOfUser(userFeesDetails)
        .subscribe((response: any) => {
          if (response > 0) {
            let data = window.history.state;
            this.notifyService.showSuccess("your payment has been successfully!", "");

            this.router.navigate(['userfeesdetails'], {
              state: {
                // BathchId: window.history.state.BatchId,
                // ScheduleId: window.history.state.ScheduleId,
                // DisplayUserName: window.history.state.DisplayUserName,
                // AcademyUserId: window.history.state.AcademyUserId,
                batchId: window.history.state.batchId,
                scheduleId: window.history.state.scheduleId,
                Redirect: window.history.state.Redirect,
                UserListData: window.history.state.UserListData
              }
            })
            // const dialogRef = this.dialog.open(StudentfeestypeComponent, {
            //   width: '100%',
            //   height: '100%',
            // });

            // this.getplayerdetail();
            this.dialogRef.close();

          }
          this.spinner.hide();
        },
          (error) => {
            this.spinner.hide();
          });
    }

  }

  getplayerdetail() {
    this.getlistservice.GetFeesDetailsByFeesTypeId(this.userdata.Id, -1, this.userdata.SubBatchId).subscribe((response: any) => {
      this.feedetail = response;
      // console.log(this.feedetail);

      if (this.feedetail != null) {
        if (this.feedetail.length > 0) {
          var CountOutstanding = 0;
          for (var i = 0; i < this.feedetail.length; i++) {
            var _outstandingAmount = this.feedetail[i].AppOutstanding;
            if (_outstandingAmount > 0) {
              CountOutstanding++;
            }
          }

          if (CountOutstanding > 0) {
            const dialogRef = this.dialog.open(StudentfeestypeComponent, {
              width: '100%',
              height: '100%',
            });
            // this.router.navigate(['studenttype'])

          }
          else {

            this.router.navigate(['studentmodule/renewsubscription'], {
              state: { example: this.userdata }
            })
          }
        }
      }
    })
  }
}

