import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from 'src/app/service/commonfunction';
import { GetListService } from 'src/app/service/getlist.service';
import { DashboardParam } from '../../_models/user.model';
import { UntypedFormGroup, Validators, FormBuilder, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-edit-training-model',
  templateUrl: './edit-training-model.component.html',
  styleUrls: ['./edit-training-model.component.scss']
})
export class EditTrainingModelComponent implements OnInit {
  userdata: any;
  playername: any;
  set: any;
  dashboardParam: DashboardParam;
  profileForm = new UntypedFormGroup({
    Weight: new UntypedFormControl(''),
    Repetition: new UntypedFormControl(''),
    userId: new UntypedFormControl(''),
    Comment: new UntypedFormControl(''),
  });

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditTrainingModelComponent>,
    public route: ActivatedRoute,
    public getlistservice: GetListService,
    public commonFunction: CommonFunction,
    private router: Router,

  ) { }

  ngOnInit(): void {
    this.setformvalue();
    
    this.playername = this.data.player;
    if (this.data.set == 1) {
      this.set = this.data.set + "st";
    }
    if (this.data.set == 2) {
      this.set = this.data.set + "nd";
    }
    if (this.data.set == 3) {
      this.set = this.data.set + "rd";
    }
    if (this.data.set > 3) {
      this.set = this.data.set + "th";
    }
  }

  Save(profileForm: UntypedFormGroup) {
    this.dialogRef.close(profileForm.value);
  }

  close() {
    this.dialogRef.close(null);
  }

  setformvalue() {
    this.profileForm.patchValue({
      Weight: this.data.weight,
      Repetition: this.data.repetition,
      userId: this.data.userId,
      Comment: this.data.Comment
    });
  }

}