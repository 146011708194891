<div class="header header-fixed header-logo-center">
  <h2 class="header-title textcolor noback">Fees transaction</h2>
  <span (click)="Close()" class="header-icon header-icon-4 textcolor right15">Close</span>
</div>
<div class="page-content header-clear-medium popup-mainarea">
  <div class="user_Details">
    <ul class="formview m-0">
      <li>
        <div class="row">
          <div class="col-5 pr-0 font-14">Amount</div>
          <div class="col-7 font-18">{{Amount}}</div>
        </div>
      </li>
      <li *ngIf="TaxableAmount!=0&&TaxName!=''&&TaxableAmount!=0">
        <div class="row">
          <div class="col-5 pr-0 font-14">{{TaxName}}@ {{TaxPercent}}%</div>
          <div class="col-7 font-18">{{TaxableAmount}}</div>
        </div>
      </li>
      <li>
        <div class="row">
          <div class="col-5 pr-0 font-14">Penalty/Discount</div>
          <div class="col-7 font-18">{{Penalty_Discount}}</div>
        </div>
      </li>
      <li>
        <div class="row">
          <div class="col-5 pr-0 font-14">Total Amount</div>
          <div class="col-7 font-18">{{TotalAmount}}</div>
        </div>
      </li>
      <li>
        <div class="row">
          <div class="col-5 pr-0 font-14">Outstanding</div>
          <div class="col-7 font-18">{{OutstandingAmount}}</div>
        </div>
      </li>

      <ng-container *ngIf="FeesType==''||FeesType==null||FeesType==undefined">
        <li>
          <div class="row">
            <div class="col-5 pr-0 font-14">Sub Start Date</div>
            <div class="col-7 font-18">{{SubStartDate | date:'d MMM yyyy'}}</div>
          </div>
        </li>
        <li>
          <div class="row">
            <div class="col-5 pr-0 font-14">Sub End Date</div>
            <div class="col-7 font-18">{{SubEndDate |date:'d MMM yyyy'}}</div>
          </div>
        </li>
        <li *ngIf="Monthplan==1">
          <div class="row">
            <div class="col-5 pr-0 font-14">Month Plan</div>
            <div class="col-7 font-18">1 Months</div>
          </div>
        </li>
        <li *ngIf="Monthplan==2">
          <div class="row">
            <div class="col-5 pr-0 font-14">Month Plan</div>
            <div class="col-7 font-18">3 Months</div>
          </div>
        </li>
        <li *ngIf="Monthplan==3">
          <div class="row">
            <div class="col-5 pr-0 font-14">Month Plan</div>
            <div class="col-7 font-18">6 Months</div>
          </div>
        </li>
        <li *ngIf="Monthplan==4">
          <div class="row">
            <div class="col-5 pr-0 font-14">Month Plan</div>
            <div class="col-7 font-18">12 Months</div>
          </div>
        </li>
      </ng-container>


      <li>
        <div class="row">
          <div class="col-5 pr-0 font-14">Extension</div>
          <div class="col-7 font-18">{{ExtensionDays}} days</div>
        </div>
      </li>
      <li>
        <div class="row">
          <div class="col-5 pr-0 font-14">Payment Date</div>
          <div class="col-7 font-18">{{PaymentDate | date:'d MMM yyyy'}}</div>
        </div>
      </li>

      <li>
        <div class="row">
          <div class="col-5 pr-0 font-14">Mode of Payment</div>
          <div class="col-7 font-18">
            <div class="col-12 font-18">{{PaymentDate | date:'d MMM yyyy'}}</div>
              <div class="col-12 font-18" *ngIf="ModeOfPayment==1">Cash</div>
              <div class="col-12 font-18" *ngIf="ModeOfPayment==2">Online Payment</div>
              <div class="col-12 font-18" *ngIf="ModeOfPayment==3">Cheque</div>
              <div class="col-12 font-18" *ngIf="ModeOfPayment==4">Draft</div>
              <div class="col-12 font-18" *ngIf="ModeOfPayment==5">NEFT</div>
              <div class="col-12 font-18" *ngIf="ModeOfPayment==7">Bank Transfer</div>
              <div class="col-12 font-18" *ngIf="ModeOfPayment==8">Benefit Pay</div>
          </div>
        </div>
      </li>

      <li *ngIf="ModeOfPayment==1">
        <div class="row">
          <div class="col-5 pr-0 font-14">Received by</div>
          <div class="col-7 font-18">{{ReceivedBy}}</div>
        </div>
      </li>
      <li *ngIf="ModeOfPayment==2">
        <div class="row">
          <div class="col-5 pr-0 font-14">Transaction Id</div>
          <div class="col-7 font-18">{{TransactionId}}</div>
        </div>
      </li>
      <ng-container *ngIf="ModeOfPayment==3">
        <li>
          <div class="row">
            <div class="col-5 pr-0 font-14">Bank Name</div>
            <div class="col-7 font-18">{{BankName}}</div>
          </div>
        </li>
        <li>
          <div class="row">
            <div class="col-5 pr-0 font-14">Cheque No.</div>
            <div class="col-7 font-18">{{ChequeNumber}}</div>
          </div>
        </li>
      </ng-container>

      <ng-container *ngIf="ModeOfPayment==4">
        <li>
          <div class="row">
            <div class="col-5 pr-0 font-14">Bank Name</div>
            <div class="col-7 font-18">{{BankName}}</div>
          </div>
        </li>
        <li>
          <div class="row">
            <div class="col-5 pr-0 font-14">Draft No</div>
            <div class="col-7 font-18">{{DraftNumber}}</div>
          </div>
        </li>
      </ng-container>


      <ng-container *ngIf="ModeOfPayment==5">
        <li>
          <div class="row">
            <div class="col-5 pr-0 font-14">Bank Name</div>
            <div class="col-7 font-18">{{BankName}}</div>
          </div>
        </li>
        <li>
          <div class="row">
            <div class="col-5 pr-0 font-14">Transaction Id</div>
            <div class="col-7 font-18">{{TransactionId}}</div>
          </div>
        </li>
      </ng-container>

      <ng-container *ngIf="ModeOfPayment==7">
        <li>
          <div class="row">
            <div class="col-5 pr-0 font-14">Received by</div>
            <div class="col-7 font-18">{{ReceivedBy}}</div>
          </div>
        </li>
        <li>
          <div class="row">
            <div class="col-5 pr-0 font-14">Bank Name</div>
            <div class="col-7 font-18">{{BankName}}</div>
          </div>
        </li>
      </ng-container>
      <ng-container *ngIf="FeesType=='Kit fees'">
        <li>
        <div class="row">
            <div class="col-5 pr-0 font-14 light-gray">Kit Type</div>
            <div class="col-7 font-18">{{KitType}}</div>
          </div>
        </li>
      </ng-container>

      <ng-container *ngIf="ModeOfPayment==8">
        <li>
          <div class="row">
            <div class="col-5 pr-0 font-14">Received by</div>
            <div class="col-7 font-18">{{ReceivedBy}}</div>
          </div>
        </li>
        <li>
          <div class="row">
            <div class="col-5 pr-0 font-14">Bank Name</div>
            <div class="col-7 font-18">{{BankName}}</div>
          </div>
        </li>
        <li>
          <div class="row">
            <div class="col-5 pr-0 font-14">Transaction Id</div>
            <div class="col-7 font-18">{{TransactionId}}</div>
          </div>
        </li>
      </ng-container>
      <ng-container *ngIf="Role==1">
        <li>
          <div class="row">
            <div class="col-5 pr-0 font-14">Admin Approval</div>
            <ng-container *ngIf="!FillStatus">
            <div class="col-7 font-18" *ngIf="AdminApproveDisplay==0">Pending<br /><span class="red" (click)="AdminApproval()" id="Approvelink">Click here to approve</span></div>
            <div class="col-7 font-18" *ngIf="AdminApproveDisplay==1">Approved</div>
            <div class="col-7 font-18" *ngIf="AdminApproveDisplay==2">Rejected</div>
           </ng-container>
           <ng-container *ngIf="FillStatus">
            <ng-container *ngIf="ApprovedStatus">
              <div class="col-7 font-14" *ngIf="AdminApproveDisplay==1">Approved</div>
            </ng-container>
            <ng-container *ngIf="RejectStatus">
              <div class="col-7 font-14" *ngIf="AdminApproveDisplay==1">Rejected</div>
          </ng-container>
           </ng-container>
          </div>
        </li>
      </ng-container>
      <li>
        <div class="row">
          <div class="col-5 pr-0 font-14">Transcation Details</div>
          <div class="col-7 font-18">{{TransactionDetails}}</div>
        </div>
      </li>
      <li>
        <div class="row">
          <div class="col-12" style="padding: 0 15px;">
            <button type="button" (click)="EmailInvoice();" class="btn btn-block loginheadercolor textcolor">Email InVoice</button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>