<div class="popup-header header-logo-center loginheadercolor">
  <div class="header-title textcolor noback">Select option</div>
</div>
<div class="page-content pb-0">
  <ul class="filter-popup">
    <span (click)="AtoZclick($event)">
      <li class="col-12" id="atoz" value="1">A-Z</li>
    </span>
    <span (click)="Inquirydateclick($event)">
      <li class="col-12" id="Inquirydate" value="2">Inquiry Date</li>
    </span>
    <span (click)="Inquirystatusclick($event)">
      <li class="col-12" id="Inquirystatus" value="3">Inquiry Status</li>
    </span>
    <li class="col-12 text-center red"><img width="16" height="16" src="../../../assets/images/KridApp-Image/close.png" alt=""></li>
  </ul>
</div>