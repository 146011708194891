<div id="page" class="ima">
    <div class="login-space">
        <img src="../../assets/images/KridApp-Image/login-logo.png" alt="KridApp" class="card ImageCenter">
        <br>
        <div class="container">
            <blockquote>
                <p class="TextDecorate">Kridapp for academy is the most comprehensive player gromming system.
                    Kridapp for academies is the complete application for sports schools. </p>
            </blockquote>
        </div>
        <!-- crousel start -->
        <div class="cover-slider owl-carousel owl-dots-no-footer text-center">
            <div data-card-height="cover" class="card bg-transparent">
                <div class="card-center">
                    <div class="content mt-n5">
                        <img class="mb-3 owlimagecenter w-img-250" alt="1"
                            src="../../assets/images/KridApp-Image/InfoFirst.png">
                    </div>
                </div>
            </div>
            <div data-card-height="cover" class="card bg-transparent">
                <div class="card-center">
                    <div class="content mt-n5">
                        <img class="mb-3 owlimagecenter w-img-180" alt="2"
                            src="../../assets/images/KridApp-Image/graphical_analysis.png">
                        <h1 class="font-15 textcolor text-center"> Detailed Graphical Analysis</h1>
                        <p class="color-highlight font-12 textcolor text-center ">Graphical Analysis of the Student
                            Progress.Cruial to develop future tarining programs</p>
                    </div>
                </div>
            </div>
            <div data-card-height="cover" class="card bg-transparent">
                <div class="card-center">
                    <div class="content mt-n5">
                        <img class="mb-3 owlimagecenter w-img-180" alt="3"
                            src="../../assets/images/KridApp-Image/graduate_student.png">
                        <h1 class="font-15 textcolor text-center"> Player Management</h1>
                        <p class="color-highlight font-12 textcolor text-center ">Capture basic details, detailed
                            analytics,Injury,awards,tournament performances of students</p>
                    </div>
                </div>
            </div>
            <div data-card-height="cover" class="card bg-transparent">
                <div class="card-center">
                    <div class="content mt-n5">
                        <img class="mb-3 owlimagecenter w-img-180" alt="4"
                            src="../../assets/images/KridApp-Image/graduate_student.png">
                        <h1 class="font-15 textcolor text-center">Sport Specific Parameters</h1>
                        <p class="color-highlight font-12 textcolor text-center">Define parameters for each sport.
                            periodic Reporting for these paramters for each students</p>
                    </div>
                </div>
            </div>
            <div data-card-height="cover" class="card bg-transparent">
                <div class="card-center">
                    <div class="content mt-n5">
                        <img class="mb-3 owlimagecenter w-img-180" alt="5"
                            src="../../assets/images/KridApp-Image/batch_management.png">
                        <h1 class="font-15 textcolor text-center">Batch Management</h1>
                        <p class="color-highlight font-12 textcolor text-center">Manage schedule of different
                            batches,Coaches,parents and administrator -keep everyone informed in realtime</p>
                    </div>
                </div>
            </div>
            <div data-card-height="cover" class="card bg-transparent">
                <div class="card-center">
                    <div class="content mt-n5">
                        <img class="mb-3 owlimagecenter w-img-150" alt="6"
                            src="../../assets/images/KridApp-Image/coach_management.png">
                        <h1 class="font-15 textcolor text-center">Coach Management</h1>
                        <p class="color-highlight font-12 textcolor text-center">Manage coaches with their expertise.
                            Assign batches to the course. coach can take the attendence and log detailed analytics of
                            their student.</p>
                    </div>
                </div>
            </div>
            <div data-card-height="cover" class="card bg-transparent">
                <div class="card-center">
                    <div class="content mt-n5">
                        <img class="mb-3 owlimagecenter w-img-250" alt="7"
                            src="../../assets/images/KridApp-Image/top_banner_kridapp.png">
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <ul class="ul">
                <li>Player Management</li>
                <li>Batch Management</li>
                <li>Coach Management</li>
                <li>Sport Specific Parameters</li>
                <li>Detailed Graphical Analysis</li>
                <li>Mobile and Web Access</li>
            </ul>
        </div>
        <p class="TextDecorate">
            Are you sports academy/school or a coach?
        </p>
        <div class="container">
            <div class="text-center contactusbutton">
                <button type="button" class="btn btn-outline-primary btn-bgborder text-primary">Contact-Us</button>
            </div>
        </div>
        <p class="TextDecorate">We have all the advanced features need for the improvement of the players and manageing
            your academy.
            We will be more then happy to provide your more details about application.</p>
    </div>

    <div class="splash-login">
        <button type="button" class="btn btn-primary btn-lg btn-block" [routerLink]="['/login']">Login</button>
    </div>
    <div class="clear"></div>

    <div class="footer-nav" *ngIf="isFooter=='1'">
        <p (click)="addToHomeScreen()"><img class="mr-1 w-img-24"
                src="../../assets/images/KridApp-Image/homescreen-logo.png" alt="">Add Kridapp to Home Screen</p> <span
            (click)="close()"><img width="12" height="12" src="../../../assets/images/icons/hometime.png" alt="" /></span>
    </div>
</div>