import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BatchPlanningComponent } from './batch-planning.component';
import { BatchPlanningDatesComponent } from './BatchPlanningDates/batch-planning-dates/batch-planning-dates.component';
import { CreateBatchPlanningComponent } from './create-batch-planning/create-batch-planning/create-batch-planning.component';
const routes: Routes = [
  {
    path: '',
    component: BatchPlanningComponent,
  },
  {
    path: 'batch-planning-dates',
    component: BatchPlanningDatesComponent
  },
  {
    path: 'create-batch-planning',
    component: CreateBatchPlanningComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BatchPlanningRoutingModule { }
