import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from 'src/app/service/commonfunction';
import { GetListService } from 'src/app/service/getlist.service';
import { DashboardParam } from '../../_models/user.model';

@Component({
  selector: 'app-dashboarddotpopup',
  templateUrl: './dashboarddotpopup.component.html',
  styleUrls: ['./dashboarddotpopup.component.scss'],
})
export class DashboarddotpopupComponent implements OnInit {
  userdata: any;
  dashboardParam: DashboardParam;
  Attendance;
  CoachAttendance;
  Drills;
  Fees;
  Inquiry;
  Student;
  SlotName;
  SlotName1;
  SlotName2;
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DashboarddotpopupComponent>,
    public route: ActivatedRoute,
    public getlistservice: GetListService,
    public commonFunction: CommonFunction,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userdata = this.commonFunction.GetUserData();
    let data = {
      academyId: this.userdata.AcademyId,
      academyName: this.data.val.AcademyName,
      batchId: this.data.val.BatchId,
      batchName: this.data.val.BatchName,
      parentBatchId: this.data.val.ParentBatchId,
      parentBatchName: this.data.val.ParentBatchName,
      SlotName: this.data.val.SlotName,
      SlotName1: this.data.val.SlotName1,
      SlotName2: this.data.val.SlotName2,

    };

    this.dashboardParam = data;
    this.Attendance = this.data.popuprights.Attendance;
    this.CoachAttendance = this.data.popuprights.CoachAttendance;
    this.Drills = this.data.popuprights.Drills;
    this.Fees = this.data.popuprights.Fees;
    this.Inquiry = this.data.popuprights.Inquiry;
    this.Student = this.data.popuprights.Student;
    this.SlotName = this.data.val.SlotName;
    this.SlotName1 = this.data.val.SlotName1;
    this.SlotName2 = this.data.val.SlotName2;
  }

  clickOption(val) {
    if (val == 1) {
      this.router.navigate(['/attendance'], {
        state: { example: this.dashboardParam }
      });
      this.close();
      //For get data
    } else if (val == 2) {
      //Student
      this.router.navigate(['/studentmodule/list'], {
        state: { example: this.dashboardParam }
      });
      this.close();
    } else if (val == 3) {
      //Coach Attendance
      //academyId, academyName, parentBatchId, batchId, parentBatchName, batchName
      this.router.navigate(['/coachattendance/coachview'], {
        state: { example: this.dashboardParam }
      });
      this.close();
    } else if (val == 4) {
      //Drills
      this.router.navigate(['/drills/drillview'], {
        state: { example: this.dashboardParam }
      });
      this.close();
    } else if (val == 5) {
      //Inquiry
      this.router.navigate(['/inquiry'], {
        state: { example: this.dashboardParam },
      });
      this.close();
    } else if (val == 6) {
      var stateparam = {
        academyId: this.dashboardParam.academyId,
        academyName: this.dashboardParam.academyName,
        batchId: this.dashboardParam.parentBatchId,
        batchName: this.dashboardParam.batchName,
        scheduleId: this.dashboardParam.batchId,
        parentBatchName: this.dashboardParam.parentBatchName
      };
      this.router.navigate(['/userlist'], {
        state: stateparam,
      });
      this.close();
    }
  }

  openslotattendance(slot) {
    this.dashboardParam.SlotName = slot;
    this.router.navigate(['/attendance'], {
      state: { example: this.dashboardParam }
    });
    this.close();
  }

  close() {
    this.dialogRef.close();
  }
}
