<div id="page">
    <div class="header header-fixed header-logo-center">
        <h2 class="header-title textcolor noback">Select Academy</h2>
    </div>
    <div class="page-content header-clear-medium">
        <form [formGroup]="SelectAcademyForm" (ngSubmit)="SelectAcademySubmit()">
            <div class="content">
                <div class="inputsection" *ngIf="AcademyLengthSize>1">
                    <select class="form-control" formControlName="AcademyId" (change)="AcademyChange($event);">
                        <option *ngFor="let item of GetAcademicList" value="{{item.Id}}">{{item.Name}}</option>
                    </select>
                </div>
                <div class="inputsection" *ngIf="RoleLengthSize>1">
                    <span class="input-title font-14">Select Role</span>
                    <select class="form-control" formControlName="RoleTypeId">
                        <option *ngFor="let item of GetRoleList" value="{{item.RoleType}}">{{item.Role}}</option>
                    </select>
                </div>
                <img style="display: none;" width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
                <img style="display: none;" class="editiconbtn" alt="" src="../../../assets/images/KridApp-Image/editicon.png"
                >
                <div class="Continuebtn">
                    <button type="submit" class="btn-block loginheadercolor textcolor">Submit</button>
                </div>
            </div>
        </form>
    </div>
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"><p class="color-white"> Loading... </p></ngx-spinner>
</div>