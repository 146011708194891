import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from 'src/app/service/commonfunction';
import { GetListService } from 'src/app/service/getlist.service';
import {RejectreasonComponent} from '../rejectreason/rejectreason.component';
@Component({
  selector: 'app-dotpopup',
  templateUrl: './dotpopup.component.html',
  styleUrls: ['./dotpopup.component.scss'],
})
export class DotpopupComponent implements OnInit {
  sortfiltervalue: any;

  isStatus = true;
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DotpopupComponent>,
    public route: ActivatedRoute,
    public getlistservice: GetListService,
    public commonFunction: CommonFunction,
    public router: Router
  ) {}

  ngOnInit(): void {
    if (this.data.val.Status == 4) {
      this.isStatus = false;
    }
  }

  inquiryOption(val) {

    if(val==2)
    {
      this.dialogRef.close();
      this.router.navigate(['/userassessment/userreportinfo'], {
        state: { example: this.data.val,FromInquiry:true,IsAdd:true}
      });
    }

    else if(val==4)
    {
      this.dialogRef.close();
      this.openrejectreason();
    }
    else
    {
      this.dialogRef.close({ InquiryOption:val,data:this.data})
    }
  }

  openrejectreason(): void {
    const dialogRef = this.dialog.open(RejectreasonComponent, {
      width: '100%',
      height: '100%',
      data: {
        data:this.data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  close() {
    this.dialogRef.close();
  }
}
