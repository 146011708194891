import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import $ from 'jquery'

@Component({
  selector: 'app-popupcomments',
  templateUrl: './popupcomments.component.html',
  styleUrls: ['./popupcomments.component.scss']
})
export class PopupcommentsComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopupcommentsComponent>,
    public route: ActivatedRoute) {
  }

  ngOnInit(): void {
    if(this.data.val.Comments==undefined)
    {
      $('#txt_comments').val(this.data.val.CategoryComments);
    }
    else{
      $('#txt_comments').val(this.data.val.Comments);
    }
  }
  SaveComments() {
    let val = $('#txt_comments').val();
    this.dialogRef.close(val);
  }
  closemodel(){
    this.dialogRef.close();
  }
}
