<div class="header header-fixed header-logo-center">
    <h2 class="header-title textcolor">Login with OTP</h2>
    <span (click)="closemodel()" class="header-icon header-icon-1 textcolor">
      <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
    </span>
    <div class="titlearea">
      <p class="textcolor mt-3">We will send a code (vai SMS text message) to you phone number.</p>
    </div>
  </div>
  <div class="page-content header-clear-medium popup-mainarea">
    <form>
      <div class="content">
          <div class="w-100">
            <input class="form-control" id="OtpPhoneNumber" placeholder="Mobile Number or Email" type="text">
          </div>
      </div>
      <div class="Continuebtn">
        <button type="button" (click)="Phonenumbercheck()" class="btn-block loginheadercolor textcolor">Send</button>
      </div>
    </form>
  </div>