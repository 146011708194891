import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlatpickrModule } from 'angularx-flatpickr';
import { TrainingRoutingModule } from './training-routing.module';
import { TrainingComponent } from './training.component';
import { TrainingPopupComponent } from './training-popup/training-popup.component';
import { PlayerTrainingComponent } from './player-training/player-training.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EditTrainingModelComponent } from './edit-training-model/edit-training-model.component';
import { SelectDrillPopupComponent } from './select-drill-popup/select-drill-popup.component';
import { NgxSpinnerModule } from "ngx-spinner";


@NgModule({
  declarations: [TrainingComponent, TrainingPopupComponent, PlayerTrainingComponent, EditTrainingModelComponent, SelectDrillPopupComponent],
  imports: [
    CommonModule,
    TrainingRoutingModule,
    FlatpickrModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
  ]
})
export class TrainingModule { }
