<div class="header header-fixed header-logo-center" id="popup1">
  <span (click)="closemodel()" class="header-icon header-icon-1 textcolor">
    <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
  </span>
  <h2 class="header-title textcolor">Batch(es)</h2>
  <span class="header-icon header-icon-4 textcolor right15" (click)="openStudentlistModal()">Next</span>
</div>
<div *ngIf="MultipleBatchSchedule==true">
  <div class="page-content header-clear-medium detail selectbatch popup-mainarea">
    <div class="card batchlist card-style" *ngFor="let item1 of GetBatchesListNew; index as i">
      <div class="content title relative">
        <a data-menu="ad-timed-2" class="w-100"><strong>{{item1.BatchName}}</strong></a>
      </div>
      <ng-container *ngFor="let item of GetScheduleList; index as i">
        <ng-container *ngIf="item1.Id==item.ParentBatchId">
          <label class="pt-3 pb-3 content" (click)="scheduleDivclick(item.Id)">
            <div class="d-flex align-items-center" id="checkboxdiv">
              <ng-container *ngIf="item.checked==true">
                <input type="checkbox" checked class="getAllcheckBoxValue" name="{{item.BatchName}}" value={{item.Id}}
                  id="schchchk_{{item.Id}}" />
                {{item.BatchName}}
              </ng-container>
              <ng-container *ngIf="item.checked==false">
                <div class="container">
                  <div class="d-flex align-items-center">
                    <input type="checkbox" class="ml-1 mr-2 getAllcheckBoxValue" name="{{item.BatchName}}" value={{item.Id}}
                      id="schchchk_{{item.Id}}" />
                    {{item.BatchName}}
                  </div>
                  <div id="DropDown_{{item.Id}}" class="dropdown" hidden>
                    <select name="Batch" id="slotvalue" (change)="selectChangeHandler($event)" class="form-control">
                      <option *ngFor="let item of slotlist" value="{{item}}">
                        {{item}}</option>
                    </select>
                  </div>
                </div>
              </ng-container>
            </div>
          </label>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<div *ngIf="MultipleBatchSchedule==false">
  <div class="page-content header-clear-medium detail selectbatch popup-mainarea">
    <div class="card batchlist card-style" *ngFor="let item1 of GetBatchesListNew; index as i">
      <div class="content title relative">
        <a data-menu="ad-timed-2" class="w-100"><strong>{{item1.BatchName}}</strong></a>
      </div>
      <ng-container *ngFor="let item of GetScheduleList; index as i">
        <ng-container *ngIf="item1.Id==item.ParentBatchId">
          <label class="pt-3 pb-3 content" (click)="scheduleDiv(item.Id)">
            <div class="d-flex align-items-center">
              <ng-container *ngIf="item.checked==true">
                <input type="checkbox" checked class="getAllcheckBoxValue" name="{{item.BatchName}}" value={{item.Id}}
                  id="schchchk_{{item.Id}}" />
                {{item.BatchName}}
              </ng-container>
              <ng-container *ngIf="item.checked==false">
                <div class="container">
                  <div class="d-flex align-items-center">
                    <input type="checkbox" class="ml-1 mr-2 getAllcheckBoxValue" name="{{item.BatchName}}" value={{item.Id}}
                      id="schchchk_{{item.Id}}" />
                    {{item.BatchName}}
                  </div>
                </div>
              </ng-container>
            </div>
          </label>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>