import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GetListService } from 'src/app/service/getlist.service';
import { PostService} from '../../service/post.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/service/notification.service';
@Component({
  selector: 'app-rejectreason',
  templateUrl: './rejectreason.component.html',
  styleUrls: ['./rejectreason.component.scss']
})
export class RejectreasonComponent implements OnInit {
  GetAcademyLeaveReasonList:any=[];
  ReasonFormGroup: UntypedFormGroup;
  constructor(
  @Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<RejectreasonComponent>,
  public getlistService : GetListService,
  private formBuilder: UntypedFormBuilder,
  public postservice : PostService,
  private spinner: NgxSpinnerService,
  private router: Router,
  public notifyService:NotificationService,
  ){}

  ngOnInit(): void {
    this.GetAcademyLeaveReason();
    this.initform();
    this.ReasonFormGroup.patchValue({
      AcademyStudentId:this.data.data.val.Id,
    });
  }
  initform()
  {
    this.ReasonFormGroup = this.formBuilder.group({
      AcademyStudentId: new UntypedFormControl(''),
      FromDate: new UntypedFormControl(''),
      ToDate:new UntypedFormControl(''),
      LeaveReason:new UntypedFormControl(''),  
      UserId:new UntypedFormControl(''),
      DropoutReasonId:new UntypedFormControl('',[Validators.required])
    });
  }

  public GetAcademyLeaveReason() {
    this.getlistService
      .GetAcademyLeaveReason(this.data.data.val.AcademyId)
      .subscribe((response: any) => {
        this.GetAcademyLeaveReasonList = response;
      },
        (error) => {
        });
  }
  public GetAcademyUserLastPaidFeesDetail() {
    this.getlistService
      .GetAcademyUserLastPaidFeesDetail(this.data.val.Id)
      .subscribe((response: any) => {
        this.GetAcademyLeaveReasonList = response;
        this.ReasonFormGroup.patchValue({
          FromDate:response.FromDate,
          ToDate:response.ToDate,
        });

      },
        (error) => {
        });
  }
  close() {
    this.dialogRef.close();
  }

  ReasonSubmit()
  {
    if (this.ReasonFormGroup.invalid) {
      this.notifyService.showError("Please Select Reason.", "");
      return;
    }
    var academyCancelAdmission=
    {
      AcademyUserId:this.ReasonFormGroup.value.AcademyStudentId,
      AcademyStudentId:this.ReasonFormGroup.value.UserId,
      FromDate:this.ReasonFormGroup.value.FromDate,
      ToDate:this.ReasonFormGroup.value.ToDate,
      DropoutReasonId:this.ReasonFormGroup.value.DropoutReasonId,
      Reason:this.ReasonFormGroup.value.LeaveReason
    };

    this.spinner.show();
    this.postservice
      .CancelAdmission(academyCancelAdmission)
      .subscribe((response: any) => {
        if(response==undefined||response==""||response==null)
        {
          this.dialogRef.close();
          this.UpdateInquiryStatus();
          this.spinner.hide();
          this.router.navigate(['/inquiry'])
            .then(() => {
              window.location.reload();
            });
        }
      },
        (error) => {
          this.spinner.hide();
        });
  }

  public UpdateInquiryStatus() {
    this.postservice
      .UpdateInquiryStatus(3,this.data.data.val.Id)
      .subscribe((response: any) => {
        this.GetAcademyLeaveReasonList = response;
        this.ReasonFormGroup.patchValue({
          FromDate:response.FromDate,
          ToDate:response.ToDate,
        });

      },
        (error) => {
        });
  }
}
