import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog,MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-popupdrill',
  templateUrl: './popupdrill.component.html',
  styleUrls: ['./popupdrill.component.scss']
})
export class PopupdrillComponent implements OnInit {
  constructor(
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<PopupdrillComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit(): void {
  }
  closemodel(){
    this.dialogRef.close()
    this.spinner.hide();
  }
  done(){
    let val={
      t1:'aaa',
      t2:'bbb'
    }
    this.dialogRef.close({data:val})
    this.spinner.hide();
  }

}
