import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoachattendanceRoutingModule } from './coachattendance-routing.module';
import { CoachattendanceadminComponent } from './coachattendanceadmin/coachattendanceadmin.component';
import { CoachattendancecoachComponent } from './coachattendancecoach/coachattendancecoach.component';
import {ReactiveFormsModule,FormsModule} from '@angular/forms';
import { PopupcoachattendanceComponent } from './popupcoachattendance/popupcoachattendance.component';
import { FlatpickrModule } from "angularx-flatpickr";
import { NgxSpinnerModule } from "ngx-spinner";
import {
  MatDatepickerModule
} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [CoachattendanceadminComponent, CoachattendancecoachComponent, PopupcoachattendanceComponent],
  imports: [
    MatNativeDateModule,
    MatDatepickerModule,
    NgxSpinnerModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    CoachattendanceRoutingModule,
    FlatpickrModule.forRoot()
  ]
})
export class CoachattendanceModule { }
