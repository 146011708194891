import { Component, OnInit } from '@angular/core';
import { CommonFunction } from '../../service/commonfunction';
import { GetListService } from '../../service/getlist.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PopupfeedbackComponent } from 'src/app/attendance/popupfeedback/popupfeedback.component';

@Component({
  selector: 'app-private-atten-stdlist',
  templateUrl: './private-atten-stdlist.component.html',
  styleUrls: ['./private-atten-stdlist.component.scss']
})
export class PrivateAttenStdlistComponent implements OnInit {

  seletddateforattendance: any;
  public GetStudentList: any[];
  public studentCount: any;
  StudentFeedBackArray: any = [];
  LeaveTypeListSArray: any = [];
  LeaveShortName: any;
  GetData: any;
  userdata: any;
  UserId: any;

  constructor(
    public commonFunction: CommonFunction,
    public getListService: GetListService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }
  public studentList(id: any, attendanceDate: any) {
    this.userdata = this.commonFunction.GetUserData();
    this.UserId = this.userdata.UserId;
    this.spinner.show();
    this.getListService
      .GetUserListOfCoach(this.UserId)
      .subscribe((response: any) => {
        this.GetStudentList = response;
        this.studentCount = response.length;
        let count = 0;
        for (let i = 0; i < response.length; i++) {
          if (response[i].LeaveTypeId == 0) {
            count++;
          }
        }

        if (this.studentCount == count) {
          var element = <HTMLInputElement>document.getElementById("selectAllCheck");
          element.checked = true;
        }
        else {
          var element = <HTMLInputElement>document.getElementById("selectAllCheck");
          element.checked = false;
        }

        for (let i = 0; i < response.length; i++) {
          this.StudentFeedBackArray.push({
            BatchId: response[i].AcademyBatchId,
            UserId: response[i].UserId,
            Homework: response[i].PrivateHomework,
            Diet: response[i].PrivateDiet,
            Comments: response[i].PrivateComments,
            Temperature: response[i].Temperature,

            PrivateHomework: response[i].PrivateHomework,
            PrivateDiet: response[i].PrivateDiet,
            PrivateComments: response[i].PrivateComments,


          });

          this.LeaveTypeListSArray.push({
            UserId: response[i].UserId,
            Id: response[i].LeaveTypeId,
            LeaveShortName: response[i].LeaveShortName
          });
        }
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
        });
  }
  opencommonfeedbackModal(): void {
    let StudentModel = {
      StudentFeedBackArray: this.StudentFeedBackArray,
      LeaveTypeListSArray: this.LeaveTypeListSArray
    }
    this.spinner.hide();

    const dialogRef = this.dialog.open(PopupfeedbackComponent, {
      width: '100%',
      height: '100%',
      data: {
        studentList: StudentModel,
        studentCount: this.studentCount,
        AttendanceDate: this.GetData.AttendanceDate,
        BatchId: this.GetData.BatchId,
        Latitude: this.GetData.Latitude,
        Longitude: this.GetData.Longitude

      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}