<div id="page" (click)="onremoveclick()">
    <!-- Header -->
    <div class="header header-fixed header-logo-center">
        <!-- <span (click)="closemodel()" data-menu="menu-sidebar-left-1" class="header-icon header-icon-1 textcolor">
            <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
        </span> -->
        <h2 class="header-title textcolor noback">Compose Message</h2>
        <span (click)="closemodel()" class="header-icon header-icon-4 textcolor right15">Close</span>
    </div>
    <!-- Header -->

    <div id="footer-bar" class="d-flex pl-2">
        <div class="mr-3 speach-icon">
            <a data-menu="menu-upload" class="bg-blue2-dark ml-2 align-items-center d-flex justify-content-center">
                <img width="18" height="18" src="../../../assets/images/icons/plus.png" alt="">
            </a>
        </div>
        <div class="flex-fill speach-input">
            <input type="text" class="form-control" placeholder="Enter your Message here">
        </div>
        <div class="ml-3 speach-icon">
            <a class="bg-blue2-dark align-items-center d-flex justify-content-center">
                <img width="18" height="18" src="../../../assets/images/icons/arrow-right.png" alt="" />
            </a>
        </div>
    </div>
    <!-- Main Content -->
    <div class="page-content header-clear-medium">
        <div class="content">

        </div>

        <!-- Footer (ngSubmit)=-->
        <form [formGroup]="ChatFormGroup">
            <div id="footer-bar" class="d-flex pl-2">
                <div class="flex-fill speach-input">
                    <input type="text" class="form-control" formControlName="textmessage"
                        placeholder="Enter your Message here">
                </div>
                <div class="ml-3 speach-icon">
                    <a type="submit" (click)="MessagesSubmit(true)" class="bg-blue2-dark align-items-center d-flex justify-content-center">
                        <img width="18" height="18" src="../../../assets/images/icons/arrow-right.png" alt="" />
                    </a>
                </div>
            </div>
        </form>
        <!-- Footer -->
    </div>
</div>