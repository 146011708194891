<div class="header header-fixed header-logo-center">
    <span (click)="closemodel()" class="header-icon header-icon-1 textcolor">
        <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
    </span>
    <h2 class="header-title textcolor">Select Drill</h2>
    <span (click)="closemodel()" class="header-icon header-icon-4 textcolor right15">Done</span>
</div>
<div class="page-content header-clear-medium popup-mainarea">

</div>
