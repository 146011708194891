import { Component, Inject, OnInit } from '@angular/core';
import { NotificationService } from '../../service/notification.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { GetListService } from '../../service/getlist.service';
import { CommonFunction } from '../../service/commonfunction';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import {PostService} from '../../service/post.service';
import { ConfirmationDialogComponent } from 'src/app/common/confirmation-dialog/confirmation-dialog.component';
import $ from "jquery";

@Component({
  selector: 'app-popupcoachattendance',
  templateUrl: './popupcoachattendance.component.html',
  styleUrls: ['./popupcoachattendance.component.scss'],
})
export class PopupcoachattendanceComponent implements OnInit {
  pipe = new DatePipe('en-US');
  attendDate = new Date();
  abId = [];
  prId = [];
  isBatchFound: boolean;
  isBatchNotFound: boolean;
  SubmitHideShow:boolean=false;
  batchList = [];
  constructor(
    public dialogRef: MatDialogRef<PopupcoachattendanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonFunction: CommonFunction,
    public getListService: GetListService,
    private spinner: NgxSpinnerService,
    public datePipe: DatePipe,
    public dialog: MatDialog,
    public postService: PostService,
    private notifyService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.GetBatchListbyCoachAttendance()
  }
  chkevent(type, val, data) {
    if (type == 1) {
      $('#presentcoach_' + data).prop('checked', false);
    } else {
      $('#absentcoach_' + data).prop('checked', false);
    }
  }
  GetBatchListbyCoachAttendance() {
    var GetUserData = this.commonFunction.GetUserData();
    let d = this.attendDate;
    var todayDate =this.datePipe.transform(this.attendDate,'yyyy-MM-dd');
    this.getListService
      .GetBatchListbyCoachAttendance(GetUserData.UserId, todayDate)
      .subscribe((response: any) => {
        if (response.length > 0) {
          this.isBatchFound = true;
          this.isBatchNotFound = false;
          let count=0;
          for(let i=0;i<response.length;i++)
          {
              if(response[i].PresentId==null)
              {
                count++;
              }
          }
          if(count>0)
          {
            this.SubmitHideShow=true;
          }
          else
          {
            this.SubmitHideShow=false;
          }
        }
        else {
          this.SubmitHideShow=false;
          this.isBatchFound = false;
          this.isBatchNotFound = true;
         
        }
        this.batchList = response;
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
        });

  }
  changedatefilter(val) {
    this.attendDate = val;
    this.GetBatchListbyCoachAttendance()


  }

  saveattendancecoach() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'attendancesave',
      data: {
        message: 'You will not be able to edit the attendance once saved. Are you sure you want to continue?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.saveattendancecoachpop()
        const a = document.createElement('a');
        a.click();
        a.remove();
      }
    });
  }

  done()
  {
    this.dialogRef.close();
  }
  saveattendancecoachpop() {
    this.prId = [];
    this.abId = [];
    this.spinner.show();
    var slides = document.getElementsByClassName("presentclass");
    for (var i = 0; i < slides.length; i++) {
      if (slides[i]['checked']) {
        if (slides[i]['disabled'] == false) {
          this.prId.push(slides[i]['classList'][0]);
        }
      }
    }

    var slides1 = document.getElementsByClassName("absentclass");
    for (var i = 0; i < slides1.length; i++) {
      if (slides1[i]['checked']) {
        if (slides[i]['disabled'] == false) {
          this.abId.push(slides1[i]['classList'][0]);
        }
      }
    }

    var GetUserData = this.commonFunction.GetUserData();
    let latitude = "";
    let longitude = "";
    var dateObj = new Date(this.attendDate);

    let month = dateObj.getMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    this.commonFunction.getPosition().then((pos) => {
      latitude = pos.lat;
      longitude = pos.lng;
      let data = {
        AcademyId: GetUserData.AcademyId,
        UserId: GetUserData.UserId,
        Day: day,
        Month: month,
        Year: year,
        Latitude: latitude,
        Longitude: longitude,
        AbsentBatchIds: this.abId.join(),
        PresentBatchIds: this.prId.join(),
      };
      if (data.AbsentBatchIds == "") {
        data.AbsentBatchIds = '0';
      }
      if (data.PresentBatchIds == "") {
        data.PresentBatchIds = '0';
      }
      this.postService
        .savecoachAttendance(data)
        .subscribe((response: any) => {
          this.dialogRef.close()
          this.notifyService.showSuccess("Attendance saved successfully!", "");
          this.spinner.hide();
        },
          (error) => {
            this.spinner.hide();
          });
    });
  }
  closemodel() {
    this.dialogRef.close();
  }
}
