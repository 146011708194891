import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { LoginComponent } from './login/login.component';
import { SelectAcademyComponent } from './select-academy/select-academy.component';
import { SideBarMenuComponent } from './side-bar-menu/side-bar-menu.component';
import { InquiryModule } from '../app/inquiry/inquiry.module';
import { InquiryComponent } from '../app/inquiry/inquiry.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { AttendanceModule } from './attendance/attendance.module';
import { PrivateattendancestdModule } from './privateattendancestd/privateattendancestd.module';
import { GetListService } from './service/getlist.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardModule } from './dashboard/dashboard.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LocationStrategy, HashLocationStrategy, DatePipe, } from "@angular/common";
import { UserassessmentModule } from './userassessment/userassessment.module'
import { NotificationService } from './service/notification.service';
import { FlatpickrModule } from "angularx-flatpickr";
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { environment } from '../environments/environment'
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxSpinnerModule } from "ngx-spinner";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CoachattendanceModule } from './coachattendance/coachattendance.module';
import { ManagefeesModule } from './managefees/managefees.module';
import { ConfirmationDialogComponent } from './common/confirmation-dialog/confirmation-dialog.component'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import {
  MatDatepickerModule
} from '@angular/material/datepicker';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { DashboarddotpopupComponent } from './common/dashboarddotpopup/dashboarddotpopup.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgChartjsModule } from 'ng-chartjs';
import { ChartdashboardComponent } from './common/chartdashboard/chartdashboard.component';
import { GoogleChartsModule } from 'angular-google-charts';

import { FeedbackComponent } from './feedback/feedback.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
// import { ChangePasswordService } from './service/change-password.service';
import { UsermessagesComponent } from './usermessages/usermessages.component';
import { PostService } from './service/post.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AlertComponent } from './AlertPopup/alert/alert.component';
import { SimpleModalModule } from 'ngx-simple-modal';
import { BatchPlanningModule } from './batch-planning/batch-planning.module';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxOneSignalModule } from 'ngx-onesignal';
import { NgxStripeModule } from 'ngx-stripe';
import { StripeComponent } from './stripe/stripe.component';
import { PayoninestripeComponent } from './studentfees/payoninestripe/payoninestripe.component';
import { SignupComponent } from './signup/signup.component';
import { HttpConfigInterceptor } from './httpConfig.interceptor';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { DashboardpersonalpopupComponent } from './common/dashboardpersonalpopup/dashboardpersonalpopup.component';
import { PrivateAttenStdlistComponent } from './common/private-atten-stdlist/private-atten-stdlist.component';
import { TrainingModule } from './training/training.module';
import { StripesuccessComponent } from './stripesuccess/stripesuccess.component';
import { StripefailureComponent } from './stripefailure/stripefailure.component';

let appId = "";
var data = JSON.parse(localStorage.getItem("academyData"));
// console.log(data);

if (data == null) {
}
else {
  appId = data.OneSignalId
  // console.log(appId)
}
const dbConfig: DBConfig = {
  name: 'MyDb',
  version: 1,
  objectStoresMeta: [{
    store: 'people',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'name', keypath: 'name', options: { unique: false } },
      { name: 'email', keypath: 'email', options: { unique: false } }
    ]
  }]
};
@NgModule({
  declarations: [
    AppComponent,
    SplashScreenComponent,
    LoginComponent,
    SelectAcademyComponent,
    SideBarMenuComponent,
    InquiryComponent,
    DashboardComponent,
    AttendanceComponent,
    ConfirmationDialogComponent,
    DashboarddotpopupComponent,
    ChartdashboardComponent,
    FeedbackComponent,
    ChangePasswordComponent,
    UsermessagesComponent,
    AlertComponent,
    StripeComponent,
    PayoninestripeComponent,
    SignupComponent,
    DashboardpersonalpopupComponent,
    PrivateAttenStdlistComponent,
    StripesuccessComponent,
    StripefailureComponent,

  ],
  imports: [
    Ng2SearchPipeModule,
    FormsModule,
    GoogleChartsModule,
    NgChartjsModule,
    Ng2GoogleChartsModule,
    NgxPayPalModule,
    MatDatepickerModule,
    MatSelectModule,
    MatDialogModule,
    InfiniteScrollModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    HttpClientModule,
    SimpleModalModule.forRoot({ container: "modal-container" }),
    ReactiveFormsModule,
    InquiryModule,
    UserassessmentModule,
    CoachattendanceModule,
    ManagefeesModule,
    DashboardModule,
    BatchPlanningModule,
    AttendanceModule,
    PrivateattendancestdModule,
    TrainingModule,

    // Testing tusharbhai // Beta use
    //NgxStripeModule.forRoot("pk_test_51I6cSNBYpgSDE2ab3W9jz9BTa55VEylsbhLX21GSgBsgIkzNU2LrPFQeSQesedvE0U8z4el3t7uLP9CnVUfRDG9V00Ehn9r0a6"),

    //Live ECPA
    NgxStripeModule.forRoot("pk_live_51IWgpSC7irhphaciaxa53tCnjsrUrYehweXnbLNDbsB56TmVckM4ZlkHDopbbjTILpu42MMiWoGZBcIsxJdbfzmE00ZPRF7feG"),

    //ECPA
    //NgxStripeModule.forRoot("pk_test_51IWgpSC7irhphaciw7ynMyjB57tZzQjPC8VTZf1XQOP2TNLKFKXXrnL3beq1fGbNpppSLg4NNtxuMAq7Z9lNOenY00LwGYevlA"),

    //siddharth
    //NgxStripeModule.forRoot("pk_test_51L16bESAPbYAeRWQgmKLW7C2FqDMy9wiu11KABP5WZcOeAeJoC53gNnQgYE6ILff9bBAujFaG7fjmZK3Wd4fbCSf00A7DU3it2"),

    FlatpickrModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-full-width',
      preventDuplicates: true,
      closeButton: true
    }),

    NgxOneSignalModule.forRoot({
      appId,
      allowLocalhostAsSecureOrigin: true,
      autoRegister: false,
      notifyButton: {
        enabled: false,
      },
    }),

    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
      //registrationStrategy: 'registerImmediately' 
      registrationStrategy: 'registerWhenStable:30000'

    }),



  ],
  providers: [NotificationService, DatePipe, GetListService, ToastrService, PostService, {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpConfigInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent],

})
export class AppModule { }