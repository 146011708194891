import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AttendanceComponent} from '../attendance/attendance.component';
import { ReasonlistComponent } from './reasonlist/reasonlist.component';
import { StudenteditlistComponent } from './studenteditlist/studenteditlist.component';

const routes: Routes = [
  {
    path : '',
    component: AttendanceComponent,
    children:[
      {
        path:'studentlist',
        component:StudenteditlistComponent
      },
      {
        path:'',
        component:ReasonlistComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AttendanceRoutingModule { }
